// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import rightArrow from '../../media/right-arrow.svg';

class NextSection extends Component {
    render() {
        let {nextTitle, nextDescription} = this.props;
        return (
            <button name="submit" type="submit" className="next-button" onClick={() => this.props.nextSectionConfirm()}>
                <div className="next-section">
                    <div className="next-up">
                        NEXT UP
                    </div>
                    <div className="section-description">
                        <h2>{nextTitle}</h2>
                        <p>{nextDescription}</p>
                    </div>
                    <div className="next-arrow">
                        <img className="arrow" src={rightArrow}/>
                    </div>
                </div>
            </button>
        );
    }
}

export default NextSection;
