// Required Dependencies
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import '../css/About.css';
import AboutCard from '../components/AboutCard.js';

import logolarge from '../media/logo_name_large.png';
import siddImage from '../media/Siddhant_Jain.jpg';
import songImage from '../media/Song_You.jpg';
import harryImage from '../media/Harry_Dong.jpg';
import arthurImage from '../media/Arthur_Nguyen-Cao.jpg';
import oliverImage from '../media/Oliver_Daniel.jpg';


class About extends Component {

    render() {

        return (
            <div>
                <div className="about-page">

                    <div className="mission-statement">
                    <div className="about-page-banner"/>
                        <h1>The biggest achievements need the best teams</h1>
                    </div>

                    <div className="jouncer-team">
                        <h1>We're the team behind Jouncer &#x1F60E;</h1>
                        <div className="team-cards">
                            <AboutCard id="b4ece008-b90f-424a-a8a2-4c754ed02af9" name="Siddhant Jain" bio="If you can't reach me, it's because I'm fighting a fake Italian for more parmesan" profileimage={siddImage} profilelink={""}/>
                            <AboutCard id="ebe370c5-f368-4afa-9d34-b3bb35ce6be1" name="Song You" bio="I make frontend developers write a lot of code" profileimage={songImage}/>
                            <AboutCard id="bb67780f-a912-4a76-9d6a-71260cf4417a" name="Harry Dong" bio="I sway in the wind like a palm tree. The happier I am the less I can see." profileimage={harryImage}/>
                            <AboutCard id="8cdea0aa-a41a-4a21-aa5f-474c1ac949da" name="Arthur Nguyen-cao" bio="Airplanes & partying are the shit" profileimage={arthurImage}/>
                            <AboutCard id="0d298769-f06c-4574-bc11-c372f5b1e7bb" name="Oliver Daniel" bio="Do I love theatre? Yes I do" profileimage={oliverImage}/>
                        </div>
                    </div>

                    <p className="jouncer-description">
                        Jouncer is more than a platform—it’s the group of people we’ve always wanted to find.
                        <br/><br/>
                        Like you, we’re creators and builders. We love to ideate and problem solve, we sweat the details, our desktops are messy with half-finished projects, and we also feel that incredible thrill when something works out better than how we imagined it would.
                        <br /><br/>
                        We understand what working on your passion feels like. But let’s face it: right now, it feels like living inside a bubble. There are so many creative, technical, ambitious people out there… but no great place to talk about our projects as they evolve, learn from each other’s techniques and wisdom, and join forces to make something better than we could make alone.
                        <br /><br/>
                        It shouldn’t be this way. We want to share in your evolutions and growth. We want to celebrate your achievements and even the mistakes you’re embarrassed by—because seeing your lessons is how we learn. We want to collaborate and create things together because there’s nothing more satisfying than making a remarkable idea real.
                        <br /><br/>
                        This is what we’re building. Jouncer isn’t the website, it’s a growing group of people who are making meaningful things, who value the power to create, and want to be a part of each other's honest, crazy, wildly ambitious journeys.
                        <br /><br/>
                        We wanted something badly enough that we went out and built it. You’re in exactly the right place if you would have built this too.
                    </p>

                    <div className="footer">Contact us at team@jouncer.io</div>
                </div>
            </div>
        );

    }
}

export default About;
