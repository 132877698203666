import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';
import {UserContext} from '../App';
import S3FileUpload from 'react-s3';

// My Dependencies
import MainNav from '../components/MainNav';
import SubNav from '../components/SubNav';
import Card from '../components/Card.js';
import Update from '../components/Update';
import AddUpdate from '../components/AddUpdate';
import UnansweredQuestion from '../components/UnansweredQuestion';
import AnsweredQuestion from '../components/AnsweredQuestion';

import '../css/MissionControl.css';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');


const UnansweredQuestions = ({
    style,
    questions,
    handleUpload,
    handleSubmit,
    userPage,
    user
}) => {

    return (
        <div id="ActivityFeed" className="ActivityFeed" style={style}>
            <div className="activity-stream">
                <div>{questions.map(props => <UnansweredQuestion userPage={userPage} {...props}/>)}</div>
            </div>
        </div>
    );
}




function removeShade() {
    var element = document.getElementById("shade");
    element.classList.remove("shade");

    var element = document.getElementById("AddUpdate");
    element.classList.remove("bring-to-front");
}

const ActivityProfile = ({
    notUser,
    style,
    activity,
    handleUpload,
    creator_teams,
    uploading,
    handleSubmit,
    new_updates,
    files,
    ref_,
    user
}) => {

    if (activity === null){
        return <utils.Spinner/>;
    }

    return (
        <div id="ActivityFeed" className="ActivityFeed" style={style}>
            <div className="activity-stream">

                {notUser ?
                    <h2>This is what other creators have been working on</h2>
                    :
                    <h2>What are you creating?</h2>
                }

                {notUser ? null :
                    <AddUpdate
                        userId={user ? user.user.user_profile.unique_id : ""}
                        ref={ref_}
                        files={files}
                        creator_teams={creator_teams}
                        handleUpload={handleUpload}
                        handleSubmit={handleSubmit}
                        multiTeam={true}/>
                }
                <div id="shade" className="shadeoff" onClick={removeShade}></div>

                {uploading === true ?
                    <div className="posting-update-badge">
                        <p>Posting Update...</p>
                    </div>
                : null}

                <div className="new-updates">
                    {new_updates.map(update => <Update date={[]} member={user.user} {...update} newUpdate={true} />)}
                </div>

                <div>{activity.map(props => props.team_question ? <AnsweredQuestion notUser={notUser} {...props}/> : <Update notUser={notUser} {...props}/>)}</div>
            </div>
        </div>
    );
}


class MissionControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            pageNumber: 0,
            toast: null,
            creator_teams: [],
            teamId: "",
            questions: [],
            files: [],
            mediaLinks: [],
            uploading: false,
            new_updates: [],
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.callActivity = this.callActivity.bind(this);
        this.callUserTeams = this.callUserTeams.bind(this);
        this.removeToast = this.removeToast.bind(this);
        this.fetchUnansweredQuestions = this.fetchUnansweredQuestions.bind(this);
        this.feed = React.createRef();
        this.addUpdate = React.createRef();
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.callActivity();
    }

    componentDidMount() {
        this.setState({toast: this.props.toast});
        setTimeout( this.removeToast, 3000);
        document.addEventListener('scroll', this.handleScroll);
        this.callUserTeams();
        this.fetchUnansweredQuestions();
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;

        if (windowBottom >= docHeight) {
            this.callActivity();
        }
    }

    callUserTeams() {
        const url = `${config.BACKEND_HOST}/all/creator_teams`;
        const self = this;

        utils.get(url)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    self.setState({ selectedTeamId: "home" });
                    if (res.data.teams_on.length > 0) {
                        self.setState(prevState => {
                            return {
                                githubRepo: res.data.github_repo_id,
                                creator_teams: res.data.teams_on,
                                teamName: res.data.teams_on.length > 0 ? res.data.teams_on[0].name : "",
                                selectedTeamImage: res.data.teams_on.length > 0 ? (res.data.teams_on[0].profile_img ? res.data.teams_on[0].profile_img : (res.data.teams_on[0].images.length > 0 ? res.data.teams_on[0].images[0].media_link : "")) : "",
                            }
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleUpload(values) {
        this.setState({files: values});

        const config2 = {
            bucketName: 'stagingjouncerfrontend',
            dirName:'static/media',
            region: 'us-east-2',
            accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
            secretAccessKey: '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',
        }

        //submit logic here
        const self = this;

        S3FileUpload
        .uploadFile(values[values.length - 1], config2)
        .then(data => {
            console.log("s3 file uploaded properly", data);

            let newMediaLinks = this.state.mediaLinks;
            newMediaLinks.push(data.location);
            this.setState({mediaLinks: newMediaLinks});
        })
        .catch(err => console.error(err));

    }

    handleUpdateSubmit(selectedTags, selectedCommits, selectedTeamId, selectedTeamImage, teamName) {
        const {user} = this.props;
        const {files, mediaLinks} = this.state;
        const {text} = this.addUpdate.current.state;

        this.setState({ uploading: true });

        this.addUpdate
            .current
            .clear();

        let miniProjectParams = { team_name: teamName }
        let miniProjectUrl = `${config.BACKEND_HOST}/teams/create_mini_team`;
        let newProjectId;
        const self = this;

        let tags = [];
        selectedTags.forEach(
            tag => {tags.push(tag.unique_id)}
        );

        let commits = [];
        selectedCommits.forEach(
            commit => {commits.push(commit.unique_id)}
        );

        let url = `${config.BACKEND_HOST}/teams/post_update`;

        utils.post(miniProjectUrl, miniProjectParams)
            .then(res => {

                if (res.status) {
                    newProjectId = res.data.team_unique_id;

                    let params = {
                        team_uuid: selectedTeamId === "mini" ? newProjectId : selectedTeamId,
                        post_description: text,
                        selected_tags: tags,
                        selected_commits: commits
                    };

                    console.log("mediaLinks", mediaLinks);

                    if (mediaLinks && mediaLinks.length > 0) {
                        params.uploaded_urls = mediaLinks;
                    }

                    utils.postMultipart(url, params)
                        .then(res => {
                            if (!res.ok) throw "Error with request";
                            self.setState(prevState => {
                                return {
                                    uploading: false,
                                    new_updates: [
                                        {
                                            team__name: teamName,
                                            team__unique_id: selectedTeamId,
                                            team__profile_img: selectedTeamImage,
                                            tags: selectedTags,
                                            member__first_name: user.user.first_name,
                                            member__last_name: user.user.last_name,
                                            member__userprofile__unique_id: user.unique_id,
                                            member__media__media_link: user.user.images[0].media_link,
                                            images: files,
                                            viewed: false,
                                            media__media_link: files && files.length > 0 ? files[0].preview : "",
                                            isUpload: true,
                                            newMedia: true,
                                            description: text,
                                            commits: selectedCommits
                                        },
                                        ...prevState.new_updates
                                    ]
                                }
                            });
                        }).catch(err => console.log(err));
                }
            }).catch(err => console.log(err));
    }

    removeToast() {
        this.setState({toast: null});
    }

    async callActivity() {
        const url = `${config.BACKEND_HOST}/all/activity`;
        const self = this;

        let number = this.state.pageNumber + 1;

        let params = {
            page_number: number,
        }

        await utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    self.setState(prevState => {
                        return {
                            activity: [...prevState.activity.concat(res.data.activity)],
                            pageNumber: number,
                        }
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    async fetchUnansweredQuestions() {
        let url = `${config.BACKEND_HOST}/all/unanswered_creator_questions`;

        const self = this;

        await utils.get(url)
            .then((res) => {
                console.log("questions", res);
                if (res.status){
                    self.setState({
                        questions: res.data.questions,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async fetchTeamActivity() {
        let url = `${config.BACKEND_HOST}/all/team_activity`;
        let params = {
            page_team_uuid: this.state.teamId,
        }
        const self = this;

        await utils.get(url, params)
            .then((res) => {
                console.log(res);
                if (res.status){
                    self.setState({
                        activity: res.data.activity,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    renderCards(){
        const {creator_teams} = this.state;
        if (creator_teams === null) {
            return <utils.Spinner/>;
        }

        let cards = creator_teams.map(({name, mission, images, profile_img, members, team_skills, unique_id, unread_updates, unread_comments}) => {
            let img = "";
            if (profile_img){
                img = utils.prefixImage(profile_img);
            }
            else if (images.length > 0){
                img = utils.image(images[0]);
            }
            return (
                <div className="card-container">
                    <Link to={`/project/${unique_id}`}>
                        <UserContext.Consumer>
                            {user => <Card id={unique_id}
                                update={4}
                                title={name}
                                members={members}
                                description={mission}
                                img={img}
                                images={images}
                                unread_updates={unread_updates}
                                unread_comments={unread_comments}
                                skillText="We need"
                                skills={Object.values(team_skills)}
                                user={user}
                                teamcard={true}/>}
                        </UserContext.Consumer>
                    </Link>
                </div>
            );
            }
        );
        return cards.length > 0 ? cards : <div className="no-results">No results</div>;
    }

    render() {

        return (
            <div className="main">
                <div className="content" id="MissionControl">

                    <section className="full-width-content">
                        <div className="banner">
                            <div className="activity-banner" id="activity-content">

                                <div className="activity-stream-home questions-list">
                                    <div className="activity-list">
                                        <UnansweredQuestions
                                            questions={this.state.questions}
                                            userPage={true}
                                            {...this.props}
                                            {...this.state}/>
                                    </div>
                                </div>


                                <div className="activity-stream-home" ref={this.feed}>
                                    <div className="activity-list">

                                        <ActivityProfile
                                            notUser={this.props.notUser}
                                            activity={this.state.activity}
                                            handleUpload={this
                                            .handleUpload
                                            .bind(this)}
                                            handleSubmit={this
                                            .handleUpdateSubmit
                                            .bind(this)}
                                            {...this.props}
                                            {...this.state}
                                            ref_={this.addUpdate}/>

                                    </div>
                                    <utils.Spinner/>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*<section className="content-section">
                        {this.props.user && this.props.user.new_user_banner
                            ? <FinishProfileBanner />
                            : null}
                    </section>*/}
                </div>
            </div>
        );
    }
}

export default MissionControl;
