import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';
import {UserContext} from '../App';

// My Dependencies
import MainNav from '../components/MainNav';
import SubNav from '../components/SubNav';
import WelcomeBanner from '../components/WelcomeBanner';
import CommentSection from '../components/CommentSection';
import MemberIcon from '../components/MemberIcon';
import Card from '../components/Card';
import AddUpdate from '../components/AddUpdate';
import MissionControl from './MissionControl';
import Update from '../components/Update';
import Octicon, {GitCommit, GitMerge, Link as Link2, Flame} from '@primer/octicons-react';
import Linkify from 'react-linkify';

import world from '../media/world.jpg';

import '../css/MissionControl.css';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');


class SinglePlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            uploading: false,
            selectedTeamImage: [],
            selectedTeamId: "",
            pageNumber: 0,
            toast: null,
            githubRepo: "",
            creator_teams: [],
            new_updates: [],
            teamName: "",
            files: [],
        };

        this.addUpdate = React.createRef();
        this.feed = React.createRef();
        this.fetchTeamActivity = this.fetchTeamActivity.bind(this);
        this.callUserTeams = this.callUserTeams.bind(this);
        this.removeToast = this.removeToast.bind(this);
        this.fetchActivityFeed = this.fetchActivityFeed.bind(this);
    }

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.setState({toast: this.props.toast});
        setTimeout( this.removeToast, 3000);
        // this.callUserTeams();
        // this.fetchTeamActivity();
    }

    removeToast() {
        this.setState({toast: null});
    }

    handleUpload(values) {
        this.setState({files: values});
    }

    handleUpdateSubmit(selectedTags, selectedCommits) {
        const {user} = this.props;
        const {files, selectedTeamImage, selectedTeamId, teamName} = this.state;
        const {text} = this.addUpdate.current.state;

        this.setState({ uploading: true });

        this.addUpdate
            .current
            .clear();

        let tags = [];
        selectedTags.forEach(
            tag => {tags.push(tag.unique_id)}
        );

        let commits = [];
        selectedCommits.forEach(
            commit => {commits.push(commit.unique_id)}
        );

        let params = {
            team_uuid: this.state.selectedTeamId,
            post_description: text,
            selected_tags: tags,
            selected_commits: commits
        };

        if (files && files.length > 0) {
            params.uploaded_file = files[0];
        }

        let url = `${config.BACKEND_HOST}/teams/post_update`;

        const self = this;
        utils.postMultipart(url, params)
            .then(res => {
                if (!res.ok) throw "Error with request";
                self.setState(prevState => {
                    return {
                        uploading: false,
                        new_updates: [
                            {
                                team__name: teamName,
                                team__unique_id: selectedTeamId,
                                team__profile_img: selectedTeamImage,
                                tags: selectedTags,
                                member__first_name: user.user.first_name,
                                member__last_name: user.user.last_name,
                                member__userprofile__unique_id: user.unique_id,
                                member__media__media_link: user.user.images[0].media_link,
                                viewed: false,
                                media__media_link: files && files.length > 0 ? files[0].preview : "",
                                isUpload: true,
                                description: text,
                                commits: selectedCommits
                            },
                            ...prevState.new_updates
                        ]
                    }
                });
            }).catch(err => console.log(err));
    }

    changeTeams(unique_id, team_img, name, github_repo_id) {
        this.setState({
            selectedTeamId: unique_id,
            selectedTeamImage: team_img,
            teamName: name,
            githubRepo: github_repo_id,
        });
    }

    callUserTeams() {
        const url = `${config.BACKEND_HOST}/all/creator_teams`;
        const self = this;

        utils.get(url)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    self.setState({ selectedTeamId: "home" });
                    if (res.data.teams_on.length > 0) {
                        self.setState(prevState => {
                            return {
                                githubRepo: res.data.github_repo_id,
                                creator_teams: res.data.teams_on,
                                teamName: res.data.teams_on.length > 0 ? res.data.teams_on[0].name : "",
                                selectedTeamImage: res.data.teams_on.length > 0 ? (res.data.teams_on[0].profile_img ? res.data.teams_on[0].profile_img : (res.data.teams_on[0].images.length > 0 ? res.data.teams_on[0].images[0].media_link : "")) : "",
                            }
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchTeamActivity() {
        let url = `${config.BACKEND_HOST}/all/creator_team_activity`;
        let number = this.state.pageNumber + 1;

        let params = {
            page_number: number,
        }

        const self = this;

        utils.get(url)
            .then((res) => {
                console.log(res);
                if (res.status){
                    self.setState({
                        activity: res.data.activity,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchActivityFeed() {
        this.setState({ selectedTeamId: "home" });
    }

    createProjectButton() {
        return <div className="card-container first-project-button">
            <div className="card">

                <div className="card-img-container">
                    <div className="card-img">
                        <p className="plus">+</p>
                        <p className="start-new">Start New Project</p>
                    </div>
                </div>

                <div className="hover-zones v-centred"/>

                <div className="card-body">
                    <h3 className="card-title">New Project</h3>
                </div>
            </div>
        </div>
    }

    render() {

        let selected_activity = this.state.activity.filter(update => (update.team__unique_id === this.state.selectedTeamId));

        return (
            <div className="main">

                <Helmet>
                    <title>Activity – Jouncer</title>
                </Helmet>
                <div className="content" id="SinglePlayer">

                    {this.state.toast ? <div className="toast">{this.state.toast}&nbsp; <i class="far fa-check-circle"></i></div> : null}


                    <section className="full-width-content">
                        <div className="banner">

                            <div className="activity-banner" id="activity-scroll">

                                <MissionControl
                                {...this.props}
                                {...this.state}
                                />

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default SinglePlayer;
