import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const _ = require("lodash");

const InputSuccess = (props) => {
    if (!_.isArray(props.messages) || props.messages.length === 0){
        return null;
    }
    return (
        <ul className="input-success">
            {props.messages.map(err => (<li>{err}</li>))}
        </ul>
    );
}

export default InputSuccess;
