const BACKEND_HOST = 'https://demo-placholdere.herokuapp.com';
// const BACKEND_HOST = 'http://localhost:8000';
const SOCKET_HOST = 'wss://demo-placholdere.herokuapp.com';
const IMAGE_HOST = 'https://djangostaticassets.s3.amazonaws.com/media';

const SCSS = {
	primary: "white",
	secondary: "#8d92a3",
	tertiary: "#17cc65",
	bgColor: "#2a2c3f",
    transition: "0.3s ease all",
    gradient: 'linear-gradient(107deg, #41ff7366, #00dedc66)',
}

const logoPath = '/img/logo.png';

const selectStyles = {
    container: styles => ({
        ...styles,
        width: "100%",
		outline: 0,
		border: "none",
    }),
    control: (base, state) => ({
        ...base,
        // transition: SCSS.transition,
        background: SCSS.bgColor,
		color: SCSS.secondary,
        border: "none",
		boxShadow: "0px"
    }),
    menu: styles => ({
        ...styles,
        transition: SCSS.transition,
        background: SCSS.bgColor,
		color: SCSS.secondary,
    }),
    // multiValue: styles => ({
    //     ...styles,
    //     background: SCSS.secondary,
	// 	color: SCSS.primary
    // }),
    // multiValueLabel: styles => ({
    //     ...styles,
    //     color: "white",
    //     fontWeight: 500
    // }),
    // multiValueRemove: styles => ({
    //     ...styles,
    //     transition: SCSS.transition,
    //     "&:hover": {
    //         background: SCSS.secondary,
    //     }
    // }),
    indicatorSeparator: styles => ({
        ...styles,
        display: "none"
    }),
    dropdownIndicator: styles => ({
        ...styles,
        color: SCSS.tertiary
    }),
    option: (styles, state) => {
        let { data, isDisabled, isFocused, isSelected } = state;
        return {
            ...styles,
            transition: SCSS.transition,
            border: "none",
			outline: 0,
            backgroundColor: isSelected ? "#161824" : (isFocused ? "#161824" : SCSS.bgColor),
            color: SCSS.secondary
        };
    },
    placeholder: base => ({
        ...base,
        color: "#aaa"
    }),
    input: base => ({
        ...base,
        color: "white"
    }),
    singleValue: (base, state) => ({})
}

export default{
    BACKEND_HOST,
    SOCKET_HOST,
    IMAGE_HOST,
    SCSS,
	logoPath,
    selectStyles
}
