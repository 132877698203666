import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {UserContext} from '../App';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';

// My Dependencies
import MainNav from '../components/MainNav.js';
import SubNav from '../components/SubNav.js';
import WelcomeBanner from '../components/WelcomeBanner.js';
import ActivityBanner from '../components/ActivityBanner.js';
import Card from '../components/Card.js';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');

class InProgress extends Component {
    state = {
        all_teams: null,
        selected: {}
    };

    async componentDidMount(){
        const {status, data} = await utils.get('/all/teams/query/');
        if (status){
            this.setState({...data});
        }
    }

    filterCards({team_skills}){
        const buckets = team_skills.map(({name}) => name);
        const selected_values = Object.values(this.state.selected);
        if (selected_values.length === 0 || selected_values.every(val => !val)){
            return true;
        }

        return buckets.some(bucket => !!this.state.selected[bucket]);
    }

    renderCards(){
        const {all_teams} = this.state;
        if (all_teams === null) {
            return <utils.Spinner/>;
        }

        let cards = all_teams.filter(this.filterCards.bind(this)).map(({name, mission, images, profile_img, members, team_skills, unique_id, unread_updates, unread_comments}) => {
            let img = "";
            if (profile_img){
                img = utils.prefixImage(profile_img);
            }
            else if (images.length > 0){
                const team_images = images;
                if (team_images.length > 0){
                    img = utils.image(team_images[0]);
                }
            }
            return (
                <div className="card-container">
                    <Link to={`/project/${unique_id}`}>
                        <UserContext.Consumer>
                            {user => <Card id={unique_id}
                                update={4}
                                title={name}
                                members={members}
                                description={mission}
                                img={img}
                                images={images}
                                unread_updates={unread_updates}
                                unread_comments={unread_comments}
                                skillText="We need"
                                skills={Object.values(team_skills)}
                                user={user}
                                teamcard={true}/>}
                        </UserContext.Consumer>
                    </Link>
                </div>
            );
            }
        );
        return cards.length > 0 ? cards : <div className="no-results">No results</div>;
    }

    handleItemClick(name) {
        this.setState(prevState => {
            let newSelected = {
                ...prevState.selected
            };
            if (name in newSelected) {
                newSelected[name] = !newSelected[name];
            } else {
                newSelected[name] = true;
            }
            return {
                selected: {
                    ...newSelected
                }
            };
        })
    }

    render() {
        return (
            <div className="cards-container">

                {/*<h2 className="col-12" style={{paddingLeft: "10px"}}>Teams</h2>

                <SubNav
                    title="Looking for these skills"
                    onItemClick={this
                    .handleItemClick
                    .bind(this)}
                    selected={this.state.selected}/>*/}

                {this.renderCards()}
            </div>
        );
    }
}

export default InProgress;
