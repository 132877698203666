import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {UserContext} from '../App';
import {Helmet} from 'react-helmet';
import {Link} from "react-router-dom";

// My Dependencies
import MainNav from '../components/MainNav';
import SubNav from '../components/SubNav';
import Card from '../components/Card';
import FinishProfileBanner from '../components/FinishProfileBanner';

// utilities
import utils from '../utils.js';
import config from '../config.js';

const _ = require("lodash");

const UserCard = ({
    first_name,
    last_name,
    userprofile__mission,
    userprofile__unique_id,
    images,
    project_images,
    user_skills
}) => {
    let userImg = "";
    if (images) {
        const profile = images;
        if (profile) {
            userImg = utils.image(profile);
        }
    }

    const skills = Object.values(user_skills);
    const project_imgs = project_images;
    const first_img = project_imgs.length > 0 ? utils.image(project_imgs[0]) : "";

    return (
        <div className="card-container">
            <Link to={`/creators/${userprofile__unique_id}`}>
                <UserContext.Consumer>
                    {user => <Card
                        id={userprofile__unique_id}
                        key={userprofile__unique_id}
                        title={`${first_name} ${last_name}`}
                        description={userprofile__mission}
                        img={first_img}
                        images={project_imgs.slice(1)}
                        userImg={userImg}
                        skills={skills}
                        user={user}/>}
                </UserContext.Consumer>
            </Link>
        </div>
    );
}

class Creators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // uni_creators: null,
            // all_creators: null,
            active_creators: null,
            // university_name: "",
            user_banner: null,
            selected: {},
            width: 0,
            height: 0
        };

        // get info from backend let data = this.getData(); console.log(data);
        // this.state = {cards: data}; get 5 most common skills build teamCards

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentWillMount() {
      window.scrollTo(0, 0);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    async componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        // const args = ['num_new_users=9999', 'num_engaged_users=9999', 'num_skill_buckets=9999'];
        // const url = `${config
        //     .BACKEND_HOST}/all/creators/query/?${args
        //     .join('&')}`;
        const args = {
            'num_new_users':9999,
            'num_engaged_users':9999,
            'num_skill_buckets':9999,
        }

        const url = `${config.BACKEND_HOST}/all/creators_active/query/`;
        const {status, data} = await utils.get(url, args);
        if (status) {
            this.setState({
                ...data
            });
        }
    }

    filterCards({user_skills}) {
        const buckets = Object
            .values(user_skills)
            .map(val => val.name);
        const selected_values = Object.values(this.state.selected);
        if (selected_values.length === 0 || !selected_values.some(val => !!val)) {
            return true;
        }

        return buckets.some(bucket => !!this.state.selected[bucket]);
    }

    handleItemClick(name) {
        this.setState(prevState => {
            let newSelected = {
                ...prevState.selected
            };
            if (name in newSelected) {
                newSelected[name] = !newSelected[name];
            } else {
                newSelected[name] = true;
            }
            return {
                selected: {
                    ...newSelected
                }
            };
        })
    }

    // renderUniCreators(cols) {
    //
    //     let {uni_creators} = this.state;
    //     if (uni_creators === null){
    //         return;
    //     }
    //     let uni_cards = uni_creators
    //         .filter(this.filterCards.bind(this))
    //         //.slice(0, 2 * cols)
    //         .map(UserCard.bind(this));
    //     return uni_cards.length > 0 ? uni_cards : <div className="no-results">No results</div>;
    //
    // }

    renderAllCreators(cols) {

        let {active_creators} = this.state;
        if (active_creators === null){
            return <utils.Spinner/>;
        }

        let all_cards = active_creators
            .filter(this.filterCards.bind(this))
            //.slice(0, 2 * cols)
            .map(UserCard.bind(this));
        return all_cards.length > 0 ? all_cards : <div className="no-results">No results</div>;

    }

    render() {
        const breakPointWidths = [-1, 576, 818, 1060];
        let cols = _.findIndex(breakPointWidths, (width) => this.state.width < width);
        if (cols === -1){
            cols = breakPointWidths.length;
        }

        // let {uni_creators, all_creators} = this.state;
        let {active_creators} = this.state;

        return (
            <div className="main">
                <Helmet>
                    <title>WAC – Jouncer</title>
                </Helmet>
                <div className="content" id="CreatorsView">
                    {this.props.user && this.props.user.new_user_banner
                        ? <FinishProfileBanner />
                        : null}

                    <section className="content-section d-flex justify-content-center">
                        <div className="cards-container">
                            <h2 className="page-title">Weekly Active Creators</h2>
                            <SubNav
                                title="Filter by skill"
                                onItemClick={this
                                .handleItemClick
                                .bind(this)}
                                selected={this.state.selected}/>
                            {/*{uni_creators ? <h2 className="page-subtitle">Creators at {this.state.university_name}</h2> : null }
                            {uni_creators ? <div className="page-content">
                                {this.renderUniCreators(cols)}
                            </div> : null }*/}
                            <div className="page-content">
                                {this.renderAllCreators(cols)}
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        );
    }
}

export default Creators;
