import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {UserContext} from '../App';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';

// My Dependencies
import MainNav from '../components/MainNav.js';
import SubNav from '../components/SubNav';

import Card from '../components/Card';
import PortfolioModal from '../components/PortfolioModal';

import '../css/Projects.css';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');



const ProjectCard = ({
    images,
    title,
    description,
    openModal,
    handleClose,
    handleClick,
    unique_id,
    user__first_name,
    user__last_name,
    user__images,
    user__userprofile__unique_id,
    unread_comments,
    id,
    date_added,
    project_skills,
    comments,
    github_url,
    hidden = false,
    ...props
}, i) => {
    const style = {
        display: hidden
            ? 'none'
            : null
    };
    let img = "";
    if (images.length > 0) {
        img = utils.image(images[0]);
    }

    const skills = Object.values(project_skills);

    return (
        <div id={`project-${unique_id}`}>
            <PortfolioModal
                projectid={unique_id}
                title={title}
                description={description}
                images={images}
                open={openModal === id}
                creatorName={`${user__first_name} ${user__last_name}`}
                creatorImg={user__images[0] ? user__images[0].media_link : ""}
                creatorId={user__userprofile__unique_id}
                project_skills={project_skills}
                github_url={github_url}
                date_added={date_added}
                handleClose={() => handleClose()}
                comments={comments}
                {...props}/>
            <UserContext.Consumer>
                {user => <Card
                id={unique_id}
                key={unique_id}
                title={title}
                description={utils.truncateTo(description, 40)}
                img={img}
                images={images}
                onClick={() => handleClick(id, unique_id)}
                user={user}
                unread_comments={unread_comments}
                skills={skills}
                style={style}
                {...props}/>}
            </UserContext.Consumer>
        </div>
    )
};



class CompletedProjects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: null,
            popular_projects: null,
            openModal: -1,
            selected: {},
            comments: null,
        };
        this.renderNewProjects = this.renderNewProjects.bind(this);
        this.renderPopularProjects = this.renderPopularProjects.bind(this);
    }

    componentWillMount() {
        this.getNewProjects();
        this.getPopularProjects();
    }

    //for portfolio modal
    handleClose() {
        this.setState({openModal: -1});
    }

    handleClick(id, unique_id) {
        this.setState({openModal: id});

        const url = `${config.BACKEND_HOST}/all/fetch_comments`;

        let params = {
            portfolio_project_unique_id: unique_id,
        }

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res)
                    this.setState({
                        comments: res.data.comments,
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    async getNewProjects() {
        const url = `${config.BACKEND_HOST}/all/new_projects/query/`;

        utils.get(url)
            .then((res) => {
                if (res.status){
                    this.setState({
                        projects: res.data.projects,
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    async getPopularProjects() {
        const url = `${config.BACKEND_HOST}/all/popular_projects/query/`;

        utils.get(url)
            .then((res) => {
                if (res.status){
                    this.setState({
                        popular_projects: res.data.projects,
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    renderPopularProjects() {
        let {popular_projects} = this.state;

        if (popular_projects === null || this.props.user === null){
            return <utils.Spinner/>;
        }

        let new_popular_projects = popular_projects.filter(this.filterCards.bind(this))
            .map(project => (
                <ProjectCard
                {...project}
                {...this.state}
                handleClose={this.handleClose.bind(this)}
                handleClick={this.handleClick.bind(this)}/>));

        return new_popular_projects.length > 0 ? new_popular_projects : <div className="no-results">No results</div>;
    }

    renderNewProjects() {
        let {projects} = this.state;

        if (projects === null || this.props.user === null){
            return <utils.Spinner/>;
        }

        let new_projects = projects.filter(this.filterCards.bind(this))
            .map(project => (
                <ProjectCard
                {...project}
                {...this.state}
                handleClose={this.handleClose.bind(this)}
                handleClick={this.handleClick.bind(this)}/>));

        return new_projects.length > 0 ? new_projects : <div className="no-results">No results</div>;
    }

    handleItemClick(name) {
        this.setState(prevState => {
            let newSelected = {
                ...prevState.selected
            };
            if (name in newSelected) {
                newSelected[name] = !newSelected[name];
            } else {
                newSelected[name] = true;
            }
            return {
                selected: {
                    ...newSelected
                }
            };
        })
    }

    filterCards({project_skills}) {
        const buckets = Object
            .values(project_skills)
            .map(val => val.name);
        const selected_values = Object.values(this.state.selected);
        if (selected_values.length === 0 || !selected_values.some(val => !!val)) {
            return true;
        }

        return buckets.some(bucket => !!this.state.selected[bucket]);
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Portfolio Projects – Jouncer</title>
                </Helmet>

                <div id="ProjectsView">
                    {/*<section className="content-section">
                        {this.props.user && this.props.user.new_user_banner
                            ? <FinishProfileBanner />
                            : null}
                    </section>*/}

                        <div className="cards-container">
                            <SubNav
                                title="Categories"
                                onItemClick={this
                                .handleItemClick
                                .bind(this)}
                                selected={this.state.selected}/>

                            <div className="PortfolioProfile" style={this.props.style}>

                                <h3 className="content-subsection-heading" style={{paddingLeft: "16px"}}>New Projects</h3>

                                <div className="portfolio-row" style={{paddingBottom: "50px"}}>
                                    {this.renderNewProjects()}
                                </div>

                                <h3 className="content-subsection-heading" style={{paddingLeft: "16px"}}>All Projects</h3>

                                <div className="portfolio-row">
                                    {this.renderPopularProjects()}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}

export default CompletedProjects;
