import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import utils from '../utils';
import config from '../config';
import ProgressNav from "../components/ProgressNav.jsx";
import View1 from "../components/CreateProfileViews/View1.jsx";
import View2 from "../components/CreateProfileViews/View2.jsx";
import View3 from "../components/CreateProfileViews/View3.jsx";
import View4 from "../components/CreateProfileViews/View4.jsx";
import '../css/CreateProfile.css';

class CreateProfile extends Component {

    render() {
        const edit = !!this.props.edit;
        let navType = edit ? "Edit Profile" : "Create Profile";
        const index = this.props.match.params.index;
        if (parseInt(index) === 4) {
            return (
                <div className="create-profile create-profile-main d-flex">
                    <View4 />
                </div>
            );
        }
        return (
            <div className="create-profile create-profile-main d-flex">

                <ProgressNav index={index} navType={navType} />

                <div className="create-profile-content">
                    <CreateProfileContent index={index}/>
                </div>
            </div>
        );
    }
}

class CreateProfileContent extends Component {
    state = {
        school: null,
        program: null,
        degree: null,
        graduationYear: null,
        userprofile: {}
    };

    componentDidMount(){
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/portfolio_university`;
        utils.get(url)
            .then(res => {
                console.log(res);
                if (res.status) {
                    const {userprofile} = res.data;
                    console.log(userprofile);
                    //wait for options to query
                    let state = { userprofile };
                    if (userprofile.university !== null){
                        state.school = {
                            label: userprofile.university.name,
                            value: userprofile.university.unique_id
                        };
                    }
                    if (userprofile.program !== null){
                        state.program = {
                            label: userprofile.program.name,
                            value: userprofile.program.unique_id
                        };
                    }
                    if (userprofile.degree !== null){
                        state.degree = {
                            label: userprofile.degree.name,
                            value: userprofile.degree.unique_id
                        };
                    }
                    if (userprofile.graduation_year !== null){
                        state.graduationYear = {
                            label: userprofile.graduation_year,
                            value: userprofile.graduation_year
                        };
                    }
                    self.setState(state);
                }
            })
            .catch(err => {
                if (typeof err === "object") {
                    console.log(err.response.body);
                } else {
                    console.log(err);
                }
                //try again?
            });
    }

    render() {
        let index = parseInt(this.props.index);
        switch (index) {
            case 1:
                return <View1 {...this.state}/>;
            case 2:
                return <View2 {...this.state}/>;
            case 3:
                return <View3 {...this.state}/>;
        }
        console.log("Invalid index");
        return (
            <Redirect to="/not-found" />
        );
    }
}

export default CreateProfile;
