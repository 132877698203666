import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import  { unregister, registerServiceWorker} from './registerServiceWorker';
var $ = require('jquery');

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
unregister();
