import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    Redirect,
    withRouter
} from "react-router-dom";
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {withCookies, Cookies} from 'react-cookie';
import './css/App.css';

// utilities
import config from './config';
import utils from './utils';

// // Open Source Dependencies import { Column, Row } from 'simple-flexbox';
// pages
import Home from "./pages/Home";
import TestHome from "./pages/TestHome";
import SinglePlayer from "./pages/SinglePlayer";
import MissionControl from "./pages/MissionControl";
import Creators from "./pages/Creators";
import Projects from "./pages/Projects";
import CreatorProfileView from "./pages/CreatorProfileView";
import TeamProfileView from './pages/TeamProfileView';
import Suggestions from './pages/Suggestions';
import GithubSignup from './pages/GithubSignup';
import SignUp from "./pages/SignUp";
import CreateProfile from "./pages/CreateProfile";
import CreateTeam from "./pages/CreateTeam";
import MainNav from './components/MainNav';
import SubNav from './components/SubNav';
import Privacy from './components/Privacy';
import LoginView from './pages/Login';
import JoinRequest from './pages/JoinRequest';
import ComeInside from './components/SignUpViews/ComeInside';
import JoinRequestSuccess from './pages/JoinRequestSuccess';
import ViewJoinRequests from './pages/ViewJoinRequests';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import ErrorPage from './pages/Error';
import About from './pages/About';
import TokenLogin from './pages/TokenLogin';
import GithubAuthentication from './pages/GithubAuthentication';
import Chat from './pages/Chat';
import EmailSignup from './pages/EmailSignup';



ReactGA.initialize('UA-121647578-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const rp = require('request-promise-native');

const UserContext = React.createContext({
    unique_id: ''
});

const TAB_NAMES = utils.TAB_NAMES;



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatSocket: null,
            dashboard: {},
            loggedIn: !!utils.token(),
            toast: null,
        };

        this.populateDashboard = this.populateDashboard.bind(this);
        this.signUpLogin = this.signUpLogin.bind(this);
    }

    async login(username = "", password = "") {
        const {cookies} = this.props;

        if (!!cookies.get('auth_token')) {
            this.setState({loggedIn: true});
            return true;
        }

        const form = {
            username,
            password
        };

        const options = {
            uri: `${config.BACKEND_HOST}/login`,
            method: 'POST',
            form,
            headers: {
                // "Content-Type": "application/x-www-form-urlencoded"
            },
            json: true
        };
        const {token, error} = await rp(options);
        if (token) {
            cookies.set('auth_token', token, {path: "/"});
            this.setState({loggedIn: true});
            this.populateDashboard();
            return true;
        } else if (error) {
            return false;
        }
    }

    signUpLogin(token) {

        const cookies = new Cookies();

        if (token) {
            cookies.set('auth_token', token, {path: "/"});
            this.setState({loggedIn: true});
            this.populateDashboard();
            return true;
        }
    }

    logout() {
        const {cookies} = this.props;
        cookies.remove('auth_token', {path: "/"});
        this.setState({loggedIn: false, dashboard: {}});
        ReactGA.event({
            category: 'User',
            action: 'Logged out of the site'
          });
    }

    authenticate() {
        // const params = {
        //     token: utils.token()
        // };

        // utils.get('/accounts/validate_access_token', params).then(({status, data}) => {
        //     if (!status) {
        //         console.log("Not a Valid Token");
        //         this.logout()
        //     }
        // }).catch(err => err.statusCode==401 ? this.logout(): console.log("Server is likely rebooting"));
    }

    populateDashboard() {
        console.log("POPULATING DASH");
        const self = this;
        utils.get('/all/dashboard/query/').then(({status, data}) => {
            if (!status) throw "Error with request";
            if (self.state.dashboard != data) {
                console.log("setting dahsboard", data);
                self.setState({dashboard: data});
            }
        }).catch(err => err.statusCode==401 ? this.logout(): console.log("Server is likely rebooting"));
    }

    componentDidMount() {
        window.addEventListener('updateDash', this.populateDashboard);
        this.populateDashboard();
        this.authenticate();
    }

    componentDidUpdate() {

        if (this.state.dashboard && this.state.dashboard.current_user && this.state.dashboard.current_user.user) {
            const fsUser = this.state.dashboard.current_user.user;
            const id=this.state.dashboard.current_user.unique_id;
            const name=`${fsUser.first_name} ${fsUser.last_name}`;
            const email=fsUser.email;

            window.fullstory(id, name, email);
            ReactGA.set({ userId:  id});
        }
    }

    render() {
        const self = this;
        const {pathname} = document.location;

        const subdir = pathname.split('/')[1];

        if (!(subdir in TAB_NAMES)){
            document.location.pathname = '/not-found';
        }

        return (
            <Router id="App">
                <UserContext.Provider value={this.state.dashboard.current_user}>

                    <Helmet>
                        <title>{`${TAB_NAMES[subdir]} – Jouncer`}</title>
                    </Helmet>
                    <Switch>
                        <Route path="/forgot-password" component={BaseWithoutNav}/>
                        <Route path="/privacy" component={BaseWithoutNav}/>
                        <Route
                            exact
                            path={'/login'}
                            render={() => <LoginView
                            {...self.props}
                            next='/activity'
                            login={this.login.bind(this)}
                            signUpLogin={this.signUpLogin}
                            loggedIn={this.state.loggedIn}/>}/>
                        <Route
                            exact
                            path="/logout"
                            render={() => <Logout
                            logout={this
                            .logout
                            .bind(this)}/>}/>
                        <Route
                            exact path='/token-login/:token'
                            render={innerProps => <TokenLogin
                            {...self.props}
                            {...innerProps}
                            next='/activity'
                            login={this
                            .login
                            .bind(this)}
                            loggedIn={this.state.loggedIn}/>}
                            />
                        <Route path="/github-signup"
                            render={() => <BaseWithoutNav signUpLogin={this.signUpLogin}/>}/>
                        <Route path="/shoesoff"
                            render={() => <BaseWithoutNav signUpLogin={this.signUpLogin}/>}/>
                        <Route path="/welcomemat"
                            render={() => <BaseWithoutNav
                            login={this.login.bind(this)}
                            loggedIn={this.state.loggedIn}
                            signUpLogin={this.signUpLogin} />} activeStyle={{color:"#17cc65"}}
                            />
                        <Route path="/testhome"
                            render={() => <BaseWithoutNav
                            login={this.login.bind(this)}
                            loggedIn={this.state.loggedIn}
                            signUpLogin={this.signUpLogin} />} activeStyle={{color:"#17cc65"}}
                            />
                        <Route path="/email-signup"
                            render={() => <BaseWithoutNav
                            login={this.login.bind(this)}
                            loggedIn={this.state.loggedIn}
                            signUpLogin={this.signUpLogin} />} activeStyle={{color:"#17cc65"}}
                            />
                        <Route exact path="/suggest-someone-interesting"
                            render={() => <BaseWithoutNav
                            loggedIn={this.state.loggedIn}/>}
                            />

                        <Route path="/about"
                            render={() => <BaseWithNav
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/>} activeStyle={{color:"#17cc65"}}
                            />
                        <Route path="/change-password"
                            render={() => <BaseWithNav
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/>}
                            activeStyle={{color:"#17cc65"}}
                            />
                        <Route path="/chat"
                            activeStyle={{color:"#17cc65"}}
                            render={() => <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}
                            ischatpage={true}
                            />}
                            />
                        <Route
                            path="/create-profile"
                            activeStyle={{color:"#17cc65"}}
                            render={() => <BaseWithNav
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/>}
                            />
                        <Route
                            path="/edit-team"
                            activeStyle={{color:"#17cc65"}}
                            render={() => <BaseWithNav
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/>}
                            />
                        <Route
                            path="/create-team"
                            activeStyle={{color:"#17cc65"}}
                            render={() => <BaseWithNav
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/>}
                            />
                        <Route
                            path="/github/auth"
                            activeStyle={{color:"#17cc65"}}
                            render={() => <BaseWithNav
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/>}
                            />
                        <Route
                            path="/activity"
                            render={() => this.state.loggedIn ? <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/> :
                            <BaseWithNav notUser={true}/>}
                            />
                        <Route
                            path="/projects"
                            render={() => this.state.loggedIn ? <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/> :
                            <BaseWithNav notUser={true}/>}
                            />
                        <Route
                            path="/creators/:id"
                            render={() => this.state.loggedIn ? <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/> :
                            <BaseWithNav notUser={true}/>}
                            />
                        <Route
                            path="/project/:id"
                            render={() => this.state.loggedIn ? <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/> :
                            <BaseWithNav notUser={true}/>}
                            />
                        <Route
                            path="/creators"
                            render={() => this.state.loggedIn ? <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}/> :
                            <BaseWithNav notUser={true}/>}
                            />
                        <Route
                            path="/"
                            render={() => this.state.loggedIn ? <BaseWithNav
                            dashboard={this.state.dashboard}
                            user={this.state.dashboard.current_user}
                            joinrequest={this.state.dashboard.has_join_requests}
                            toast={this.state.toast}/> : <Redirect to="/welcomemat/"/>}
                            />
                    </Switch>
                </UserContext.Provider>
            </Router>
        )
    }
}

const BaseWithNav = withRouter((props) => {
    return (
        <div className="base">

            {props.notUser ? <MainNav notUser={true}/> : <MainNav user={props.user} newNotif={props.dashboard ? props.dashboard.new_notif : false} joinrequest={props.joinrequest} ischatpage={props.ischatpage}/>}
            <Helmet>
                <title>{`${TAB_NAMES[window.location.pathname.split('/')[1]]} – Jouncer`}</title>
            </Helmet>

            <Switch>
                <Route path="/activity" render={innerProps => <SinglePlayer user={props.user} notUser={props.notUser} {...props} {...innerProps} />} activeStyle={{color:"#17cc65"}}/>
                <Route exact path="/project/:id" render={innerProps => <TeamProfileView notUser={props.notUser} {...props} {...innerProps} />} activeStyle={{color:"#17cc65"}}/>
                <Route exact path="/creators" render={() => <Creators user={props.user} />} activeStyle={{color:"#17cc65"}}/>
                <Route path="/creators/:id" render={innerProps => <CreatorProfileView test={"I AM TESTING"} notUser={props.notUser} {...props} {...innerProps}/>} activeStyle={{color:"#17cc65"}}/>
                <Route exact path="/project/:id/join" component={JoinRequest} />
                <Route exact path="/project/:id/request-success" component={JoinRequestSuccess} />
                <Route path="/projects" render={() => <Projects user={props.user} toast={props.toast} />} activeStyle={{color:"#17cc65"}} notUser={props.notUser}/>
                <Route path="/requests" component={ViewJoinRequests} />
                <Route path="/github/auth" render={() => <GithubAuthentication/>} />
                <Route exact path="/" render={() => (<Redirect to="/activity"/>)}/>
                <Route path="/not-found" component={ErrorPage}/>
                <Route exact path="/create-profile/:index" render={innerProps => <CreateProfile {...props} {...innerProps} />}/>
                <Route exact path="/create-profile" render={() => <Redirect to='/create-profile/0' />} />
                <Route exact path="/create-team/:index" component={CreateTeam}/>
                <Route exact path="/create-team/:index/:id" component={CreateTeam} />
                <Route exact path="/create-team" render={() => <Redirect to='/create-team/0' />} />
                <Route exact path="/edit-team/:index/:id" render={props => (<CreateTeam edit={true} {...props} />)} />
                <Route exact path="/edit-team" render={props => (<Redirect to="/create-team/0" />)} />
                <Route path="/about" component={About}/>
                <Route exact path="/chat/team/:teamId/:chatId" render={(innerProps) => (<Chat dashboard={props.dashboard} teamId={innerProps.match.params.teamId} chatId={innerProps.match.params.chatId} {...innerProps} />)} />
                <Route exact path="/chat/team/:teamId" render={(innerProps) => (<Chat dashboard={props.dashboard} teamId={innerProps.match.params.teamId} {...innerProps} />)} />
                <Route exact path="/chat/:chatId" render={(innerProps) => (<Chat dashboard={props.dashboard} chatId={innerProps.match.params.chatId} {...innerProps} />)} />
		        <Route path="/chat" render={(innerProps) => (<Chat dashboard={props.dashboard} {...innerProps}/>)} />
            </Switch>
        </div>
    );
})

const BaseWithoutNav = withRouter((props) => {
    return (
        <div className="base">

            <Helmet>
                <title>{TAB_NAMES[window.location.pathname.split('/')[1]]} – Jouncer</title>
            </Helmet>
            <Switch>
                <Route exact path="/suggest-someone-interesting" render={innerProps => <Suggestions {...props} {...innerProps} />} activeStyle={{color:"#17cc65"}}/>
                <Route exact path="/github-signup/:index" render={innerProps => <GithubSignup {...props} {...innerProps} signUpLogin={props.signUpLogin}/>}/>
                <Route exact path="/github-signup" render={() => <Redirect to='/github-signup/0' />} />
                <Route exact path="/privacy" component={Privacy}/>
                <Route path="/testhome" render={() => <TestHome {...props} signUpLogin={props.signUpLogin}/>}/>
                <Route path="/welcomemat" render={() => <Home {...props} signUpLogin={props.signUpLogin}/>}/>
                <Route path="/email-signup" render={() => <EmailSignup {...props} signUpLogin={props.signUpLogin}/>}/>
                <Route path="/shoesoff" render={() => <SignUp {...props} signUpLogin={props.signUpLogin}/>}/>
                <Route exact path="/forgot-password/:index" component={ForgotPassword}/>
                <Route exact path="/forgot-password/:index/:key" component={ForgotPassword}/>
            </Switch>
        </div>
    );
})

const Logout = (props) => {
    props.logout();
    return (<Redirect to="/welcomemat/"/>)
}

export {UserContext};
export default withCookies(App);
