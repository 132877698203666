import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import '../css/ViewJoinRequests.css';

import {Column, Row,} from 'simple-flexbox';
import TextField from '@material-ui/core/TextField';

import MemberIcon from '../components/MemberIcon';
import utils from '../utils';
import config from '../config';

// Other
var hdate = require('human-date');

class ViewJoinRequests extends Component {
	constructor(props) {
		super(props);
		this.state = {
			teams: null,
		}

        this.redirectToChat = this.redirectToChat.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.acceptJoinRequest = this.acceptJoinRequest.bind(this);
        this.declineJoinRequest = this.declineJoinRequest.bind(this);
	}

	componentDidMount() {

		utils.get("/all/join_requests").then(function({status, data}){
            if (!status) throw "Request returned error!";
			var joinRequests = data.all_applicants;
            console.log(joinRequests);

			// get all unique teams
			var teams = [];
			var teamData = [];
			for (var i = 0; i < joinRequests.length; i++) {
				if (teams.includes(joinRequests[i].team.id) === false) {
					teams.push(joinRequests[i].team.id);

					joinRequests[i].team.joinRequests = [];
					teamData.push(joinRequests[i].team);
				}
			}

			// organize data
			for (var i = 0; i < joinRequests.length; i++) {
				var teamIndex = -1;

				// get index of team
				for (var j = 0; j < teams.length; j++) {
					if (teams[j] === joinRequests[i].team.id) {
						teamIndex = j;
					}
				}

				// push user data to team
				teamData[teamIndex].joinRequests.push(joinRequests[i]);
			}

			// format data
			var newTeamData = [];
			for (var i = 0; i < teamData.length; i++) {
				// get skills
				var relevantSkills = [];
				for (var j = 0; j < teamData[i].team_skills.length; j++) {
					relevantSkills.push({skill: teamData[i].team_skills[j].name, active: true,})
				}

				var requests = [];
				for (var j = 0; j < teamData[i].joinRequests.length; j++) {
                    let curRequest = teamData[i].joinRequests[j];
					var request = {
                        id: curRequest.unique_id,
						name: curRequest.user.first_name + " " + curRequest.user.last_name,
						readableTime: hdate.relativeTime(new Date(curRequest.date)),
						relevantSkills: relevantSkills,
						imageLink: curRequest.user.images.length > 0 ? utils.image(curRequest.user.images[0]) : null,
						message: curRequest.description,
                        convoId: curRequest.conversation_uuid,
					};
					requests.push(request);
				}

				var newData = {
                    id: teamData[i].unique_id,
					teamName: teamData[i].name,
					requests: requests,
				};

				newTeamData.push(newData);
			}

			this.setState({teams: newTeamData});

		}.bind(this)).catch(function(err) {
			console.log(err);
		});
	}

    redirectToChat(teamId, convoId){
        this.props.history.push(`/chat/${convoId}`);
    }

    acceptJoinRequest(id){
        const self = this;
        utils.post('/all/invite_to_team', {join_request_uuid: id}).then(({status, data}) => {
            if (!status) throw "Err with response!";

            // logic for accept
            console.log(data);
            self.setState({teams: self.state.teams.map(t => {
                t.requests = t.requests.filter(r => r.id !== id);
                return t;
            }).filter(t => t.requests.length > 0)});
        }).catch(err => console.log(err));
    }

    declineJoinRequest(id){
        const self = this;
        utils.post('/all/decline_into_team', {join_request_uuid: id}).then(({status, data}) => {
            if (!status) throw "Err with response!";
            // logic for decline
            console.log(data);
            self.setState({teams: self.state.teams.map(t => {
                t.requests = t.requests.filter(r => r.id !== id);
                return t;
            }).filter(t => t.requests.length > 0)});
        }).catch(err => console.log(err));
    }

    handleMessage(teamId, convoId = null, joinRequestId = null){
        if (convoId) {
            this.redirectToChat(teamId, convoId);
            return;
        }
        console.log("Convo doesn't exist. Creating...");
        const self = this;
        utils.post("/all/initiate_join_request_chat", {
            join_request_uuid: joinRequestId
        }).then(({status, data}) => {
            if (!status) throw "Error with chat init!";
            self.redirectToChat(teamId, data.conversation_uuid);
        }).catch(err => console.log(err));
    }

	render() {
        if (this.state.teams === null) return null;
		var joinRequestsLayout = this.state.teams.map(function(team) {
			var teamName = team["teamName"];
			var requests = team["requests"];
            let teamId = team.id;

			var teamRequestLayout = requests.map(function(teamRequest) {
				var personName = teamRequest["name"];
				var readableTime = teamRequest["readableTime"];
				var relevantSkills = teamRequest["relevantSkills"];
				var image = teamRequest["imageLink"];
				var message = teamRequest["message"];
                let {convoId, id} = teamRequest;

				var skillsLayout = relevantSkills.map(function(skill) {
					if (skill["active"] === true) {
						return (<div className="view-join-requests-skill-tag-active">{skill["skill"]}</div>);
					} else {
						return (<div className="view-join-requests-skill-tag-inactive">{skill["skill"]}</div>);
					}
				}.bind(this));

				return (<div className="view-join-requests-card">
					<Column>

						<div className="view-join-requests-card-header">
							<Row vertical="center">
								<MemberIcon
                                    className="view-join-requests-card-header-profile-image"
                                    id={"asdf"}
                                    name={personName}
                                    imgSrc={image}
                                    />
								<Column>
									<div className="view-join-requests-card-header-name">{personName}</div>
									<div className="view-join-requests-card-header-date">{readableTime}</div>
								</Column>
								<Row className="view-join-requests-card-header-skills">{skillsLayout}</Row>
								<button className="btn btn-secondary" onClick={() => this.handleMessage(teamId, convoId, id)}>Chat</button>
							</Row>
						</div>

						<div className="view-join-requests-card-content">
							{message}
						</div>

						<div className="view-join-requests-card-footer">
							<Row horizontal="center">
								<button className="view-join-requests-card-footer-decline-button" onClick={() => this.declineJoinRequest(id)}>Decline</button>
								<button className="view-join-requests-card-footer-invite-button" onClick={() => this.acceptJoinRequest(id)}>Invite To Team</button>
							</Row>
						</div>

					</Column>
				</div>);
			}.bind(this));

			return (<div className="view-join-requests-view-container">
				<Column>
					<div className="view-join-requests-view-container-name">{teamName}</div>
					{teamRequestLayout}
				</Column>
			</div>);
		}.bind(this));


		return (<div className="view-join-requests-view-background">
			<div className="view-join-requests-view-main">
				<div className="view-join-requests-view-content">
					<Column horizontal="center">
						<div className="view-join-requests-view-header">{
                                this.state.teams.length == 0
                                    ? "No new requests to collaborate on your projects"
                                    : "You have new requests to collaborate on your projects:"
                        }</div>
						{joinRequestsLayout}
					</Column>
				</div>
			</div>
		</div>)
	}
}

export default ViewJoinRequests;
