// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Badge from "./Badge.js";

class SubNavItem extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let txt = this.props.txt;
        let active = this.props.active;

        return (
            <li className="nav-item" onClick={this.props.onClick}>
                <Badge txt={txt} active={active} className="primary" />
            </li>
        );
    }
}

export default SubNavItem;
