import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import {Link} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import CommentSection from './CommentSection';
import Tooltip from './Tooltip';
import utils from '../utils';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
        this.images = props
            .images
            .map(utils.image);
    }

    previous() {
        const slots = this.props.images.length;
        let newIndex = this.state.index - 1;
        if (newIndex < 0) {
            newIndex = slots - 1;
        }
        return newIndex;
    }

    next() {
        const slots = this.props.images.length;
        return (this.state.index + 1) % slots;
    }

    handleBack() {
        this.setState({
            index: this.previous()
        });
    }

    handleForward() {
        this.setState({
            index: this.next()
        });
    }

    handleImageClick(i) {
        this.setState({index: i});
    }

    componentDidMount() {
        window.addEventListener('keydown', ({keyCode: which}) => {
            //left arrow
            if (which === 37) {
                this.handleBack();
                //right arrow
            } else if (which === 39) {
                this.handleForward();
            }
        })
    }


    render() {
        const {images} = this;
        const {index} = this.state;
        return (
            <div className="Carousel">
                <div className="centred row">
                    <div
                        className="chevron-left"
                        onClick={this
                        .handleBack
                        .bind(this)}>
                        <i className="highlight fas fa-chevron-left"/>
                    </div>
                    <img className="main-image" src={images[index]} alt=""/>
                    {/*<div className="main-image" style={{
                        backgroundImage: `url('${images[index]}')`,

                    }}/>*/}
                    <div
                        className="chevron-right"
                        onClick={this
                        .handleForward
                        .bind(this)}>
                        <i className="highlight fas fa-chevron-right"/>
                    </div>
                </div>
                <div className="h-centred row">
                    {images.map(((img, i) => (<div
                        className={"image" + (i ===index
                        ? " active"
                        : "")}
                        onClick={() => this.handleImageClick(i)}
                        style={{
                        backgroundImage: `url('${img}')`
                    }}/>)).bind(this))}
                </div>
            </div>
        )
    }
}

export {Carousel};

export default({
    projectid,
    images,
    title,
    description,
    open,
    handleClose,
    project_skills,
    external_link,
    creatorImg,
    creatorName,
    creatorId,
    github_url,
    date_added,
    comments
}) => {

    let creatorPic = {
       background: `url('${utils.prefixImage(creatorImg)}')`,
       width: '35px',
       height: '35px',
       backgroundSize: "cover",
       backgroundPosition: "center center",
       backgroundRepeat: "no-repeat",
    }

    const normalizeUrl = require('normalize-url');
    let extLink = external_link ? normalizeUrl(external_link) : "";

    return (
        <Modal className="PortfolioModal" open={open} onClose={() => handleClose()}>
            <div className="centred">

                <div className="close" onClick={() => handleClose()}>
                    <span className="highlight fas fa-times"/>
                </div>

                <div className="modal-card">

                    <div className="modal-card-content">
                        {images.length > 0
                            ? <Carousel images={images}/>
                            : null}
                        <div className="information">
                            <div className="row split">
                                <span className="title">{title}
                                    {github_url ? <a href={github_url} target="_blank" className="tooltip-anchor">
                                        <i class="fab fa-github"></i>
                                        <Tooltip name="Imported from Github" orientation="above"/>
                                    </a> : null}
                                </span>
                                <div className="skills v-centred wrap">
                                    {project_skills.map(({skills}) => (skills.map(({name}) => (
                                        <div className="skill">{name}</div>
                                    ))))}
                                </div>
                            </div>

                            <p>{description}</p>
                            {external_link ? <a href={extLink} target="_blank" className="highlight">{extLink}</a> : null}

                            <Link to={`/creators/${creatorId}`} className="project-creator">
                                {creatorImg ? <div className="commenter-pic" style={creatorPic}/> : null}
                                <p>{creatorName ? creatorName : null} {date_added ? `· Posted ${utils.fromNow(date_added)}` : null}</p>
                            </Link>


                        </div>
                        <div className="portfolio-comments">
                            <a>{comments ? comments.length : "No"} Comments</a>
                            {comments ? <CommentSection comments={comments} projectid={projectid}/> : null}
                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    )
}
