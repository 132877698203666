import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import config from '../config';
import utils from '../utils';

import TeamSidebar from '../components/TeamSidebar';
import {TeamTopProfile} from '../components/TeamTopProfile';
import {TeamBottomProfile} from '../components/TeamBottomProfile';

// import feather from 'feather-icons';

import '../css/App.css';
import '../css/TeamProfile.css';
import '../css/CreatorProfile.css';
// import mock from '../mock';

const getTeam = async(id) => {
    const url = `${config.BACKEND_HOST}/all/team_page/query/?page_team_uuid=${id}`;
    return utils.get(url);
}

const getUser = async() => {
    const url = `${config.BACKEND_HOST}/all/dashboard/query/`;
    const {status, data: {
            current_user
        }} = await utils.get(url);
    if (status) {
        return current_user;
    }
}

export default class TeamProfileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: {},
            team_members: [],
            user: {},
            activity: [],
            id: -1
        };
        //TODO: REMOVE
        this.cache = [];
    }

    async componentDidMount() {
        this.forceUpdate();
    }

    async componentDidUpdate() {
        if (Object.keys(this.state.user).length === 0) {
            getUser().then(user => this.setState({user}))
        }

        const {id} = this.props.match.params;
        if (id === this.state.id) {
            return;
        }
        if (id in this.cache) {
            this.setState(this.cache[id]);
            return;
        }
        await getTeam(id).then((({status, ...data}) => {
            console.log(status, data);
            if (status) {
                const newState = {
                    ...data.data,
                    id
                };
                this.cache[id] = newState;
                this.setState(newState);
            }
        }).bind(this)).catch((err => this.props.history.replace('/not-found')).bind(this));

    }

    // static async getDerivedStateFromProps(props, state) {     const {id} =
    // props.match.params;     alert(`props: ${id} state: ${state.id}`)     if
    // (state.id !==  id) {         const {status, data} = await getUser(id);
    // alert(status)         if (status) {             return { ...data,     id   };
    //         }     }     return null; }



    render() {
        return (
            <div className="main profile-main">
                <div className="content">
                    {this.state.id === -1
                        ? <div className="centred">
                                <Helmet>
                                    <title>Loading...</title>
                                </Helmet>
                                <utils.Spinner/></div>
                        : <div className="TeamProfileView">
                            <Helmet>
                                <title>{`${this.state.team.name} – Jouncer`}
                                    </title>
                            </Helmet>

                            <div>
                                <TeamSidebar {...this.state}/>
                                {this.state.team.external_link ? <TeamTopProfile {...this.state}/> : null}
                                <TeamBottomProfile {...this.state} notUser={this.props.notUser} currentuser={this.state.user.user}/>
                            </div>

                        </div>}
                </div>
            </div>
        )
    }
}
