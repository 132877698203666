// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import S3FileUpload from 'react-s3';

import Header from './Header';
import NextSection from './NextSection';
import Card from '../Card';
import Badge from "../Badge.js";
import PortfolioItem from "../PortfolioItem.jsx";
import SkillsMenu from '../SkillsMenu.jsx';

import Octicon, {Eye, Octoface} from '@primer/octicons-react';

import utils from '../../utils';
import config from '../../config';
const uniqueString = require('unique-string');
const _ = require("lodash");




const ProjectCard = ({
    id,
    title,
    profile_img,
    titleErrors,
    description,
    mission,
    link,
    skills,
    skillBuckets,
    editSkills,
    media,
    newMedia,
    isNew,
    changed,
    save,
    remove,
    handleChange,
    handleMediaChange,
    profilePhotoDrop,
    handleClick,
    handleClose,
    handleDelete,
    openModal,
    removeItem,
    hidden = false,
}, i) => {
    const style = {
        display: hidden
            ? 'none'
            : null
    };
    let img = "";

    if (profile_img && profile_img.preview) {
        img = profile_img.preview;
    } else if (profile_img) {
        img = utils.prefixImage(profile_img);
    } else if (media.length > 0 && media[0].preview) {
        img = media[0].preview;
    } else if (media.length > 0) {
        img = utils.image(media[0]);
    }

    return (
        <div>
            <PortfolioItem
                team={true}
                id={id}
                img={profile_img.preview ? profile_img : utils.prefixImage(profile_img)}
                title={title}
                mission={mission}
                titleErrors={titleErrors}
                description={description}
                link={link}
                skills={skills}
                skillBuckets={skillBuckets}
                editSkills={editSkills}
                media={media}
                isNew={isNew}
                changed={changed}
                save={save}
                remove={remove}
                profilePhotoDrop={profilePhotoDrop}
                handleChange={handleChange}
                handleMediaChange={handleMediaChange}
                handleClick={handleClick}
                handleClose={handleClose}
                handleDelete={handleDelete}
                openModal={openModal}
                open={openModal === id}/>

            <Card
                id={id}
                title={title}
                description={mission ? utils.truncateTo(mission, 40) : mission}
                img={img}
                images={media}
                newMedia={newMedia ? true : false}
                onClick={() => handleClick(id)}
                style={style}
                removeItem={removeItem}
                remove={true}/>
        </div>
    )
};



const SkillsModal = (props) => {
    const {isOpen, toggle} = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="skills-modal modal-dialog-centered modal-lg">
            <ModalHeader toggle={toggle}>Select the skills you used for this project</ModalHeader>
            <ModalBody>
                <SkillsMenu {...props} />
            </ModalBody>
        </Modal>
    );
}


class View1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            profile_img: "",
            userId: "",
            projectId: "",
            joinRequests: [],
            activeProjects: [],
            unusedRepos: [],
            skillBuckets: [],
            nextSectionConfirm: false,
            newMediaLinks: [],
            coverLinks: [],
            uploading: false,
            scrolltoitem: false,
            deleteJoinRequestConfirm: "",
            test: "",
            modal: false,
            loading: false,
        };

        this.getProjects = this.getProjects.bind(this);
        this.getSkills = this.getSkills.bind(this);
        this.getJoinRequests = this.getJoinRequests.bind(this);
        this.getUnusedRepos = this.getUnusedRepos.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.selectedGithubRepo = this.selectedGithubRepo.bind(this);
        this.deleteJoinRequest = this.deleteJoinRequest.bind(this);
        this.joinRequests = this.joinRequests.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleBucketClick = this.handleBucketClick.bind(this);
        this.handleSkillClick = this.handleSkillClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.imageUpload = this.imageUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.profilePhotoDrop = this.profilePhotoDrop.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.saveNotif = this.saveNotif.bind(this);
        this.addItem = this.addItem.bind(this);
        this.validateItem = this.validateItem.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.nextSectionConfirm = this.nextSectionConfirm.bind(this);
    }

    componentWillMount() {
        const url = window.location.href;
        const id = url.split('=')[1];
        this.setState({userId: id});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getProjects();
        this.getSkills();
        this.getJoinRequests();
        this.getUnusedRepos();
    }
    getSkills() {
        const url = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
        const self = this;
        const params = {
            userprofile_unique_id: this.state.userId,
        }
        utils
            .get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    let {skills, all_skill_buckets} = res.data;
                    self.setState({
                        skillBuckets: all_skill_buckets
                            .filter(bucket => !bucket.hidden)
                            .map(bucket => {
                                let hasActiveSkill = false;
                                bucket.id = bucket.unique_id;
                                bucket.skills = bucket
                                    .skills
                                    .filter(skill => !skill.hidden && (!skill.is_user_added || skills.indexOf(skill.id) >= 0)) // not hidden AND (if user created, display if user has that skill)
                                    .map(skill => {
                                        skill.key = skill.id;
                                        skill.label = skill.name;
                                        skill.value = skill
                                            .id
                                            .toString();
                                        skill.active = skills.indexOf(skill.id) >= 0;
                                        hasActiveSkill = hasActiveSkill || skill.active;
                                        return skill;
                                    });
                                bucket.active = hasActiveSkill;
                                return bucket;
                            })
                    });
                }
            })
            .catch(err => {
                console.log(err.message);
                //try again?
            });
    }

    getProjects() {
        const url = `${config.BACKEND_HOST}/accounts/teams_data`;
        const params = {
            userprofile_unique_id: this.state.userId,
        }
        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    self.setState({
                        activeProjects: res
                            .data
                            .teams
                            .map(team => ({
                                    ...team,
                                    unique_id: team.unique_id,
                                    link: team.external_link,
                                    skills: _.flatten(team.team_skills.map(b => b.skills.map(s => s.id))),
                                    media: team.media,
                                    profile_img: team.profile_img,
                                    isNew: false,
                                    changed: false
                                })),
                        projectId: res.data.teams[0] ? res.data.teams[0].unique_id : ""
                    });
                    self.addItem();
                    return;
                }
                throw "Response status false";
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
            });
    }

    getJoinRequests() {
        const url = `${config.BACKEND_HOST}/accounts/join_requests_to_send`;
        const params = {
            userprofile_unique_id: this.state.userId,
        }
        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    this.setState({ joinRequests: res.data.join_requests });
                    return;
                }
                throw "Response status false";
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
            });
    }

    getUnusedRepos() {
        const url = `${config.BACKEND_HOST}/accounts/unused_repos`;
        const params = {
            userprofile_unique_id: this.state.userId,
        }
        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){

                    let newUnusedRepos = res.data.github_repos;
                    for (let i = newUnusedRepos.length - 1; i >= 0; i--){
                        if ( newUnusedRepos[i] && newUnusedRepos[i].already_used === true) {
                           newUnusedRepos.splice(i, 1);
                       }
                    }

                    this.setState({ unusedRepos: res.data.github_repos });
                    return;
                }
                throw "Response status false";
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
            });
    }

    selectedGithubRepo(id) {

        let newUnusedRepos = this.state.unusedRepos;
        for (let i = 0; i < newUnusedRepos.length; i++){
           if ( newUnusedRepos[i].id === id) {
               newUnusedRepos.splice(i, 1);
               this.setState({unusedRepos: newUnusedRepos});
           }
        }

        this.setState({ uploading: true });
        this.setState({ scrolltoitem: true });

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/import_team_from_github`;
        const params = {
            userprofile_unique_id: this.state.userId,
            repo_id: id
        }

        utils.post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({ uploading: false });
                    self.setState(prevState => {
                        return {
                            activeProjects: [
                                ...prevState.activeProjects,
                                {
                                    ...res.data.team,
                                    skills: _.flatten(res.data.team.team_skills.map(b => b.skills.map(s => s.id))),
                                },
                            ]
                        }

                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    removeItem(id) {
        console.log("removing " + id);

        let item = this.state.activeProjects.filter(item => item.unique_id  ===  id)[0];

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/delete_team`;
        const params = {
            userprofile_unique_id: this.state.userId,
            team_unique_id: item.unique_id
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        activeProjects: self
                            .state
                            .activeProjects
                            .filter(item => item.unique_id != id),
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });

    }

    deleteJoinRequest(id) {
        this.setState({ deleteJoinRequestConfirm: id });
    }

    deleteJoinRequestConfirm(id) {
        console.log("removing " + id);

        let item = this.state.joinRequests.filter(item => item.unique_id  ===  id)[0];

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/delete_join_request`;
        const params = {
            join_request_unique_id: id
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        joinRequests: self
                            .state
                            .joinRequests
                            .filter(item => item.unique_id != id),
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });

    }

    changeProjectPreview(id) {
        this.setState({ projectId: id });
    }

    openModal(itemId){
        //transfer skills state
        let {skills} = _.find(this.state.activeProjects, {unique_id: itemId});

        this.setState({
            modal: true,
            activeItem: itemId,
            skillBuckets: this.state.skillBuckets
                .map(bucket => {
                    let hasActiveSkill = false;
                    bucket.skills = bucket
                        .skills
                        .map(skill => {
                            skill.active = skills.indexOf(skill.id) >= 0;
                            hasActiveSkill = hasActiveSkill || skill.active;
                            return skill;
                        });
                    bucket.active = hasActiveSkill;
                    return bucket;
                }),
        });
    }

    closeModal(){
        // save skills to portfolio project
        let skills = [];
        this.state.skillBuckets.forEach(b => {
            b.skills.forEach(s => {
                if (s.active){
                    skills.push(s.id);
                }
            });
        });

        this.setState({
            modal: !this.state.modal,
            activeProjects: this.state.activeProjects.map(p => {
                if (p.unique_id == this.state.activeItem){
                    p.skills = skills;
                    p.changed = true;
                }
                return p;
            }),
            skillBuckets: this.state.skillBuckets
                .map(bucket => {
                    bucket.active = false;
                    bucket.skills = bucket
                        .skills
                        .map(skill => {
                            skill.active = false;
                            return skill;
                        });
                    return bucket;
                }),
        });
    }
    handleBucketClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (id == bucket.id) {
                        bucket.active = !bucket.active;
                    }
                    return bucket;
                });
            return prevState;
        });
    }

    handleSkillClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    bucket.skills = bucket.skills.map(skill => {
                        if (id == skill.id) {
                            skill.active = !skill.active;
                        }
                        return skill;
                    });
                    return bucket;
                });
            return prevState;
        });
    }

    profilePhotoDrop(accepted, rejected, id, isNew) {
        //display preview
        if (accepted.length > 0) {
            this.setState({
                activeProjects: this
                    .state
                    .activeProjects
                    .map(item => {
                        if (item.unique_id  ===  id) {
                            item["profile_img"] = accepted[0];
                            item.changed = true;

                        }

                        return item;
                    })
                });

            const config2 = {
                bucketName: 'stagingjouncerfrontend',
                dirName:'static/media',
                region: 'us-east-2',
                accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
                secretAccessKey: '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',
            }

            //coverLinks

            S3FileUpload
            .uploadFile(accepted[0], config2)
            .then(data => {
                console.log("s3 file uploaded properly", data);

                if (isNew) {
                    this.state.coverLinks.push(data.location);

                } else {
                    const url = `${config.BACKEND_HOST}/teams/add_team_cover_image`;
                    const self = this;
                    //need some way of discerning which one is the cover
                    const params = {
                        team_uuid: id,
                        uploaded_url: data.location
                    };

                    utils.postMultipartNoToken(url, params)
                        .then(raw => raw.json())
                        .then((res) => {
                            console.log(res);
                            self.setState({ submitEnabled: true });
                        })
                        .catch((err) => {
                            console.log(err);
                            self.setState({ submitEnabled: true });
                            //error logic
                        });
                }
            })
            .catch(err => console.error(err));

        }
    }

    handleMediaChange(values, id = undefined, isNew) {
        const config2 = {
            bucketName: 'stagingjouncerfrontend',
            dirName:'static/media',
            region: 'us-east-2',
            accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
            secretAccessKey: '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',
        }

        //submit logic here
        const url = `${config.BACKEND_HOST}/teams/add_team_standard_image`;
        const self = this;
        //need some way of discerning which one is the cover

        S3FileUpload
        .uploadFile(values[values.length - 1], config2)
        .then(data => {
            console.log("s3 file uploaded properly", data);

            if (isNew) {
                let newMediaList = this.state.newMediaLinks;
                newMediaList.push(data.location);

                self.setState({
                    activeProjects: this
                        .state
                        .activeProjects
                        .map(item => {
                            if (item.unique_id  ===  id) {
                                item["media"] = values;
                                item.newMedia = true;
                                item.changed = true;
                            }
                            return item;
                        }),
                    newMediaLinks: newMediaList });
            } else {
                const params = {
                    team_uuid: id,
                    uploaded_url: data.location
                };

                utils.postMultipartNoToken(url, params)
                    .then(raw => raw.json())
                    .then((res) => {
                        console.log(res);
                        self.setState({
                            activeProjects:
                                this.state
                                .activeProjects
                                .map(item => {
                                    if (item.unique_id  ===  id) {
                                        item["media"] = values;
                                        item.newMedia = true;
                                        item.changed = true;
                                    }
                                    return item;
                                }),
                            submitEnabled: true
                        });
                    })
                    .catch((err) => {
                        if (typeof err ==="object"){
                            console.log(err.response.body);
                        }
                        else{
                            console.log(err);
                        }
                        self.setState({submitEnabled: true});
                        //error logic
                    });
            }
        })
        .catch(err => console.error(err));
    }

    imageUpload(cover, values, id = undefined) {
        const urlcover = `${config.BACKEND_HOST}/teams/add_team_cover_image`;
        const url = `${config.BACKEND_HOST}/teams/add_team_standard_image`;
        const self = this;

        const paramscover = {
            team_uuid: id,
            uploaded_url: cover[0]
        }

        utils.postMultipartNoToken(urlcover, paramscover)
            .then(raw => raw.json())
            .then((res) => {
                console.log(res);
                self.setState({submitEnabled: true});
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
                //error logic
            });

        if (values.length > 0) {

            values.map( link => {
                const params = {
                    team_uuid: id,
                    uploaded_url: link
                };

                utils.postMultipartNoToken(url, params)
                    .then(raw => raw.json())
                    .then((res) => {
                        console.log(res);
                        self.setState({submitEnabled: true});
                    })
                    .catch((err) => {
                        console.log(err);
                        self.setState({submitEnabled: true});
                        //error logic
                    });
            })

        }
    }

    handleChange(name, value, id = undefined) {
        this.setState({
            activeProjects: this
                .state
                .activeProjects
                .map(item => {
                    if (item.unique_id  ===  id) {
                        item[name] = value;
                        item.changed = true;
                    }
                    return item;
                })
        });
    }
    submitClick(event) {
        event.preventDefault();
        if (this.state.submitEnabled && (event.target.name  ===  "submit" || event.target.name  ===  "save")) {
            this
                .state
                .activeProjects
                .forEach(item => {
                    if (item.changed) {
                        this.saveItem(item.unique_id);
                    }
                });

            this.saveNotif();
            if (event.target.name  ===  "submit") {
                const self = this;
                let checkSuccess = setInterval(() => {
                    if (self.state.activeProjects.filter(item => item.changed).length  ===  0) {
                        self.setState({submitSuccess: true});
                        clearInterval(checkSuccess)
                    }
                }, 500);
            }
        }
    }

    addItem() {
        let items = this.state.activeProjects;
        items.push({
            unique_id: uniqueString(),
            profile_img: "",
            title: "",
            mission: "",
            description: "",
            link: "",
            team_skills: [],
            skills: [],
            media: [],
            isNew: true,
            changed: false,
        });
        this.setState({portfolioItems: items});
    }

    validateItem(item){
		if (!item.title || item.title.length == 0){
			item.titleErrors = ["Please give your project a title"];
			return false;
		}
		return true;
	}

    saveItem(id, newTeam) {
        if (this.state.submitEnabled) {
            this.setState({submitEnabled: false});
            this.setState({
				activeProjects: this.state.activeProjects.map(item => {

                    if (item.unique_id  ===  id) {

                        if (!this.validateItem(item)) {
                            return item;
                        }

                        //api call
                        const self = this;
                        const url = `${config.BACKEND_HOST}/accounts/teams_data`;

                        let params = {
                            userprofile_unique_id: this.state.userId,
                            team_name: item.title,
                            team_mission: item.mission ? item.mission : "",
                            team_description: item.description ? item.description : "",
                            team_link: item.link ? item.link : "",
                            selected_skills: item.skills,
                            existing_file_uuids: item
                                .media
                                .filter(m => "unique_id" in m)
                                .filter(m => m.unique_id != "fake-unique_id" )
                                .map(m => m.unique_id),
                            is_cover_in_existing: item.media.length > 0
                        };

                        if (!newTeam) {
                            params.team_unique_id = id;
                        }

                        utils.postMultipartNoToken(url, params)
                            .then(raw => raw.json())
                            .then(res => {
                                if (res.status) {
                                    console.log(res.status);
                                    if (item.isNew) {
                                        this.addItem();
                                        if (this.state.newMediaLinks.length > 0 || this.state.coverLinks.length > 0) {
                                            this.imageUpload(this.state.coverLinks, this.state.newMediaLinks, res.team.unique_id);
                                        }
                                    }
                                    this.saveNotif();
                                    self.setState({
                                        activeProjects: self
                                            .state
                                            .activeProjects
                                            .map(item => {
                                                if (item.unique_id  ===  id) {
                                                    item.unique_id = res.team.unique_id; //insert returned ID here
                                                    item.isNew = false;
                                                    item.changed = false;
                                                }
                                                return item;
                                            })
                                    });

                                }
                            })
                            .catch(err => {
                                console.log(err);
                            })
                            . finally(() => self.setState({submitEnabled: true}));
                    }
					return item;
                })
			});
        }
    }

    handleDelete(values, unique_id) {

        this.setState({
            activeProjects:
                this.state
                .activeProjects
                .map(item => {
                    if (item.unique_id  ===  unique_id) {
                        item["media"] = item.media.filter(media => media.unique_id !== values[0].unique_id);
                        item.newMedia = true;
                        item.changed = true;
                    }
                    return item;
                }),
            });

        const url = `${config.BACKEND_HOST}/teams/delete_image`;
        const self = this;

        const params = {
            image_uuid: values[0].unique_id
        };

        utils.postMultipartNoToken(url, params)
            .then(raw => raw.json())
            .then((res) => {
                console.log(res);
                self.setState({submitEnabled: true});
            })
            .catch((err) => {
                if (typeof err ==="object"){
                    console.log(err.response.body);
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    saveNotif() {
        this.setState({saved: true});

        setTimeout(() => {
            this.setState({
            saved: false
          })
      }, 3000);
    }

    handleClose(id, newProject, title) {
        this.setState({openPortfolioModal: -1});
        this.setState({submitEnabled: true});
        this.saveItem(id, newProject);
    }

    handleClick(id) {
        this.setState({openPortfolioModal: id});
    }

    nextPage(event) {
        event.preventDefault();

        this.setState({loading: true});

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/teams_data`;
        const params = {
            userprofile_unique_id: this.state.userId,
            import_projects: true
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState({
                        submitSuccess: true,
                        loading: false
                    });
                    console.log(":)");
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    joinRequests() {

        return (this.state.joinRequests.map(({unique_id, team__unique_id, team__name, team__mission, team__profile_img, team__admin__first_name, team__admin__image, team__admin__userprofile__unique_id}) =>
            <div className="join-request-card">
                <div className="team-cover"
                    style={{
                        backgroundImage: team__profile_img ? `url('${utils.prefixImage(team__profile_img)}')` : utils.randomGradient(team__unique_id),
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center" }}>
                        {!team__profile_img && team__name.length > 0 ? team__name[0].toUpperCase() : ""}
                </div>
                <div className="info">
                    <div className="description">
                        <h2>{team__name}</h2>
                        <p>{team__mission}</p>
                    </div>
                    <div className="extras">
                        <div className="members">
                            <div className="member-img"
                                style={{
                                    backgroundImage: team__admin__image ? `url('${utils.prefixImage(team__admin__image)}')` : utils.randomGradient(team__admin__userprofile__unique_id),
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center" }}>
                                {!team__admin__image && team__admin__first_name.length > 0 ? team__admin__first_name[0].toUpperCase() : ""}
                            </div>
                            <div>
                                <p>{team__admin__first_name}</p>
                                <p className="admin">ADMIN</p>
                            </div>
                        </div>
                        <div className="request-btn">
                            <div className={"btn " + `${this.state.deleteJoinRequestConfirm === unique_id ? "confirming" : ""}`} onClick={() => (
                                this.state.deleteJoinRequestConfirm === unique_id
                                ? this.deleteJoinRequestConfirm(unique_id)
                                : this.deleteJoinRequest(unique_id))}>
                                    {this.state.deleteJoinRequestConfirm === unique_id ? "Yes, I'm 💯 Sure" : "Leave Team"}
                            </div>
                            {this.state.deleteJoinRequestConfirm === unique_id ? <p className="regret" onClick={() => this.setState({deleteJoinRequestConfirm: ""})}>Wait.... Nevermind</p> : null}
                        </div>
                    </div>
                </div>
            </div>));
    }


    nextSectionConfirm() {
        this.setState({ nextSectionConfirm: true });
    }


    render() {
        const pIndex = 0;
        if (this.state.submitSuccess && this.state.nextSectionConfirm) {
            return <Redirect to={`${(pIndex + 1).toString()}?id=${this.state.userId}`} push/>;
        }

        let projectPreview = `https://jouncer.com/project/${this.state.projectId}`;

        if (this.state.uploading === false && this.state.scrolltoitem === true) {
            document.getElementById("active-projects-title").scrollIntoView({ block: 'start',  behavior: 'smooth' });
            this.setState({ scrolltoitem: false });
        }

        return (
            <div className="github-signup-page d-flex justify-content-center align-items-center">
                <form action={(pIndex + 1).toString()} onSubmit={this.nextPage}>
                    <input type="hidden" name="id" value={this.state.userId} />

                    <Header id={this.state.userId} title="Active Projects" subtitle="We took a peek at your Github and pulled all of the projects you’ve commited to in the past two weeks." privateRepos={true}/>

                    <div className="content">
                        {this.state.joinRequests.length > 0 ?
                            <div className="join-requests">
                                <h2>Existing Collaborators</h2>
                                <p>Someone you're already working with has created these ongoing projects on Jouncer</p>

                                {this.joinRequests()}

                            </div> :
                        null}


                        <div className="active-projects">
                            <h2 id="active-projects-title">Active Projects</h2>
                            <p>Projects you're currently working on will have a progress timeline so you can share progress and receive feedback.</p>

                            <div className="active-project-cards">
                                {this.state.activeProjects.map(({unique_id, profile_img, newMedia, title, mission, description, team_skills, media, link, isNew, skills}) =>
                                    <div className="project-card" onClick={() => this.changeProjectPreview(unique_id)}>
                                        {title ?
                                            <div className="switch-preview">
                                                <Octicon className="icon" icon={Eye}/>
                                                <p>Preview this Project</p>
                                            </div>
                                        : null}
                                        <ProjectCard
                                            id={unique_id}
                                            title={title}
                                            mission={mission}
                                            description={description}
                                            link={link}
                                            skills={skills}
                                            skillBuckets={this.state.skillBuckets}
                                            editSkills={this.openModal}
                                            media={media}
                                            newMedia={newMedia}
                                            profilePhotoDrop={this.profilePhotoDrop}
                                            remove={this.removeItem}
                                            handleChange={this.handleChange}
                                            handleMediaChange = {this.handleMediaChange}
                                            handleClick={this.handleClick}
                                            handleClose={this.handleClose}
                                            handleDelete={this.handleDelete}
                                            openModal={this.state.openPortfolioModal}
                                            removeItem={this.removeItem}
                                            profile_img={profile_img}
                                            isNew={isNew}/>
                                    </div>)
                                }
                            </div>

                            <SkillsModal
                                isOpen={this.state.modal}
                                toggle={this.closeModal}
                                skillBuckets={this.state.skillBuckets}
                                handleBucketChange={this.handleBucketClick}
                                handleSkillChange={this.handleSkillClick}
                                />

                            {this.state.projectId !== "" ?
                                <div className="preview">
                                    <p className="preview-banner">Active Project Preview</p>
                                    <iframe id="project-preview" src={projectPreview}></iframe>
                                </div>
                            : null}


                            {this.state.uploading === true ?
                                <div className="importing-project-badge">
                                    <p>Importing Project...</p>
                                </div>
                            : null}
                        </div>

                        {this.state.unusedRepos.length > 0 ?
                            <div className="unused-repos">
                                <h3>Completed Projects</h3>
                                <p>These are projects you haven't worked on in the past two weeks. If you will be working on any of these this week, you can turn it into an active project.</p>
                                <div className="github-project-chips">
                                    {this.state.unusedRepos.map(
                                        ({id, name, full_name}) =>
                                        <div id={name} className="project-chip" onClick={() => this.selectedGithubRepo(id)}>{name}</div>
                                    )}
                                </div>
                            </div>
                        : null}

                        <NextSection nextTitle="Past Projects" nextDescription="We’ll help you showcase projects you’ve completed and are proud to share." nextSectionConfirm={this.nextSectionConfirm}/>

                        {this.state.loading === true ?
                            <div className="github-loading">
                                <p>Loading Past Projects from Github&nbsp;
                                <Octicon className="icon" icon={Octoface}/>
                                </p>
                            </div>
                        : null}
                    </div>
                </form>
            </div>
        );
    }
}

export default View1;
