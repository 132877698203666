import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

const pIndex = 4;

const View4 = (props) => {
    return (
        <div className="finish mx-auto my-auto">
            <div className="col">
                <h2 className="create-profile-headline">Congrats, you've finished your profile!</h2>
                <p className="create-profile-subheadline">You're sharing some great work with the community.<br/>Start finding amazing creators and spread the love.</p>
                <Link to="/creators" className="btn" onClick={() => window.dispatchEvent(new Event('update_dash'))}>Start Exploring</Link>
            </div>
        </div>
    )
}

export default View4;
