import React, {Component} from 'react';
import {Link} from "react-router-dom";
import utils from '../utils';
import config from '../config';

import NotificationCenter from './NotificationCenter.js';
import Tooltip from './Tooltip';
import Octicon, {Globe} from '@primer/octicons-react';

export class NewUserNav extends Component {

    constructor(props){
        super(props);

        this.state = {
            email: "",
            emailErrors: [],
            emailText: "default",
            submitEnabled: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendActivationEmail = this.sendActivationEmail.bind(this);
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    validateInput(){
        let hasError = false;
        if (!this.state.email || this.state.email.indexOf('@') == -1 || this.state.email.indexOf('.') == -1){
            this.setState({emailErrors: ["Please enter a valid email"]});
            hasError = true;
        }
        return !hasError;
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            emailErrors: [],
        });

        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            event.preventDefault();

            //submit logic here
            let self = this;
            const url = `${config.BACKEND_HOST}/accounts/email_signup`;
            let email = this.state.email;
            let params = {
                email_address: email.toLowerCase()
            };
            utils.post(url, params)
            .then(res => {
                console.log("this is the res:");
                console.log(res);
                if (res.status){
                    self.setState({submitSuccess: true});

                    if (!res.is_registered && !res.is_validated) {
                        self.setState({emailErrors: ["Check your email for your activation link!"]});
                    }

                    else if (res.is_registered && res.is_validated) {
                        self.setState({emailErrors: ["You already have an account! Sign in instead"]});
                    }

                    else if (res.is_registered && !res.is_validated) {
                        self.setState({emailErrors: ["registeredonly"]});
                    }
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    emailErrors: ["Something went wrong. Please try again later."]
                });
            });

        }
    }

    sendActivationEmail() {
        //submit logic here
        let self = this;
        const url = `${config.BACKEND_HOST}/accounts/send_activation_email`;
        let email = this.state.email;
        let params = {
            email_address: email.toLowerCase()
        };
        utils.post(url, params)
        .then(res => {
            if (res.status){
                console.log(res);
                self.setState({submitSuccess: true});
            }
            else{
                throw "Something went wrong."
            }
        })
        .catch(err => {
            console.log(err);
            self.setState({
                submitEnabled: true,
                emailErrors: ["Something went wrong. Please try again later."]
            });
        });
    }

    mainlinks = [
        '/activity',
        '/projects/',
        '/creators'
    ];

    renderMainNavLinks() {
        let activePage = window.location.pathname.split('/')[1];
        return(
            <div className="navbar-nav mr-auto">
                {this.mainlinks.map(link =>
                    <div className="nav-item">
                        <Link className={`nav-link ${activePage === link.split('/')[1] ? "active" : ""}`} to={link}>
                            {utils.TAB_NAMES[link.split('/')[1]]}
                        </Link>
                    </div>)}
            </div>
        );
    }

    render() {

        const logoContainerStyles = {
            backgroundImage: `url(${config.logoPath})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
        };

        return (
            <div className="navbar-collapse" id="navbarSupportedContent">

                <Link className="home-link" to="/welcomemat">
                    <div style={logoContainerStyles} className="logo-container"></div>
                    <h2>Jouncer</h2>
                </Link>

                {this.renderMainNavLinks()}

                <div className="newuser-nav">
                    <p className="newuser-text">To join the community, create an account</p>

                    <div className="newuser-buttons">

                        <Link to="/email-signup">
                            <div className="signup-btn">
                                Sign Up
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export class ExistingUserNav extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: {},
            stale: true,
            chatSocket: null,
            chatMessage: "",
            notifDisplay: false,
            notificationBubble: false,
        };
    }

    componentDidMount() {
        this.setState(() => {
            this.connectSocket();
        });

        this.getNotification();

        // chat notification state is retrieved from localstorage
        let storedChatNotifState = localStorage.getItem("chatMessage");
        storedChatNotifState = JSON.parse(storedChatNotifState);
        if (storedChatNotifState) {
            this.setState({chatMessage: storedChatNotifState});
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.user !== state.user || props.newNotif !== state.notificationBubble) {
    //         return {user: props.user, stale: false, notificationBubble: props.newNotif}
    //     }
    // }

    getNotification() {
        let url = `${config.BACKEND_HOST}/all/is_new_notif`;

        const self = this;
        utils.get(url)
            .then((res) => {
                console.log(res);
                if (res.status){
                    self.setState({
                        notificationBubble: res.new_notif,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        // before refresh, store chat notification state in localstorage
        localStorage.setItem("chatMessage", JSON.stringify(this.state.chatMessage));
    }

    mainlinks = [
        '/activity',
        '/projects/',
        '/creators'
    ];

    linksright = [
        '/chat',
        '/requests',
    ];

    // BEGINNING OF CHAT NOTIFICATIONS

    connectSocket(){
        // maintains connection and gets all messages. pushes last message off stack when necessary
        let id = "";
        const {user} = this.state;

        if (user && !this.state.stale) {
            id = user.unique_id;
        }

        // let socket = new WebSocket(`${config.SOCKET_HOST}/wss/chat/${id}/`);
        let socket = new WebSocket(`${config.SOCKET_HOST}/wss/chat/ebe370c5-f368-4afa-9d34-b3bb35ce6be1/`);


        socket.onopen = this.onOpen.bind(this);
        socket.onmessage = this.onMessage.bind(this);

        this.setState({socket});
    }

    onOpen(event){
        //check for errors here
        const self = this;
        this.ping = setInterval(() => {
            console.log("ping");
            self.state.socket.send(JSON.stringify({request_type: "ping"}));
        }, 60000);

    }

    onMessage(event){
        if (event.data === "pong"){
            console.log("pong");
            return;
        }

        const res = utils.camel(JSON.parse(event.data));
        const {requestType} = res;

        if (requestType === "current_message" && !this.props.ischatpage){
            this.setState({chatMessage: "message"});
        }
    }

    removeBubble() {
        this.setState({chatMessage: null});
    }

    showNotifications() {
        this.setState({ notificationBubble: false });

        if (this.state.notifDisplay) {
            this.setState({notifDisplay: false});
        } else {
            this.setState({notifDisplay: true});
        }
    }

    // END OF CHAT NOTIFICATIONS

    renderMainNavLinks() {
        let activePage = window.location.pathname.split('/')[1];
        return(
            <div className="navbar-nav mr-auto">
                {this.mainlinks.map(link =>
                    <div className="nav-item">
                        <Link className={`nav-link ${activePage === link.split('/')[1] ? "active" : ""}`} to={link}>
                            {utils.TAB_NAMES[link.split('/')[1]]}
                        </Link>
                    </div>)}
            </div>
        );
    }

    renderRightNavLinks(){
        const {joinrequest} = this.props;

        return (<ul className="navbar-nav">

            <Link className="nav-link create-team-btn" to="/create-team/0">
                Start Project
            </Link>

            <div className="notification-section">
                <div className="notification-button nav-link nav-right-links tooltip-anchor" onClick={() => this.showNotifications()}>
                    {this.state.notificationBubble ? <div className="notification-bubble"></div> : <div/>}
                    <Octicon className="icon" size="medium" icon={Globe}/>
                    <Tooltip name="Notifications" orientation="below"/>
                </div>

                {this.state.notifDisplay ?
                <NotificationCenter showNotifications={this.showNotifications} />
                : null}
                {this.state.notifDisplay ? <div className="click-off" onClick={() => this.showNotifications()}/> : null}
            </div>

            <Link className="nav-link nav-right-links tooltip-anchor chat-link" to="/chat" onClick="removeBubble()">
                {this.state.chatMessage ? <div className="notification-bubble"></div> : <div/>}
                <i className="fas fa-comment-alt"></i>
                <Tooltip name="Chat" orientation="below"/>
            </Link>

            <Link className="nav-link nav-right-links tooltip-anchor join-requests-link" to="/requests">
                {this.props.joinrequest == true ? <div className="notification-bubble"/> : <div/>}
                <i className="fas fa-user-plus"></i>
                <Tooltip name="Join Requests" orientation="below"/>
            </Link>
        </ul>);
    }

    render() {
        const {user, stale} = this.props;
        let url = "";
        let firstLetter = "";
        let bg = "#00000000";
        if (user && user.user && !stale) {
            url = utils.firstImage(user.user.images);
            bg = url ? `url('${url}')` : utils.randomGradient(user.unique_id);
            firstLetter = url ? "" : user.user.first_name.substring(0, 1).toUpperCase();
        }

        const your_profile = user
            ? `/creators/${user.unique_id}`
            : '/creators/'

        const logoContainerStyles = {
            backgroundImage: `url(${config.logoPath})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
        };

        const emailLink = "mailto:feedback@jouncer.io?subject=I've%20Got%20Some%20Feedback&body=Hi%20Jouncer,%0A%0AI've%20been%20using%20the%20site%20for%20a%20while,%20and%20the%20best%20part%20is%20_______.%0A%0ASome%20things%20you%20can%20improve:%0A%0A[Don't%20be%20shy,%20let%20us%20know!%20Everything%20piece%20of%20feedback%20helps%20us%20improve%20:)]"

        return (
            <div className="navbar-collapse" id="navbarSupportedContent">
                <Link style={logoContainerStyles} className="logo-container" to="/activity"></Link>

                {this.renderMainNavLinks()}
                {this.renderRightNavLinks()}


                <div id="profile-img" className="img-container o-tooltip-container">
                    <Link to={your_profile}>
                        <div className="profile-img rounded-circle img-fluid d-flex justify-content-center align-items-center cover"
                            style={{backgroundImage: bg}}>
                            {firstLetter}
                        </div>
                    </Link>

                    <span id="menu-tooltip" className="o-tooltip top">
                        <Link to={your_profile}>
                            <div className="menu-active-zone"/>
                            <div className="menu-active-zone-2"/>
                        </Link>
                        <div className="links column">
                            <Link to="/create-profile/1">Edit Profile</Link>
                            <Link to="/change-password">Change Password</Link>
                            <Link push to="/logout">Logout</Link>
                        </div>
                        <div className="more v-centred split">
                            <Link to="/about">About</Link>
                            <span>•</span>

                            <a href={emailLink}>Feedback</a>
                            <span>•</span>
                            <span>v1.1.24</span>
                            {/*<span>•</span>
                            <Link to="">Privacy</Link>*/}
                        </div>
                    </span>
                </div>
            </div>
        )
    }
}



class MainNav extends Component {

    render() {
        return (
            <nav className="navbar fixed-top navbar-expand-md navbar-dark"
                id="main-nav">

                { this.props.notUser ? <NewUserNav/> : <ExistingUserNav {...this.props}/> }

            </nav>
        )
    }
}

export default MainNav;
