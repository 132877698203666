// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import Modal from '@material-ui/core/Modal';

import InputError from '../components/InputError.jsx';
import Privacy from '../components/Privacy.jsx';

import '../css/SignUp.css';
import utils from '../utils';
import config from '../config';

import '../css/SignUp.css';

const selectStyles = config.selectStyles;
var moment = require('moment');
const _ = require("lodash");

class SignUp extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const {index, key} = this.props.match.params;

        return (
            <div className="signup signup-main d-flex justify-content-center align-items-center">
                <div className="signup-box d-flex align-items-center">
                    <div className="highlight"></div>
                    <SignUpContent signUpLogin={this.props.signUpLogin}/>
                </div>
            </div>
        );
    }
}

const graduationYearOptions = [
    {
        value: '2012',
        label: '2012'
    }, {
        value: '2013',
        label: '2013'
    }, {
        value: '2014',
        label: '2014'
    }, {
        value: '2015',
        label: '2015'
    }, {
        value: '2016',
        label: '2016'
    }, {
        value: '2017',
        label: '2017'
    }, {
        value: '2018',
        label: '2018'
    }, {
        value: '2019',
        label: '2019'
    }, {
        value: '2020',
        label: '2020'
    }, {
        value: '2021',
        label: '2021'
    }, {
        value: '2022',
        label: '2022'
    }, {
        value: '2023',
        label: '2023'
    }, {
        value: '2024',
        label: '2024'
    }, {
        value: '2025',
        label: '2025'
    }, {
        value: '2026',
        label: '2026'
    }, {
        value: '2027',
        label: '2027'
    }
];

class SignUpContent extends Component {
    constructor(props){
        super(props);
        const cookies = new Cookies();
        this.state = {
            activationCode: "",
            token: "",

            submitEnabled: true,
            submitSuccess: false,
            first: "",
            firstErrors: [],
            last: "",
            lastErrors: [],

            school: props.school,
            schoolOptions: [],
            graduationYear: props.graduationYear,
            graduationYearOptions: graduationYearOptions,
            program: props.program,
            programOptions: [],
            degree: props.degree,
            degreeOptions: [],

            schoolErrors: [],

            profilePicture: "",

            password: "",
            passwordErrors: [],
            confirmPassword: "",
            confirmPasswordErrors: [],
            isAgreed: false,
            isAgreedErrors: [],

            openModal: false,
        };
        this.handleSchoolChange = this
            .handleSchoolChange
            .bind(this);
        this.handleGraduationChange = this
            .handleGraduationChange
            .bind(this);
        this.handleProgramChange = this
            .handleProgramChange
            .bind(this);
        this.handleDegreeChange = this
            .handleDegreeChange
            .bind(this);
        this.onDrop = this
            .onDrop
            .bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getUniversity = this.getUniversity.bind(this);

        const schoolUrl = `${config.BACKEND_HOST}/accounts/search_university`;
        const programUrl = `${config.BACKEND_HOST}/accounts/search_program`;
        const degreeUrl = `${config.BACKEND_HOST}/accounts/search_degree`;
        this.getOptions(schoolUrl, "schoolOptions");
        this.getOptions(programUrl, "programOptions");
        this.getOptions(degreeUrl, "degreeOptions");
    }

    getQueryString(url) {
        // url split may fail if they are signing up with email, which has no variables in the url

         var queryString = url ? url.split('?')[1].split('=')[1] : window.location.search.slice(1);

         if (!queryString) {
             return (<Redirect to="/welcomemat" />);
         }

         if (queryString.length > 0) {
             return queryString;
         }
         return false;
    }

    componentWillMount() {
        const window_url = window.location.href;
        const activationCode = this.getQueryString(window_url);
        this.setState({activationCode: activationCode});
        //this.prefillInformation(activationCode);
    }

    getOptions(url, optionName, term = "") {
        const self = this;
        utils
            .get(url, {term: term})
            .then(res => {
                if (res.status) {
                    //set options
                    self.setState({
                        [optionName]: res
                            .data
                            .results
                            .map(result => ({
                                label: result
                                    .label
                                    .toString(),
                                value: result
                                    .id
                                    .toString()
                            }))
                    });
                }
            })
            .catch((err) => {
                if (typeof err === "object") {
                    console.log(err.response.body);
                } else {
                    console.log(err);
                }
                //try again?
            });
    }

    getUniversity(option) {
        const url = `${config.BACKEND_HOST}/accounts/search_university`;
        const self = this;
        utils
            .get(url, {term: option})
            .then(res => {
                if (res.status) {
                    //set options
                    self.setState({
                        schoolOptions: res
                            .data
                            .results
                            .map(result => ({
                                label: result
                                    .label
                                    .toString(),
                                value: result
                                    .id
                                    .toString()
                            }))
                    });
                }
            })
            .catch((err) => {
                if (typeof err === "object") {
                    console.log(err.response.body);
                } else {
                    console.log(err);
                }
                //try again?
            });
    }

    handleChange(event){
        let value = event.target.type ==='checkbox' ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
    }

    handleSchoolChange(option) {
        this.setState({school: option});
    }
    handleGraduationChange(option) {
        this.setState({graduationYear: option});
    }
    handleProgramChange(option) {
        this.setState({program: option});
    }
    handleDegreeChange(option) {
        this.setState({degree: option});
    }

    onDrop(accepted, rejected) {
        //display preview
        if (accepted.length > 0) {
            this.setState({profilePicture: accepted[0]});
        }
    }

    validateInput(){
        const {first, last, password, confirmPassword, school, program, degree, graduationYear} = this.state;
        let errors = {
            passwordErrors: [],
            confirmPasswordErrors: [],
            isAgreedErrors: [],
        };
        let hasError = false;

        if (!school || !program || !degree || !graduationYear) {
            let schoolErrors = ["Please fill out your school information"];
            this.setState({schoolErrors});
            return false;
        }

        if (typeof first !== "string" || first.length ===0){
            let firstErrors = ["Please enter a first name"];
            this.setState({firstErrors});
            hasError = true;
        }

        if (password.length < 6){
            errors.passwordErrors.push("Please enter a longer password");
            hasError = true;
        }

        this.setState(errors);

        return !hasError;
    }

    handleSubmit(event){

        event.preventDefault();
        this.setState({
            passwordErrors: [],
            confirmPasswordErrors: [],
            isAgreedErrors: [],
            inputCodeErrors: []
        });

        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            event.preventDefault();

            //submit logic here
            let self = this;
            const url = `${config.BACKEND_HOST}/accounts/signup_main`;

            let params = {
                activation_code: self.state.activationCode,
                first_name: self.state.first,
                last_name: self.state.last,
                password: self.state.password,
                university_uuid: self.state.school.value,
                program_uuid: self.state.program.value,
                degree_uuid: self.state.degree.value,
                graduation_year: self.state.graduationYear.value
            };

            if (typeof this.state.profilePicture === "object"){
                params.personal_image = this.state.profilePicture;
            }
            else {
                params.existing_personal_image = this.state.profilePictureId;
            }

            utils.postMultipart(url, params)
            .then(raw => raw.json())
            .then(res => {
                console.log(res);
                self.setState({submitEnabled: true});
                if (res.status){
                    self.setState({
                        submitSuccess: true,
                        token: res.token,
                    });
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    isAgreedErrors: ["Something went wrong. Please try again later."]
                });
            });

        }
    }

    handleClose() {
        this.setState({openModal: false});
    }


    handleClick(id, unique_id) {
        this.setState({openModal: true});

        const url = `${config.BACKEND_HOST}/all/fetch_comments`;

        let params = {
            portfolio_project_unique_id: unique_id,
        }

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res)
                    this.setState({
                        comments: res.data.comments,
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    render() {
        if (this.state.submitSuccess){
            if (this.props.signUpLogin(this.state.token)) {
                let toastText = `Hey ${this.state.first}, you're done signing up!`
                return (<Redirect to="/" />);
            }
        }

        let profileDropzoneStyle, profilePicture;
        profilePicture = this.state.profilePicture;
        if (profilePicture) {
            if (typeof profilePicture  ===  "object"){
                profilePicture = profilePicture.preview;
            }
            profileDropzoneStyle = {
                backgroundImage: `url('${profilePicture}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center"
            };
        }

        return (
            <div className="signup-content view1">
                <p className="heading1">
                    Glad you could make it, we're excited to see your projects!
                </p>

                <form id="signup1-form" action="/comeinside" onSubmit={this.handleSubmit} noValidate autoComplete="off">

                    <p className="signup-heading2">
                        Which University do you go to?
                    </p>

                    <div className="form-row">
                        <div className="col-8">
                            <Select
                                name="school"
                                placeholder="University"
                                value={this.state.school}
                                onChange={this.handleSchoolChange}
                                onInputChange={this.getUniversity}
                                options={this.state.schoolOptions}
                                styles={selectStyles}/>

                        </div>
                        <div className="col-4">
                            <Select
                                name="graduationYear"
                                placeholder="Graduation Year"
                                value={this.state.graduationYear}
                                onChange={this.handleGraduationChange}
                                options={this.state.graduationYearOptions}
                                styles={selectStyles}/>

                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-7">
                            <Select
                                name="program"
                                placeholder="Program"
                                value={this.state.program}
                                onChange={this.handleProgramChange}
                                options={this.state.programOptions}
                                styles={selectStyles}/>

                        </div>
                        <div className="col-5">
                            <Select
                                name="degree"
                                placeholder="Degree"
                                value={this.state.degree}
                                onChange={this.handleDegreeChange}
                                options={this.state.degreeOptions}
                                styles={selectStyles}/>

                        </div>
                    </div>


                    <p className="signup-heading2">
                        Start off by introducing yourself and add a profile picture:
                    </p>

                    <div className="form-row">
                        <Dropzone
                            onDrop={this.onDrop}
                            accept="image/jpeg, image/png"
                            className="profile-dropzone mx-auto d-flex justify-content-center align-items-center"
                            style={profileDropzoneStyle}>
                            {({isDragActive, isDragReject}) => {
                                if (isDragActive) {
                                    return "Upload";
                                }
                                if (isDragReject) {
                                    return <p className="large">X</p>;
                                }
                                if (!this.state.profilePicture) {
                                    return <p className="large">+</p>;
                                }
                                return;
                            }}
                        </Dropzone>
                    </div>

                    <div className="form-row">
                        <div className="col-6">
                            <input name="first" type="text" value={this.state.first} onChange={this.handleChange} placeholder="First Name" className={"input input-add-outline " +
                            (this.state.firstErrors.length > 0 ? "error" : "")}/>
                        </div>
                        <div className="col-6">
                            <input name="last" type="text" value={this.state.last} onChange={this.handleChange} placeholder="Last Name" className="input input-add-outline "/>
                        </div>
                        <InputError errors={this.state.firstErrors} />
                    </div>

                    {this.state.isFacebook ? null :

                        <div>
                            <p className="signup-heading2">
                                Activate your account
                            </p>

                            <div className="form-row">
                                <div className="col-12 password-input">
                                    <input name="password" type="password" value={this.state.password} onChange={this.handleChange} placeholder="Password" className={"input input-add-outline " +
                                        (this.state.passwordErrors.length > 0 ? "error" : "")} />
                                    <div className="passwordSuffix">min. 6 chars</div>
                                    <InputError errors={this.state.passwordErrors} />
                                </div>
                            </div>
                        </div>

                    }


                    {this.state.schoolErrors.length > 0 ? <p className="errors">{this.state.schoolErrors}</p> : null}

                    <div className="form-row">
                        <button name="submit" type="submit" value="Submit" className="btn btn-secondary">Submit</button>
                        <p className="privacy-statement">By clicking Submit you are agreeing to our <a className="privacy-policy-link" onClick={() => this.handleClick()}>Privacy Policy</a>.</p>
                    </div>



                    <Modal className="PortfolioModal" open={this.state.openModal} onClose={() => this.handleClose()}>
                        <div className="centred">

                            <div className="close" onClick={() => this.handleClose()}>
                                <span className="highlight fas fa-times"/>
                            </div>

                            <div className="modal-card">
                                <div className="modal-card-content">
                                    <Privacy/>
                                </div>
                            </div>
                        </div>
                    </Modal>


                </form>
            </div>
        );
    }
}

export default SignUp;
