// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Switch, Route, Redirect} from "react-router-dom";
import Card from '../components/Card';
import Idea from '../components/Idea';
import LoginMenu from '../components/LoginMenu';
import InputError from '../components/InputError.jsx';
import GitHubLogin from 'react-github-login';
import Linkify from 'react-linkify';
import Octicon, {Octoface, MarkGithub, Star, Eye, RepoForked} from '@primer/octicons-react';

import '../css/Home.css';

import utils from '../utils';
import config from '../config';

import githubrepo from "../media/githubrepo.png";
import success from "../media/success.png";
import landing from "../media/landingpage.jpg";
import description from "../media/description.png";
import comment1 from "../media/comment1.png";
import comment2 from "../media/comment2.png";
import post from "../media/post.png";


import addUpdate from "../media/addUpdate.jpg";
import testUpdate from "../media/testUpdate.jpg";
import karenPost from "../media/karenPost.jpg";
import addyPost from "../media/addyPost.jpg";
import sebastienPost from "../media/sebastienPost.jpg";

import astronGithub from "../media/astron-github.png";
import astronPage from "../media/astron-page.jpg";
import kevinsPage from "../media/kevinsPage.jpg";

import person1 from "../media/person1.jpg";
import person2 from "../media/person2.jpg";
import person3 from "../media/person3.jpg";
import person4 from "../media/person4.jpg";
import person5 from "../media/person5.jpg";
import person6 from "../media/person6.jpg";
import person7 from "../media/person7.jpg";
import person8 from "../media/person8.jpg";

import logolarge from "../media/logo_name_large.png";

import backend from "../media/backend.jpg";
import frontend from "../media/frontend.jpg";
import scripting from "../media/scripting.jpg";
import dataScience from "../media/data_science.jpg";
import mobile from "../media/mobile.jpg";


export class Ideas extends Component {
    render() {

        let commentsList=Object.values({
            0: {
                description: "hello",
                member: {
                    first_name: "Jenny",
                    last_name: "Lim",
                    image: mobile,
                }
            },
        });

        return(
            <Idea
                name="Jelly Bean"
                profileImage={backend}
                youridea={false}
                idea="the best way to party"
                teamName="Jellybean Party"
                upvoteCount={43}
                userUpvote={false}
                comments={commentsList}/>
        );
    }
}

export class InProgress extends Component {
    render() {
        return(
            <div>Active Projects</div>
        );
    }
}

export class CompletedProjects extends Component {
    render() {

        let imageList=Object.values({
            0: backend,
            1: frontend,
            2: scripting,
            3: dataScience,
            4: mobile
        });

        return(
            <div className="cards">

                <Card
                    title="Is This a Feature?"
                    description="Bringing you Premium-Grade Errors and Missing Endslashes"
                    images={imageList}
                    img={imageList[0]}
                    homepage={true}/>
                <Card
                    title="Frontend"
                    description="Making the most beautiful pixel art with a hardcore programming language"
                    images={imageList}
                    img={imageList[1]}
                    homepage={true}/>
                <Card
                    title="Terminal"
                    description="Illness. Yeah, that's what this is."
                    images={imageList}
                    img={imageList[2]}
                    homepage={true}/>
                <Card
                    title="Blobby Bois"
                    description="We used lots of numbers to make these cool blobby bois"
                    images={imageList}
                    img={imageList[3]}
                    homepage={true}/>

            </div>
        );
    }
}

class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: "",
            emailErrors: [],
            emailText: "default",
            submitEnabled: true,
            githubStatus: null,
            watch: 0,
            star: 0,
            fork: 0,
            count: 0,
            currentUpdate: testUpdate,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendActivationEmail = this.sendActivationEmail.bind(this);
        this.GithubSuccess = this.GithubSuccess.bind(this);
        this.GithubFail = this.GithubFail.bind(this);
        this.signupPrompt = this.signupPrompt.bind(this);
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    validateInput(){
        let hasError = false;
        if (!this.state.email){
            this.setState({emailErrors: "Please enter a valid email"});
            hasError = true;
        }
        return !hasError;
    }

    handleSubmit(event){

        console.log("SUBMITTING EMAIL");
        event.preventDefault();
        this.setState({
            emailErrors: [],
        });

        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            event.preventDefault();

            //submit logic here
            let self = this;
            const url = `${config.BACKEND_HOST}/accounts/email_signup`;
            let email = this.state.email;
            let params = {
                email_address: email.toLowerCase()
            };
            utils.post(url, params)
            .then(res => {
                console.log("this is the res:");
                console.log(res);
                if (res.status){
                    self.setState({submitSuccess: true});

                    if (!res.is_registered && !res.is_validated) {
                        self.setState({emailText: "newuser"});
                    }

                    else if (res.is_registered && res.is_validated) {
                        self.setState({emailText: "activated"});
                    }

                    else if (res.is_registered && !res.is_validated) {
                        self.setState({emailText: "registeredonly"});
                    }
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    emailErrors: ["Something went wrong. Please try again later."]
                });
            });

        }
    }

    sendActivationEmail() {
        //submit logic here
        let self = this;
        const url = `${config.BACKEND_HOST}/accounts/send_activation_email`;
        let email = this.state.email;

        let params = {
            email_address: email.toLowerCase()
        };

        console.log(email);
        utils.post(url, params)
        .then(res => {
            if (res.status){
                console.log(res);
                self.setState({submitSuccess: true});
            }
            else{
                throw "Something went wrong."
            }
        })
        .catch(err => {
            console.log(err);
            self.setState({
                submitEnabled: true,
                emailErrors: ["Something went wrong. Please try again later."]
            });
        });
    }

    GithubSuccess(response) {
        console.log(response);
        this.setState({
            githubStatus: true
        });

        const url = `${config.BACKEND_HOST}/accounts/github_repos_data_collect`;
        const params = {
            github_code: response.code,
        }
        const self = this;

        utils.post(url, params)
            .then((res) => {
                console.log(res);

                if (res.status && res.token) {
                    if (this.props.signUpLogin(res.token)) {
                        window.location = `http://jouncer.com/activity`;
                    }
                } else if (res.status){
                    self.setState({submitSuccess: true});
                    window.location = `http://jouncer.com/github-signup/0?id=${res.userprofile_unique_id}`;
                }
                throw "Response status false";
            })
            .catch((err) => {
                if (typeof err === "object"){
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    GithubFail(response) {
        console.log(response);
        this.setState({githubStatus: false});
    }

    signupPrompt(y) {

        const onSuccess = response => this.GithubSuccess(response);
        const onFailure = response => this.GithubFail(response);

        return (
            <div className={y ? "github-signup-text-small github-signup-text" : "github-signup-text"}>
                {y ?
                    <div className="authorize-github-small">
                        <Octicon className="icon" size='medium' icon={MarkGithub}/>
                        <GitHubLogin
                            className="button"
                            clientId="5d294ab362a4f18f3352"
                            redirectUri=""
                            scope="read:user user:email user:follow"
                            buttonText="Sign Up"
                            onSuccess={onSuccess}
                            onFailure={onFailure}/>
                    </div>
                    :
                    <div className="authorize-github">
                        <Octicon className="icon" size='medium' icon={MarkGithub}/>
                        <GitHubLogin
                            className="button"
                            clientId="5d294ab362a4f18f3352"
                            redirectUri=""
                            scope="read:user user:email user:follow"
                            buttonText="Sync GitHub"
                            onSuccess={onSuccess}
                            onFailure={onFailure}/>
                    </div>
                }

                {this.state.githubStatus === false ? <p className="error">Oh shit, we hit a bug. Try one more time</p> : null}
                {y ? null : <p className="email-signup"><Link to="/email-signup">Don't have Github? </Link></p>}

            </div>
        );
    }

    githubLoading() {

        return(
            <div className="github-loading">
                <p>Loading Github Repositories&nbsp;
                <Octicon className="icon" icon={Octoface}/>
                </p>
                <p>This will take up to 15 seconds</p>
            </div>
        );
    }


    render() {

        let emailText = "";

        if (this.state.emailText === "default") {
            emailText = "";
        } else if (this.state.emailText === "newuser") {
            emailText = "Check your email for your activation link!";
        } else if (this.state.emailText === "activated") {
            emailText = "You already have an account! Sign in instead.";
        }

        return (
            <div>
                <section className="mainnav-home">
                    <img className="home-logo" src={logolarge} />

                    <div className="flex">
                        {this.state.githubStatus === null ? this.signupPrompt(true) : this.githubLoading()}
                        <Link to="/login" className="login-p">Login</Link>
                    </div>
                </section>


                <div className="homepage homepage-main tandem-main d-flex flex-column justify-content-center">

                    <div className="home-intro row justify-content-center align-items-center">
                        <p className="home-heading">What are you creating?</p>

                        <div className="homepage-hero">
                            <div className="features one">
                                <p className="feature">See what your friends are building</p>
                                <p className="feature">Share your process</p>
                            </div>
                            <div>
                                <div className="examples-box">
                                    <img className="add-update" src={addyPost}/>
                                </div>
                            </div>
                            <div className="features two">
                                <p className="feature">Get detailed feedback</p>
                                <p className="feature">Collaborate with creators</p>
                            </div>
                        </div>
                        <Link className="enter-site" to="/projects">Discover Projects</Link>
                    </div>


                    <div className="justify-content-center align-items-center github-signup">

                        <div className="creators">
                            <div className="creators-list">
                                <img src={person4}/>
                                <img src={person1}/>
                                <img src={person3}/>
                                <img src={person6}/>
                            </div>
                            <div className="value-add-description">
                                A community of creators
                            </div>
                            <div className="creators-list">
                                <img src={person5}/>
                                <img src={person2}/>
                                <img src={person7}/>
                                <img src={person8}/>
                            </div>
                        </div>

                        <div className="story-of-project">
                            <div className="value-add-content">
                                <div className="left">
                                    <div className="value-add-description">
                                        Tell your project's story
                                    </div>
                                    <img className="astron-github" src={astronGithub}/>
                                </div>
                                <div className="right">
                                    <img className="astron-page" src={astronPage}/>
                                </div>
                            </div>
                        </div>

                        <div className="showcase-work">
                            <div className="value-add-content">
                                <img className="kevins-page" src={kevinsPage}/>
                                <div className="showcase-description value-add-description">
                                    Showcase your work
                                </div>
                            </div>
                        </div>


                        {/*<div className="github-signup-illustration">

                            <div className="github-repo">
                                <h2 className="import">Import your project repos with a single login</h2>
                                <h2 className="share">Share your progress with other creators to receive feedback</h2>
                                <img src={githubrepo} className="githubrepo"/>
                            </div>

                            <div className="jouncer-content">
                                <div className="project-page">
                                    <img src={description} className="description"/>
                                    <img src={landing} className="landing"/>
                                </div>
                                <div className="updates">
                                    <img src={comment1} className="comment1"/>
                                    <img src={comment2} className="comment2"/>
                                    <img src={post} className="post"/>
                                </div>
                            </div>
                        </div>*/}
                    </div>


                    {this.state.githubStatus === null ? this.signupPrompt() : this.githubLoading()}

                    {/*<div className="designers-banner">
                        <div className="text">
                            <h2>Don't have Github because you have the Adobe Creative Suite instead?</h2>
                            <p>Add your design projects and join the design community on Jouncer</p>
                        </div>
                        <div className="illustration">

                        </div>
                    </div>*/}

                    <div className="footer">Contact us at team@jouncer.io</div>

                </div>
            </div>
        );
    }
}

export default Home;
