import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Select from 'react-select';

import utils from '../../utils';
import config from '../../config';
import SkillsMenu from '../SkillsMenu.jsx';
import Dropzone from 'react-dropzone';
import MediaItemContainer from "../MediaItemContainer.jsx";
import S3FileUpload from 'react-s3';

import Octicon, { ArrowUp } from '@primer/octicons-react';

const moment = require('moment');
const uniqueString = require('unique-string');
const _ = require("lodash");
const pIndex = 2;

export default class View2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            id: props.id,
            media: [],
            submitting: false,
            profilePicture: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.submitting = this.submitting.bind(this);

        this.getData();
    }
    async getData(){
        const self = this;
        const url = `${config.BACKEND_HOST}/teams/create3`;

        await utils.get(url, {team_uuid: this.state.id})
            .then((res) => {
                console.log(res);
                if (res.status){
                    let {images, profile_img} = res.data;
                    //set state
                    self.setState({
                        media: images,
                        profilePicture: utils.prefixImage(profile_img)
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
                //error logic
            });
    }

    handleDelete(values) {

        this.setState({media: this.state.media.filter(item => item.id !== values[0].id)});

        const url = `${config.BACKEND_HOST}/teams/delete_image`;
        const self = this;

        const params = {
            image_uuid: values[0].unique_id
        };

        utils.postMultipart(url, params)
            .then(raw => raw.json())
            .then((res) => {
                console.log(res);
                self.setState({submitEnabled: true});
            })
            .catch((err) => {
                if (typeof err ==="object"){
                    console.log(err.response.body);
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    handleChange(values){
        if (this.state.submitEnabled){

            const config2 = {
                bucketName: 'stagingjouncerfrontend',
                dirName:'static/media',
                region: 'us-east-2',
                accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
                secretAccessKey: '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',
            }

            //submit logic here
            const url = `${config.BACKEND_HOST}/teams/add_team_standard_image`;
            const self = this;
            //need some way of discerning which one is the cover

            S3FileUpload
            .uploadFile(values[values.length - 1], config2)
            .then(data => {
                console.log("s3 file uploaded properly", data);

                const params = {
                    team_uuid: this.state.id,
                    uploaded_url: data.location
                };

                utils.postMultipart(url, params)
                    .then(raw => raw.json())
                    .then((res) => {
                        console.log(res);
                        self.setState({submitEnabled: true});
                    })
                    .catch((err) => {
                        if (typeof err ==="object"){
                            console.log(err.response.body);
                        }
                        else{
                            console.log(err);
                        }
                        self.setState({submitEnabled: true});
                        //error logic
                    });
            })
            .catch(err => console.error(err));
        }
    }

    onDrop(accepted, rejected) {
        //display preview
        if (accepted.length > 0) {
            this.setState({profilePicture: accepted[0]});

            const config2 = {
                bucketName: 'stagingjouncerfrontend',
                dirName:'static/media',
                region: 'us-east-2',
                accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
                secretAccessKey: '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',
            }

            S3FileUpload
            .uploadFile(accepted[0], config2)
            .then(data => {
                console.log("s3 file uploaded properly", data);

                const url = `${config.BACKEND_HOST}/teams/add_team_cover_image`;
                const self = this;
                //need some way of discerning which one is the cover
                const params = {
                    team_uuid: this.state.id,
                    uploaded_url: data.location
                };

                utils.postMultipart(url, params)
                    .then(raw => raw.json())
                    .then((res) => {
                        console.log(res);
                        self.setState({ submitEnabled: true });
                    })
                    .catch((err) => {
                        if (typeof err ==="object"){
                            console.log(err.response.body);
                        }
                        else{
                            console.log(err);
                        }
                        self.setState({ submitEnabled: true });
                        //error logic
                    });
            })
            .catch(err => console.error(err));

        }
    }

    submitting(event) {
        event.preventDefault();
        this.setState({ submitting: true });
        setTimeout(() => {
            this.setState({
                submitSuccess: true
            })
        }, 3000);
    }

    render() {

        let profileDropzoneStyle, profilePicture;
        profilePicture = this.state.profilePicture;
        if (profilePicture) {
            if (typeof profilePicture  ===  "object"){
                profilePicture = profilePicture.preview;
            }
            profileDropzoneStyle = {
                backgroundImage: `url('${profilePicture}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center"
            };
        }

        if (this.state.submitSuccess){
            console.log('winner');
            return (<Redirect to={"/project/" + this.state.id} />);
        }
        return (
            <div className="col-sm-10 col-md-8 mx-auto">
                <form action="" onSubmit={this.submitting}>

                    <div className="form-row no-margin">
                        <h2 className="create-team-headline float-left">Media</h2>
                    </div>
                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">Add a cover image for your project preview</h3>
                    </div>

                    <Dropzone
                        onDrop={this.onDrop}
                        accept="image/jpeg, image/png"
                        className="cover-dropzone d-flex justify-content-center align-items-center"
                        style={profileDropzoneStyle}>
                        {({isDragActive, isDragReject}) => {
                            if (isDragActive) {
                                return "Upload";
                            }
                            if (isDragReject) {
                                return <p className="large">X</p>;
                            }
                            if (!this.state.profilePicture) {
                                return <p className="large">+</p>;
                            }
                            return;
                        }}
                    </Dropzone>

                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">Add other screenshots, diagrams, and gifs to showcase your project</h3>
                    </div>

                    <div className="form-row create-team-media">
                        <MediaItemContainer
                            items={this.state.media}
                            handleUploadChange={this.handleChange}
                            hideCheck={true}
                            isSmall={false}
                            handleDelete={this.handleDelete}
                            s3change={true} />
                    </div>



                    <div className="col create-team-confirm-box">
                        <div className="row">
                            <p className="create-team-headline mx-auto">Are you ready to start creating?</p>
                        </div>

                        <div className="row">
                            <button name="submit" type="submit" className="btn btn-primary col-sm-10 mx-auto">Finish</button>
                        </div>

                        {this.state.submitting === true ?
                            <div className="uploading">
                                <Octicon className="icon" icon={ArrowUp}/>
                                Uploading Your Project...
                            </div>
                        : null }
                    </div>


                </form>
            </div>
        );
    }
}
