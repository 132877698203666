import React from 'react';

/**
params:
txt
bool active optional default(false)
string id optional
function onClick optional
string("primary" or "secondary") className optional default(primary)
int(0 to 2) color optional default(0)
*/

const Badge = (props) => {
    let {txt, active, onClick, id, className, color, size} = props;
	let classes = "badge";
	if (className){
		classes += ` badge-${className}`;
	}
	else{
		classes += " badge-primary";
	}
    if (size){
        classes += ` badge-${size}`;
    }

    if (active || color === 2){
        classes += " active";
    }
	else if (color === 1){
		classes += " lighter";
	}
    if (onClick){
        return (
            <span id={id} onClick={onClick} className={classes}>{txt}</span>
        );
    }

    return (
        <span id={id} className={classes}>{txt}</span>
    );
}

export default Badge;
