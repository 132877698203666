// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Select from 'react-select';

// Open Source Dependencies
import { Column, Row } from 'simple-flexbox';

import ProgressNavItem from "./ProgressNavItem.jsx";

const ProgressNav = (props) => {
    let items, title, subdir;
    if (props.navType === "Create Profile"){
        title = "Create Your Profile";
        subdir = "create-profile";
        items = [
           {index: 1, name: "About You", status: "inactive"},
           {index: 2, name: "Skills", status: "inactive"},
           {index: 3, name: "Portfolio", status: "inactive"}
       ];
    }
    else if (props.navType === "Create Team" || props.navType === "Edit Team"){
        title = props.navType === "Create Team" ? "Create a Team" : "Edit Your Team";
        subdir = "create-team";
        items = [
           {index: 1, name: "Name and Description", status: "inactive"},
           {index: 2, name: "Tech Stack", status: "inactive"},
           {index: 3, name: "Media", status: "inactive"}
       ];
    }
    items = items.map(item => {
        if (item.index <= props.index){
            item.status = "done";
        }
        return item;
    })
    items[Math.min(props.index, items.length - 1)].status = "active";
    return (
        <nav className="navbar fixed-top navbar-expand-md navbar-dark progress-nav" id="progress-nav">
            <p className="navbar-brand mb-0 h1" href="#">{title}</p>
            <div>
                <ul className="navbar-nav mr-auto">
                    {items.map(ProgressNavItem)}
                </ul>
            </div>
        </nav>
    );
}

export default ProgressNav;
