// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

const View3 = (props) => {
    return (
        <div className="forgot-password-content view3">
            <p className="heading1">Your password has been changed!</p>
            <Link to="/login" className="btn btn-primary">Login</Link>
        </div>
    );
}

export default View3;
