import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

// utils
import utils from '../utils';
import config from '../config';

import img1 from '../media/Arthur_Nguyen-Cao.jpg';
import img2 from '../media/Oliver_Daniel.jpg';
import img3 from '../media/Siddhant_Jain.jpg';

const rp = require('request-promise-native');

class WelcomeBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            friends: [],
        };
    }

    async componentDidMount() {
        const url = `${config.BACKEND_HOST}/accounts/facebook_friends`;
        const self = this;

        await utils.get(url)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    self.setState({
                        friends: res.data.friends,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {

        let { friends } = this.state;

        let friendslist = friends.map(friend =>

            {
                let fbImage = {
                    backgroundImage: `url(${utils.prefixImage(friend.images)})`,
                    height: '160px',
                    width: '160px',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                }

                return(
                    <div className="facebook-friend">
                        <Link to={`/creators/${friend.unique_id}`}>
                            <div className="facebook-friend-image" style={fbImage}></div>
                            <p>{friend.user__first_name} {friend.user__last_name}</p>
                        </Link>
                    </div>
                );
            }
        )

        return (
            <section className="full-width-content">
                <div className="banner">
                    <div className="welcome-banner">
                        <h2>Welcome!</h2>
                        <p>Take a look at some of the great projects that people are working on. <br/>
                        When you’re ready, share your own!
                        </p>
                        <Link to="/create-profile/0" className="btn btn-primary">Complete your Profile</Link>
                    </div>
                    <div className="your-friends">
                        <div className="your-friends-container">
                            <div className="your-friends-text">
                                <h2>Who do you know?</h2>
                                <p>Here are some friends who are already creating on Jouncer</p>
                            </div>

                            <div className="your-friends-images">
                                {friendslist}
                            </div>

                        </div>
                        <p className="invite-friends">Don’t see a friend who you think works on great projects? You can always <a href="#">invite them</a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default WelcomeBanner;
