import React, {Component} from 'react';
import {Link} from 'react-router-dom';
// import config from '../config';
import utils from '../utils';
import config from '../config';

import Card from './Card';
import Octicon, {LinkExternal} from '@primer/octicons-react';

class TeamTopProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFollowing: undefined
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isFollowing === undefined) {
            return {isFollowing: props.is_starred};
        }

        return null;
    }

    async handleFollowToggle() {
        const {isFollowing} = this.state;
        console.log('toggling');
        const {status} = await utils.post(`${config.BACKEND_HOST}/all/team_page/query/`, {
            [isFollowing
                    ? 'stop_following'
                    : 'start_following']: true,
            page_team_uuid: this.props.team.unique_id
        });

        if (!status) {
            //todo: remove
            alert('Error!');
            return;
        }

        this.setState(prevState => {
            return {
                isFollowing: !prevState.isFollowing
            };
        }, () => window.dispatchEvent(new Event('updateDash')));
    }

    render() {
        const {team, is_current_user_team_page} = this.props;
        if (typeof(team) === 'undefined' || !Object.keys(team).length) {
            return;
        }

        const {name, images} = team;
        let onClick,
            text;
        if (is_current_user_team_page) {
            if (team.admin__userprofile__id === this.props.user.id) {
                text = <div>
                    Admin
                    <span className="fas fa-cog"></span>
                    {"\u00a0\u00a0"}
                    <Link className="temp-edit-link" to={`/edit-team/0/${team.unique_id}`}>Edit Project</Link>
                    {/*<span style={{display: "visible"}} className="o-tooltip left">
                        <div>Edit Team</div>
                        <div>Close Team</div>
                        <div>Delete Team</div>
                    </span>*/}
                </div>;
            } else {
                //TODO: functionality
                text = <div>
                    Member
                    {/*<span className="fas fa-cog"></span>
                    <span className="o-tooltip left">
                        Leave Team
                    </span>*/}
                </div>;
            }
            // TODO: remove
        } else {
            // onClick = this
            //     .handleFollowToggle
            //     .bind(this);
            // if (this.state.isFollowing) {
            //     text = "Unfollow –";
            // } else {
            //     text = "  Follow +";
            // }
        }

        let team_images = images;
        team_images = images.slice(0,images.length-1);

        return (
            <div className="TeamTopProfile">

                <div className="row latest-build">
                    <h2>Latest Build</h2>

                    {team.external_link.length > 10 ? <div className="external-link-section">
                        <a target="_blank" href={team.external_link} className="highlight"><Octicon className="icon" icon={LinkExternal}/></a>
                    </div> : null }

                    <iframe id="latest-build-preview" src={team.external_link} >
                    </iframe>
                </div>
            </div>
        );
    }
}

export {TeamTopProfile};
