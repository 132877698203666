import React, {Component} from 'react';
import {Link} from "react-router-dom";

import config from '../config';
import utils from '../utils';



const CreatorLink = (creator) => {
    return (
        <Link to={`/creators/${creator.userprofile__unique_id}`}>{`${creator.first_name} ${creator.last_name}`}</Link>
    )
};

const MAX_INVITEES = 999;

const SkillDropdown = ({name, skills}) => (
    <div className="SkillDropdown column">
        <div className="skill-name">
            {name}
        </div>
        <div className="v-centred wrap">
            {skills.map(({name}) => <div className="skill small">{name}</div>)}
        </div>
    </div>
)

class CreatorSidebar extends Component {

    constructor(props){
        super(props);
        this.handleMessage = this.handleMessage.bind(this);
        this.redirectToChat = this.redirectToChat.bind(this);
    }

    redirectToChat(convoId){
        console.log("this is the convo ID");
        console.log(convoId);
        this.props.history.push(`/chat/${convoId}`);
    }

    handleMessage(convoId = null){
        console.log("handlemessage is being called");
        console.log(this.props.conversation_uuid);
        if (convoId) {
            this.redirectToChat(convoId);
            return;
        } else {
            console.log("Convo doesn't exist. Creating...");
            const self = this;
            const {unique_id} = this.props.page_user;
            utils.post(`${config.BACKEND_HOST}/all/initiate_general_chat`, {
                page_uuid: unique_id
            }).then(({status, data}) => {
                if (!status) throw "Error with chat init!";

                // TODO: remove redundant code once prop name is spelled right
                const {conversation_uuid} = data;
                self.redirectToChat(conversation_uuid);
            }).catch(err => console.log(err));
        }
    }

    render() {
        const {
            notUser,
            id,
            page_user,
            invited,
            invited_by,
            is_current_users_page,
            sidebarCollapsed
        } = this.props;
        if (typeof(page_user) === 'undefined' || Object.keys(page_user).length === 0) {
            return;
        }

        const {
            images = [],
            user__first_name = "",
            user__last_name = "",
            user_skills = [],
            university__name = "",
            program__name = "",
            graduation_year = "",
        } = page_user;

        let backgroundImage,
            innerText;
        if (images.length > 0) {
            backgroundImage = `url('${utils.image(images[0])}')`;

                console.log("memberimage");
                console.log(utils.image(images[0]));
                console.log(images[0]);
        } else {
            backgroundImage = utils.randomGradient(id);
            innerText = user__first_name
                .charAt(0)
                .toUpperCase();
        }
        return (
            <div className="CreatorSidebar v-centred column">

                <div className="profile-img d-flex justify-content-center align-items-center text-center image"
                    style={{
                    backgroundImage
                }}>
                    {innerText}
                </div>

                <div className="o-content v-centred column">
                    <div className="full-name">{`${user__first_name} ${user__last_name}`}</div>
                    {program__name && university__name
                        ? (
                            <div className="program">
                                {university__name}
                                <br/> {program__name} {graduation_year}
                            </div>
                        )
                        : null}

                        {notUser ? null : (!is_current_users_page
                        ? <div className="section">
                                <div className="centred btn btn-primary" onClick={() => this.handleMessage()}>Message</div>
                                {/*<div className="centred button">Invite to Team</div>*/}
                            </div>
                        : null )}

                        <div className="row">
                                {Object
                                    .keys(user_skills)
                                    .length > 0
                                    ? <div className="section">
                                            <div className="subheading-label">SKILLS</div>
                                            {Object
                                                .values(user_skills)
                                                .map(SkillDropdown)}
                                        </div>
                                    : null}
                        </div>
                </div>


                <div className="see-more centred highlight collapsed">
                    {/* <i data-feather="chevron-down"/> */}
                    <i className="fas fa-chevron-down"/>
                </div>

            </div>
        )
    }
}

export {CreatorSidebar};
