import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {UserContext} from '../App';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';

// My Dependencies
import MainNav from '../components/MainNav.js';
import SubNav from '../components/SubNav.js';
import NewIdea from '../components/NewIdea.js';
import Idea from '../components/Idea.js';

import ideabanner from '../media/ideabanner.jpg';
import siddImage from '../media/Siddhant_Jain.jpg';
import songImage from '../media/Song_You.jpg';

import '../css/Ideas.css';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');

class Suggestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            new_ideas: [],
            ideas: [],
            testing: false,
            postedIdea: false,
            upvoteSubmit: false,
        };
        this.getData = this.getData.bind(this);
        this.addIdea = React.createRef();
    }

    componentWillMount() {
        this.getData();
    }

    async getData() {
        const url = `${config.BACKEND_HOST}/all/friend_projects`;
        utils.get(url)
            .then((res) => {
                if (res.status){
                    this.setState({
                        ideas: res.data,
                        testing: true,
                    });
                }
            })
            .catch((err) => {
                this.setState({ githubAuthorized: false });
                console.log(err.message);
            });
    }

    renderIdeas(userId) {
        const {ideas} = this.state.ideas;
        if (ideas == null) {
            return <utils.Spinner/>;
        }

        if (ideas) {
            let ideasList = ideas.map(({unique_id, description, date_added, team__unique_id, team__name, upvote_count, user_upvote, comments}) => {
                    let img = "";
                    return (
                        <Idea
                            id={unique_id}
                            idea={description}
                            teamName={team__name}
                            teamUniqueId={team__unique_id}
                            upvoteCount={upvote_count}
                            userUpvote={user_upvote}
                            comments={comments}
                            dateAdded={date_added}/>
                    );
                }
            );
            return ideasList.length > 0 ? ideasList : <div className="no-results">No Ideas Yet! Be the first :)</div>;
        }
    }

    removeShade() {
        var element = document.getElementById("shade");
        element.classList.remove("shade");

        var element = document.getElementById("new-idea");
        element.classList.remove("bring-to-front");
    }

    handleIdeaSubmit() {
        const {text} = this.addIdea.current.state;
        this.setState({ postedIdea: true });
        this.addIdea
            .current
            .clear();

            console.log("text", text);

        let params = {
            description: text
        };

        const self = this;
        utils.postMultipartNoToken(`${config.BACKEND_HOST}/teams/post_idea`, params)
            .then((res) => {
                if (!res.ok) throw "Error with request";
                self.setState(prevState => {
                    return {
                        new_ideas: [
                            {
                                isUpload: true,
                                description: text,
                                comments: [],
                            },
                            ...prevState.new_ideas
                        ]
                    }
                });
            }).catch(err => console.log(err));
    }

    render() {

        let currentuser = this.props.user ? this.props.user.user : null;

        let ideaBannerWithFinishProfile = {
            width: "100%",
            height: "560px",
            position: "absolute",
            background: "linear-gradient(45deg, #FA8BFF, #2BD2FF, #2BFF88)",
            opacity: "0.7",
        }

        let ideaBanner = {
            width: "100%",
            height: "460px",
            position: "absolute",
            background: "linear-gradient(45deg, #FA8BFF, #2BD2FF, #2BFF88)",
            opacity: "0.7",
        }

        return (
            <div className="main">
                <Helmet>
                    <title>Ideas – Jouncer</title>
                </Helmet>

                    {/*<section className="content-section">
                        {this.props.user && this.props.user.new_user_banner
                            ? <FinishProfileBanner /> : null}
                    </section>*/}

                    <section className="content-section d-flex justify-content-center">
                        <div className="cards-container ideas-box">
                            <h2>Do you know anyone who's working on something interesting?</h2>

                            <NewIdea user={this.props.user} handleIdeaSubmit={this.handleIdeaSubmit.bind(this)} ref={this.addIdea}/>

                            <div id="shade" className="shadeoff" onClick={this.removeShade}></div>

                            {this.state.postedIdea ?
                                (this.state.new_ideas[0] ?
                                    <div className="shared-idea-badge">Added Awesome Creator!</div>
                                    : <div className="sharing-idea-badge">Adding Awesome Creator...</div>)
                                : null}

                            {this.state.new_ideas.map(idea => <Idea idea={idea.description} youridea={true} {...idea}/>)}

                            {this.renderIdeas(this.props.user ? this.props.user.unique_id : null)}

                        </div>
                    </section>
                </div>
        );
    }
}

export default Suggestions;
