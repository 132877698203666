// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

class SignupNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            pageNumber: 0,
        };
    }

    componentWillMount() {
        const url = window.location.href;
        const id = url.split('=')[1].split('&')[0];
        const number = url.split('?')[0].slice(-1);
        this.setState({
            userId: id,
            pageNumber: number,
        });
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.page !== this.props.page){
            this.setState({pageNumber: nextProps.page});
        }
    }

    render() {
        let {userId, pageNumber} = this.state;
        console.log(pageNumber);
        return (
            <div className="signup-nav">
                <div className="links">
                    <p className={pageNumber == 0 ? "active" : ""}>Active Projects</p>
                    <p className={pageNumber == 1 ? "active" : ""}>Past Projects</p>
                    <p className={pageNumber == 2 ? "active" : ""}>Skills</p>
                    <p className={pageNumber == 3 ? "active" : ""}>Profile</p>
                </div>
            </div>
        );
    }
}

export default SignupNav;
