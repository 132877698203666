// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import InputError from '../InputError.jsx';
import utils from '../../utils';
import config from '../../config';

const _ = require("lodash");
const v = require('validator');
const pIndex = 2;

export default class View2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            password: "",
            passwordErrors: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
            // [event.target.name + "Errors"]: []
        });
    }
    validateInput(){
        const {password} = this.state;
        if (password.length < 6){
            let passwordErrors = ["Minimum 6 characters"];
            this.setState({passwordErrors});
            return false;
        }
        return true;
    }
    handleSubmit(event){
        event.preventDefault();
        this.setState({passwordErrors: []});
		console.log({
			key_code: this.props.keyCode,
			password_first: this.state.password,
			password_second: this.state.password
		});
        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            //submit logic here
            const url = `${config.BACKEND_HOST}/accounts/update_forgotten_password`;
            const self = this;
            utils.post(url, {
				key_code: this.props.keyCode,
				password_first: this.state.password,
				password_second: this.state.password
			})
                .then((res) => {
                    console.log(res);
                    if (res.status){
						self.setState({submitSuccess: true});
                    }
                })
                .catch((err) => {
                    let msg = [];
                    if (typeof err === "object"){
                        const body = err.response.body;
                        console.log(body);
                        if (body.message){
                            msg.push(body.message);
                        }
                        else{
                            msg.push("Something went wrong. Please try again later.");
                        }
                    }
                    else{
                        console.log(err);
                        msg.push(err);
                    }
                    self.setState({
                        submitEnabled: true,
                        inviteCodeErrors: msg
                    });
                    //error logic
                });
        }
    }
    render() {
        if (this.state.submitSuccess){
            return (<Redirect to={"/forgot-password/" + (pIndex + 1).toString()} push />);
        }
        return (
            <div className="forgot-password-content view0">
                <p className="heading1">Type in your new password</p>
                <form id="forgot-password-form-2" action="/forgot-password/3" onSubmit={this.handleSubmit} noValidate>
                    <div className="mx-auto group">
                        <input name="password" type="password" value={this.state.password} onChange={this.handleChange} placeholder="Password" className={"input input-add-outline " +
                            (this.state.passwordErrors.length > 0 ? "error" : "")}  />
                        <button name="submit" type="submit" value="Submit" className="btn btn-secondary">Change Password</button>
                        <br />
                        <InputError errors={this.state.passwordErrors} />
                    </div>
                </form>
            </div>
        );
    }
}
