import React, {Component} from 'react';

import utils from '../utils';
import config from '../config';

import SubNavItem from "./SubNavItem.js";

class SubNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skill_buckets: [],
            selected: props.selected || []
        };

    }

    async componentDidMount() {
        const url = `${config.BACKEND_HOST}/all/skill_bucket/`;
        const skill_buckets = await utils.get(url);
        this.setState({skill_buckets});
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selected !==  state.selected) {
            return {selected: props.selected};
        }
        return null;
    }

    render() {
        const {title} = this.props;
        return (
            <nav
                className="navbar fixed-top navbar-expand-md navbar-dark sub-nav"
                id="sub-nav">
                <p className="navbar-brand mb-0 h1" href="#">{title}</p>
                <div className="badge-container">
                    <ul className="navbar-nav mr-auto row">
                        {this
                            .state
                            .skill_buckets
                            .map(({name}) => (<SubNavItem
                                txt={name}
                                onClick={() => this.props.onItemClick(name)}
                                active={this.state.selected[name]}/>))}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default SubNav;
