// Required Dependencies
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import '../css/About.css';
import GithubLogo from '../media/Github-logo.png';

import utils from '../utils';
import config from '../config';


class GithubAuthentication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitSuccess: false,
        };
        console.log(this.props);
    }

    getQueryString(url) {
         var queryString = url ? url.split('=')[1] : window.location.search.slice(1);
         return queryString;
    }

    componentDidMount() {
        const window_url = window.location.href;

        const gitcode = this.getQueryString(window_url);

        //SUBMITTING GITHUB AUTH TOKEN
        const url = `${config.BACKEND_HOST}/accounts/submit_github_code`;
        const self = this;

        utils.post(url, {
                github_code: gitcode,
            })
            .then((res) => {
                console.log(res);
                if (res.status){
                    self.setState({submitSuccess: true});
                    window.location = "https://jouncer.com/create-profile/3";
                    return;
                }
                throw "Response status false";
            })
            .catch((err) => {
                if (typeof err === "object"){
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    render() {

        return (
            <div className="github-authentication-page">
                <div className="github-auth-page-content">
                    <img src={GithubLogo} className="github-logo"></img>
                    <h2>Authenticating your Github Profile...</h2>
                </div>
            </div>
        );

    }
}

export default GithubAuthentication;
