import React from 'react';
import utils from '../utils';
// import moment from 'moment';


class ScrollButton extends React.Component {
    constructor() {
        super();

        this.state = {
            intervalId: 0
        };
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    render () {
    return <button title="Back to top" className="waveform-scroll highlight"
             onClick={ () => { this.scrollToTop(); }}>
                <p>Top</p>
            </button>;
    }
}



export default ({activity, currentUser}) => {
    // const currentWeek = moment().week();
    let weeks = new Array(52).fill(0);
    let earliest_week = Infinity;

    for (let [week_num, {normalized}] of Object.entries(activity)){
        weeks[week_num] = normalized * 100;
        earliest_week = Math.min(earliest_week, week_num);
    }

    let waveformStyle = {};

    if (currentUser) {
        waveformStyle = {
            marginTop: "494px",
        }
    } else {
        waveformStyle = {
            marginTop: "60px"
        }
    }

    return (
        <div id="Waveform" style={waveformStyle} className="Waveform split column">
            <ScrollButton  scrollStepInPx="150" delayInMs="15">Top{/*<div className="highlight fas fa-chevron-up"/>*/}</ScrollButton>
            {weeks.slice(0,60).map((width, i) => {
                const style = {
                    width: width
                        ? `${width}%`
                        :
                        //`${utils.randint(1, 100)}%`
                        '3px'
                };
                return (<div className="week" key={`wave-week-${i}`} style={style}/>)
            })}
        </div>
    );
};
