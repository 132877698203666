// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class MediaItem extends Component{
    constructor(props) {
        super(props);

        this.handleCheckClick = this.handleCheckClick.bind(this);
        this.remove = this.remove.bind(this);
    }
    handleCheckClick(){
        this.props.toggleActive(this.props.id);
    }
    remove(){
        this.props.remove(this.props.id, this.props.unique_id);
    }
    render(){
        const imgContainerStyle = {
            backgroundImage: `url('${this.props.img}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
        };
        let classNames = "";
        let hideCheck = false;
        if ("hideCheck" in this.props && this.props.hideCheck){
            classNames += " d-none";
            hideCheck = true;
        }
        if (this.props.active){
            classNames += " active";
        }
        return (
            <div className="media-item-container">
                <div className="media-img-box" style={imgContainerStyle}>
                    <div className={"check-container" + classNames} onClick={this.handleCheckClick}>
                        <i className="far fa-check fa-xs"></i>
                    </div>
                    <div className="media-img-shadow">
                        <div className="x-container" onClick={this.remove}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
