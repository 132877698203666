import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import { Cookies } from 'react-cookie';

import InputError from '../InputError.jsx';
import '../../css/SignUp.css';
import utils from '../../utils';
import config from '../../config';

var moment = require('moment');
const _ = require("lodash");

class ComeInside extends Component{
    constructor(props){
        super(props);
    }

    render(){

        console.log(this.props);

        return (
            <div className="signup-content view3">
                <p className="heading1">
                    Your account has been verified.
                    <br />
                    Thanks for joining us!
                </p>

                <p className="heading2 gray">Now come take a look at our beautiful login page!</p>

                <div className="row">
                    <Link to="/login" className="btn btn-primary mx-auto">Log In</Link>
                </div>

                <div className="row">
                    <Link to="/activity" className="link mx-auto">Explore site</Link>
                </div>
            </div>
        );
    }
}

export default ComeInside;
