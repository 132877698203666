// Required Dependencies
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

class AboutCard extends Component {

    render() {

        let {id, profileimage, name, bio} = this.props;

        return (
            <Link to={`/creators/${id}`} className="card-container">
                <div className="card">
                    <div className="card-img-container">
                        <div className="card-img" style={{backgroundImage:`url(${profileimage})`}}></div>
                    </div>

                    <div className="card-body">
                        <h3 className="card-title">{name}</h3>
                        <p className="card-description">{bio}</p>
                    </div>
                </div>
            </Link>
        );
    }
}

export default AboutCard;
