import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

// My Dependencies
import MainNav from '../components/MainNav.js';
import SubNav from '../components/SubNav.js';

// utils
import utils from '../utils';
import config from '../config';

class ErrorPage extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="main">
                <div className="content d-flex justify-content-center">
                    <div>
                        <h1 style={{marginTop: "100px"}}>We couldn't find the page you were looking for. :(</h1>
                        <p style={{marginTop: "100px"}}>If you think this may be a bug, please let us know at <a href="mailto:mailman@jouncer.io">mailman@jouncer.io</a></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
