import React, { Component } from 'react';

class Tooltip extends Component {

  render() {
    return (
      <div className={`tooltip-container-${this.props.orientation}`}>
          <div target="blank" className={`guiding-tooltip-${this.props.orientation}`}>{this.props.name}</div>
      </div>
    );
  }
}

export default Tooltip;
