import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

const FinishProfileBanner = props => (<div className="finish-profile v-centred split">
    <span>It looks like you haven’t finished your profile yet. Do you want to do that now?</span>
    <Link to="/create-profile/0" className="btn btn-primary">Yes, let's showcase my work!</Link>
</div>)

export default FinishProfileBanner;
