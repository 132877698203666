import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import Dropzone from 'react-dropzone';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import config from "../../config.js";
import utils from "../../utils.js";
import Badge from "../Badge.js";
import Card from "../Card.js";
import PortfolioItem from "../PortfolioItem.jsx";
import SkillsMenu from '../SkillsMenu.jsx';

const uniqueString = require('unique-string');
const _ = require("lodash");
const scss = config.SCSS;
const selectStyles = config.selectStyles;
const pIndex = 3;



const ProjectCard = ({
    id,
    title,
    titleErrors,
    description,
    link,
    skills,
    skillBuckets,
    editSkills,
    media,
    newMedia,
    isNew,
    changed,
    save,
    remove,
    handleChange,
    handleClick,
    selectedGithubRepo,
    handleClose,
    openModal,
    githubrepos,
    githubAuthorized,
    removeItem,
    hidden = false,
}, i) => {
    const style = {
        display: hidden
            ? 'none'
            : null
    };
    let img = "";

    if (media.length > 0 && newMedia) {
        img = media[0].preview;
    } else if (media.length > 0) {
        img = utils.image(media[0]);
    }

    return (
        <div>
            <PortfolioItem
                id={id}
                title={title}
                titleErrors={titleErrors}
                description={description}
                link={link}
                skills={skills}
                skillBuckets={skillBuckets}
                editSkills={editSkills}
                media={media}
                isNew={isNew}
                changed={changed}
                save={save}
                remove={remove}
                handleChange={handleChange}
                selectedGithubRepo={selectedGithubRepo}
                handleClick={handleClick}
                handleClose={handleClose}
                openModal={openModal}
                githubrepos={githubrepos}
                githubAuthorized={githubAuthorized}

                open={openModal === id}/>

            <Card
                id={id}
                title={title}
                description={utils.truncateTo(description, 40)}
                img={img}
                images={media}
                newMedia={newMedia ? true : false}
                onClick={() => handleClick(id)}
                style={style}
                removeItem={removeItem}
                remove={true}/>
        </div>
    )
};



const SkillsModal = (props) => {
    const {isOpen, toggle} = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="skills-modal modal-dialog-centered modal-lg">
            <ModalHeader toggle={toggle}>Select the skills you used for this project</ModalHeader>
            <ModalBody>
                <SkillsMenu {...props} />
            </ModalBody>
        </Modal>
    );
}

const emailLink = "mailto:creative@jouncer.io?subject=Project%20Visualization&body=Hi%20Jouncer%20design%20team%2C%0A%0AI%20would%20love%20to%20have%20some%20visuals%20to%20showcase%20my%20projects.%0A%0AThe%20project%20I%20would%20like%20visualized%20is%20called%20________%20and%20is%20registered%20to%20ACCOUNT%40mail.utoronto.ca.%0A%0AThese%20are%20some%20details%20or%20stylistic%20preferences%20I%20have%3A%0A%0A(Add%20descriptions%2C%20images%2C%20or%20links%20to%20other%20designs)%0A%0AI%20look%20forward%20to%20further%20discussing%20the%20imagery!"

class View3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            saved: false,
            portfolioItems: [],
            skillBuckets: [],
            modal: false,
            activeItem: "",
            openPortfolioModal: -1,
            githubAuthorized: false,
            githubrepos: [],
        };

        this.openModal = this
            .openModal
            .bind(this);
        this.closeModal = this
            .closeModal
            .bind(this);
        this.handleBucketClick = this
            .handleBucketClick
            .bind(this);
        this.handleSkillClick = this
            .handleSkillClick
            .bind(this);
        this.handleClick = this
            .handleClick
            .bind(this);
        this.handleClose = this
            .handleClose
            .bind(this);
        this.selectedGithubRepo = this
            .selectedGithubRepo
            .bind(this);
        this.handleChange = this
            .handleChange
            .bind(this);
        this.submitClick = this
            .submitClick
            .bind(this);
        this.saveItem = this
            .saveItem
            .bind(this);
        this.removeItem = this
            .removeItem
            .bind(this);
        this.saveNotif = this
            .saveNotif
            .bind(this);

        this.getData();
    }
    getData() {
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/portfolio_projects`;
        utils
            .get(url)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        portfolioItems: res
                            .data
                            .all_projects
                            .map(project => ({
                                ...project,
                                id: project.unique_id,
                                link: project.external_link,
                                skills: _.flatten(project.project_skills.map(b => b.skills.map(s => s.id))),
                                media: project.images,
                                isNew: false,
                                changed: false
                            }))
                    });
                    self.addItem();
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });

        const skillsUrl = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
        utils
            .get(skillsUrl)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    let {skills, all_skill_buckets} = res.data;
                    self.setState({
                        skillBuckets: all_skill_buckets
                            .filter(bucket => !bucket.hidden)
                            .map(bucket => {
                                // let hasActiveSkill = false;
                                bucket.id = bucket.unique_id;
                                bucket.skills = bucket
                                    .skills
                                    .filter(skill => !skill.hidden && (!skill.is_user_added || skills.indexOf(skill.id) >= 0)) // not hidden AND (if user created, display if user has that skill)
                                    .map(skill => {
                                        skill.key = skill.id;
                                        skill.label = skill.name;
                                        skill.value = skill
                                            .id
                                            .toString();
                                        // skill.active = skills.indexOf(skill.id) >= 0;
                                        // hasActiveSkill = hasActiveSkill || skill.active;
                                        return skill;
                                    });
                                // bucket.active = hasActiveSkill;
                                return bucket;
                            })
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    componentWillMount() {
        this.importGithubRepos();
    }

    importGithubRepos() {
        const url = `${config.BACKEND_HOST}/accounts/github_repos`;
        utils.get(url)
            .then((res) => {
                if (res.status){
                    this.setState({
                        githubrepos: res.data.github_repos,
                        githubAuthorized: true,
                    });
                }
            })
            .catch((err) => {
                this.setState({ githubAuthorized: false });
                console.log(err.message);
            });
    }

    openModal(itemId){
        //transfer skills state
        let {skills} = _.find(this.state.portfolioItems, {id: itemId});
        this.setState({
            modal: true,
            activeItem: itemId,
            skillBuckets: this.state.skillBuckets
                .map(bucket => {
                    let hasActiveSkill = false;
                    bucket.skills = bucket
                        .skills
                        .map(skill => {
                            skill.active = skills.indexOf(skill.id) >= 0;
                            hasActiveSkill = hasActiveSkill || skill.active;
                            return skill;
                        });
                    bucket.active = hasActiveSkill;
                    return bucket;
                }),
        });
    }
    closeModal(){
        console.log("close modal");
        console.log(this.state.skillBuckets);
        // save skills to portfolio project
        let skills = [];
        this.state.skillBuckets.forEach(b => {
            b.skills.forEach(s => {
                if (s.active){
                    console.log(s.name);
                    skills.push(s.id);
                }
            });
        });

        console.log("skills", skills);

        this.setState({
            modal: !this.state.modal,
            portfolioItems: this.state.portfolioItems.map(p => {
                if (p.id == this.state.activeItem){
                    p.skills = skills;
                    p.changed = true;
                }
                return p;
            }),
            skillBuckets: this.state.skillBuckets
                .map(bucket => {
                    bucket.active = false;
                    bucket.skills = bucket
                        .skills
                        .map(skill => {
                            skill.active = false;
                            return skill;
                        });
                    return bucket;
                }),
        });
    }
    handleBucketClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (id == bucket.id) {
                        bucket.active = !bucket.active;
                    }
                    return bucket;
                });
            return prevState;
        });
    }

    handleSkillClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    bucket.skills = bucket.skills.map(skill => {
                        if (id == skill.id) {
                            skill.active = !skill.active;
                        }
                        return skill;
                    });
                    return bucket;
                });
            return prevState;
        });
    }

    getDescription(desc, readme) {
        let description = "";

        if (readme) {
            let readmeshort = readme.split("\n");
            let newstring = '';

            // returns the first 6 "paragraphs" in the readme (includes blank lines for space)
            for (let i = 0; i < readmeshort.length && i < 6; i++) {
                newstring = newstring + '\n' + readmeshort[i];
            }

            // stripping readme of markdown symbols, mainly #, links, and images
            readme = newstring.replace(/# /g, '').replace(/#/g, '').replace(/<.*?>/g, '').replace(/[!](?!' ').*?[)]/g, '');
        }

        if (desc) {
            if (readme) {
                description = desc + '\n' + readme;
                return description;
            }
            return desc;
        } else if (readme) {
            description = readme;
            return description;
        } else {
            return "";
        }
    }

    async selectedGithubRepo(id, full_name, name) {

        let repoTitle = name.replace(/-/g, " ");

        const url = `${config.BACKEND_HOST}/accounts/get_repo_data`;
        await utils.get(url, {repo_full_name: full_name})
            .then((res) => {
                console.log(res);
                if (res.status){

                    const repoData = res.data.repo_data;

                    this.setState({
                        portfolioItems: this
                            .state
                            .portfolioItems
                            .map(item => {
                                if (item.id  ===  id) {
                                    item.title = repoTitle;
                                    item.description = this.getDescription(repoData.description, repoData.readme);
                                    item.link = repoData.link;
                                    item.github_url = repoData.link;
                                    item.github_repo_id = repoData.id;
                                    item.changed = true;

                                    repoData.skills.map(skill => {
                                        item.skills.push(skill.skill_id);
                                        this.handleBucketClick(skill.skill_bucket_unique_id);
                                        this.handleSkillClick(skill.skill_id);
                                    })
                                }
                                return item;
                            })
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    handleChange(name, value, id = undefined) {
        this.setState({
            portfolioItems: this
                .state
                .portfolioItems
                .map(item => {
                    if (item.id  ===  id) {
                        item[name] = value;
                        item.changed = true;
                        if (name == "media") {
                            item.newMedia = true;
                        }
                    }
                    return item;
                })
        });
    }

    submitClick(event) {
        event.preventDefault();
        if (this.state.submitEnabled && (event.target.name  ===  "submit" || event.target.name  ===  "save")) {
            this
                .state
                .portfolioItems
                .forEach(item => {
                    if (item.changed) {
                        this.saveItem(item.id);
                    }
                });

            this.saveNotif();
            if (event.target.name  ===  "submit") {
                const self = this;
                let checkSuccess = setInterval(() => {
                    if (self.state.portfolioItems.filter(item => item.changed).length  ===  0) {
                        self.setState({submitSuccess: true});
                        clearInterval(checkSuccess)
                    }
                }, 500);
            }
        }
    }

    addItem() {
        let items = this.state.portfolioItems;
        items.push({
            id: uniqueString(),
            title: "",
            description: "",
            link: "",
            skills: [],
            media: [],
            isNew: true,
            changed: false,
        });
        this.setState({portfolioItems: items});
    }

	validateItem(item){
		if (!item.title){
			item.titleErrors = ["Please give your project a title"];
			return false;
		}
		return true;
	}

    saveNotif() {
        this.setState({saved: true});

        setTimeout(() => {
            this.setState({
            saved: false
          })
      }, 3000);
    }

    saveItem(id) {
        if (this.state.submitEnabled) {
            this.setState({submitEnabled: false});
            this.setState({
				portfolioItems: this.state.portfolioItems.map(item => {

                    if (item.id  ===  id) {

                        if (!this.validateItem(item)) {
                            return item;
                        }

                        //api call
                        const self = this;
                        const url = `${config.BACKEND_HOST}/accounts/portfolio_projects`;
                        // const url = "https://harrydong.com/req.php";

                        let params = {
                            current_user: 'A',
                            project_title: item.title,
                            project_description: item.description,
                            project_link: item.link,
                            selected_skills: item.skills,
                            existing_file_uuids: item
                                .media
                                .filter(m => "unique_id" in m)
                                .map(m => m.unique_id),
                            uploaded_files: item
                                .media
                                .filter(m => !("unique_id" in m)),
                        };

                        if (item.github_url && item.github_repo_id) {
                            params.github_url = item.github_url;
                            params.github_repo_id = item.github_repo_id;
                        }

                        console.log("params", params);
                        if (!item.isNew) {
                            params.project_uuid = item.id;
                        }
                        utils
                            .postMultipart(url, params)
                            .then(raw => raw.json())
                            .then(res => {
                                console.log(res);
                                if (res.status) {
                                    //set ID
                                    this.importGithubRepos();
                                    if (item.isNew) {
                                        this.addItem();
                                    }
                                    this.saveNotif();
                                    self.setState({
                                        portfolioItems: self
                                            .state
                                            .portfolioItems
                                            .map(item => {
                                                if (item.id  ===  id) {
                                                    item.id = res.data.project_uuid; //insert returned ID here
                                                    item.isNew = false;
                                                    item.changed = false;
                                                }
                                                return item;
                                            })
                                    });

                                }
                            })
                            .catch(err => {
                                console.log(err);
                            })
                            . finally(() => self.setState({submitEnabled: true}));
                    }
					return item;
                })
			});

        }
    }
    removeItem(id) {
        console.log("removing " + id);
        let item = this
            .state
            .portfolioItems
            .filter(item => item.id  ===  id)[0];
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/portfolio_projects`;
        const params = {
            project_uuid: item.id,
            delete_project: true
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        portfolioItems: self
                            .state
                            .portfolioItems
                            .filter(item => item.id != id)
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    handleClose(id) {
        this.setState({openPortfolioModal: -1});
        this.setState({submitEnabled: true});
        this.saveItem(id);
    }

    handleClick(id) {
        this.setState({openPortfolioModal: id});
    }

    render() {
        if (this.state.submitSuccess) {
            return <Redirect to={(pIndex + 1).toString()} push />;
        }

        return (
            <div className="mx-auto view3">
                <form action={(pIndex + 1).toString()} onSubmit={event => event.preventDefault()}>

                    <div className="form-row no-margin">
                        <h2 className="create-profile-headline float-left">Portfolio</h2>
                    </div>

                    <div className="form-row no-margin">
                        <h3 className="create-profile-subheadline">If you're struggling to showcase your
                            projects visually, <a className="link" href={emailLink}>get in touch</a> and we'll help you create a visual for it.</h3>
                    </div>

                    <div className="form-row">
                        <div className="github-auth-banner">
                            {!this.state.githubAuthorized ? <p>Do you code? Want to import all of your important repos?</p>
                                : <p><i class="far fa-check-circle"></i>&nbsp;&nbsp;Your account is synced with Github</p> }
                            {!this.state.githubAuthorized ?
                                <a className="authorize-github"
                                    href="https://github.com/login/oauth/authorize?client_id=5d294ab362a4f18f3352&redirect_uri=https://jouncer.com/github/auth">
                                    <i class="fab fa-github"></i>&nbsp;&nbsp;Sync with Github
                                </a>
                                : null }

                            </div>
                    </div>

                    <div className="row margin-bottom-30">
                        {this
                            .state
                            .portfolioItems
                            .map(item => (
                                <div className="card-container">
                                    <ProjectCard
                                        id={item.id}
                                        title={item.title}
										titleErrors={item.titleErrors}
                                        description={item.description}
                                        link={item.link}
                                        skills={item.skills}
                                        skillBuckets={this.state.skillBuckets}
                                        editSkills={this.openModal}
                                        media={item.media}
                                        newMedia={item.newMedia}
                                        isNew={item.isNew}
                                        changed={item.changed}
                                        remove={this.removeItem}
                                        handleChange={this.handleChange}
                                        handleClick={this.handleClick}
                                        selectedGithubRepo={this.selectedGithubRepo}
                                        handleClose={this.handleClose}
                                        openModal={this.state.openPortfolioModal}
                                        githubrepos={this.state.githubrepos}
                                        githubAuthorized={this.state.githubAuthorized}
                                        removeItem={this.removeItem} />
                                </div>
                            ))}
                    </div>

                    <div className="form-row no-margin">
                        <button
							id="save-btn"
                            name="save"
                            type="submit"
                            className="btn btn-secondary"
                            onClick={this.submitClick}>Save Portfolio</button>
						<button
                            name="submit"
                            type="submit"
                            className="btn btn-primary"
                            onClick={this.submitClick}>Save and Finish</button>
                    </div>
					<div className="form-row">
						<Link to={(pIndex + 1).toString()} className="small-link">Skip</Link>
                        {this.state.saved === true ? <p className="small-link" style={{color: '#05c672'}}>Portfolio Saved!</p> : null}
					</div>

                </form>
                <SkillsModal
                    isOpen={this.state.modal}
                    toggle={this.closeModal}
                    skillBuckets={this.state.skillBuckets}
                    handleBucketChange={this.handleBucketClick}
                    handleSkillChange={this.handleSkillClick}
                    />
            </div>
        );
    }
}

export default View3;
