import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Cookies} from 'react-cookie';
import {Redirect} from 'react-router-dom';

// utils
import utils from '../utils';
import config from '../config';

class TokenLogin extends Component {

    componentDidMount(){
        if (!this.props.loggedIn){
            const cookies = new Cookies();
            cookies.set('auth_token', this.props.match.params.token, {path: "/"});
            this.props.login();
        }
    }

    render() {
        if (this.props.loggedIn){
            return <Redirect to="/activity" />;
        }
        return null;
    }
}

export default TokenLogin;
