// Required Dependencies
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

import CommentSection from './CommentSection';
import Tooltip from './Tooltip';

import allideas from '../all_ideas.txt';

import utils from '../utils';
import config from '../config';

class evolutionCard extends Component {
    render() {
        return(
            <div className="evolution-card">
                {this.props.evolutionContent}
            </div>
        );
    }
}

class Idea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            metricNumber: 0,
            metricActive: false,
            evolutionsOpen: false,
            mediaActive: false,
        };
        this.metricClick = this.metricClick.bind(this);
        this.mediaClick = this.mediaClick.bind(this);
        this.handleUpvoteSubmit = this.handleUpvoteSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ metricNumber: this.props.upvoteCount, metricActive: (this.props.userUpvote == 1 ? true : false) });
    }

    metricClick() {
        if (this.state.metricActive == false) {
            this.handleUpvoteSubmit(true);
            this.setState({ metricActive: true, metricNumber: this.state.metricNumber + 1 });


        } else if (this.state.metricActive == true) {
            this.handleUpvoteSubmit(false);
            this.setState({ metricActive: false, metricNumber: this.state.metricNumber - 1 });
        }
    }

    handleUpvoteSubmit(upvoteAction) {

        let params = {};

        if (upvoteAction) {
            params = {
                idea_unique_id: this.props.id,
                upvote: true,
            };
        } else {
            params = {
                idea_unique_id: this.props.id,
                downvote: true,
            };
        }

        const self = this;
        utils.postMultipartNoToken(`${config.BACKEND_HOST}/all/anonymous_upvote`, params)
            .then((res) => {
                if (!res.ok) throw "Error with request";
                self.setState(prevState => {
                });
            }).catch(err => console.log(err));
    }

    mediaClick() {
        if (this.state.mediaActive == false) {
            this.setState({ mediaActive: true });
        } else if (this.state.mediaActive == true) {
            this.setState({ mediaActive: false });
        }
    }

    showevolutions() {
        if (this.state.evolutionsOpen == false) {
            this.setState({ evolutionsOpen: true });
        } else {
            this.setState({ evolutionsOpen: false });
        }
    }

    startTeam() {
        window.location.href = `/create-team/0?id=${this.props.id}`;
    }

    render() {

        let {id, idea, dateAdded, comments, ideaMedia, youridea} = this.props;

        let metricStyle = {
            background: this.state.metricActive ? 'linear-gradient(100deg, rgba(65, 255, 115, 0.9), rgba(0, 222, 220, 0.9))' : 'linear-gradient(100deg, rgba(65, 255, 115, 0.5), rgba(0, 222, 220, 0.5))',
        }

        let ideaMediaIcon = {
            width: '56px',
            height: '56px',
            margin: '12px 0px 12px 12px',
        }

        let ideaMediaLarge = {
            display: this.state.mediaActive ? 'flex' : 'none',
            width: '100%',
            height: 'auto',
            margin: '0px',
        }

        let flex = {
            display: this.state.mediaActive ? 'inline' : 'flex',
        }

        let metricFire = {
             fontSize: this.state.metricNumber == 0 ? '20px' : '20px',
        }

        let evolutionStyle = {
            height: this.state.evolutionsOpen ? 'auto' : '40px',
            transition: '0.4s',
        }

        return (
            <div className="idea-container">

                <div className="metric-box" onClick={() => this.metricClick()} style={metricStyle}>
                    <div className="metric">
                        <i className="fas fa-burn" style={metricFire}></i>
                        <p>{this.state.metricNumber == 0 ? null : this.state.metricNumber}</p>
                    </div>
                </div>

                <div className="idea">
                    <div className="idea-content" style={flex}>
                        <div style={{ display: 'flex', width: "100%" }}>

                        {/*<Link to={`/creators/${userId}`} className="person-info">
                            <div className="idea-image tooltip-anchor" style={{backgroundImage:`url(${profileImage})`}}>
                                <Tooltip name={name} orientation="above"/>
                            </div>
                        </Link>

                            {this.props.ideaMedia ?
                                <div className="idea-media">
                                    <img src={this.props.ideaMedia} style={ideaMediaIcon} onClick={() => this.mediaClick()}/>
                                </div>
                            : null}
                            */}

                            <div className="idea-text">
                                <p>{idea}</p>
                                <a>{comments ? comments.length : "No"} {comments.length == 1 ? "Comment" : "Comments"} · Posted {utils.fromNow(dateAdded)}</a>
                                <CommentSection suggestions={true} comments={comments} ideaid={id} />
                            </div>
                        </div>

                        <div className="idea-media">
                            <img src={ideaMedia} style={ideaMediaLarge} onClick={() => this.mediaClick()}/>
                        </div>


                        {/*<div className="evolution-section" style={evolutionStyle}>
                            <h3 onClick={() => this.showevolutions()}>Evolutions</h3>
                            <div className="evolution-cards">
                                <evolutionCard evolutionContent="What about a full-fledged pet service? Not just transportation, but housing and food as well."/>
                                <evolutionCard evolutionContent="I don't think dogs will pay for rides, but they'll probably pay for head scratches. On-demand head scratching?"/>
                                <evolutionCard evolutionContent="...Uber for regular people BUT with puppy petting included?? Man I would be so loyal to Uber for that."/>
                            </div>
                        </div>*/}
                    </div>

                </div>
            </div>
        );
    }
}

export default Idea;
