import config from './config';
import React from 'react';
import {Cookies} from 'react-cookie';
var FormData = require('form-data');
const rp = require('request-promise-native');
const fetch = require('node-fetch');
const moment = require('moment');
const _ = require("lodash");

const cookies = new Cookies();

const TAB_NAMES = {
    '': 'Log In',
    'welcomemat': 'Home',
    'testhome': 'TestHome',
    'privacy' : 'Privacy Policy',
    'forgot-password': 'Forgot Password',
    'change-password': 'Change Password',
    'login': 'Login',
    'logout': 'Logout',
    'github-signup': 'Github Signup',
    'shoesoff': 'Signup',
    'comeinside': 'Welcome',
    'about': 'About',
    'create-profile': 'Create Profile',
    'create-team': 'Start Project',
    'edit-team': 'Edit Team',
    'not-found': 'Not Found!',
    'activity': 'Activity',
    'creators': 'Creators',
    'projects': 'Projects',
    'project': 'Project',
    'requests': 'Join Requests',
    'invites': 'Invites',
    'chat': 'Chat',
    'token-login': 'Token Login',
    'github': 'Github Authentication',
    'suggest-someone-interesting': 'Suggest Someone',
    'email-signup': 'Email Signup',
    get: function(target, name) {
        return target.hasOwnProperty(name) ? target[name] : '';
    }
};

const token = () => cookies.get('auth_token');

const camel = (obj) => {
    var camelCaseObject = {};
    _.forEach(
        obj,
        function(value, key) {
            let isArray = _.isArray(value);
            if (isArray || _.isPlainObject(value)) {     // checks that a value is a plain object or an array - for recursive key conversion
                value = camel(value);               // recursively update keys of any values that are also objects
                if (isArray){
                    value = _.values(value);
                }
            }
            camelCaseObject[_.camelCase(key)] = value;
        }
    )
    return camelCaseObject;
}

const toFront = (arr, index) => {
    let copy = [arr[index]];
    arr.forEach((elem, currIndex) => {
        if (index !== currIndex){
            copy.push(elem);
        }
    });
    return copy;
}


const shortTime = timeStr => {
    return moment(timeStr).calendar(null, {
        sameDay: 'h:mm a',
        nextDay: 'h:mm a',
        nextWeek: 'MMM D',
        lastDay: '[Yesterday]',
        lastWeek: 'ddd',
        sameElse: 'MMM D'
    });
}

const fromNow = timeStr => {
    return moment(timeStr).fromNow();
}

const login = async (username = "", password = "") => {
    const params = {
        username,
        password
    };
    const {status, data} = await get("/login", params);
    if (status){
        cookies.set('auth_token', data.token);
    }
}

const isLoggedIn = () => {
    return !!token();
}

const get = async (url, params = undefined) => {
    if (url[0] === "/") {
        url = `${config.BACKEND_HOST}${url}`;
    }
    let options = {
        uri: url,
        qs: params,
        json: true
    };
    if (token()){
        options.headers = {
            'Authorization': `Token ${token()}`
        };
    }
    return rp(options);
}

const post = async (url, params = undefined) => {
    if (url[0] === "/") {
        url = `${config.BACKEND_HOST}${url}`;
    }
    let options = {
        uri: url,
        method: "POST",
        form: params,
        json: true
    };
    if (token()){
        options.headers = {
            'Authorization': `Token ${token()}`
        };
    }
    return rp(options);
}
const postMultipart = (url, params = undefined) => {
    var form = new FormData();
    if (url[0] === "/") {
        url = `${config.BACKEND_HOST}${url}`;
    }
    for (var key in params) {
        if (key in params) {
            let val = params[key];
            console.log(key, val, typeof val);
            if (_.isArray(val)){
                val.forEach((elem, i) => {
                    form.append(key + `[${i}]`, elem);
                });
            }
            else{
                form.append(key, val);
            }
        }
    }
    const options = {
        method: "POST",
        headers: {
            'Authorization': `Token ${token()}`,
            // "Content-Type": "multipart/form-data",
            // 'Access-Control-Allow-Credentials': true,
            // 'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
            // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            // 'Access-Control-Allow-Origin': '*'
        },
        body: form,
        credentials: "include",
        mode: "cors"
    };
    return fetch(url, options);
    // const options = {
    //     uri: url,
    //     method: "POST",
    //     formData: form,
    //     headers: {
    //         'Authorization': 'Token 09e6398532b1331df746640847c483df3c5ff1fb',
    //         "Content-Type": "multipart/form-data",
    //     }
    // };
    // return rp(options);
}

const postMultipartNoToken = (url, params = undefined) => {
    var form = new FormData();
    if (url[0] === "/") {
        url = `${config.BACKEND_HOST}${url}`;
    }
    for (var key in params) {
        if (key in params) {
            let val = params[key];
            console.log(key, val, typeof val);
            if (_.isArray(val)){
                val.forEach((elem, i) => {
                    form.append(key + `[${i}]`, elem);
                });
            }
            else{
                form.append(key, val);
            }
        }
    }
    const options = {
        method: "POST",
        headers: {
        },
        body: form,
        credentials: "include",
        mode: "cors"
    };
    return fetch(url, options);
    // const options = {
    //     uri: url,
    //     method: "POST",
    //     formData: form,
    //     headers: {
    //         'Authorization': 'Token 09e6398532b1331df746640847c483df3c5ff1fb',
    //         "Content-Type": "multipart/form-data",
    //     }
    // };
    // return rp(options);
}

const image = ({
    media_link = ""
}) => `${config.IMAGE_HOST}/${media_link}`;

const prefixImage = img => img ? `${config.IMAGE_HOST}/${img}` : null;

const firstImage = (images) => {
    let url = "";
    if (images.length > 0){
        url = image(images[0]);
    }

    return url;
}

const randint = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const gradients = [
    "19A5C8", "4C0082",
    "000241", "1DB6A4",
    "76001C", "DBA830",
    "2EA96A", "004334",
    "648FD9", "300065",
    "9C0927", "0D004E",
    "701800", "E3D06C",
    "000344", "D63DA9",
    "780055", "EEA767",
    "005E67", "00A8BC",
    "036EC1", "31B25C",
    "009AB9", "130055",
    "01CF9A", "001067",
    "BD7323", "880675",
    "0047A4", "BB2BB3",
    "C7771A", "4E003B",
    "5C0CC7", "08001E",
    "00B071", "002137",
    "0EA1E7", "002283",
    "405FFF", "18005A",
    "E08539", "740303",
    "D25243", "64002F"
];

function genGradient(seed){
    //index determines which pair is chosen, order seed determines their order
    let index, order;
    index = seed % (gradients.length / 2) * 2;
    order = parseInt(seed.toString().charAt(0)) % 2;
    return [gradients[index + order], gradients[index + (order + 1) % 2]];
}
function hash(seed){
    var hash = 0, i, chr;
    if (seed.length ===0) return hash;
    for (i = 0; i < seed.length; i++) {
        chr   = seed.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
const randomGradient = (seed = undefined) => {
    let start, end;
    if (typeof seed  ===  "string"){
        let gradientSeed = Math.abs(hash(seed));
        let result = genGradient(gradientSeed);
        start = result[0];
        end = result[1];
    }
    else {
        start = randint(0, 0xffffff).toString(16);
        end = randint(0, 0xffffff).toString(16);
    }
    return `linear-gradient(135deg, #${start} 0%, #${end} 100%)`;
}




//get tag colors
const tagColors = [
    "5C0CC7", "521df2",
    "D25243", "E08539",
    "059e70", "05c672",
    "5fcc9e", "e55149",
    "5FCC9E", "a640ef",
    "5C0CC7", "521df2",
    "036EC1", "00aaad",
    "6254ea", "4286f4",
    "135fdb", "e86f51",
    "e55149", "a640ef",
    "5fcc9e", "c1365d",
    "c1365d", "059E70",
    "5C0CC7", "521df2",
    "D25243", "E08539",
    "059e70", "05c672",
    "5fcc9e", "e55149",
    "a640ef", "5C0CC7",
    "521df2", "036EC1",
    "00aaad", "6254ea",
    "4286f4", "135fdb",
    "e86f51", "e55149",
    "a640ef", "5fcc9e",
];

function genTagColor(seed){
    //index determines which pair is chosen, order seed determines their order
    let index;
    index = seed % (tagColors.length);
    return tagColors[index];
}

const getTagColor = (seed = undefined) => {
    let tagColor;
    if (typeof seed  ===  "string"){
        let tagSeed = Math.abs(hash(seed));
        tagColor = genTagColor(tagSeed);
    }
    else {
        tagColor = randint(0, 0xffffff).toString(16);
    }
    return `#${tagColor}`;
}




const imageExists = (image_url) => {

    let image = new Image();
    image.src = image_url;

    return image.complete;

}
//thanks, StackOverflow
//https://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling
function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

function truncateTo(text, length) {
    const words = text.split(" ");
    let ret = [];
    let total = 0;
    for (let word of words) {
        ret.push(word);
        total += word.length;
        if (total >= length) {
            break;
        }
    }
    let retStr = ret.join(" ");
    if (text.length > retStr.length) {
        retStr += '...';
    }
    return retStr;
}

function objEmpty(obj) {
    for (let _ in obj) return false;
    return true;
}

const Spinner = () => <img className="spinner" src="/img/spinner.gif" alt="" style={{width: "150px", height: "150px", margin: "auto"}}/>


export default {
    TAB_NAMES,
    isLoggedIn,
    login,
    camel,
    shortTime,
    fromNow,
    toFront,
    token,
    get,
    post,
    postMultipart,
    postMultipartNoToken,
    image,
    prefixImage,
    firstImage,
    randint,
    randomGradient,
    getTagColor,
    imageExists,
    isScrolledIntoView,
    truncateTo,
    objEmpty,
    Spinner
};
