// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import config from '../../config';
import utils from "../../utils.js";

import Header from './Header';
import NextSection from './NextSection';
import { Cookies } from 'react-cookie';

import Octicon, {Octoface} from '@primer/octicons-react';

const selectStyles = config.selectStyles;

const graduationYearOptions = [
    {
        value: '2012',
        label: '2012'
    }, {
        value: '2013',
        label: '2013'
    }, {
        value: '2014',
        label: '2014'
    }, {
        value: '2015',
        label: '2015'
    }, {
        value: '2016',
        label: '2016'
    }, {
        value: '2017',
        label: '2017'
    }, {
        value: '2018',
        label: '2018'
    }, {
        value: '2019',
        label: '2019'
    }, {
        value: '2020',
        label: '2020'
    }, {
        value: '2021',
        label: '2021'
    }, {
        value: '2022',
        label: '2022'
    }, {
        value: '2023',
        label: '2023'
    }, {
        value: '2024',
        label: '2024'
    }, {
        value: '2025',
        label: '2025'
    }, {
        value: '2026',
        label: '2026'
    }, {
        value: '2027',
        label: '2027'
    }
];

class View4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,

            userId: "",

            profilePicture: "",

            first: "",
            firstErrors: [],
            last: "",
            lastErrors: [],

            personalMessage: "",
			personalMessageErrors: [],

            school: props.school,
            schoolOptions: [],
            graduationYear: props.graduationYear,
            graduationYearOptions: graduationYearOptions,
            program: props.program,
            programOptions: [],
            degree: props.degree,
            degreeOptions: [],
			schoolErrors: [],

            token: "",
        };

        this.prefillInformation = this.prefillInformation.bind(this);
        this.onDrop = this
            .onDrop
            .bind(this);
        this.handleChange = this
            .handleChange
            .bind(this);
        this.handleSchoolChange = this
            .handleSchoolChange
            .bind(this);
        this.handleGraduationChange = this
            .handleGraduationChange
            .bind(this);
        this.handleProgramChange = this
            .handleProgramChange
            .bind(this);
        this.handleDegreeChange = this
            .handleDegreeChange
            .bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.getUniversity = this.getUniversity.bind(this);

        const schoolUrl = `${config.BACKEND_HOST}/accounts/search_university`;
        const programUrl = `${config.BACKEND_HOST}/accounts/search_program`;
        const degreeUrl = `${config.BACKEND_HOST}/accounts/search_degree`;
        this.getOptions(schoolUrl, "schoolOptions");
        this.getOptions(programUrl, "programOptions");
        this.getOptions(degreeUrl, "degreeOptions");
    }

    componentWillMount() {
        const url = window.location.href;
        const id = url.split('=')[1].split('&')[0];
        this.setState({userId: id});
        // this.importGithubRepos();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.prefillInformation();
    }

    getOptions(url, optionName, term = "") {
        const self = this;
        utils
            .get(url, {term: term})
            .then(res => {
                if (res.status) {
                    //set options
                    self.setState({
                        [optionName]: res
                            .data
                            .results
                            .map(result => ({
                                label: result
                                    .label
                                    .toString(),
                                value: result
                                    .id
                                    .toString()
                            }))
                    });
                }
            })
            .catch((err) => {
                if (typeof err === "object") {
                    console.log(err.response.body);
                } else {
                    console.log(err);
                }
                //try again?
            });
    }

    getUniversity(option) {
        const url = `${config.BACKEND_HOST}/accounts/search_university`;
        const self = this;
        utils
            .get(url, {term: option})
            .then(res => {
                if (res.status) {
                    //set options
                    self.setState({
                        schoolOptions: res
                            .data
                            .results
                            .map(result => ({
                                label: result
                                    .label
                                    .toString(),
                                value: result
                                    .id
                                    .toString()
                            }))
                    });
                }
            })
            .catch((err) => {
                if (typeof err === "object") {
                    console.log(err.response.body);
                } else {
                    console.log(err);
                }
                //try again?
            });
    }


    prefillInformation() {
        let self = this;
        const url = `${config.BACKEND_HOST}/accounts/activation_prefill`;
        let params = {
            userprofile_unique_id: this.state.userId,
        };

        utils.post(url, params)
        .then(res => {
            if (res.status){
                let profile = res.data.userprofile;
                self.setState({
                    first: profile.user.first_name,
                    last: profile.user.last_name,
                    personalMessage: profile.mission,
                    profilePicture: utils.prefixImage(profile.user.images[0].media_link)
                });
        }
            else{
                throw "Something went wrong."
            }
        })
        .catch(err => {
            console.log(err);
            self.setState({
                submitEnabled: true,
            });
        });
    }

    // FOR PROFILE IMAGE
    onDrop(accepted, rejected) {
        //display preview
        if (accepted.length > 0) {
            this.setState({profilePicture: accepted[0]});
        }
    }

    // FOR MISSION
    handleChange(event) {
        let val = event.target.value
        if (event.target.name  ===  "personalMessage") {
            //max 110 chars
            val = val.substring(0, 110);
        } else if (event.target.name  ===  "bio") {
            //max 1000 chars
            val = val.substring(0, 1000);
        }
        this.setState({
            [event.target.name]: val
        });
    }

    // FOR SCHOOL INFO
    handleSchoolChange(option) {
        this.setState({school: option});
    }

    handleGraduationChange(option) {
        this.setState({graduationYear: option});
    }
    handleProgramChange(option) {
        this.setState({program: option});
    }
    handleDegreeChange(option) {
        this.setState({degree: option});
    }

    validateInput(){
        const {first, last, password, confirmPassword, school, program, degree, graduationYear} = this.state;

        if (typeof first !== "string" || first.length ===0){
            let firstErrors = ["Please enter a first name"];
            this.setState({firstErrors});
            return false;
        }

        if (!school || !program || !degree || !graduationYear) {
            let schoolErrors = ["Please fill out your school information"];
            this.setState({schoolErrors});
            return false;
        }

        return true;
    }

    nextPage(event){

        event.preventDefault();

        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});

            //submit logic here
            let self = this;
            const url = `${config.BACKEND_HOST}/accounts/signup_main`;

            let params = {
                userprofile_unique_id: this.state.userId,
                first_name: self.state.first,
                last_name: self.state.last,
                university_uuid: self.state.school.value,
                program_uuid: self.state.program.value,
                degree_uuid: self.state.degree.value,
                graduation_year: self.state.graduationYear.value
            }

            if (typeof this.state.profilePicture === "object"){
                params.personal_image = this.state.profilePicture;
            }

            utils.postMultipart(url, params)
            .then(raw => raw.json())
            .then(res => {
                console.log(res);
                self.setState({submitEnabled: true});
                if (res.status){
                    if (this.props.signUpLogin(res.token)) {
                        self.setState({
                                submitSuccess: true,
                                token: res.token,
                            });
                    }
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    isAgreedErrors: ["Something went wrong. Please try again later."]
                });
            });

        }
    }

    nextSectionConfirm() {
        console.log("finished profile info")
    }

    render() {
        const pIndex = 3;
        if (this.state.submitSuccess) {

            return <Redirect to="/activity" push />;
        }
                        console.log(this.state.submitSuccess);

        let profileDropzoneStyle, profilePicture;
        profilePicture = this.state.profilePicture;
        if (profilePicture) {
            if (typeof profilePicture  ===  "object"){
                profilePicture = profilePicture.preview;
            }
            profileDropzoneStyle = {
                backgroundImage: `url('${profilePicture}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center"
            };
        }

        return (
            <div className="github-signup-page d-flex justify-content-center align-items-center">
                <form action={"/"} onSubmit={this.nextPage}>

                    <Header title="Sign Up" subtitle="You’re about to join a group of people who are passionate about building and creating cool stuff. Introduce yourself!"/>

                    <div className="content">
                        <div className="form-row profile">
                            <Dropzone
                                onDrop={this.onDrop}
                                accept="image/jpeg, image/png"
                                className="profile-dropzone d-flex justify-content-center align-items-center"
                                style={profileDropzoneStyle}>
                                {({isDragActive, isDragReject}) => {
                                    if (isDragActive) {
                                        return "Upload";
                                    }
                                    if (isDragReject) {
                                        return <p className="large">X</p>;
                                    }
                                    if (!this.state.profilePicture) {
                                        return <p className="large">+</p>;
                                    }
                                    return;
                                }}
                            </Dropzone>

                            <div className="personal-info">
                                <div className="form-row">
                                    <div className="col-6">
                                        <input name="first" type="text" value={this.state.first} onChange={this.handleChange} placeholder="First Name" className={"input input-add-outline " +
                                        (this.state.firstErrors.length > 0 ? "error" : "")}/>
                                    </div>
                                    <div className="col-6">
                                        <input name="last" type="text" value={this.state.last} onChange={this.handleChange} placeholder="Last Name" className="input input-add-outline "/>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <textarea
                                        name="personalMessage"
                                        value={this.state.personalMessage}
                                        onChange={this.handleChange}
                                        className="form-control personal-message"
                                        rows="2"
                                        placeholder="Tl;dr..."></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="university-info">
                            <div className="form-row">
                                <div className="col-8">
                                    <Select
                                        name="school"
                                        placeholder="University"
                                        value={this.state.school}
                                        onChange={this.handleSchoolChange}
                                        options={this.state.schoolOptions}
                                        onInputChange={this.getUniversity}
                                        styles={selectStyles}/>

                                </div>
                                <div className="col-4">
                                    <Select
                                        name="graduationYear"
                                        placeholder="Graduation Year"
                                        value={this.state.graduationYear}
                                        onChange={this.handleGraduationChange}
                                        options={this.state.graduationYearOptions}
                                        styles={selectStyles}/>

                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-7">
                                    <Select
                                        name="program"
                                        placeholder="Program"
                                        value={this.state.program}
                                        onChange={this.handleProgramChange}
                                        options={this.state.programOptions}
                                        styles={selectStyles}/>

                                </div>
                                <div className="col-5">
                                    <Select
                                        name="degree"
                                        placeholder="Degree"
                                        value={this.state.degree}
                                        onChange={this.handleDegreeChange}
                                        options={this.state.degreeOptions}
                                        styles={selectStyles}/>

                                </div>
                            </div>
                        </div>

                        {this.state.schoolErrors.length > 0 ? <p className="errors">{this.state.schoolErrors}</p> : null}

                        <NextSection nextTitle={"Enter Jouncer"} nextDescription={"You're done signing up! Complete your account"} nextSectionConfirm={this.nextSectionConfirm}/>
                    </div>
                </form>
            </div>
        );
    }
}

export default View4;
