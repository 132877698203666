import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Linkify from 'react-linkify';

import config from '../config';
import utils from '../utils';

const CreatorLink = (creator) => {
    return (
        <Link to={`/creators/${creator.unique_id}`} className="invited-people-link">{`${creator.user__first_name} ${creator.user__last_name}`}</Link>
    )
};

const MAX_INVITEES = 999;

const SkillDropdown = ({name, skills}) => (
    <div className="SkillDropdown column">
        <div className="skill-name">
            {name}
        </div>
        <div className="v-centred wrap">
            {skills.map(({name}) => <div className="skill small">{name}</div>)}
        </div>
    </div>
)

class Bio extends Component {

    render() {

      const {
          id,
          page_user,
          invited,
          invited_by,
          is_current_users_page,
          sidebarCollapsed
      } = this.props;
      if (typeof(page_user) === 'undefined' || Object.keys(page_user).length === 0) {
          return;
      }

      const {
          images = [],
          user__first_name = "",
          user__last_name = "",
          user_skills = [],
          university = {},
          program = {}
      } = page_user;

      let backgroundImage,
          innerText;
      if (images.length > 0) {
          backgroundImage = `url('${utils.image(images[0])}')`;
      } else {
          backgroundImage = utils.randomGradient(id);
          innerText = user__first_name
              .charAt(0)
              .toUpperCase();
      }


              const {mission, description} = page_user;
              if (!mission && !description){
                  return null;
              }

        return (
            <div className="Bio v-centred column">
                <div className="row">
                    {mission
                        ? (<div className="mission">
                            <div className="subheading-label">MISSION</div>
                            <div className="mission-content">
                                {mission}
                            </div>
                        </div>)
                        : ""
                    }
                </div>
                <div className="row">
                        {description
                            ? (
                                <Linkify properties={{target: '_blank'}}>
                                    <div className="mission-description">
                                        {description}
                                    </div>
                                </Linkify>
                            )
                            : ""
                        }
                </div>
            </div>
        )
    }
}

export {Bio};
