import React, {Component} from 'react';
import {Link} from "react-router-dom";

import utils from '../utils';
import Badge from "./Badge.js";

class Card extends Component{
    constructor(props){
        super(props);
        this.gradient = utils.randomGradient(props.id);
        this.initialBackground = props.img;
        this.state = {
            index: -1,
            background: this.initialBackground,
            unread_comments: null,
            newMedia: false,
        };
        this.images = this.generateImages();
        this.removeProject = this.removeProject.bind(this);
        this.renderTeam = this.renderTeam.bind(this);
        this.renderCardImage = this.renderCardImage.bind(this);
    }

    generateImages(){
        if (this.props.homepage) {
            return [this.props.img, ...(this.props.images)].map(this.tryImage.bind(this));
        }

        if (this.props.newMedia && this.props.images.length > 0) {
            return [this.props.img, ...(this.props.images || []).map(image => image.preview ? image.preview : utils.image(image))].map(this.tryImage.bind(this));
        }
        return [this.props.img, ...(this.props.images || []).map(utils.image)].map(this.tryImage.bind(this));
    }

    componentDidMount() {
        this.setState({unread_comments: this.props.unread_comments});
    }

    componentDidUpdate(){
        const bg = this.tryImage(this.props.img);

        if (bg !== this.images[0] || this.props.newMedia !== this.state.newMedia){
            this.setState({ newMedia: this.props.newMedia });
            this.images = this.generateImages();
            this.forceUpdate();
        }
    }

    tryImage(img){
        try{return img ? `url('${img}')` : this.gradient}catch(e){return this.gradient;}
    }

    handleHover({clientX, target, ...ev}){
        let {images} = this.props;
        if (!images || images.length === 0){
            return;
        }
        const {top, left} = target.getBoundingClientRect();
        const width = target.clientWidth;
        const delta = Math.floor(width / images.length);
        // const y = clientY - top;
        const x = Math.max(0,clientX - left);
        const index = Math.min(Math.floor(x / delta), images.length - 1);

        this.setState({
            index,
            background: this.tryImage(utils.image(images[index]))
        });
    }

    handleMouseLeave(){
        this.setState({
            index: -1,
            background: this.initialBackground
        });
    }

    removeProject(event) {
        this.props.removeItem(this.props.id);
        event.stopPropagation();
    }

    removeBubble() {
        this.setState({unread_comments: null});
    }

    onClickFunction() {
        let {unread_comments, onClick} = this.props;
        if (unread_comments) {
            this.removeBubble();
        }
        if (onClick) {
            onClick();
        }
    }

    renderCardImage() {
        let {id = undefined, title, description, img, images  = [], userImg, update} = this.props;
        let backgroundImg, innerText, userImgElement, gradient;

        gradient = utils.randomGradient(id || title);

        if (img){
            backgroundImg = `url('${img}')`;
        }
        else{
            innerText = title;
        }

        return(
            <div className="card-img-container" onMouseMove={this.handleHover.bind(this)}
                        onMouseLeave={this.handleMouseLeave.bind(this)}>

                {this.props.selected ? <div className="selected"></div> : null}

                {
                    this.images.map(((img_, i) => (
                        <div className="card-img" style={{backgroundImage: img_, display: i === this.state.index + 1 ? 'inherit':'none'}}>
                            {innerText && innerText.length > 0 ? <div className="gradient-text">{utils.truncateTo(innerText || "", 20)}</div> : ""}
                            {!title ? <div className="add-portfolio-project">Add Project</div> : null}
                        </div>
                    )).bind(this))
                }
            </div>
        );
    }

    renderTeam() {
        let {members} = this.props;

        if (!members) {
            return null;
        }

        return (
            <div className="team-preview">
                <div className="team">
                    {
                        members.map(member => (
                            <Link to={`/creators/${member.member__userprofile__unique_id}`} className="team-member-preview-link">
                                <div className="team-member-preview" style={{backgroundImage: member.image ? `url(${utils.prefixImage(member.image)})` : utils.randomGradient(member.member__userprofile__unique_id)}}>
                                    {member.image ? null : <p>{member.member__first_name.substring(0, 1).toUpperCase()}</p>}
                                </div>
                            </Link>
                        ))
                    }
                </div>
                <p className="team-title">TEAM</p>
            </div>
        );
    }

    render(){
        let {id = undefined, title, description, images = [], skillText, onClick, user: page_user, newMedia, update, unread_updates, unread_comments, members, teamcard, inProgress} = this.props;

        if (unread_updates > 9) {
            unread_updates = 9;
        }

        if (unread_comments > 9) {
            unread_comments = 9;
        }

        return (
            <div className="card" onClick={(event) => { this.onClickFunction() }}>
                <div className="bubble-card-bubbles">
                    {unread_updates ?
                    <div className="update-bubble">
                        <p className={unread_updates == 1 ? "single-unread-update" : null}>{unread_updates} &nbsp;{unread_updates == 1 ? "new update" : "new updates"}</p>
                    </div> :
                    null }

                    {this.state.unread_comments ?
                    <div className="comment-bubble">
                        <p className={unread_comments == 1 ? "single-unread-update" : null}>
                            <i class="fas fa-comment"></i> {unread_comments} &nbsp;{unread_comments == 1 ? "new comment" : "new comments"}</p>
                    </div> :
                    null }
                </div>

                {this.renderCardImage()}

                <div className="hover-zones v-centred">
                     {images.map((_, i) => <span className={i === this.state.index ? "active" : null}/>)}
                </div>

                <div className="card-body">

                    {this.renderTeam()}

                    <h3 className="card-title">{utils.truncateTo(title || "", 20)}</h3>

                    {this.props.goToProject ?
                        <Link className="project-page-link" to={`/project/${id}`}>
                            Go to Project
                        </Link> : null
                    }

                    <p className={`card-description ${members ? "hide-description-on-hover" : ""}`}>{utils.truncateTo(description || "", 80)}</p>
                </div>

                {this.props.remove && title ?
                    <div className="remove-project-button" onClick={(event) => this.removeProject(event)}>
                        <button type="button" >Remove Project</button>
                    </div>
                : null}
            </div>
        );
    }
}

export default Card;
