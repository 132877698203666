// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import GitHubLogin from 'react-github-login';
import Octicon, {Octoface, MarkGithub} from '@primer/octicons-react';

import utils from '../../utils';
import config from '../../config';


class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            githubStatus: null,
        };
        this.GithubSuccess = this.GithubSuccess.bind(this);
        this.GithubFail = this.GithubFail.bind(this);
    }

    GithubSuccess(response) {
        console.log(response);
        this.setState({
            githubStatus: true
        });

        const url = `${config.BACKEND_HOST}/accounts/submit_github_code`;
        const params = {
            userprofile_unique_id: this.props.id,
            github_code: response.code,
        }
        const self = this;

        utils.post(url, params)
            .then((res) => {
                console.log(res);
                window.location.reload();
                throw "Response status false";
            })
            .catch((err) => {
                if (typeof err === "object"){
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    GithubFail(response) {
        console.log(response);
        this.setState({ githubStatus: false });
    }

    render() {
        let {title, subtitle, privateRepos} = this.props;

        const onSuccess = response => this.GithubSuccess(response);
        const onFailure = response => this.GithubFail(response);

        return (
            <div className="header">
                <div className="header-text">
                    <h2>{title}</h2>
                    <p>{subtitle}</p>

                    {privateRepos ?
                        <div className="github-signup-text">
                            <div className="authorize-github-private">
                                <Octicon className="icon" size='medium' icon={MarkGithub}/>
                                <GitHubLogin
                                    className="button"
                                    clientId="5d294ab362a4f18f3352"
                                    redirectUri=""
                                    scope="repo read:user user:email user:follow"
                                    buttonText="Import a Private Repo"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}/>
                            </div>

                            {this.state.githubStatus === false ? <p className="error">Oh shit, we hit a bug. Try one more time</p> : null}

                        </div>
                    : null }
                </div>
            </div>
        );
    }
}

export default Header;
