import React, {Component} from 'react';
import MediaItemContainer from '../components/MediaItemContainer';
import {UserContext} from '../App';

import Card from '../components/Card';

import Octicon, {GitCommit, GitMerge, Link, Sync, Octoface, Check} from '@primer/octicons-react';

// utils
import utils from '../utils';
import config from '../config';


let randomProgressPrompt = [
    "Got the influx db up and running with Go to track my puppy’s tail for his daily fitness metrics. He wags his tail 15000 times a day!",
    "I changed the gear angle of the right rotor of the tea scooper so it doesn’t spill any more. So close to finishing the tea bot now.",
    "Completed new UI designs for the Squiggly coin logo. Time to present them to the client.",
    "Deployed my new neopet wresting game on EC2 with nginx. Expecting a flood of gamers in a few minutes!",
    "Drew out the blueprints for the prosthetic arm. I think the knuckles will be particularly tricky with the actuator positioning."
]

let randomSneakPeekPrompt = [
    "In my quest to build a decentralized internet I’m gonna build a compression algo, encrypted sharding, a new space saving app",
    "Our Django endpoints have been running too slow, this week I’m gonna remove nested model encoding",
    "Currently adding functionality to the chatbot so it can use common slang like brb and btw, and you can add custom phrases",
]

let randomFeedbackPrompt = [
    "Thoughts on Flask vs Django? Heard good things about Django out of the box, but I heard it has a much steeper learning curve",
    "I'm using JWT tokens for auth, is there something better for Go?",
    "Finished my first build in Ruby, should I deploy on Heroku or almighty AWS?",
    "I'd love to get feedback/thoughts on the look of this signup page",
]


class AddUpdate extends Component{

    constructor(props){
        super(props);
        this.state = {
            text: "",
            tags: [],
            newTag: "",
            deletingTag: "",
            commits: [],
            selectedCommits: [],
            refreshing: false,
            complete: false,
            selectedTeamId: "",
            selectedTeamImage: [],
            teamName: "",
            githubRepo: "",
            projectName: "",
            newProject: false,
            prompt: ""
        }
        this.shareUpdate = React.createRef();
        this.getTags = this.getTags.bind(this);
        this.addTag = this.addTag.bind(this);
        this.selectTag = this.selectTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.fetchCommits = this.fetchCommits.bind(this);
        this.refreshCommits = this.refreshCommits.bind(this);
        this.postUpdate = this.postUpdate.bind(this);
        this.checkForTab = this.checkForTab.bind(this);
        this.miniProject = this.miniProject.bind(this);
    }

    componentDidMount(){
        this.getTags();
        this.fetchCommits();
        this.shareUpdate.current.addEventListener('input', ({target}) => {
            target.style.height = 'auto';
            target.style.height = `${target.scrollHeight}px`;
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.teamId !== this.props.teamId || prevProps.creator_teams !== this.props.creator_teams) {
            this.setState({
                teamName: this.props.creator_teams[0].name,
                selectedTeamId: this.props.creator_teams[0].unique_id,
                githubRepo: this.props.creator_teams[0].github_repo_id,
            });
            this.getTags(this.props.creator_teams[0].unique_id);
            this.fetchCommits(this.props.creator_teams[0].unique_id);
        }
    }

    clear(){
        this.props.handleUpload([]);
        this.setState({
            text: ""
        });
        this.shareUpdate.current.style.height = "196px";
    }

    handleTextChange(){
        let {value} = this.shareUpdate.current;
        this.setState({
            text: value
        });
    }

    addShade() {
        var element = document.getElementById("shade");
        element.classList.add("shade");

        var element = document.getElementById("AddUpdate");
        element.classList.add("bring-to-front");
    }

    removeShade() {
        var element = document.getElementById("shade");
        element.classList.remove("shade");

        var element = document.getElementById("AddUpdate");
        element.classList.remove("bring-to-front");
    }

    getTags(teamMissionId) {
        const {teamId} = this.props;
        const url = `${config.BACKEND_HOST}/teams/tag_bank`;
        const self = this;

        if (teamMissionId || teamId) {
            let params;

            if (this.props.multiTeam) {
                params = {
                    team_unique_id: teamMissionId,
                }
            } else {
                params = {
                    team_unique_id: teamId,
                }
            }

            let tagsList = ["Idea", "Progress", "Sneak Peek", "Feedback Request"];

            utils.get(url, params)
                .then((res) => {
                    if (res.status){
                        console.log(res);
                        self.setState(prevState => {
                            return {
                                tags: res.data.tags
                            }
                        });
                        let randomTag = tagsList[Math.floor(Math.random() * tagsList.length)];
                        this.selectTag(randomTag);

                        let randomPrompt;

                        if (randomTag === "Idea") {
                            randomPrompt = "Share a new idea you've had about this project!";
                        } else if (randomTag === "Progress") {
                            randomPrompt = randomProgressPrompt[Math.floor(Math.random() * randomProgressPrompt.length)];
                        } else if (randomTag === "Sneak Peek") {
                            randomPrompt = randomSneakPeekPrompt[Math.floor(Math.random() * randomSneakPeekPrompt.length)];
                        } else if (randomTag === "Feedback Request") {
                            randomPrompt = randomFeedbackPrompt[Math.floor(Math.random() * randomFeedbackPrompt.length)];
                        }
                        this.setState({prompt: randomPrompt});
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (this.state.newTag){
                this.addTag(this.state.newTag);
                this.setState({newTag: ""});
            }
        }
    }

    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    addTag(name){
        const self = this;
        const url = `${config.BACKEND_HOST}/teams/add_tag`;
        const params = {
            tag_name: name,
            team_unique_id: this.props.multiTeam ? this.state.selectedTeamId : this.props.teamId,
        };

        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState(prevState => {
                        prevState.tags.push(
                            res.data.tag
                        );
                        return prevState;
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    selectTag(name) {
        this.setState(prevState => {
            let newSelected = [
                ...prevState.tags
            ];

            newSelected.map(selected => {
                if (selected.name === name) {
                    if (selected.active && selected.active === true) {
                        selected.active = false;
                    } else {
                        selected["active"] = true;

                        let randomPrompt;

                        if (name === "Idea") {
                            randomPrompt = "Share a new idea you've had about this project!";
                        } else if (name === "Progress") {
                            randomPrompt = randomProgressPrompt[Math.floor(Math.random() * randomProgressPrompt.length)];
                        } else if (name === "Sneak Peek") {
                            randomPrompt = randomSneakPeekPrompt[Math.floor(Math.random() * randomSneakPeekPrompt.length)];
                        } else if (name === "Feedback Request") {
                            randomPrompt = randomFeedbackPrompt[Math.floor(Math.random() * randomFeedbackPrompt.length)];
                        }
                        this.setState({prompt: randomPrompt});
                    }
                }
            })

            return {
                tags: [
                    ...newSelected
                ]
            };
        })
    }

    deleteTag(id) {
        const self = this;
        const url = `${config.BACKEND_HOST}/teams/delete_tag`;
        const params = {
            tag_unique_id: id,
        };

        utils.post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState(prevState => {
                        prevState.tags = prevState.tags.filter(tag => tag.unique_id != id);
                        return prevState;
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });

    }


    fetchCommits(teamMissionId) {
        const {teamId} = this.props;
        let url = `${config.BACKEND_HOST}/all/get_team_commits`;

        let params;

        if (this.props.multiTeam) {
            params = {
                page_team_uuid: teamMissionId,
                // page_number: 1,
            }
        } else {
            params = {
                page_team_uuid: teamId,
                // page_number: 1,
            }
        }

        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    self.setState({
                        commits: res.data.commits,
                        refreshing: false,
                    });

                    setTimeout(() => {
                        self.setState({
                            complete: false
                        })
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    githubCommits() {
        let {commits} = this.state;

        return (
            <div className="commits">
                {commits.map(commit => {

                    return <div className={`commit ${commit.active === true ? "active" : ""}`} onClick={() => this.selectCommit(commit.unique_id)}>
                        {commit.message.split(" ", 2)[0] === "Merge" ? <Octicon className="git-merge" icon={GitMerge}/> : <Octicon className="git-commit" icon={GitCommit}/>}
                        <div className="info">
                            <p className="message">{commit.message}</p>
                            <p className="branch">
                                Branch <div className="branch-name">{commit.branch_name}</div>
                            </p>
                        </div>
                        {commit.external_link ?
                            <a href={commit.external_link} target="_blank" className="link">
                                <Octicon className="git-link" icon={Link}/>
                            </a>
                        : null }
                    </div>
                })}
            </div>
        );
    }

    selectCommit(id) {
        this.setState(prevState => {
            let newSelected = [
                ...prevState.commits
            ];

            newSelected.map(selected => {
                if (selected.unique_id === id) {
                    if (selected.active && selected.active === true) {
                        selected.active = false;
                    } else {
                        selected["active"] = true;
                    }
                }
            })

            return {
                commits: [
                    ...newSelected
                ]
            };
        })
    }

    refreshCommits() {
        const {teamId} = this.props;
        let url = `${config.BACKEND_HOST}/all/refresh_commits`;

        let params = {
            page_team_uuid: teamId,
        }

        this.setState({
            refreshing: true,
            complete: true
        });

        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    this.fetchCommits();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    postUpdate(selectedList, selectedCommitList, selectedTeamId, selectedTeamImage, teamName) {
        this.props.handleSubmit(selectedList, selectedCommitList, selectedTeamId, selectedTeamImage, teamName);
        this.removeShade();

        selectedList.forEach(tag =>
            {this.selectTag(tag.name)}
        )

        selectedCommitList.forEach(commit =>
            {this.selectCommit(commit.unique_id)}
        )
    }

    checkForTab(event) {
        if (event.keyCode === 9) {
            event.preventDefault();
            this.setState(
                {
                    text: this.state.text + '    '
                }
            );
        }
    }

    miniProject() {
        this.setState({
            selectedTeamId: "mini",
            selectedTeamImage: "",
            teamName: "",
            githubRepo: "",
            saveProject: false,
        });
    }

    changeTeams(unique_id, team_img, name, github_repo_id) {
        this.setState({
            selectedTeamId: unique_id,
            selectedTeamImage: team_img,
            teamName: name,
            githubRepo: github_repo_id,
        });

        this.getTags(unique_id);
        this.fetchCommits(unique_id);
    }

    renderCards(){
        const {creator_teams} = this.props;
        if (creator_teams === null) {
            return <utils.Spinner/>;
        }

        let cards = creator_teams.map(({name, mission, images, profile_img, members, team_skills, unique_id, github_repo_id, unread_updates, unread_comments}) => {
            let previewImg = "";
            let img = "";
            if (profile_img){
                previewImg = profile_img;
                img = utils.prefixImage(profile_img);
            }
            else if (images.length > 0){
                previewImg = images[0];
                img = utils.image(images[0]);
            }

            let backgroundImg, innerText, userImgElement, gradient;

            gradient = utils.randomGradient(unique_id || name);

            if (img){
                backgroundImg = `url('${img}')`;
            }
            else {
                backgroundImg = gradient;
                innerText = name;
            }

            let selected = unique_id === this.state.selectedTeamId;

            return (
                <div className="card-container" onClick={() => this.changeTeams(unique_id, previewImg, name, github_repo_id)}>

                    <UserContext.Consumer>
                        {user =>
                            <div className="card">
                                <div className="card-img-container">

                                    {selected ? <div className="selected"></div> : null}

                                    <div className="card-img" style={{backgroundImage: backgroundImg}}>
                                        {innerText && innerText.length > 0 ? <div className="gradient-text">{utils.truncateTo(innerText || "", 20)}</div> : ""}
                                    </div>
                                </div>
                            </div>
                        }
                    </UserContext.Consumer>
                </div>
            );
            }
        );
        return cards.length > 0 ? cards : "";
    }


    render(){

        const canPost = this.props.files.length || this.state.text.length;

        let selectedList = this.state.tags.filter(tag =>
            tag.active && tag.active === true
        )

        let selectedTags = selectedList.map(tag => {
            return <div className="tag active" style={{backgroundColor: `${utils.getTagColor(tag.unique_id)}` }}>
                {tag.name}
                <div className="minus" onClick={() => this.selectTag(tag.name, tag.unique_id)}>&#8211;</div>
            </div>
        })

        let selectedCommitList = this.state.commits.filter(commit =>
            commit.active && commit.active === true
        )

        let branches = [];
        selectedCommitList.forEach(
            commit => {
                if (branches.includes(commit.branch_name)) {
                } else {
                    branches.push(commit.branch_name);
                }
            }
        );


        return (
            <div id="AddUpdate" className="AddUpdate activity-card">
                <div className="activity-content">

                    {this.props.multiTeam ?
                        <div className="your-teams">
                            <div className="cards">
                                {this.renderCards()}
                                <div id="project-name">
                                    <textarea
                                        id="wip-project"
                                        name="projectName"
                                        ref={this.projectName}
                                        placeholder="Work in Progress Title"
                                        rows="1"
                                        value={this.state.projectName}
                                        onClick={this.miniProject}
                                        onChange={this.handleChange}/>
                                </div>
                            </div>
                        </div>
                    : null}

                    <div className="tags">
                        <p className="tags-title">Add Tags</p>
                        {this.state.tags.map(tag =>
                            <div className={`unselected-tag ${tag.active === true ? "active" : ""}`}>
                                <div className={`tag ${this.state.deletingTag === tag.name ? "deleting-tag" : ""}`} style={{backgroundColor: `${utils.getTagColor(tag.unique_id)}`}} onClick={() => {this.selectTag(tag.name)}}>
                                    {tag.name}
                                </div>

                                {this.state.deletingTag === tag.name ?
                                    <div className="delete-notice">
                                        <i class="fas fa-exclamation-circle"></i>
                                        Heads up!<br/>Deleting will remove this tag from all previously tagged updates
                                        <div className="controls">
                                            <p className="cancel" onClick={() => {this.setState({ deletingTag: "" })}}>Cancel</p>
                                            <p className="delete" onClick={() => this.deleteTag(tag.unique_id)}>Delete</p>
                                        </div>
                                    </div>
                                : null }

                                {tag.is_user_added && this.state.deletingTag != tag.name ? <div className="trash" onClick={() => {this.setState({ deletingTag: tag.name })}}><i class="fas fa-trash"></i></div> : null}
                            </div>
                        )}
                        <input name="newTag" type="text" value={this.state.newTag} placeholder="Create a new tag" className="new-tag-input" onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
                    </div>

                    {selectedTags.length > 0 ?
                        <div className="selected-tags">
                            {selectedTags}
                        </div>
                    : null}

                    {selectedCommitList.length > 0 ?
                        <div className="selected-commits">
                            <div className="commit-marker">
                                {selectedCommitList.length}
                            </div>
                            <div className="commits-text">
                                {selectedCommitList.length === 1 ? "commit" : "commits"} in&nbsp;
                                {branches.length === 1 ? "branch" : "branches"}
                                {branches.map(branch => <p>{branch}</p>)}
                            </div>
                        </div>
                    : null }

                    <textarea
                        className="form-control"
                        ref={this.shareUpdate}
                        name=""
                        id=""
                        placeholder={selectedTags.length > 0 ?
                            this.state.prompt
                            :
                            `Share a new update ${this.state.teamName ? `to ${this.state.teamName}` : (this.state.projectName ? `to ${this.state.projectName}` : "")}`
                        }
                        value={this.state.text}
                        onChange={this.handleTextChange.bind(this)}
                        rows="5"
                        onKeyDown={this.checkForTab}
                        onClick={this.addShade}></textarea>
                    <div className="add-media-row">
                    <MediaItemContainer
                        teamId={this.state.selectedTeamId}
                        items={this.props.files}
                        handleUploadChange={this.props.handleUpload.bind(this)}
                        hideCheck={true}
                        isSmall={true}/>
                    </div>

                    {this.state.githubRepo || this.props.githubRepo ?

                        <div className="commits-section">
                            <div className="title-section">
                                <p className="title">Commits</p>
                                {this.state.refreshing === false ?
                                    (this.state.complete === true ? <p className="refresh"><Octicon className="check" icon={Check}/> Done Refreshing</p> : <p className="refresh" onClick={() => this.refreshCommits()}><Octicon className="sync" icon={Sync}/> Get Latest Commits</p>)
                                    : <p className="refreshing"><Octicon className="octoface" icon={Octoface}/> Pulling from Github...</p> }
                            </div>

                            {this.state.commits.length > 0 ?
                                this.githubCommits()
                                : <p className="refreshing">
                                    <Octicon className="octoface" icon={Octoface}/> Pulling Commits from Github...
                                </p>}
                        </div>
                    : null }

                    <div className="update-bottom">
                        <div className="update-controls">
                            <div className="hit-enter">
                                Insert code using a set of <div className="enter-badge">`backticks`</div>
                            </div>
                            <span className="v-centred tag-text" onClick={() => this.clear()}>Clear</span>
                            <button className={"btn " + (canPost ? "btn-secondary" : "btn-tertiary")} onClick={() => {this.postUpdate(selectedList, selectedCommitList, this.state.selectedTeamId, this.state.selectedTeamImage, this.state.teamName ? this.state.teamName : this.state.projectName)}} disabled={canPost ? "" : "disabled"}>Post Update</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default AddUpdate;
