import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Select from 'react-select';

import utils from '../../utils';
import config from '../../config';
import SkillsMenu from '../SkillsMenu.jsx';
import MediaItemContainer from "../MediaItemContainer.jsx";

const moment = require('moment');
const uniqueString = require('unique-string');
const _ = require("lodash");
const pIndex = 1;

export default class View1 extends Component {
    constructor(props){
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            id: props.id,
            names: [],
            nameOptions: [],
            skillBuckets: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleBucketChange = this.handleBucketChange.bind(this);
        this.handleSkillChange = this.handleSkillChange.bind(this);
        this.addSkill = this.addSkill.bind(this);

        this.getData();
    }
    async getData(){
        const self = this;

        const url = `${config.BACKEND_HOST}/teams/create2`;
        await utils.get(url, {team_uuid: this.state.id})
            .then((res) => {
                console.log(res);
                if (res.status){
                    let {skills, all_skill_buckets} = res.data;
                    skills = skills.map(skill => skill.id);
                    self.setState({
                        skillBuckets: all_skill_buckets
                            .filter(bucket => !bucket.hidden)
                            .map(bucket => {
                                let hasActiveSkill = false;
                                bucket.id = bucket.unique_id;
                                bucket.skills = bucket
                                    .skills
                                    .filter(skill => !skill.hidden && (!skill.is_user_added || skills.indexOf(skill.id) >= 0)) // not hidden AND (if user created, display if user has that skill)
                                    .map(skill => {
                                        skill.key = skill.id;
                                        skill.label = skill.name;
                                        skill.value = skill
                                            .id
                                            .toString();
                                        skill.active = skills.indexOf(skill.id) >= 0;
                                        hasActiveSkill = hasActiveSkill || skill.active;
                                        return skill;
                                    });
                                bucket.active = hasActiveSkill;
                                return bucket;
                            })
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
                //error logic
            });

        const namesUrl = `${config.BACKEND_HOST}/teams/search_members`;
        await utils.get(namesUrl)
            .then((res) => {
                console.log(res);
                if (res.status){
                    //set state
                    self.setState({
                        nameOptions: res.data.results.map(m => ({label: m.label, value: m.id}))
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
                //error logic
            });

    }
    handleNameChange(values){
        this.setState({names: values.map(val => val)});
    }
    handleBucketChange(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (id === bucket.id) {
                        bucket.active = !bucket.active;
                    }
                    return bucket;
                });
            return prevState;
        });
    }
    handleSkillChange(id) {
        console.log(id);
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    bucket.skills = bucket.skills.map(skill => {
                        if (id == skill.id) {
                            skill.active = !skill.active;
                        }
                        return skill;
                    });
                    return bucket;
                });
            return prevState;
        });
    }
    addSkill(name, bucketId){
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (bucket.id  ===  bucketId){
                        bucket.skills.push({
                            id: uniqueString(),
                            name: name,
                            active: true,
                            isNew: true,
                        });
                    }
                    return bucket;
                });
            return prevState;
        });

        const self = this;
        const url = `${config.BACKEND_HOST}/all/add_skill`;
        const params = {
            add_skill: true,
            new_skill: name,
            bucket_uuid: bucketId,
        };
        utils
            .get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState(prevState => {
                        prevState.skillBuckets = prevState
                            .skillBuckets
                            .map(bucket => {
                                if (bucket.id  ===  bucketId){
                                    bucket.skills = bucket.skills.map(skill => {
                                        if (skill.name  ===  name){
                                            skill.id = res.data.skill_id;
                                        }
                                        return skill;
                                    });
                                }
                                return bucket;
                            });
                        return prevState;
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }
    handleSubmit(event){
        event.preventDefault();
        if (this.state.submitEnabled){
            this.setState({submitEnabled: false});

            let skillIds = [];
            this.state
                .skillBuckets
                .filter(bucket => bucket.active)
                .forEach(bucket => {
                    skillIds = _.concat(skillIds, bucket.skills.filter(skill => skill.active && _.isInteger(skill.id)).map(skill => skill.id));
                });

            //submit logic here
            const url = `${config.BACKEND_HOST}/teams/create2`;
            const self = this;
            const params = {
                team_uuid: this.state.id,
                selected_users: this.state.names.map(name => name.value),
                selected_skills: skillIds
            };
            console.log(params);
            utils.post(url, params)
                .then((res) => {
                    console.log(res);
                    if (res.status){
                        //set state
                        self.setState({submitSuccess: true});
                    }
                })
                .catch((err) => {
                    if (typeof err ==="object"){
                        console.log(err.response.body);
                    }
                    else{
                        console.log(err);
                    }
                    self.setState({submitEnabled: true});
                    //error logic
                });
        }
    }
    render() {
        if (this.state.submitSuccess){
            return (<Redirect to={"/create-team/" + (pIndex + 1).toString() + "/" + this.state.id} push />);
        }
        // let namesMenu;
        // if (this.state.nameOptions.length > 0){
        //     namesMenu = <Select
        //             isMulti
        //             name="names"
        //             placeholder="Enter Names"
        //             onChange={this.handleNameChange}
        //             options={this.state.nameOptions}
        //             value={this.state.names}
        //             styles={selectStyles}
        //         />;
        // }
        return (
            <div className="col-sm-10 col-md-8 mx-auto">
                <form action="/create-team/2" onSubmit={this.handleSubmit}>

                    {/* <div className="form-row no-margin">
                        <h2 className="create-team-headline float-left">Build Your Team</h2>
                    </div>
                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">Do you already have some great creators in mind?</h3>
                    </div>
                    <div className="form-row">
                        {namesMenu}
                    </div> */}

                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">What are some of the skills that you're using for this project?</h3>
                    </div>

                    <SkillsMenu
                        skillBuckets={this.state.skillBuckets}
                        handleBucketChange={this.handleBucketChange}
                        handleSkillChange={this.handleSkillChange}
                        addSkill={this.addSkill}
                        />

                    <div className="form-row">
                        <button name="submit" type="submit" className="btn btn-primary">Next</button>
                    </div>

                </form>
            </div>
        );
    }
}
