// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Switch, Route, Redirect} from "react-router-dom";
import Card from '../components/Card';
import Idea from '../components/Idea';
import LoginMenu from '../components/LoginMenu';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import InputError from '../components/InputError.jsx';

import '../css/Home.css';

import utils from '../utils';
import config from '../config';

import logolarge from "../media/logo_name_large.png";
import fix from "../media/fix.png";
import future from "../media/future.png";
import lift from "../media/lift.png";
import lift2 from "../media/lift2.png";
import paint from "../media/paint.png";
import shout from "../media/shout.png";
import wheel from "../media/wheelbarrow.png";

import fb from "../media/facebook_logo.png";

import backend from "../media/backend.jpg";
import frontend from "../media/frontend.jpg";
import scripting from "../media/scripting.jpg";
import dataScience from "../media/data_science.jpg";
import mobile from "../media/mobile.jpg";


export class Ideas extends Component {
    render() {

        let commentsList=Object.values({
            0: {
                description: "hello",
                member: {
                    first_name: "Jenny",
                    last_name: "Lim",
                    image: mobile,
                }
            },
        });

        return(
            <Idea
                name="Jelly Bean"
                profileImage={backend}
                youridea={false}
                idea="the best way to party"
                teamName="Jellybean Party"
                upvoteCount={43}
                userUpvote={false}
                comments={commentsList}/>
        );
    }
}

export class InProgress extends Component {
    render() {
        return(
            <div>Projects in Progress</div>
        );
    }
}

export class CompletedProjects extends Component {
    render() {

        let imageList=Object.values({
            0: backend,
            1: frontend,
            2: scripting,
            3: dataScience,
            4: mobile
        });

        return(
            <div className="cards">

                <Card
                    title="Is This a Feature?"
                    description="Bringing you Premium-Grade Errors and Missing Endslashes"
                    images={imageList}
                    img={imageList[0]}
                    homepage={true}/>
                <Card
                    title="Frontend"
                    description="Making the most beautiful pixel art with a hardcore programming language"
                    images={imageList}
                    img={imageList[1]}
                    homepage={true}/>
                <Card
                    title="Terminal"
                    description="Illness. Yeah, that's what this is."
                    images={imageList}
                    img={imageList[2]}
                    homepage={true}/>
                <Card
                    title="Blobby Bois"
                    description="We used lots of numbers to make these cool blobby bois"
                    images={imageList}
                    img={imageList[3]}
                    homepage={true}/>

            </div>
        );
    }
}

const STEPS = [
    {
        title: 'Ideas',
        link: 'ideas',
        component: Ideas
    },
    {
        title: 'Projects In Progress',
        link: 'inprogress',
        component: InProgress
    }, {
        title: 'Completed Projects',
        link: 'completedprojects',
        component: CompletedProjects
    }
]

class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: "",
            emailErrors: [],
            emailText: "default",
            submitEnabled: true,
            uuid: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendActivationEmail = this.sendActivationEmail.bind(this);
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    renderSteps(tab) {
        const Component = tab.component;

        return (
            <Route
                path={`/welcomemat/${tab.link}`}
                render={() => (<Component {...this.props} {...this.state}/>)}/>
        );
    }

    validateInput(){
        let hasError = false;
        if (!this.state.email){
            this.setState({emailErrors: "Please enter a valid email"});
            hasError = true;
        }
        return !hasError;
    }

    handleSubmit(event){

        event.preventDefault();
        this.setState({
            emailErrors: [],
        });

        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            event.preventDefault();

            //submit logic here
            let self = this;
            const url = `${config.BACKEND_HOST}/accounts/email_signup`;
            let email = this.state.email;
            let params = {
                email_address: email.toLowerCase()
            };
            utils.post(url, params)
            .then(res => {
                console.log(res);
                if (res.status){
                    self.setState({
                        submitSuccess: true,
                        uuid: res.user_profile__activation_id
                    });

                    if (!res.is_registered && !res.is_validated) {
                        self.setState({emailText: "newuser"});
                    }

                    else if (res.is_registered && res.is_validated) {
                        self.setState({emailText: "activated"});
                    }

                    else if (res.is_registered && !res.is_validated) {
                        self.setState({emailText: "registeredonly"});
                    }
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    emailErrors: ["Something went wrong. Please try again later."]
                });
            });

        }
    }

    sendActivationEmail() {
        //submit logic here
        let self = this;
        const url = `${config.BACKEND_HOST}/accounts/send_activation_email`;
        let email = this.state.email;
        let params = {
            email_address: email.toLowerCase()
        };
        utils.post(url, params)
        .then(res => {
            if (res.status){
                console.log(res);
                self.setState({
                    submitSuccess: true,
                });
            }
            else{
                throw "Something went wrong."
            }
        })
        .catch(err => {
            console.log(err);
            self.setState({
                submitEnabled: true,
                emailErrors: ["Something went wrong. Please try again later."]
            });
        });
    }

    render() {

        if (this.state.submitSuccess === true) {
            return <Redirect to={`/shoesoff?id=${this.state.uuid}`} push />;
        }

        const responseFacebook = (response) => {
            console.log(response);
            let self = this;
            const url = `${config.BACKEND_HOST}/facebook_login`;
            const reaccessUrl = `${config.BACKEND_HOST}/accounts/update_facebook_access_token`;
            let params = {
                access_token: response.accessToken
            }

            utils.post(url, params)
            .then(res => {
                console.log(res);
                if (res.status){
                    console.log(res);
                    self.setState({submitSuccess: true});
                    if (res.token) {
                        this.props.facebookLogin(res.token, false);
                    } else if (res.activation_code) {

                        console.log(res.activation_code);
                        window.location = `/shoesoff/${res.activation_code}`;
                    }
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    emailErrors: ["Something went wrong. Please try again later."]
                });
            });
        }

        let emailText = "";

        if (this.state.emailText === "default") {
            emailText = "";
        } else if (this.state.emailText === "newuser") {
            emailText = "Check your email for your activation link!";
        } else if (this.state.emailText === "activated") {
            emailText = "You already have an account! Sign in instead.";
        }

        return (
            <div>
                <section className="mainnav-home">
                    <img className="home-logo" src={logolarge} />
                </section>






                <div className="homepage homepage-main tandem-main d-flex flex-column justify-content-center">

                    <div className="home-intro-email row justify-content-center align-items-center">
                        <p className="home-heading">What are you making?</p>
                        <p className="home-subheading">We have brought together people with exceptional skills and drive to create so that we can build more powerful things together than we could build alone.</p>
                    </div>

                    <div className="invite-code-section">
                        <img src={paint} className="img-paint"/>
                        <img src={fix} className="img-fix"/>

                        <div className="invite-code-box d-flex align-items-center">
                            <div className="signup-content view0">

                                <form id="signup-form-0" action="javascript:void(0);" onSubmit={this.handleSubmit} noValidate autoComplete="off">
                                    <div className="invite-code row group">
                                        <input name="email" type="text" value={this.state.email} onChange={this.handleChange} placeholder="Email" className={"email input input-add-outline " + (this.state.emailErrors.length > 0 ? "error" : "") + (this.state.email != "" ? "email-active" : "")} />

                                        {/* If email exists, setState to "You've done this before. 🤔 Check your email to activate your account, or RESEND THE ACTIVATION LINK" */}
                                        {this.state.emailText === "registeredonly" ?
                                            <p className="resend-activation">You've signed up, but haven't activated your account! <a onClick={this.sendActivationEmail}>Resend your activation code.</a></p> :
                                        <p className="email-text">{emailText}</p>}


                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary">{this.state.submitEnabled ? "Sign Up" : <i class="far fa-check-circle"></i>}</button>

                                        <InputError errors={this.state.inviteCodeErrors} />
                                    </div>
                                </form>

                                <Link to="/welcomemat">Sign up with Github</Link>

                            </div>
                        </div>
                    </div>

                    <div className="login-section">
                        <img src={lift} className="img-lift"/>
                        <img src={lift2} className="img-lift2"/>

                        <div className="login-link ">
                                <p>Already have an account?</p>
                                <Link to="/login" className="login-p">Login</Link>
                        </div>
                    </div>

                    <div className="about-section">
                        <img src={shout} className="img-shout"/>
                        <img src={wheel} className="img-wheel"/>
                    </div>

                    <div className="footer">Burning Questions? Contact us at team@jouncer.io</div>

                </div>
            </div>
        );
    }
}

export default Home;
