import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import Badge from '../components/Badge.js';
import InputError from "../components/InputError.jsx";
import utils from '../utils';
import config from '../config';
import '../css/JoinRequest.css';

class JoinRequestSuccess extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {id} = this.props.match.params;

        return (
            <div className="main join-request-main d-flex justify-content-center align-items-center">
                <div className="content join-request-content">

                    <h1 className="join-request-view-header">Your request has been sent</h1>
                    <p className="join-request-view-subheader mb-40">Hopefully it’s a good fit!</p>
                    <Link className="btn btn-secondary" to={`/project/${id}`}>Sweet!</Link>

                </div>
            </div>
        );
    }
}

export default JoinRequestSuccess;
