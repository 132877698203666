// Required Dependencies
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

// utils
import utils from '../utils';
import config from '../config';


class NewIdea extends Component {

    constructor(props){
        super(props);
        this.newIdea = React.createRef();
        this.state = {
            text: ""
        }
    }

    componentDidMount(){
        this.newIdea.current.addEventListener('input', ({target}) => {
            target.style.height = 'auto';
            target.style.height = `${target.scrollHeight}px`;
        });
    }

    clear(){
        this.setState({
            text: ""
        });
        this.newIdea.current.style.height = "133px";
    }

    handleTextChange(){
        let {value} = this.newIdea.current;
        this.setState({
            text: value
        });
    }

    addShade() {
        var element = document.getElementById("shade");
        element.classList.add("shade");

        var element = document.getElementById("new-idea");
        element.classList.add("bring-to-front");
    }

    removeShade() {
        var element = document.getElementById("shade");
        element.classList.remove("shade");

        var element = document.getElementById("new-idea");
        element.classList.remove("bring-to-front");
    }

    render(){

        let {user} = this.props;

        let img = "";
        let profileLink = "";
        let imageExists = false;
        let firstLetter = "";

        if (user && user.user.images[0]){
            img = utils.prefixImage(user.user.images[0].media_link);
            profileLink = user.unique_id;
            imageExists = true;
        } else if (user) {
            img = utils.randomGradient(user.unique_id);
            profileLink = user.unique_id;
            imageExists = false;
            firstLetter = user.user.first_name.substring(0, 1).toUpperCase();
        }

        return (
            <div id="new-idea" className="idea-container">

                {/*<Link to={`/creators/${profileLink}`} className="person-info">
                    <div className="idea-image" style={imageExists ? {backgroundImage:`url(${img})`} : {background: img}}>{firstLetter}</div>
                </Link>*/}

                <div className="idea">
                    <textarea
                        className="form-control"
                        name=""
                        id=""
                        ref={this.newIdea}
                        value={this.state.text}
                        onChange={this.handleTextChange.bind(this)}
                        placeholder="Name, university, what they're working on"
                        rows="3"
                        onClick={this.addShade}></textarea>
                    <div className="glow"></div>

                    <div className="idea-submit">
                        <span className="v-centred tag-text" onClick={() => this.clear()}>Clear</span>
                        <button className={"btn " + (true ? "btn-primary" : "btn-secondary")} onClick={() => {this.props.handleIdeaSubmit(); this.removeShade();}}>Share the Creator</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewIdea;
