// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import utils from '../utils';
import config from '../config';

import '../css/GithubSignup.css';
import ProgressNav from '../components/ProgressNav';
import SignupNav from '../components/GithubSignupViews/SignupNav';

import View1 from "../components/GithubSignupViews/View1.jsx";
import View2 from "../components/GithubSignupViews/View2.jsx";
import View3 from "../components/GithubSignupViews/View3.jsx";
import View4 from "../components/GithubSignupViews/View4.jsx";
import View5 from "../components/GithubSignupViews/View5.jsx";


class GithubSignup extends Component {

    render() {
        const index = this.props.match.params.index;
        if (parseInt(index) === 4) {
            console.log("last page");
            return (
                <div className="signup-page">
                    <View5 />
                </div>
            );
        }
        return (
            <div className="signup-page">
                <SignupNav page={index} />
                <GithubSignupContent index={index} signUpLogin={this.props.signUpLogin}/>
            </div>
        );
    }
}


class GithubSignupContent extends Component {
    render() {
        let index = parseInt(this.props.index);
        switch (index) {
            case 0:
                return <View1 {...this.state}/>;
            case 1:
                return <View2 {...this.state}/>;
            case 2:
                return <View3 {...this.state}/>;
            case 3:
                return <View4 {...this.state} signUpLogin={this.props.signUpLogin}/>;
        }
        console.log("Invalid index");
        return (
            <Redirect to="/not-found" />
        );
    }
}

export default GithubSignup;
