// Required Dependencies
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

// utils
import utils from '../utils';
import config from '../config';


class Question extends Component {

    constructor(props){
        super(props);
        this.question = React.createRef();
        this.state = {
            text: ""
        }
        this.checkForTab = this.checkForTab.bind(this);
    }

    componentDidMount(){
        this.question.current.addEventListener('input', ({target}) => {
            target.style.height = 'auto';
            target.style.height = `${target.scrollHeight}px`;
        });
    }

    clear(){
        this.setState({
            text: ""
        });
        this.question.current.style.height = "133px";
    }

    handleTextChange(){
        let {value} = this.question.current;
        this.setState({
            text: value
        });
    }

    addShade() {
        var element = document.getElementById("shade");
        element.classList.add("shade");

        var element = document.getElementById("question");
        element.classList.add("bring-to-front");
    }

    removeShade() {
        var element = document.getElementById("shade");
        element.classList.remove("shade");

        var element = document.getElementById("question");
        element.classList.remove("bring-to-front");
    }

    checkForTab(event) {
        if (event.keyCode === 9) {
            event.preventDefault();
            this.setState(
                {
                    text: this.state.text + '    '
                }
            );
        }
    }

    render(){

        let {user} = this.props;

        let img = "";
        let profileLink = "";
        let imageExists = false;
        let firstLetter = "";

        if (user && user.user.images[0]){
            img = utils.prefixImage(user.user.images[0].media_link);
            profileLink = user.unique_id;
            imageExists = true;
        } else if (user) {
            img = utils.randomGradient(user.unique_id);
            profileLink = user.unique_id;
            imageExists = false;
            firstLetter = user.user.first_name.substring(0, 1).toUpperCase();
        }

        return (
            <div id="question" className="question-container">
                <div className="tag active question-tag" style={{backgroundColor: `${utils.getTagColor("question")}` }}>
                    Question
                </div>
                <textarea
                    className="form-control"
                    name=""
                    id=""
                    ref={this.question}
                    value={this.state.text}
                    onChange={this.handleTextChange.bind(this)}
                    onKeyDown={this.checkForTab}
                    placeholder="What did you recently make progress on?"
                    rows="3"
                    onClick={this.addShade}></textarea>
                <div className="glow"></div>

                <div className="question-submit">
                    <div className="hit-enter">
                        Insert code using a set of <div className="enter-badge">`backticks`</div>
                    </div>
                    <span className="v-centred tag-text" onClick={() => this.clear()}>Clear</span>
                    <button className={"btn " + (true ? "btn-primary" : "btn-secondary")} onClick={() => {this.props.handleQuestionSubmit(); this.removeShade();}}>Ask</button>
                </div>
            </div>
        )
    }
}

export default Question;
