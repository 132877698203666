// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import View0 from "../components/ForgotPasswordViews/View0.jsx";
import View1 from "../components/ForgotPasswordViews/View1.jsx";
import View2 from "../components/ForgotPasswordViews/View2.jsx";
import View3 from "../components/ForgotPasswordViews/View3.jsx";
import '../css/ForgotPassword.css';

class ForgotPassword extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const {index, key} = this.props.match.params;
        return (
            <div className="forgot-password forgot-password-main d-flex justify-content-center align-items-center">
                <div className="forgot-password-box d-flex align-items-center">
                    <div className="highlight"></div>
                    <ForgotPasswordContent index={index} keyCode={key} />
                </div>
            </div>
        );

    }
}

class ForgotPasswordContent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let index = parseInt(this.props.index);
        switch(index){
            case 0:
                return <View0 />;
            case 1:
                return <View1 />;
            case 2:
                return <View2 keyCode={this.props.keyCode} />;
            case 3:
                return <View3 />;
        }
        console.log("Invalid index");
        return (
            <h1>Not Found!</h1>
        );
    }
}

export default ForgotPassword;
