import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import Badge from '../components/Badge.js';
import InputError from "../components/InputError.jsx";
import utils from '../utils';
import config from '../config';
import '../css/JoinRequest.css';

const _ = require("lodash");

class JoinRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: false,
            submitSuccess: false,
            team: {},
            skill_match: [],
            bucket_match: [],
            description: "",
            descriptionErrors: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.getData();
    }

    getData(){
        const self = this;
        const {id} = this.props.match.params;
        const url = `${config.BACKEND_HOST}/all/join_request_to`;
        utils.get(url, {team_uuid: id})
            .then(res => {
                console.log(res);
                if (res.status){
                    const {team, skill_match, bucket_match} = res.data;
                    self.setState({team, skill_match, bucket_match, submitEnabled: true});
                }
            })
            .catch(err => {
                console.log(err.message);
                //try again?
            });
    }
    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }
    validateInput(){
        const {description} = this.state;
        let errors = {};
        if (!description){
            errors.descriptionErrors = ["Please enter a message"];
        }
        this.setState(errors);
        return _.isEmpty(errors);
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
			descriptionErrors: [],
		});
        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});

            //submit logic here
            const url = `${config.BACKEND_HOST}/all/join_request_to`;
            const self = this;
            utils.post(url, {
                    team_uuid: this.state.team.unique_id,
                    description: this.state.description
                })
                .then((res) => {
                    console.log(res);
                    if (res.status){
                        self.setState({submitSuccess: true});
                    }
                })
                .catch((err) => {
                    if (typeof err === "object"){
                        console.log(err.response.body);
                    }
                    else{
                        console.log(err);
                    }
                    self.setState({submitEnabled: true});
                    //error logic
                });
        }
    }

    render() {
        if (this.state.submitSuccess) {
            const {id} = this.props.match.params;
            return <Redirect to={`/project/${id}/request-success`} push />;
        }
        if (!this.state.team){ return "Loading..."; }

        const {unique_id, name} = this.state.team;

        let skills = [];
        this.state.skill_match.forEach(skill =>
            skills.push(<Badge className="badge badge-primary badge-lg" key={skill.id} id={skill.id} txt={skill.name} active={true} />)
        );
        this.state.bucket_match.forEach(bucket => {
            bucket.skills.forEach(skill =>
                skills.push(<Badge className="badge badge-primary badge-lg lighter" key={skill.id} id={skill.id} txt={skill.name} active={false} />)
            );
        });

        return (
            <div className="main join-request-main d-flex justify-content-center align-items-center">
              <div className="content join-request-content">

                  <form onSubmit={this.handleSubmit}>

                      <h1 className="join-request-view-header">Collaborate on {name}</h1>
                      <p className="join-request-view-subheader mb-40">{"Start the conversation by showing how you're a good fit for the team"}</p>
                      {skills.length > 0 ? <p className="join-request-view-subsubheader">Here are the skills you could contribute to the team:</p> : null}

                      <div className="row" style={{marginLeft: "-5px", marginRight: "-5px"}}>
                          {skills}
                      </div>

                      <div className="form-row">
                          <textarea name="description" value={this.state.description} onChange={this.handleChange} placeholder="Why are you interested in joining, and why are you the best fit?" className={"form-control large " + (_.isEmpty(this.state.descriptionErrors) ? "" : "error")} rows={6} ></textarea>
                          <InputError errors={this.state.descriptionErrors} />
                      </div>

                      <div className="row justify-content-center">
                          <button className="btn btn-secondary" name="submit" type="submit">Collaborate</button>
                      </div>

                  </form>

              </div>
            </div>
        )
    }
}

export default JoinRequest;
