import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import Badge from '../components/Badge.js';
import InputSuccess from "../components/InputSuccess.jsx";
import InputError from "../components/InputError.jsx";
import utils from '../utils';
import config from '../config';
import '../css/ChangePassword.css';

const _ = require("lodash");
const v = require("validator");

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            oldPassword: "",
            oldPasswordErrors: [],
            newPassword: "",
            newPasswordErrors: [],
            confirmPassword: "",
            confirmPasswordErrors: [],
            generalErrors: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }
    validateInput(){
        const {oldPassword, newPassword, confirmPassword} = this.state;
        let errors = {};
        if (newPassword.length < 6){
            errors.newPasswordErrors = ["Please enter a longer password"];
        }
        if (newPassword !== confirmPassword) {
            errors.confirmPasswordErrors = ["Passwords don't match"];
        }
        this.setState(errors);
        return _.isEmpty(errors);
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitSuccess: false,
            oldPasswordErrors: [],
            newPasswordErrors: [],
            confirmPasswordErrors: [],
            generalErrors: []
        });
        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});

            //submit logic here
            const url = `${config.BACKEND_HOST}/accounts/update_existing_password`;
            const self = this;
            const {oldPassword, newPassword, confirmPassword} = this.state;
            utils.post(url, {
                    existing_password: oldPassword,
                    password_first: newPassword,
                    password_second: confirmPassword
                })
                .then((res) => {
                    console.log(res);
                    if (res.status){
                        self.setState({submitSuccess: true});
                        return;
                    }
                    throw "Response status false";
                })
                .catch((err) => {
                    if (typeof err === "object"){
                        console.log(err.response.body);
                    }
                    else{
                        console.log(err);
                    }
                    self.setState({submitEnabled: true});
                    self.setState({generalErrors: ["Something wasn't quite right"]});
                });
        }
    }

    render() {

        return (
            <div className="main change-password-main d-flex justify-content-center align-items-center">
                <div className="content change-password-content">

                    <form onSubmit={this.handleSubmit}>

                        <h1 className="change-password-headline">Change Password</h1>

                        <div className="row align-items-center">
                            <div className="col-sm-4">
                                <label className="inline-label">Old Password</label>
                            </div>
                            <div className="col-sm-8">
                                <input name="oldPassword" type="password" autoComplete="current-password" value={this.state.oldPassword} onChange={this.handleChange} className={"input " + (_.isEmpty(this.state.oldPasswordErrors) && _.isEmpty(this.state.generalErrors) ? "" : "error")} />
                            </div>
                            <div className="col offset-sm-4">
                                <InputError errors={this.state.oldPasswordErrors} />
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-sm-4">
                                <label className="inline-label">New Password</label>
                            </div>
                            <div className="col-sm-8">
                                <input name="newPassword" type="password" autoComplete="new-password" value={this.state.newPassword} onChange={this.handleChange} className={"input " + (_.isEmpty(this.state.newPasswordErrors) && _.isEmpty(this.state.generalErrors) ? "" : "error")} />
                            </div>
                            <div className="col offset-sm-4">
                                <InputError errors={this.state.newPasswordErrors} />
                            </div>
                        </div>

                        <div className="row align-items-center mb-30">
                            <div className="col-sm-4">
                                <label className="inline-label">Confirm Password</label>
                            </div>
                            <div className="col-sm-8">
                                <input name="confirmPassword" type="password" autoComplete="new-password" value={this.state.confirmPassword} onChange={this.handleChange} className={"input " + (_.isEmpty(this.state.confirmPasswordErrors) && _.isEmpty(this.state.generalErrors) ? "" : "error")} />
                            </div>
                            <div className="col offset-sm-4">
                                <InputSuccess messages={this.state.submitSuccess ? ["Your password has been changed successfully"] : []} />
                                <InputError errors={_.concat(this.state.confirmPasswordErrors, this.state.generalErrors)} />
                            </div>
                        </div>

                        <div className="form-row">
                            <button className="btn btn-secondary" name="submit" type="submit">Save Changes</button>
                        </div>

                  </form>

              </div>
            </div>
        )
    }
}

export default ChangePassword;
