// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

import config from "../config.js";
import utils from "../utils.js";
import Badge from './Badge.js';

const scss = config.SCSS;
const selectStyles = config.selectStyles;

class BucketsContainer extends Component{
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event){
        this.props.toggleActive(event.target.id);
    }
    render() {
        const badges = this.props.buckets.map(bucket => {
            return (
                <Badge key={bucket.id} id={bucket.id} txt={bucket.name} active={bucket.active} onClick={this.handleClick} size="lg" />
            );
        });
        return (
            <div className="buckets-container">
                {badges}
            </div>
        );
    }
}
class SkillsContainer extends Component{
    constructor(props){
        super(props);

        this.state = {
            newSkill: ""
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    handleClick(event){
        this.props.toggleActive(event.target.id);
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (this.state.newSkill){
                this.props.addSkill(this.state.newSkill, this.props.id);
                this.setState({newSkill: ""});
            }
        }
    }
    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }
    render(){
        const badges = this.props.skills.map(skill => {
            return (
                <Badge key={skill.id} id={skill.id} txt={skill.name} active={skill.active} onClick={this.handleClick} className="secondary" size="lg" />
            );
        });
        return (
            <div>
                <div className="row no-margin">
                    <h3>{this.props.bucketName}</h3>
                </div>
                <div className="row">
                    <div className="skills-container">
                        {badges}
                        {this.props.addSkill ? <input name="newSkill" type="text" value={this.state.newSkill} placeholder="Add a skill" className="input add-skill-input" onKeyPress={this.handleKeyPress} onChange={this.handleChange} /> : null}
                    </div>
                </div>
            </div>
        );
    }
}


export default class SkillsMenu extends Component {
    constructor(props){
        super(props);
    }
    render() {
        let skillsContainers = this.props.skillBuckets.filter(bucket => bucket.active).map(bucket =>
            (<SkillsContainer
                key={bucket.id}
                id={bucket.id}
                bucketName={bucket.name}
                skills={bucket.skills}
                toggleActive={this.props.handleSkillChange}
                addSkill={this.props.addSkill}
                />)
        );
        if (!skillsContainers.length){
            skillsContainers = "Select a skill group to get started!"
        }
        return (
            <div className="skills-menu">
                <div className="row buckets-container-row">
                    <BucketsContainer buckets={this.props.skillBuckets} toggleActive={this.props.handleBucketChange} />
                </div>

                <div className="row">
                    <div className="skills-detailed-box">

                        {skillsContainers}

                    </div>
                </div>
            </div>

        );
    }
}
