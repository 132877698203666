import React, {Component} from 'react';
import {Link, Switch, Route, Redirect} from "react-router-dom";
import PortfolioModal from './PortfolioModal';
import Card from './Card';
import Waveform from './Waveform';
import Update from './Update';
import moment from 'moment';

// utils
import utils from '../utils';
import config from '../config';



class ActivityProfile extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            activity: [],
        };
    }

    componentDidMount(){
        this.fetchActivity();
    }

    async fetchActivity() {
        let url = `${config.BACKEND_HOST}/all/creator_activity`;
        let params = {
            page_user_uuid: this.props.id,
        }
        const self = this;

        await utils.get(url, params)
            .then((res) => {
                console.log(res);
                if (res.status){
                    self.setState({
                        activity: res.data.activity,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        let { activity } = this.state;
        if (activity.length === 0) {
            return <small>There doesn't seem to be anything here</small>;;
        }

        return (
            <div id="ActivityProfile" className="ActivityProfile" style={this.props.style}>
                <div className="activity-stream">
                    {activity.map(({
                        updates
                    }, i) => {
                        return (
                            <div id={`week-${i}`}>
                                {updates.map(props => <Update notUser={this.props.notUser} {...props}/>)}
                            </div>
                        );
                    })}
                </div>
                <Waveform activity={activity.slice(0, 60)}/>
            </div>
        );
    }
}

export class CreatorBottomProfile extends Component {

    handleScroll() {
        const activity = document.querySelectorAll('[id^="week-"]');
        const waveform = document.getElementById('Waveform');

        if (!waveform) {
            return;
        }

        Array
            .from(waveform.children)
            .forEach((el, i) => {
                if (i > 0) {
                    el
                        .classList
                        .remove('highlight');
                }
            })

        const weeksWithActivity = Array
            .from(activity.values())
            .map((el, i) => {
                if (el.children.length > 0 && i < waveform.children.length) {
                    return i;
                }
            })
            .filter(val => val !== undefined);

        if (weeksWithActivity.length === 0) {
            return;
        }

        const weeksInView = weeksWithActivity.filter(i => utils.isScrolledIntoView(activity[i]));

        // console.log(weeksInView, weeksWithActivity);

        let last;
        if (weeksInView.length > 0) {
            last = weeksInView[weeksInView.length - 1];
        } else {
            last = weeksWithActivity[weeksWithActivity.length - 1];
        }

        if (last != 60) {
            waveform
                .children[last + 1]
                .classList
                .add('highlight');
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    render() {
        const {page_user} = this.props;
        if (typeof(page_user) === 'undefined' || !Object.keys(page_user)) {
            return;
        }

        const {location, match} = this.props;

        return (
            <div className="CreatorBottomProfile">
                <div className="v-centred" id="tabs">
                    <p className="tab column active">Activity</p>
                </div>
                <div className="component">
                    <ActivityProfile {...this.props} {...this.state}/>
                </div>
            </div>
        )
    }
}
