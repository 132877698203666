import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import utils from '../utils';
import config from '../config';
import ProgressNav from "../components/ProgressNav.jsx";

import View0 from "../components/CreateTeamViews/View0.jsx";
import View1 from "../components/CreateTeamViews/View1.jsx";
import View2 from "../components/CreateTeamViews/View2.jsx";
import '../css/CreateTeam.css';

class CreateTeam extends Component {
    constructor(props){
        super(props);
    }
    render() {
        // hacky way to differentiate edit vs create, TODO improve in future
        let navType = this.props.edit ? "Edit Team" : "Create Team"
        let id = this.props.match.params.id;
        if (this.props.edit && !id) return <Redirect to="/not-found" />;
        const index = Math.max(Math.min(this.props.match.params.index, 2), 0);
        return (
            <div className="create-team create-team-main d-flex">

                <ProgressNav index={index} navType={navType} />

                <div className="create-team-content">
                    <CreateTeamContent index={index} id={this.props.match.params.id} />
                </div>
            </div>
        );
    }
}

class CreateTeamContent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let index = parseInt(this.props.index);
        if (index > 0 && !this.props.id) return <Redirect to="/create-team/0" />;

        switch(index){
            case 0:
                return <View0 id={this.props.id} />;
            case 1:
                return <View1 id={this.props.id} />;
            case 2:
                return <View2 id={this.props.id} />;
        }
        console.log("Invalid index");
        return (
            <Redirect to="/not-found" />
        );
    }
}

export default CreateTeam;
