import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Dropzone from 'react-dropzone';

import config from "../../config.js";
import utils from "../../utils.js";

const uniqueString = require('unique-string');
const _ = require("lodash");
const scss = config.SCSS;
const selectStyles = config.selectStyles;
const pIndex = 1;

export default class View1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            personalMessage: "",
			personalMessageErrors: [],
            bio: "",
			bioErrors: [],
            profilePicture: "",
			saveButtonText: "Save"
        };
        this.handleChange = this
            .handleChange
            .bind(this);
        this.onDrop = this
            .onDrop
            .bind(this);
		this.save = this
            .save
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);
    }

    componentWillMount() {
        this.getDerivedStateFromProps();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.userprofile ===state.userprofile) {
            return null;
        }

        if (document.getElementsByTagName('textarea'))
        console.log(props);
        return {
            ...props,
            personalMessage: props.userprofile.mission || "",
            bio:             props.userprofile.description || "",
            profilePicture:  props.userprofile.user && props.userprofile.user.images.length > 0 ? utils.image(props.userprofile.user.images[0]) : "",
            profilePictureId: props.userprofile.user && props.userprofile.user.images.length > 0 ? props.userprofile.user.images[0].unique_id : null
        }
    }

    handleChange(event) {
        let val = event.target.value
        if (event.target.name  ===  "personalMessage") {
            //max 110 chars
            val = val.substring(0, 110);
        } else if (event.target.name  ===  "bio") {
            //max 1000 chars
            val = val.substring(0, 1000);
        }
        this.setState({
            [event.target.name]: val
        });
    }
    onDrop(accepted, rejected) {
        //display preview
        if (accepted.length > 0) {
            this.setState({profilePicture: accepted[0]});
        }
    }
	validateInput(){
        const {personalMessage, bio} = this.state;
        return true;
    }
	save(event){
		this.setState({saveButtonText: "Saving..."});
		this.handleSubmit(event, false);
	}
    handleSubmit(event, redirect = true) {
        event.preventDefault();
        if (this.state.submitEnabled && this.validateInput()) {
            this.setState({submitEnabled: false});
            const self = this;
            const url = `${config.BACKEND_HOST}/accounts/portfolio_aboutyou`;
            let params = {
                // Pass a simple key-value pair
                personal_message: this.state.personalMessage,
                personal_description: this.state.bio
            };

            if (typeof this.state.profilePicture === "object"){
                params.personal_image = this.state.profilePicture;
            }
            else{
                params.existing_personal_image = this.state.profilePictureId;
            }


            console.log("params");
            console.log(params);
            utils.postMultipart(url, params)
            .then(raw => raw.json())
            .then(res => {
                console.log(res);
                self.setState({submitEnabled: true});
                if (res.status && redirect) {
                    self.setState({submitSuccess: true});
                }
				else{
					self.setState({saveButtonText: "Saved!"});
				}
            })
            .catch(err => {
                console.log(err);
                self.setState({submitEnabled: true});
                //try again?
            });
        }
    }
    render() {
        if (this.state.submitSuccess) {
            return <Redirect to={(pIndex + 1).toString()} push/>;
        }

        let profileDropzoneStyle, profilePicture;
        profilePicture = this.state.profilePicture;
        if (profilePicture) {
            if (typeof profilePicture  ===  "object"){
                profilePicture = profilePicture.preview;
            }
            profileDropzoneStyle = {
                backgroundImage: `url('${profilePicture}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center"
            };
        }
        return (
            <div className="col-sm-10 col-md-8 mx-auto">
                <form action={(pIndex + 1).toString()} onSubmit={this.handleSubmit}>

                    <div className="col">

                        <div className="form-row no-margin">
                            <h2 className="create-profile-headline float-left">Personal Message</h2>
                        </div>
                        <div className="form-row no-margin">
                            <p className="create-profile-subheadline">A single statement to describe who you are and what you are passionate about</p>
                        </div>
                        <div className="form-row">
                            <textarea
                                name="personalMessage"
                                value={this.state.personalMessage}
                                onChange={this.handleChange}
                                className="form-control"
                                rows="2"
                                placeholder="I am..."></textarea>
                        </div>

                        <div className="row">
                            <div className="col-sm-7 col-lg-8">
                                <div className="form-row no-margin">
                                    <h2 className="create-profile-headline float-left">Bio</h2>
                                </div>
                                <div className="form-row">
                                    <textarea
                                        name="bio"
                                        value={this.state.bio}
                                        onChange={this.handleChange}
                                        className="form-control bio pull-left"
                                        placeholder="Add a personal description"></textarea>
                                </div>

                            </div>

                            <div className="col-sm-5 col-lg-4">
                                <div className="form-row no-margin text-center">
                                    <h2 className="create-profile-headline col">Profile Picture</h2>
                                </div>
                                <div className="form-row">
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        accept="image/jpeg, image/png"
                                        className="profile-dropzone mx-auto d-flex justify-content-center align-items-center"
                                        style={profileDropzoneStyle}>
                                        {({isDragActive, isDragReject}) => {
                                            if (isDragActive) {
                                                return "Upload";
                                            }
                                            if (isDragReject) {
                                                return <p className="large">X</p>;
                                            }
                                            if (!this.state.profilePicture) {
                                                return <p className="large">+</p>;
                                            }
                                            return;
                                        }}
                                    </Dropzone>
                                </div>

                            </div>

                        </div>

                        <div className="form-row no-margin">
                            <button name="submit" type="submit" className="btn btn-primary">Next</button>
                        </div>
						<div className="form-row">
                            <Link to={(pIndex + 1).toString()} className="small-link">Skip</Link>
							<a id="save-button" href="" onClick={this.save} className="small-link">{this.state.saveButtonText}</a>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
