import React, {Component} from 'react';
import {Helmet} from 'react-helmet';

import config from '../config';
import utils from '../utils';

import {CreatorSidebar} from '../components/CreatorSidebar';
import {Bio} from '../components/Bio';
import {CreatorTopProfile} from '../components/CreatorTopProfile';
import {CreatorBottomProfile} from '../components/CreatorBottomProfile';

// import feather from 'feather-icons';

import '../css/CreatorProfile.css';
// import mock from '../mock';

const getUser = async(id) => {
    return utils.get('/all/creator_page/query/', {page_user_uuid: id});
}

class CreatorProfileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_user: {},
            id: -1,
            sidebarCollapsed: false
        };
        //TODO: REMOVE
        this.cache = [];
    }

    async componentDidMount() {
        window.addEventListener('scroll', (() => {
            const tabs = document.getElementById('tabs');
            if (!tabs)
                return;
            const {top} = tabs.getBoundingClientRect();
            if (top === 42) {
                this.setState({sidebarCollapsed: true});
            } else if (this.state.sidebarCollapsed) {
                this.setState({sidebarCollapsed: false});
            }
        }).bind(this));
        this.forceUpdate();
    }

    async componentDidUpdate() {

        const {id} = this.props.match.params;
        if (id === this.state.id) {
            return;
        }
        if (id in this.cache) {
            this.setState(this.cache[id]);
            return;
        }

        await getUser(id).then((({status, data}) => {
            if (status) {
                const newState = {
                    ...data,
                    id
                };
                this.cache[id] = newState;
                this.setState(newState);
            }
        }).bind(this)).catch((err => {
            this
                .props
                .history
                .replace('/not-found')
        }).bind(this))
    }

    render() {
        return (
            <div className="main profile-main">
                <div className="content">
                    {this.state.id === -1
                        ? <div className="h-centred">
                                <Helmet>
                                    <title>Loading...</title>
                                </Helmet>
                                <utils.Spinner/>
                            </div>
                        : <div className="CreatorProfileView">
                            <Helmet>
                                <title>{(({user__first_name, user__last_name}) => `${user__first_name} ${user__last_name} – Jouncer`)(this.state.page_user)}</title>
                            </Helmet>
                            <div className="profile-content">
                                <CreatorSidebar {...this.state} history={this.props.history} notUser={this.props.notUser}/>
                                <div className="right-content">
                                    <Bio {...this.state}/>
                                    <CreatorTopProfile {...this.state}/>
                                    <CreatorBottomProfile
                                        {...this.state}
                                        location={this.props.location}
                                        notUser={this.props.notUser}
                                        match={this.props.match}/>
                                </div>
                            </div>

                        </div>}
                </div>
            </div>
        )
    }
}

export default CreatorProfileView;
