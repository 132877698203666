import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Select from 'react-select';

import utils from '../../utils';
import config from '../../config';
import SkillsMenu from '../SkillsMenu.jsx';
import MediaItemContainer from "../MediaItemContainer.jsx";
import InputError from "../InputError.jsx";

import Octicon, {MarkGithub} from '@primer/octicons-react';

const moment = require('moment');
const uniqueString = require('unique-string');
const _ = require("lodash");
const v = require("validator");
const pIndex = 0;

export default class View0 extends Component {
    constructor(props){
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            name: "",
			nameErrors: [],
            mission: "",
			missionErrors: [],
            description: "",
			descriptionErrors: [],
            link: "",
			linkErrors: [],
            ideaId: "",
            id: "",
            unusedRepos: [],
        };
        if ("id" in props && props.id){
            this.state.id = props.id;
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getUnusedRepos = this.getUnusedRepos.bind(this);
        this.selectedGithubRepo = this.selectedGithubRepo.bind(this);

        this.getData();
        this.getIdea();
    }

    componentDidMount() {
        this.getUnusedRepos();
    }

    async getIdea() {

        let pageUrl = window.location.href.split("=");
        let id = pageUrl[1];
        let params = {idea_unique_id: id};

        const url = `${config.BACKEND_HOST}/teams/idea`;
        const self = this;

        await utils.get(url, params)
            .then((res) => {
                console.log(res);
                if (res.status){
                    console.log(res);
                    self.setState({
                        mission: res.idea.description,
                        ideaId: id,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getData(){
        const url = `${config.BACKEND_HOST}/teams/create1`;
        const self = this;
        let params;
        if ("id" in this.state && this.state.id){
            params = {team_uuid: this.state.id};
        }
        await utils.get(url, params)
            .then((res) => {
                console.log(res);
                if (res.status){
                    //set state
                    self.setState({
                        name: res.data.team.name,
                        mission: res.data.team.mission,
                        description: res.data.team.description,
                        link: res.data.team.external_link,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                //error logic
            });
    }

    getUnusedRepos() {
        const url = `${config.BACKEND_HOST}/accounts/unused_repos`;
        const params = {
            userprofile_unique_id: this.state.id,
        }
        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){

                    let newUnusedRepos = res.data.github_repos;
                    for (let i = newUnusedRepos.length - 1; i >= 0; i--){
                        if ( newUnusedRepos[i] && newUnusedRepos[i].already_used === true) {
                           newUnusedRepos.splice(i, 1);
                       }
                    }

                    this.setState({ unusedRepos: res.data.github_repos });
                    return;
                }
                throw "Response status false";
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
            });
    }


    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }
	validateInput(){
        const {name, mission, description, link} = this.state;
		let errors = {};
		if (!name){
			errors.nameErrors = ["Please enter a project name"];
		}
		if (!mission){
			errors.missionErrors = ["Please enter a mission for your project"];
		}
		if (!description){
			errors.descriptionErrors = ["Please enter a description of your project"];
		}
		if (link && !v.isURL(link)){
			errors.linkErrors = ["Please enter a valid link"];
		}
		this.setState(errors);
        return _.isEmpty(errors);
    }

    selectedGithubRepo(id) {

        let newUnusedRepos = this.state.unusedRepos;
        for (let i = 0; i < newUnusedRepos.length; i++){
           if ( newUnusedRepos[i].id === id) {
               newUnusedRepos.splice(i, 1);
               this.setState({unusedRepos: newUnusedRepos});
           }
        }

        this.setState({ uploading: true });
        this.setState({ scrolltoitem: true });

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/import_team_from_github`;
        const params = {
            userprofile_unique_id: "",
            repo_id: id
        }

        utils.post(url, params)
            .then(res => {
                console.log("import team from github", res);
                if (res.status) {
                    //autofill
                    self.setState({
                        uploading: false,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    handleSubmit(event){
        event.preventDefault();
		this.setState({
			nameErrors: [],
			missionErrors: [],
			descriptionErrors: [],
			linkErrors: []
		})
        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});

            //submit logic here
            const url = `${config.BACKEND_HOST}/teams/create1`;
            const self = this;

            let params = {
                    team_name: this.state.name,
                    team_mission: this.state.mission,
                    team_description: this.state.description,
                    team_link: this.state.link,
                };

            if (this.state.ideaId.length > 0) {
                params.idea_unique_id = this.state.ideaId;
            }

            if ("id" in this.state && this.state.id){
                params.team_uuid = this.state.id;
            }

            utils.post(url, params)
                .then((res) => {
                    if (res.status){
                        //set state
                        self.setState(prevState => {
                            prevState.submitSuccess = true;
                            if (prevState.id.length === 0){
                                prevState.id = res.team_uuid;
                            }
                            return prevState;
                        });
                    }
                })
                .catch((err) => {
                    if (typeof err ==="object"){
                        console.log(err.response.body);
                    }
                    else{
                        console.log(err);
                    }
                    self.setState({submitEnabled: true});
                    //error logic
                });
        }
    }
    render() {
        if (this.state.submitSuccess){
            return (<Redirect to={"/create-team/" + (pIndex + 1).toString() + "/" + this.state.id} push />);
        }

        return (
            <div className="col-sm-12 col-md-10 col-lg-8 mx-auto">
                <form action="/create-team/1" onSubmit={this.handleSubmit}>

                    {/*{this.state.unusedRepos.length > 0 ?
                        <div className="unused-repos">

                            <h3><Octicon className="icon" icon={MarkGithub} size="medium"/>Import this project from Github</h3>
                            <p>These are the projects that you have up on Github. You can import one of your repos to start this project.</p>
                            <div className="github-project-chips">
                                {this.state.unusedRepos.map(
                                    ({id, name, full_name}) =>
                                    <div id={name} className="project-chip" onClick={() => this.selectedGithubRepo(id)}>{name}</div>
                                )}
                            </div>
                        </div>
                    : null}*/}

                    <div className="form-row no-margin">
                        <h2 className="create-team-headline float-left">Project Name</h2>
                    </div>
                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">Give us a quick way to remember your project</h3>
                    </div>
                    <div className="form-row">
                        <input name="name" value={this.state.name} onChange={this.handleChange} className={"input " + (this.state.nameErrors.length > 0 ? "error" : "")} placeholder="My Project Name" />
						<InputError errors={this.state.nameErrors} />
                    </div>

                    <div className="form-row no-margin">
                        <h2 className="create-team-headline float-left">Project Tl;dr</h2>
                    </div>
                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">What are you creating? Describe your project in a short, sweet snippet.</h3>
                    </div>
                    <div className="form-row">
                        <textarea name="mission" value={this.state.mission} onChange={this.handleChange} className={"form-control large " + (this.state.missionErrors.length > 0 ? "error" : "")} rows="2" placeholder="Building X to do Y"></textarea>
						<InputError errors={this.state.missionErrors} />
                    </div>

                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">Provide any technical details about the project and your vision of the result.</h3>
                    </div>
                    <div className="form-row">
                        <textarea name="description" value={this.state.description} onChange={this.handleChange} className={"form-control " + (this.state.descriptionErrors.length > 0 ? "error" : "")}  placeholder="Project description"></textarea>
						<InputError errors={this.state.descriptionErrors} />
                    </div>

                    <div className="form-row no-margin">
                        <h2 className="create-team-headline float-left">Link</h2>
                    </div>
                    <div className="form-row no-margin">
                        <h3 className="create-team-subheadline">If your project is online, provide a link and we will show a live preview of your latest build</h3>
                    </div>
                    <div className="form-row">
                        <input name="link" value={this.state.link} onChange={this.handleChange} placeholder="Link" className={"input " + (this.state.linkErrors.length > 0 ? "error" : "")} />
						<InputError errors={this.state.linkErrors} />
                    </div>

                    <div className="form-row">
                        <button name="submit" type="submit" className="btn btn-primary">Next</button>
                    </div>

                </form>
            </div>
        );
    }
}
