// Required Dependencies
import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

import config from "../config.js";
import utils from "../utils.js";
import Badge from "./Badge.js";
import MediaItemContainer from "./MediaItemContainer.jsx";
import InputError from "./InputError.jsx";
import Dropzone from 'react-dropzone';

const img = "https://www.w3schools.com/w3css/img_lights.jpg";
const scss = config.SCSS;
const selectStyles = config.selectStyles;


class PortfolioItem extends Component{
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            titleErrors: [],
            fakeSaveText: "Save Project",
            newProject: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.editSkills = this.editSkills.bind(this);
        // this.handleSkillChange = this.handleSkillChange.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.fakeSave = this.fakeSave.bind(this);
        this.SaveLoad = this.SaveLoad.bind(this);
        this.remove = this.remove.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    handleInputChange(event){
        this.setState({ title: this.props.title });
        this.props.handleChange(event.target.name, event.target.value, this.props.id);
    }

    titleError() {
        this.setState({ titleErrors: ["Please enter a title"] });
    }

    editSkills(event){
        this.props.editSkills(this.props.id);
    }
    // handleSkillChange(values){
    //     this.props.handleChange("skills", values, this.props.id)
    // }

    renderGithubProjects() {
        let githublist = this.props.githubrepos.map(
            ({name, full_name, already_used}) => {
                if (!already_used) {
                    return <div id={name} className="project-chip" onClick={() => this.props.selectedGithubRepo(this.props.id, full_name, name)}>{name}</div>
                } else {
                    return;
                }
            }
        )
        return (githublist);
    }

    handleUploadChange(values){
        this.props.handleMediaChange(values, this.props.id, this.props.isNew);
    }

    remove(){
        this.props.remove(this.props.id);
    }

    showBanner() {
        let showBanner = false;
        let activeRepos = 0;

        if (!this.props.githubrepos) {
            return showBanner;
        }

        for (let i=0; i<this.props.githubrepos.length; i++) {
            if (this.props.githubrepos[i].already_used == false) {
                activeRepos++;
            }
        }
        if (activeRepos > 0) {
            showBanner = true;
        }
        return showBanner;
    }

    onDrop(accepted, rejected) {
        this.props.profilePhotoDrop(accepted, rejected, this.props.id, this.props.isNew);
    }

    SaveLoad() {
        this.setState({ fakeSaveText: "..." });
        this.props.handleSave(this.props.id, this.props.isNew);
        setTimeout( this.fakeSave, 400);
    }

    fakeSave() {
        this.setState({ fakeSaveText: "Project Saved!" });
    }

    render(){
        let skillNames = [];
        this.props.skillBuckets.forEach(b => {
            b.skills.forEach(s => {
                if (this.props.skills.indexOf(s.id) >= 0){
                    skillNames.push(s);
                }
            })
        })

        let showBanner = this.showBanner();

        let profileDropzoneStyle, profilePicture;
        profilePicture = this.props.img;
        if (profilePicture) {
            if (typeof profilePicture  ===  "object"){
                profilePicture = profilePicture.preview;
            }
            profileDropzoneStyle = {
                backgroundImage: `url('${profilePicture}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center"
            };
        }

        return (
            <Modal className="PortfolioModal"
                open={this.props.openModal === this.props.id}
                onClose={this.state.title || this.props.title || (this.props.media.length == 0 && !this.props.description && !this.props.link)

                    ? () => this.props.handleClose(this.props.id, this.props.isNew, this.state.title)
                    : () => this.titleError() }>

                <div className="centred-edit-modal">
                    <div className="modal-card modal-bottom-padding">

                        <div className="modal-card-content">
                            <div className="portfolio-item">

                                {this.props.team ? <div className="media">
                                    <div className="cover-image">
                                        <p>Add a cover image to represent your project</p>
                                        <Dropzone
                                            onDrop={this.onDrop}
                                            accept="image/jpeg, image/png"
                                            className="profile-dropzone d-flex justify-content-center align-items-center"
                                            style={profileDropzoneStyle}>
                                            {({isDragActive, isDragReject}) => {
                                                if (isDragActive) {
                                                    return "Upload";
                                                }
                                                if (isDragReject) {
                                                    return <p className="large">X</p>;
                                                }
                                                if (!this.props.img) {
                                                    return <p className="large">+</p>;
                                                }
                                                return;
                                            }}
                                        </Dropzone>
                                    </div>

                                    <div className="standard-media">
                                        <p>Add images, screenshots, and gifs to showcase your project</p>
                                        <MediaItemContainer
                                            items={this.props.media}
                                            handleUploadChange={this.handleUploadChange}
                                            handleDelete={this.props.handleDelete}
                                            hideCheck={true}
                                            isSmall={true}
                                            unique_id={this.props.id} />
                                    </div>

                                </div>


                                : <div className="form-row portfolio-media-section">
                                    <MediaItemContainer
                                        items={this.props.media}
                                        handleUploadChange={this.handleUploadChange}
                                        handleDelete={this.props.handleDelete}
                                        hideCheck={true}
                                        isSmall={true}
                                        unique_id={this.props.id} />
                                </div>
                                }

                                {showBanner ?
                                    <div className="form-row">
                                        <div className="github-projects">
                                            <p><i class="fab fa-github"></i>&nbsp;&nbsp;Import this project from a Github repository</p>
                                            <div id="github-project-chips" className="github-project-chips">
                                                {this.renderGithubProjects()}
                                            </div>
                                        </div>
                                    </div> :
                                null }

                                <div className="form-row">
                                    <input name="title" value={this.props.title} onChange={this.handleInputChange} placeholder="Title" className={"input input-add-outline title " + (this.state.titleErrors && this.state.titleErrors.length > 0 ? "error" : "")} />
                					<InputError errors={this.state.titleErrors} />
                                </div>

                                {this.props.team ?
                                    <div className="form-row">
                                        <textarea name="mission" value={this.props.mission}  onChange={this.handleInputChange} placeholder="Mission" className="form-control mission" rows="2"></textarea>
                                    </div>
                                : null}

                                <div className="form-row">
                                    <textarea name="description" value={this.props.description}  onChange={this.handleInputChange} placeholder="Description" className="form-control" rows="5"></textarea>
                                </div>

                                <div className="form-row">
                                    <input name="link" value={this.props.link} onChange={this.handleInputChange} placeholder="Link" className="input highlight" />
                                </div>

                                <div className="form-row">
                                    <div className="skills-container d-flex flex-wrap">
                                        {skillNames.map(s => (<Badge key={s.id} id={s.id} txt={s.name} active={true} />))}
                                        <button className="btn btn-tertiary add-skill-btn" onClick={this.editSkills}>Add Skills</button>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <button
                                        id="fake-save"
                                        className="btn btn-secondary"
                                        onClick={this.SaveLoad}>{this.state.fakeSaveText}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

class PortfolioImg extends Component{
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(){
        this.props.removeImg(this.props.id);
    }
    render(){
        const imgContainerStyle = {
            backgroundImage: `url('${this.props.img}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
        };
        return (
            <div className="img-container">
                <div className="img-box" style={imgContainerStyle}>
                    <div className="x-container" onClick={this.handleClick}>
                        <i className="fal fa-times"></i>
                    </div>
                </div>
            </div>
        );
    }
}

export default PortfolioItem;
