import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import '../css/Chat.css';
import utils from '../utils';
import {Link} from "react-router-dom";
import config from '../config';
import ChatTeamItem from '../components/ChatTeamItem';
import {Column, Row} from 'simple-flexbox';
import TextField from '@material-ui/core/TextField';
import MemberIcon from '../components/MemberIcon';
const _ = require ("lodash");
const moment = require("moment");


class Chat extends Component {

	constructor(props) {
		super(props);

        let {teamId, convoId, chatId} = this.props;

		this.state = {
			chatSocket: null,
			accessToken: utils.token(),
			previewData: [],
            teams: [],
            chatContentData: null,
            activeTeam: teamId || null,
            activeChat: convoId || null,
			selectedChat: chatId || null,
		}

        this.sectionData = {
            all: [
                {
                sectionName: 'applications',
                chatsName: 'jrcMessagesList',
            },
            {
                sectionName: 'general',
                chatsName: 'generalConversations',
            }],
            team: [{
                sectionName: 'joinRequests',
                chatsName: 'jrcMessagesList',
            },{
                sectionName: 'teamChat',
                chatsName: 'atcMessagesList',
            },{
                sectionName: 'memberChat',
                chatsName: 'tmcMessagesList',
            }]
        };

        this.loadPage = this.loadPage.bind(this);
        this.selectChat = this.selectChat.bind(this);
        this.selectTeam = this.selectTeam.bind(this);
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.dashboard !== this.props.dashboard){

			if (Object.entries(nextProps.dashboard).length === 0){
	            return;
	        }

			const {dashboard} = nextProps;
	        const {activeTeam, activeChat, selectedChat} = this.state;

	        // init data
	        this.userId = dashboard.current_user.unique_id;
	        this.teamLookup = _.keyBy(dashboard.teams_on, team => team.unique_id);

	        let teams = [{
	            id: null,
	            imgSrc: null,
	            hasNotification: false,
	            active: activeTeam === null
	        }].concat(dashboard.teams_on.map(team => ({
	            id: team.unique_id,
	            imgSrc: utils.prefixImage(team.profile_img),
	            hasNotification: false,
	            active: team.unique_id === activeTeam
	        })));

	        const self = this;

	        this.setState({teams}, () => {
	            self.loadPage(activeTeam, selectedChat);
	            self.connectSocket();
	        });
		}
	}


    loadPage(activeTeam = null, selectedChat = null){

        // get chat data
        let self = this;
        let params;
        let url = `${config.BACKEND_HOST}/chat/all`;
        if (activeTeam !== null){
            params = {team_uuid: activeTeam};
            url = `${config.BACKEND_HOST}/chat/team`;
        }

		console.log("LOADING CHAT");
        utils.get(url, params).then(({status, data}) => {
            if (!status) throw "Request failed";
            data = utils.camel(data);

			console.log("HAS IT LOADED YET");

            // get/set lookups
            let {teamLookup, userId} = this;
            let {teams} = this.state;
            let memberLookupArray = data.memberLookups.map(m => {
                m.name = `${m.userFirstName} ${m.userLastName}`;
                return m;
            });
            let memberLookup = _.keyBy(memberLookupArray, member => member.userUuid);
            let members = memberLookupArray.map(m => ({id: m.userUuid, name: m.name, imgSrc: utils.prefixImage(m.userImage.mediaLink)}))
            let chatLookup = {};

            //set active chat if doesn't exist. TODO get this from backend once implemented
            if (selectedChat === null){
                if (activeTeam !== null){
                    selectedChat = data.atcMessagesList.length > 0 ? data.atcMessagesList[0].uniqueId : null;
                }
                else{
                    selectedChat = data.generalConversations.length > 0 ? data.generalConversations[0].uniqueId : null;
                }
            }

            // get sections
            let sections;
            if (activeTeam === null){
                sections = self.sectionData.all;
            }
            else{
                sections = self.sectionData.team;
            }

            //update teams
            teams = teams.map(team => {
                team.active = team.id === activeTeam;
                return team;
            });

            // prepare sidebar data and chatLookup
            let previewData = sections.filter(s => data[s.chatsName]).map(section => {
                section.chats = data[section.chatsName].map(chat => {
                    let recipientId, name, imgSrc;
                    if (chat.currentMemberUuids && chat.currentMemberUuids.length == 2){
                        recipientId = _.difference(chat.currentMemberUuids, [userId])[0];
                        let member = memberLookup[recipientId];
                        name = member.name;
                        imgSrc = utils.prefixImage(member.userImage.mediaLink);
                    }
                    else{
                        recipientId = activeTeam;
                        let team = teamLookup[activeTeam];
                        name = team.name;
                        imgSrc = utils.prefixImage(team.profile_img);
                    }
                    return {
                        id: chat.uniqueId,
                        recipientId,
                        name,
                        imgSrc,
                        lastMessage: chat.lastMessage.message,
                        lastMessageStatus: chat.lastMessage.isSystem ? "system" : "read",
                        time: utils.shortTime(chat.lastMessage.dateSent),
                        selected: chat.uniqueId === selectedChat,
                        section: section.sectionName, //used in chat lookup
                    };
                });
                chatLookup = {
                    ...chatLookup,
                    ...(_.keyBy(section.chats, chat => chat.id))
                };
                return section;
            });

            self.memberLookup = memberLookup;
            self.chatLookup = chatLookup;

            let team;
            if (activeTeam !== null) {
                team = teamLookup[activeTeam];
            }
            // prepare chat content data (many dummy values since it'll get updated in this.selectChat())
            let chatContentData = {
                id: selectedChat,
                name: activeTeam === null ? null : team.name,
                type: activeTeam === null ? "general" : "teamChat",
                members: activeTeam === null ? null : members,
                memberName: null,
                messages: null
            };

            self.setState({previewData, chatContentData, activeTeam, teams}, () => self.selectChat(selectedChat, true));
        }).catch(err => {
            console.log(err);
        });
    }

    updateSidebar(convoId, message){
        let {previewData} = this.state;
        previewData = previewData.map(section => {
            let chatUpdatedIndex = null;
            section.chats = section.chats.map((chat, index) => {
                if (chat.id !== convoId) {
                    return chat;
                }
                chatUpdatedIndex = index;
                // let lastMessageStatus = "unread";
                let lastMessageStatus = "read";
                if (message.isSystem){
                    lastMessageStatus = "system";
                }
                else if (message.read){
                    lastMessageStatus = "read";
                }
                return {
                    ...chat,
                    lastMessage: message.message,
                    lastMessageStatus,
                    time: utils.shortTime(message.dateSent)
                };
            });
            if (chatUpdatedIndex !== null){
                //move to top (this code sucks, improve later)
                section.chats = utils.toFront(section.chats, chatUpdatedIndex);
            }
            return section;
        });
        this.setState({previewData});
    }

    connectSocket(){
        // maintains connection and gets all messages. pushes last message off stack when necessary
        let socket = new WebSocket(`${config.SOCKET_HOST}/wss/chat/${this.userId}/`);
		console.log(socket);
        console.log("the socket has worked but in chat!!");

        socket.onopen = this.onOpen.bind(this);
        socket.onmessage = this.onMessage.bind(this);
        socket.onclose = this.onClose.bind(this);

        this.setState({socket});
    }

    onOpen(event){
        //check for errors here
        const self = this;
        this.ping = setInterval(() => {
            console.log("ping");
            self.state.socket.send(JSON.stringify({request_type: "ping"}));
        }, 60000);
        if (this.state.activeChat !== null){
            this.getEarlierMessages(this.state.activeChat);
        }
    }

    onMessage(event){
        if (event.data === "pong"){
            console.log("pong");
            return;
        }

        const res = utils.camel(JSON.parse(event.data));
        console.log(res);

        const {message, messages, user, requestType} = res;

        if (requestType === "earlier_messages"){
            //TODO pass chat ID to check if chat is still active
            this.setEarlierMessages(messages);
        }
        else if (requestType === "current_message"){
            const convoId = message.conversationUniqueId;
            // TODO: handle creation of new chat room (since member lookups will be empty)
            this.updateSidebar(convoId, message);
            if (convoId === this.state.activeChat){
                let {chatContentData} = this.state;
                let messageObj = {
                    type: "sent",
                    text: message.message,
                    profileImgSrc: null,
                    senderId: message.userFrom,
                    showImg: false,
                };

                // if is system
                if (message.isSystem){
                    messageObj.type = "system";
                }
                // if message was received
                else if (message.userFrom !== this.userId){
                    messageObj.type = "received";
                    // if last message was also received, set profile img accordingly
                    messageObj.showImg = true;
                    messageObj.profileImgSrc =  utils.prefixImage(this.memberLookup[message.userFrom].userImage.mediaLink);
                    if (_.last(chatContentData.messages).type === "received"){
                        _.last(chatContentData.messages).showImg = false;
                    }
                }
                chatContentData.messages.push(messageObj);
                this.setState({chatContentData});
            }
        }
    }

    onClose(event){
        console.log("Chat disconnected :(. Attempting to reconnect...");
        clearInterval(this.ping);
        if (this.props.history.location.pathname.includes("/chat")){
            this.connectSocket();
        }
    }

    getEarlierMessages(convoId, messageCount = 200){
        if (this.state.socket === null || this.state.socket.readyState !== 1) throw "Socket not open";
        // request messages
        this.state.socket.send(JSON.stringify({
            'request_type': 'earlier_messages', // as opposed to 'send_message'
            'accessToken': this.state.accessToken,
            'conversationID': convoId,
            'message_count': messageCount
        }));
    }

    setEarlierMessages(messages, convoId = null){
        let {chatContentData} = this.state;
        chatContentData.messages = [];
        messages.forEach(message => {
            let messageObj = {
                type: "sent",
                text: message.message,
                profileImgSrc: null,
                senderId: message.userFrom,
                senderName: message.userFrom ? this.memberLookup[message.userFrom].name : null,
                showImg: false,
            };

            // if is system
            if (message.isSystem){
                messageObj.type = "system";
            }
            // if message was received
            else if (message.userFrom !== this.userId){
                messageObj.type = "received";
                // if last message was also received, set profile img accordingly
                messageObj.showImg = true;
                messageObj.profileImgSrc = utils.prefixImage(this.memberLookup[message.userFrom].userImage.mediaLink);
                if (_.last(chatContentData.messages).type === "received"){
                    _.last(chatContentData.messages).showImg = false;
                }
            }
            chatContentData.messages.push(messageObj);
        });
        this.setState({chatContentData});
    }

    sendMessage(message){
        this.state.socket.send(JSON.stringify({
            request_type: 'send_message',
            message: message,
            accessToken: this.state.accessToken,
            conversationID: this.state.activeChat
        }));
    }

    selectTeam(id){
        if (id === this.state.activeTeam) return;

        const self = this;
        this.loadPage(id, null);
    }

    selectChat(id, force = false){
        if (id === this.state.selectedChat && !force) return;

        let {previewData, chatContentData, activeTeam} = this.state;
        let {memberLookup, teamLookup, chatLookup} = this;

        // update sidebar
        previewData = previewData.map(section => {
            section.chats = section.chats.map(chat => {
                chat.selected = chat.id === id;
                return chat;
            });
            return section;
        });

        // update content
        let chatData = chatLookup[id];
        if (!chatData) {
            this.setState({chatContentData: {}});
            return;
        };
        chatContentData = {
            ...chatContentData,
            id: id,
            type: chatData.section,
            messages: null
        };
        if (["general", "applications"].includes(chatData.section)){
            chatContentData.name = chatData.name;
            this.props.history.replace(`/chat/${id}`);
        }
        else{
            this.props.history.replace(`/chat/team/${activeTeam}/${id}`);
            if (chatData.section === "memberChat"){
                chatContentData.memberName = memberLookup[chatData.recipientId].name;
                chatContentData.memberId = memberLookup[chatData.recipientId].userUuid;
            }
        }

        const self = this;
        this.setState({activeChat: id, previewData, chatContentData}, () => {
            // set cookies
            if (self.state.socket !== null && self.state.socket.readyState === 1){
                self.getEarlierMessages(id);
            }
            // else should be handled by the socket.onOpen() function
        });
    }

    render(){



					console.log("this.props");
			console.log(this.props);

        const loaded = Object.entries(this.props.dashboard).length !== 0;
        const {previewData, chatContentData, teams} = this.state;
        if (loaded && teams.length === 1 && previewData.filter(s => s.chats.length !== 0).length === 0) {
            return (<div className="chat-view-background">
                    <Column className="chat-view-container" horizontal="center" vertical="center">
                        <h1>You have no conversations yet!</h1>
                        <p>Join a team or initiate a chat from someone's profile 😁</p>
                    </Column>
			</div>);
        }
        return (
			<div className="chat-view-background">
                {loaded ?
                    <Row className="chat-view-container">
                        <ChatSidebar
                            previewData={previewData}
                            teams={teams}
                            selectTeam={this.selectTeam}
                            selectChat={this.selectChat}
                            />

                        <ChatContent
                            chatContentData={chatContentData}
                            sendMessage={this.sendMessage.bind(this)}
                            />
                    </Row>
                    : <utils.Spinner/>
                }
			</div>
        );
    }
}



const ChatPreview = ({id, recipientId, name, imgSrc = null, lastMessage, lastMessageStatus = "read", time, selected, onClick}) => {

    if (!['read', 'unread', 'system'].includes(lastMessageStatus)) {
        throw "Invalid message state";
    }

    return (<div className={"chat-view-conversation-row" + (selected ? " selected" : "")} onClick={() => onClick(id)}>
        <Row className="chat-view-100">
            <MemberIcon className="chat-preview-img" id={recipientId} name={name} imgSrc={imgSrc} tooltip="false"/>
            <Column vertical="center" className="chat-view-conversation-details">
                <Row vertical="center" className="chat-view-conversation-details-top">
                    <div className="chat-preview-name">{name}</div>
                    <div className="chat-preview-time">{time}</div>
                </Row>
                <div className={`chat-preview-msg ${lastMessageStatus}`}>{lastMessage}</div>
            </Column>
        </Row>
    </div>);
}

class ChatSidebar extends Component {

    renderSectionHeader({sectionName, teamId = null}){
        // sectionName == teamName for this case
        if (teamId !== null){
            return (
                <Row key={sectionName} vertical="center" className="chat-view-header-bar-team">
                    <Link className="chat-preview-team-link" to={`/teams/${teamId}`}><span className="chat-view-header-bar-team-left">{sectionName}</span><span className="chat-view-header-bar-team-right">Team Page ></span></Link>
                </Row>
            );
        }

        const formattedSectionName = _.startCase(sectionName);

        return (<Row key={sectionName} vertical="center" className="chat-view-header-bar-team">
            <div className="chat-view-header-bar-team-left">{formattedSectionName}</div>
        </Row>);
    }

    render(){
        let {previewData, teams, selectTeam, selectChat} = this.props;

        let chatTeamItems = teams.map(team => <ChatTeamItem key={team.id} id={team.id} imgSrc={team.imgSrc} hasNotification={team.hasNotification} active={team.active} onClick={selectTeam} />);
        return (
            <Column horizontal="center" className="chat-view-container-left">
                <div className="chat-view-header-bar-left">Messages</div>
                <Row wrap={true} horizontal="center">{chatTeamItems}</Row>

                {previewData.filter(s => s.chats && s.chats.length > 0).map(section => (
                    <Column key={section.sectionName} className="chat-sidebar-column">
                        {this.renderSectionHeader(section)}
						<div className="all-chat-previews">
	                        {section.chats.map(chat => (
	                            <ChatPreview {...chat} key={chat.id} onClick={selectChat} />
	                        ))}
						</div>
                    </Column>
                ))}

            </Column>
        );
    }
}

const ChatContentNavMemberIcon = props => (<Link to={`/creators/${props.id}`}><MemberIcon {...props} className="chat-nav-icon" orientation="below" tooltip="true"/></Link>);

const Message = ({type, text, profileImgSrc, senderName, senderId = null, showImg = false}) => {
    let img = null;
    let rowPos = "end";

    if (!['sent', 'received', 'system'].includes(type)){
        throw "Invalid message type";
    }
    if (type === "received"){
        rowPos = "start";
        if (showImg){
            img = <ChatContentNavMemberIcon id={senderId} imgSrc={profileImgSrc} name={senderName} />;
        }
        else{
            img = <div className="member-icon-spacer" />
        }
    }
    else if (type === "system"){
        rowPos = "center";
    }

    return (<Row horizontal={rowPos} vertical="end" className={`message-row ${type}`}>
            {img}
            <div className="message-box">
                {text}
            </div>
        </Row>)
};

class ChatContent extends Component {

    prevId = null;
    state = {
        message: "",
    }

    renderChatContentNav(name, type, members = null, memberName = null, memberId = null){

        // TODO: implement member icon onClick when members != null

        // cases:
        // entire team chat (requires members array)
        // individual chat in team (requires memberName, where name -> teamname)
        // join request (no extra args)
        // general chat (no extra args)
        if (!['teamChat', 'memberChat', 'joinRequests', 'general'].includes(type)){
            throw "Invalid type for chat content nav";
        }

        let chatNavExtraContent = null;
        switch (type) {
            case "teamChat":
                // TODO: onclick open member's chat
                chatNavExtraContent = (<div className="member-icons d-flex">
                    <div className="member-icon-label">Members</div>
                    {members.map(ChatContentNavMemberIcon)}
                </div>);
                break;
            case "memberChat":
                chatNavExtraContent = (<div className="member-desc d-flex">
                    <div className="member-desc-text">You're chatting with <Link className="member-name" to={`/creators/${memberId}`}>{memberName}</Link></div>
                </div>);
                break;
            case "joinRequests":
                chatNavExtraContent = (<div className="join-request-options d-flex">
                    {/*TODO: implement buttons not just as links */}
                    <Link to="/requests" className="join-request-decline">{/*ADD FONTAWESOME HERE*/}Decline</Link>
                    <Link to="/requests" className="join-request-accept">Accept</Link>
                </div>);
                break;
        }

        return (
            <div className="chat-content-nav d-flex align-items-center">
                <div className="nav-name">{name}</div>
                {chatNavExtraContent}
            </div>
        );
    }

    scrollToBottom() {
        if (this.chatBox){
            this.messagesEnd.scrollIntoView();
        }
    }

    isBottomAligned(){
        if (!this.chatBox) return false;

        let chatBoxBottom = this.chatBox.getBoundingClientRect().bottom;
        let messageEndBottom = this.messagesEnd.getBoundingClientRect().bottom;
        return Math.abs(chatBoxBottom - messageEndBottom) < 5;
    }

    componentWillUpdate(){
        if (this.props.chatContentData && this.props.chatContentData.messages !== null){
            this.scrollAfterUpdate = this.isBottomAligned() || this.prevId !== this.props.chatContentData.id;
            this.prevId = this.props.chatContentData.id;
        }
    }

    componentDidUpdate(prevProps){
        if (this.scrollAfterUpdate){
            this.scrollToBottom();
        }
        this.scrollAfterUpdate = false;
    }

    handleSubmit(event){
        event.preventDefault();
        let trimmed = this.state.message.trim();
        if (trimmed){
            this.props.sendMessage(trimmed);
            this.setState({message: ""});
        }
    }

    render(){
        if (this.props.chatContentData === null) return null;
        if (utils.objEmpty(this.props.chatContentData)) return <Column className="chat-view-container-right" horizontal="center" vertical="center">
            <h1 className="chat-content-err">You have no general conversations yet!</h1>
            <p>You can initiate a conversation with a creator from their profile page 😁</p>
        </Column>;
        const {id, name, type, members, memberName, memberId, messages} = this.props.chatContentData;

        return (
            <Column className="chat-view-container-right">

                {this.renderChatContentNav(name, type, members, memberName, memberId)}

                <div className="chat-view-main" ref={el => {this.chatBox = el}}>
                    <div className="chat-view-main-area">
                        <Column vertical="end" className="chat-view-main-area-column">
                            {messages === null ? <utils.Spinner /> : messages.map(Message)}
                        </Column>
                    </div>
                    <div style={{ float:"left", clear: "both" }}
                         ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </div>

                <form className="chat-view-message-field" onSubmit={this.handleSubmit.bind(this)}>
                    <Row>
                        <input type="text" className="chat-view-message-field-input" placeholder="Type a message..." value={this.state.message} onChange={e => this.setState({message: e.target.value})} />
                        <button className="chat-view-message-field-send-button" ref="chatSubmitButton">Send</button>
                    </Row>
                </form>
            </Column>
        );
    }
}

export default Chat;
