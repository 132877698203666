// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

const ProgressNavItem = props => {
    let index = props.index;
	let active = props.status  ===  "active" || props.status  ===  "done";
    if (props.status ==="done"){
        index = (<i className="fas fa-check"></i>);
    }

    return (
        <li className={"nav-item" + (active ? " active" : "")}>
            <div className="index-container">
                <div className="index-box">
                    {index}
                </div>
            </div>
            <div className="item-name">{props.name}</div>
        </li>
    );
}

export default ProgressNavItem;
