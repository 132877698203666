import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Card from './Card';
import {Icon} from '@material-ui/core';
import {CreatorSidebar} from '../components/CreatorSidebar';
import PortfolioModal from './PortfolioModal';

import utils from '../utils';
import config from '../config';

const _ = require("lodash");

const MAX_TEAMS = 3;



const ProjectCard = ({
    images,
    title,
    description,
    openModal,
    handleClose,
    handleClick,
    unique_id,
    id,
    hidden = false,
    ...props
}, i) => {
    const style = {
        display: hidden
            ? 'none'
            : null
    };

    let img = "";
    if (images.length > 0) {
        img = utils.image(images[0]);
    }
    return (
        <div>
            <PortfolioModal
                title={title}
                description={description}
                images={images}
                open={openModal === id}
                handleClose={() => handleClose()}
                {...props}/>
            <Card
                id={unique_id}
                title={title}
                description={description ? utils.truncateTo(description, 40) : description}
                img={img}
                images={images}
                onClick={() => handleClick(id, unique_id)}
                style={style}
                {...props}/>
        </div>
    )
};


const IconCard = ({name, mission, images, unique_id, profile_img}) => {
    let img = "";
    if (profile_img) {
        img = utils.prefixImage(profile_img);
    } else if (images.length > 0) {
        img = utils.image(images[0]);
    }

    return (<Link to={`/project/${unique_id}`}>
        <Card id={unique_id} title={name} description={mission ? utils.truncateTo(mission, 110) : ""} img={img} />
    </Link>)
}



class CreatorTopProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: -1,
            toggled: false,
        };

        console.log("top profile was added");
    }

    handleClose() {
        this.setState({openModal: -1});
    }


    handleClick(id, unique_id) {
        this.setState({openModal: id});

        const url = `${config.BACKEND_HOST}/all/fetch_comments`;

        let params = {
            portfolio_project_unique_id: unique_id,
        }

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res)
                    this.setState({
                        comments: res.data.comments,
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    toggle() {
        this.setState(prevState => {
            return {
                toggled: !prevState.toggled
            };
        });
    }

    render() {
        const {page_user, teams_on, portfolio_projects} = this.props;
        let {toggled} = this.state;

        if (typeof(page_user) ==='undefined' || !Object.keys(page_user).length) {
            return;
        }

        if (portfolio_projects.length === 0 && teams_on.length === 0) {
            return <small>There doesn't seem to be anything here</small>;
        }

        return (
            <div className="CreatorTopProfile">

                {teams_on.length > 0 &&
                    <div className="teams-section">
                        <h3>Active Projects</h3>
                        <div className="team-row">
                            {teams_on.map(IconCard)}
                        </div>
                    </div>
                }

                {portfolio_projects.length > 0 ?
                    <div className="teams-section">
                        <h3>Completed Projects</h3>
                        <div className="team-row">
                            {portfolio_projects.map(props => (<ProjectCard
                                {...props}
                                {...this.state}
                                handleClose={this
                                .handleClose
                                .bind(this)}
                                handleClick={this
                                .handleClick
                                .bind(this)}/>))}
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}

export {CreatorTopProfile};
