// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Switch, Route, Redirect} from "react-router-dom";
import Card from '../components/Card';
import Idea from '../components/Idea';
import LoginMenu from '../components/LoginMenu';
import InputError from '../components/InputError.jsx';
import GitHubLogin from 'react-github-login';
import Octicon, {Octoface, MarkGithub} from '@primer/octicons-react';

import '../css/Home.css';

import utils from '../utils';
import config from '../config';

import githubrepo from "../media/githubrepo.png";
import success from "../media/success.png";
import landing from "../media/landingpage.jpg";
import description from "../media/description.png";
import comment1 from "../media/comment1.png";
import comment2 from "../media/comment2.png";
import post from "../media/post.png";

import logolarge from "../media/logo_name_large.png";

import backend from "../media/backend.jpg";
import frontend from "../media/frontend.jpg";
import scripting from "../media/scripting.jpg";
import dataScience from "../media/data_science.jpg";
import mobile from "../media/mobile.jpg";


export class Ideas extends Component {
    render() {

        let commentsList=Object.values({
            0: {
                description: "hello",
                member: {
                    first_name: "Jenny",
                    last_name: "Lim",
                    image: mobile,
                }
            },
        });

        return(
            <Idea
                name="Jelly Bean"
                profileImage={backend}
                youridea={false}
                idea="the best way to party"
                teamName="Jellybean Party"
                upvoteCount={43}
                userUpvote={false}
                comments={commentsList}/>
        );
    }
}

export class InProgress extends Component {
    render() {
        return(
            <div>Active Projects</div>
        );
    }
}

export class CompletedProjects extends Component {
    render() {

        let imageList=Object.values({
            0: backend,
            1: frontend,
            2: scripting,
            3: dataScience,
            4: mobile
        });

        return(
            <div className="cards">

                <Card
                    title="Is This a Feature?"
                    description="Bringing you Premium-Grade Errors and Missing Endslashes"
                    images={imageList}
                    img={imageList[0]}
                    homepage={true}/>
                <Card
                    title="Frontend"
                    description="Making the most beautiful pixel art with a hardcore programming language"
                    images={imageList}
                    img={imageList[1]}
                    homepage={true}/>
                <Card
                    title="Terminal"
                    description="Illness. Yeah, that's what this is."
                    images={imageList}
                    img={imageList[2]}
                    homepage={true}/>
                <Card
                    title="Blobby Bois"
                    description="We used lots of numbers to make these cool blobby bois"
                    images={imageList}
                    img={imageList[3]}
                    homepage={true}/>

            </div>
        );
    }
}

class TestHome extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: "",
            emailErrors: [],
            emailText: "default",
            submitEnabled: true,
            githubStatus: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendActivationEmail = this.sendActivationEmail.bind(this);
        this.GithubSuccess = this.GithubSuccess.bind(this);
        this.GithubFail = this.GithubFail.bind(this);
        this.signupPrompt = this.signupPrompt.bind(this);
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    validateInput(){
        let hasError = false;
        if (!this.state.email){
            this.setState({emailErrors: "Please enter a valid email"});
            hasError = true;
        }
        return !hasError;
    }

    handleSubmit(event){

        console.log("SUBMITTING EMAIL");
        event.preventDefault();
        this.setState({
            emailErrors: [],
        });

        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            event.preventDefault();

            //submit logic here
            let self = this;
            const url = `${config.BACKEND_HOST}/accounts/email_signup`;
            let email = this.state.email;
            let params = {
                email_address: email.toLowerCase()
            };
            utils.post(url, params)
            .then(res => {
                console.log("this is the res:");
                console.log(res);
                if (res.status){
                    self.setState({submitSuccess: true});

                    if (!res.is_registered && !res.is_validated) {
                        self.setState({emailText: "newuser"});
                    }

                    else if (res.is_registered && res.is_validated) {
                        self.setState({emailText: "activated"});
                    }

                    else if (res.is_registered && !res.is_validated) {
                        self.setState({emailText: "registeredonly"});
                    }
                }
                else{
                    throw "Something went wrong."
                }
            })
            .catch(err => {
                console.log(err);
                self.setState({
                    submitEnabled: true,
                    emailErrors: ["Something went wrong. Please try again later."]
                });
            });

        }
    }

    sendActivationEmail() {
        //submit logic here
        let self = this;
        const url = `${config.BACKEND_HOST}/accounts/send_activation_email`;
        let email = this.state.email;

        let params = {
            email_address: email.toLowerCase()
        };

        console.log(email);
        utils.post(url, params)
        .then(res => {
            if (res.status){
                console.log(res);
                self.setState({submitSuccess: true});
            }
            else{
                throw "Something went wrong."
            }
        })
        .catch(err => {
            console.log(err);
            self.setState({
                submitEnabled: true,
                emailErrors: ["Something went wrong. Please try again later."]
            });
        });
    }

    GithubSuccess(response) {
        console.log(response);
        this.setState({
            githubStatus: true
        });

        const url = `${config.BACKEND_HOST}/accounts/github_repos_data_collect`;
        const params = {
            github_code: response.code,
        }
        const self = this;

        utils.post(url, params)
            .then((res) => {
                console.log(res);

                if (res.status && res.token) {
                    if (this.props.signUpLogin(res.token)) {
                        window.location = `http://jouncer.com/activity`;
                    }
                } else if (res.status){
                    self.setState({submitSuccess: true});
                    window.location = `http://jouncer.com/github-signup/0?id=${res.userprofile_unique_id}`;
                }
                throw "Response status false";
            })
            .catch((err) => {
                if (typeof err === "object"){
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    GithubFail(response) {
        console.log(response);
        this.setState({githubStatus: false});
    }

    signupPrompt() {

        const onSuccess = response => this.GithubSuccess(response);
        const onFailure = response => this.GithubFail(response);

        return (
            <div className="github-signup-text">
                <div className="authorize-github">
                    <Octicon className="icon" size='medium' icon={MarkGithub}/>
                    <GitHubLogin
                        className="button"
                        clientId="5d294ab362a4f18f3352"
                        redirectUri=""
                        scope="repo public_repo"
                        buttonText="Sync GitHub"
                        onSuccess={onSuccess}
                        onFailure={onFailure}/>
                </div>

                {this.state.githubStatus === false ? <p className="error">Oh shit, we hit a bug. Try one more time</p> : null}
                {/*<p>Show us your Github and we’ll show you a magic trick ✨</p>*/}
                <p className="email-signup"><Link to="/email-signup">Don't have Github? </Link></p>

            </div>
        );
    }

    githubLoading() {

        return(
            <div className="github-loading">
                <p>Loading Github Repositories&nbsp;
                <Octicon className="icon" icon={Octoface}/>
                </p>
                <p>This will take up to 15 seconds</p>
            </div>
        );
    }


    render() {

        let emailText = "";

        if (this.state.emailText === "default") {
            emailText = "";
        } else if (this.state.emailText === "newuser") {
            emailText = "Check your email for your activation link!";
        } else if (this.state.emailText === "activated") {
            emailText = "You already have an account! Sign in instead.";
        }

        return (
            <div>
                <section className="mainnav-home">
                    <img className="home-logo" src={logolarge} />

                    <Link to="/login" className="login-p">Login</Link>
                </section>


                <div className="homepage homepage-main tandem-main d-flex flex-column justify-content-center">

                    <div className="home-intro row justify-content-center align-items-center">
                        <p className="home-heading">What are you building?</p>
                        {/*<p className="home-subheading">We have brought together people with exceptional skills and drive to create so that we can build more powerful things together than we could build alone.</p>*/}
                    </div>


                    <div className="justify-content-center align-items-center github-signup">

                        {this.state.githubStatus === null ? this.signupPrompt() : this.githubLoading()}

                        <div className="github-signup-illustration">
                            <div className="github-repo">
                                <h2 className="import">Import your project repos with a single login</h2>
                                <h2 className="share">Share your progress with other creators to receive feedback</h2>
                                {/*<div className="github">
                                    <Octicon className="icon" size='medium' icon={MarkGithub}/>
                                    <img src={success} className="success"/>
                                </div>*/}
                                <img src={githubrepo} className="githubrepo"/>
                            </div>

                            <div className="jouncer-content">
                                <div className="project-page">
                                    <img src={description} className="description"/>
                                    <img src={landing} className="landing"/>
                                </div>
                                <div className="updates">
                                    <img src={comment1} className="comment1"/>
                                    <img src={comment2} className="comment2"/>
                                    <img src={post} className="post"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="designers-banner">
                        <div className="text">
                            <h2>Don't have Github because you have the Adobe Creative Suite instead?</h2>
                            <p>Add your design projects and join the design community on Jouncer</p>
                        </div>
                        <div className="illustration">

                        </div>
                    </div>*/}

                    <div className="footer">Contact us at team@jouncer.io</div>

                </div>
            </div>
        );
    }
}

export default TestHome;
