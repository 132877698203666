import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import GitHubLogin from 'react-github-login';
import Octicon, {MarkGithub} from '@primer/octicons-react';

import utils from '../utils';
import config from '../config';


class PleaseSignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
    }

    GithubSuccess(response) {
        console.log(response);
        this.setState({
            githubStatus: true
        });

        const url = `${config.BACKEND_HOST}/accounts/github_repos_data_collect`;
        const params = {
            github_code: response.code,
        }
        const self = this;

        utils.post(url, params)
            .then((res) => {
                console.log(res);

                if (res.status && res.token) {
                    if (this.props.signUpLogin(res.token)) {
                        window.location = `http://jouncer.com/activity`;
                    }
                } else if (res.status){
                    self.setState({submitSuccess: true});
                    window.location = `http://jouncer.com/github-signup/0?id=${res.userprofile_unique_id}`;
                }
                throw "Response status false";
            })
            .catch((err) => {
                if (typeof err === "object"){
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    GithubFail(response) {
        console.log(response);
        this.setState({githubStatus: false});
    }

    render(){

        const onSuccess = response => this.GithubSuccess(response);
        const onFailure = response => this.GithubFail(response);

        return (
            <div className="please-signup">
                <p>Hey! Glad you like this work. Sign up to {this.props.text}.</p>
                <div className="please-signup-buttons">
                    <div className="authorize-github">
                        <GitHubLogin
                            className="button"
                            clientId="5d294ab362a4f18f3352"
                            redirectUri=""
                            scope="read:user user:email user:follow"
                            buttonText="Sign Up with GitHub"
                            onSuccess={onSuccess}
                            onFailure={onFailure}/>
                        <Octicon size="small" className="icon" icon={MarkGithub}/>
                    </div>
                    <Link to="/email-signup">
                        <button className="email">Sign Up with Email</button>
                    </Link>
                </div>
            </div>
        );
    };
};

export default PleaseSignUp;
