// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import Header from './Header';
import NextSection from './NextSection';

class View5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
        };
    }

    componentWillMount() {
        const url = window.location.href;
        const id = url.split('=')[1].split('&')[0];
        this.setState({userId: id});
        // this.importGithubRepos();
    }

    render() {
        const pIndex = 3;
        return (
            <div className="signup-complete-page">
                <h2>You're all done!</h2>
                <Link to="/activity" onClick={() => window.dispatchEvent(new Event('update_dash'))}>
                    <div className="btn btn-primary explore-site-btn">
                        Explore the site
                    </div>
                </Link>
            </div>
        );
    }
}

export default View5;
