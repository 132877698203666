// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import { Cookies } from 'react-cookie';

import InputError from '../InputError.jsx';
import utils from '../../utils';
import config from '../../config';

const View1 = (props) => {
    return (
        <div className="forgot-password-content view1">
            <p className="heading1">Great!</p>
            <p className="heading1">Check your email to reset your password</p>
        </div>
    );
}

export default View1;
