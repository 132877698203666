import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {UserContext} from '../App';
import {Link, Switch, Route, Redirect} from "react-router-dom";
import {Helmet} from 'react-helmet';

// My Dependencies
import MainNav from '../components/MainNav.js';
import SubNav from '../components/SubNav.js';
import WelcomeBanner from '../components/WelcomeBanner.js';
import ActivityBanner from '../components/ActivityBanner.js';
import Card from '../components/Card.js';

import InProgress from '../pages/ProjectsInProgress.js';
import CompletedProjects from '../pages/ProjectsCompletedProjects.js';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');


const PAGES = [
    {
        title: 'Active',
        link: 'active',
        component: InProgress
    }, {
        title: 'Completed',
        link: 'completedprojects',
        component: CompletedProjects
    }
]

class Projects extends Component {
    state = {
        all_teams: null,
        selected: {}
    };

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    async componentDidMount(){
        const {status, data} = await utils.get('/all/teams/query/');
        if (status){
            this.setState({...data});
        }
    }

    renderPage(tab) {
        const Component = tab.component;

        return (
            <Route
                path={`/projects/${tab.link}`}
                render={() => (<Component {...this.props} {...this.state}/>)}/>
        );
    }

    render() {

        return (
            <div className="main">

                <Helmet>
                    <title>Projects – Jouncer</title>
                </Helmet>
                <div className="content" id="TeamsView">

                    {/*<section className="content-section">
                        {this.props.user && this.props.user.new_user_banner
                            ? <FinishProfileBanner />
                            : null}
                    </section>*/}

                    <section className="content-section d-flex justify-content-center">
                        <div className="cards-container">

                            <h2 className="page-title">Projects</h2>

                            <div className="real-subnav">
                                <div className="subnav-tabs">

                                    {PAGES.map(({
                                        title,
                                        link
                                    }) => {

                                        const pathname = document.location.pathname;

                                        const root = pathname.slice(pathname.indexOf("projects") + 9);
                                        const active = root.startsWith(link)
                                            ? "active"
                                            : "";

                                        return (
                                            <Link to={`/projects/${link}`} className={`tab column ${active}`}>{title}</Link>
                                        )
                                    })}

                                </div>
                            </div>

                            <div className="page-content">
                                <Switch>
                                    {PAGES.map(this.renderPage.bind(this))}

                                    <Redirect from="" to={`/projects/${PAGES[0].link}`}/>
                                </Switch>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Projects;
