// TODO: MERGE WITH SIDENAVITEM

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

// utilities
import utils from '../utils';

class ChatTeamItem extends Component {
    constructor(props){
        super(props);
    }
    render() {
        let id = this.props.id;
        let imgSrc = this.props.imgSrc;
        let active = typeof this.props.active !== "undefined" && this.props.active;
        let hasNotification = typeof this.props.hasNotification !== "undefined" && this.props.hasNotification;
        let onClick = this.props.onClick;
        let text = null;

        let backgroundImage;
        if (id === null){
            text = <span className="all-text" title="General Conversations">All</span>;
        }
        else{
            backgroundImage = imgSrc && !imgSrc.startsWith('linear-gradient') ? `url('${imgSrc}')` : utils.randomGradient(id);
        }

        const sideNavImgStyle = {
            backgroundImage,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
        };

        let displayDot = "none";
        if (hasNotification){
            displayDot = "visible";
        }

        return (
            <div className={`chat-view-team-icon d-flex justify-content-center align-items-center ${active ? "active" : ""}`} onClick={() => onClick(id)}>
                <div className="team-icon-bg" style={sideNavImgStyle} ></div>
                <span className="dot" style={{display: displayDot}}></span>
                {text}
            </div>
        );
    }
}

export default ChatTeamItem;
