import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Card from './Card';
import Waveform from './Waveform';
import moment from 'moment'
import AddUpdate from './AddUpdate';
import CommentSection from './CommentSection';
import MemberIcon from './MemberIcon';
import Octicon, {GitCommit, GitMerge, Link as Link2, Telescope} from '@primer/octicons-react';
import Update from '../components/Update';

import placeholderpic from '../media/Harry_Dong.jpg';

import config from '../config';
import utils from '../utils';

const _ = require('lodash');



function removeShade() {
    var element = document.getElementById("shade");
    element.classList.remove("shade");

    var element = document.getElementById("AddUpdate");
    element.classList.remove("bring-to-front");
}

const ActivityProfile = ({
    notUser,
    id,
    githubRepo,
    style,
    activity,
    new_updates,
    is_current_user_team_page,
    files,
    handleUpload,
    handleSubmit,
    ref_,
    user
}) => {

    return (
        <div id="ActivityProfile" className="ActivityProfile TeamPageActivity" style={style}>

            {activity.length === 0 && is_current_user_team_page ?
                <div className="why-this">
                    <Octicon className="telescope" size="medium" icon={Telescope}/>
                    <p>Why this? Share why you're working on this to get featured on the Active Projects page</p>
                </div>
                : null
            }

            <div className="activity-stream">
            <div className="v-centred">
                <div id="tabs" className="tab column active">Activity</div>
            </div>
                {is_current_user_team_page
                    ? <AddUpdate
                            ref={ref_}
                            files={files}
                            handleUpload={handleUpload}
                            handleSubmit={handleSubmit}
                            teamId={id}
                            githubRepo={githubRepo}/>
                    : null}
                    <div id="shade" className="shadeoff" onClick={removeShade}></div>
                <div className="new-updates">
                    {new_updates.map(update => <Update date={[]} member={user.user} {...update} newUpdate={true}/>)}
                </div>
                {activity.map(({
                    updates
                }, i) => {
                    return (
                        <div id={`week-${i}`}>
                            {updates.map(props => <Update notUser={notUser} {...props}/>)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const TABS = [
    {
        title: 'Activity',
        link: '',
        component: ActivityProfile
    },
    // {     title: 'Portfolio',     link: '#portfolio',     component:
    // PortfolioProfile }
]

export class TeamBottomProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            new_updates: [],
            activity: [],
        };
        this.addUpdate = React.createRef();
        this.fetchActivity = this.fetchActivity.bind(this);
    }

    handleScroll() {
        const activity = document.querySelectorAll('[id^="week-"]');
        const waveform = document.getElementById('Waveform');

        if (!waveform){
            return;
        }

        Array
            .from(waveform.children)
            .forEach((el, i) => {
                if (i > 0) {
                    el
                        .classList
                        .remove('highlight');
                }
            })
        const weeksWithActivity = Array
            .from(activity.values())
            .map((el, i) => {
                if (el.children.length > 0 && i < waveform.children.length) {
                    return i;
                }
            })
            .filter(val => val !== undefined);

        if (weeksWithActivity.length === 0) {
            return;
        }

        const weeksInView = weeksWithActivity.filter(i => utils.isScrolledIntoView(activity[i]));

        let last;
        if (weeksInView.length > 0) {
            last = weeksInView[weeksInView.length - 1];
        } else {
            last = weeksWithActivity[weeksWithActivity.length - 1];
        }

        if (last != 60) {
            waveform
                .children[last + 1]
                .classList
                .add('highlight');
        }

    }

    componentWillMount(){
        window.onscroll = this.handleScroll.bind(this);
        this.fetchActivity();
    }

    componentWillUnmount() {
        window.onscroll = undefined;
    }

    handleUpload(values) {
        this.setState({files: values});
    }

    async fetchActivity() {
        let url = `${config.BACKEND_HOST}/all/team_activity`;
        let params = {
            page_team_uuid: this.props.id,
        }
        const self = this;

        await utils.get(url, params)
            .then((res) => {
                console.log(res);
                if (res.status){
                    self.setState({
                        activity: res.data.activity,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleUpdateSubmit(selectedTags, selectedCommits) {
        const {user} = this.props;
        const {files} = this.state;
        const {text} = this.addUpdate.current.state;
        this.addUpdate
            .current
            .clear();

        let tags = [];
        selectedTags.forEach(
            tag => {tags.push(tag.unique_id)}
        );

        let commits = [];
        selectedCommits.forEach(
            commit => {commits.push(commit.unique_id)}
        );

        let params = {
            team_uuid: this.props.team.unique_id,
            post_description: text,
            selected_tags: tags,
            selected_commits: commits
        };

        if (files && files.length > 0) {
            params.uploaded_file = files[0];
        }

        const self = this;
        utils.postMultipart(`${config.BACKEND_HOST}/teams/post_update`, params)
            .then((res) => {
                if (!res.ok) throw "Error with request";
                self.setState(prevState => {
                    return {
                        new_updates: [
                            {
                                tags: selectedTags,
                                member__first_name: user.user.first_name,
                                member__last_name: user.user.last_name,
                                member__userprofile__unique_id: user.unique_id,
                                member__media__media_link: user.user.images[0].media_link,
                                viewed: false,
                                media__media_link: files && files.length > 0 ? files[0].preview : "",
                                isUpload: true,
                                description: text,
                                commits: selectedCommits
                            },
                            ...prevState.new_updates
                        ]
                    }
                });
            }).catch(err => console.log(err));
    }

    render() {
        const {team} = this.props;
        if (typeof(team) === 'undefined' || !Object.keys(team).length) {
            return;
        }

        return (
            <div className="TeamBottomProfile">

                <div className="component">

                    <ActivityProfile
                        {...this.props}
                        {...this.state}
                        githubRepo={team.github_repo_id}
                        handleUpload={this
                        .handleUpload
                        .bind(this)}
                        handleSubmit={this
                        .handleUpdateSubmit
                        .bind(this)}
                        ref_={this.addUpdate}/>
                </div>
            </div>
        )
    }
}
