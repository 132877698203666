// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import Badge from "../Badge.js";
import SkillsMenu from '../SkillsMenu.jsx';
import Header from './Header';
import NextSection from './NextSection';

import Octicon, {Octoface} from '@primer/octicons-react';

import config from "../../config.js";
import utils from "../../utils.js";
const uniqueString = require('unique-string');
const _ = require("lodash");



class View3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            skillBuckets: [],
            userId: "",
            loading: false,
        };
        this.handleBucketClick = this
            .handleBucketClick
            .bind(this);
        this.handleSkillClick = this
            .handleSkillClick
            .bind(this);
        this.addSkill = this
            .addSkill
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);
    }

    componentWillMount() {
        const url = window.location.href;
        const id = url.split('=')[1].split('&')[0];
        this.setState({userId: id});
        // this.importGithubRepos();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getData();
    }

    getData() {
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
        const params = {
            userprofile_unique_id: this.state.userId,
        }

        utils.get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    let {skills, all_skill_buckets} = res.data;
                    self.setState({
                        skillBuckets: all_skill_buckets
                            .filter(bucket => !bucket.hidden)
                            .map(bucket => {
                                let hasActiveSkill = false;
                                bucket.id = bucket.unique_id;
                                bucket.skills = bucket
                                    .skills
                                    .filter(skill => !skill.hidden) // not hidden AND (if user created, display if user has that skill)
                                    .map(skill => {
                                        skill.key = skill.id;
                                        skill.label = skill.name;
                                        skill.value = skill
                                            .id
                                            .toString();
                                        skill.active = skills.indexOf(skill.id) >= 0;
                                        hasActiveSkill = hasActiveSkill || skill.active;
                                        return skill;
                                    });
                                bucket.active = hasActiveSkill;
                                return bucket;
                            })
                    });
                }
            })
            .catch(err => {
                console.log(err.message);
                //try again?
            });
    }
    handleBucketClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (id == bucket.id) {
                        bucket.active = !bucket.active;
                    }
                    return bucket;
                });
            return prevState;
        });
    }
    handleSkillClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    bucket.skills = bucket.skills.map(skill => {
                        if (id == skill.id) {
                            skill.active = !skill.active;
                        }
                        return skill;
                    });
                    return bucket;
                });
            return prevState;
        });
    }
    addSkill(name, bucketId){
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (bucket.id === bucketId){
                        bucket.skills.push({
                            id: uniqueString(),
                            name: name,
                            active: true,
                            isNew: true,
                        });
                    }
                    return bucket;
                });
            return prevState;
        });

        const self = this;
        const url = `${config.BACKEND_HOST}/all/add_skill`;
        const params = {
            add_skill: true,
            new_skill: name,
            bucket_uuid: bucketId,
            userprofile_unique_id: this.state.userId,
        };
        utils
            .get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState(prevState => {
                        prevState.skillBuckets = prevState
                            .skillBuckets
                            .map(bucket => {
                                if (bucket.id === bucketId){
                                    bucket.skills = bucket.skills.map(skill => {
                                        if (skill.name === name){
                                            skill.id = res.data.skill_id;
                                        }
                                        return skill;
                                    });
                                }
                                return bucket;
                            });
                        return prevState;
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.submitEnabled) {
            this.setState({
                submitEnabled: false,
                loading: true
            });

            let skillIds = [];
            this
                .state
                .skillBuckets
                .filter(bucket => bucket.active)
                .forEach(bucket => {
                    skillIds = _.concat(skillIds, bucket.skills.filter(skill => skill.active && _.isInteger(skill.id)).map(skill => skill.id));
                });

            const self = this;
            const url = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
            const params = {
                selected_skills: skillIds,
                userprofile_unique_id: this.state.userId,
            };
            utils
                .post(url, params)
                .then(res => {
                    console.log("submitting");
                    console.log(res);
                    if (res.status) {
                        self.setState({
                            submitSuccess: true,
                            loading: false
                        });
                    }
                })
                .catch(err => {
                    console.log(err.message);
                    self.setState({submitEnabled: true});
                    //try again?
                });
        }
    }

    nextSectionConfirm() {
        console.log("finished skills")
    }

    render() {
        const pIndex = 2;
        if (this.state.submitSuccess) {
            return <Redirect to={`${(pIndex + 1).toString()}?id=${this.state.userId}`} push />;
        }

        return (
            <div className="github-signup-page d-flex justify-content-center align-items-center">
                <form action={(pIndex + 1).toString()} onSubmit={this.handleSubmit}>
                    <input type="hidden" name="id" value={this.state.userId} />

                    <Header title="Skills" subtitle="We’ve already selected the languages we could pull from your Github repo. Add any other skills you’ve learned throughout the years."/>

                    <div className="content">
                        <SkillsMenu
                            skillBuckets={this.state.skillBuckets}
                            handleBucketChange={this.handleBucketClick}
                            handleSkillChange={this.handleSkillClick}
                            addSkill={this.addSkill} />

                        <NextSection nextTitle={"Join the Community"} nextDescription={"That’s the end of the magic trick! Join Jouncer and see what other people are making."} nextSectionConfirm={this.nextSectionConfirm}/>

                        {this.state.loading === true ?
                            <div className="github-loading">
                                <p>Loading Your Skills from Github&nbsp;
                                <Octicon className="icon" icon={Octoface}/>
                                </p>
                            </div>
                        : null}

                    </div>
                </form>
            </div>
        );
    }
}

export default View3;
