// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import { Cookies } from 'react-cookie';

import InputError from '../InputError.jsx';
import utils from '../../utils';
import config from '../../config';

const _ = require("lodash");
const v = require('validator');
const pIndex = 0;

export default class View0 extends Component {
    constructor(props){
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            email: "",
            emailErrors: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
            // [event.target.name + "Errors"]: []
        });
    }
    validateInput(){
        const {email} = this.state;
        if (!v.isEmail(email)){
            let emailErrors = ["Please enter a valid email"];
            this.setState({emailErrors});
            return false;
        }
        return true;
    }
    handleSubmit(event){
        event.preventDefault();
        this.setState({emailErrors: []});
        if (this.state.submitEnabled && this.validateInput()){
            this.setState({submitEnabled: false});
            //submit logic here
            const url = `${config.BACKEND_HOST}/accounts/forgot_password`;
            const self = this;
            utils.post(url, {email: this.state.email.toLowerCase()})
                .then((res) => {
                    console.log(res);
                    if (res.status){
						self.setState({submitSuccess: true});
                    }
                })
                .catch((err) => {
                    let msg = [];
                    if (typeof err ==="object"){
                        const body = err.response.body;
                        console.log(body);
                        if (body.message){
                            msg.push(body.message);
                        }
                        else{
                            msg.push("Something went wrong. Please try again later.");
                        }
                    }
                    else{
                        console.log(err);
                        msg.push(err);
                    }
                    self.setState({
                        submitEnabled: true,
                        inviteCodeErrors: msg
                    });
                    //error logic
                });
        }
    }
    render() {
        if (this.state.submitSuccess){
            return (<Redirect to={(pIndex + 1).toString()} push />);
        }
        return (
            <div className="forgot-password-content view0">
                <p className="heading1">Forgot your password?</p>
                <p className="heading2">Enter your email below to reset your password</p>
                <form id="forgot-password-form-0" action="/forgot-password/1" onSubmit={this.handleSubmit} noValidate>
                    <div className="mx-auto group">
                        <input name="email" type="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" className={"input input-add-outline " +
                            (this.state.emailErrors.length > 0 ? "error" : "")}  />
                        <button name="submit" type="submit" value="Submit" className="btn btn-secondary">Send via Email</button>
                        <br />
                        <InputError errors={this.state.emailErrors} />
                    </div>
                </form>
            </div>
        );
    }
}
