// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';

import MediaItem from "./MediaItem.jsx";
import config from "../config.js";
import utils from '../utils';

const uniqueString = require('unique-string');

export default class MediaItemContainer extends Component{
    constructor(props) {
        super(props);

        this.onDrop = this.onDrop.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }
    onDrop(accepted, rejected) {
        let items = this.props.items;
        accepted.forEach(item => {
            if (!("id" in item)){
                item.id = uniqueString();
            }
            items.push(item);
        });
        this.props.handleUploadChange(items, this.props.teamId);
    }
    toggleActive(id){
        this.props.handleUploadChange(this.props.items.map(item => {
            item.active = false;
            if (item.id  ===  id){
                if ("active" in item){
                    item.active = !item.active;
                }
                else{
                    item.active = true;
                }
            }
            return item;
        }));
    }
    removeItem(id, unique_id){
        this.props.handleDelete(this.props.items.filter(item => item.id === id), unique_id);
    }
    render(){
        let items = this.props.items.map(item => {
            let src = "";
            if ("media_link" in item){
                src = utils.image(item);
            }
            else{
                src = item.preview;
            }
            return <MediaItem key={item.id} id={item.id} unique_id={this.props.unique_id} img={src} hideCheck={this.props.hideCheck} remove={this.removeItem} toggleActive={this.toggleActive} active={("active" in item) ? item.active : false} />;
        });

        let rowClasses = "";
        if (this.props.isSmall){
            rowClasses = " small";
        }
        return (
            <div className={"media-item-row" + rowClasses}>

                {items}

                <Dropzone onDrop={this.onDrop} accept="image/jpeg, image/png, image/gif" className="add-media-box d-flex justify-content-center align-items-center">
                    {({ isDragActive, isDragReject }) => {
                        if (isDragActive) {
                            return (
                                <div className="upload-media-view">
                                    <i className="fal fa-plus"></i>
                                    <p className="large upload-text">Upload</p>
                                </div>
                            );
                        }
                        if (isDragReject) {
                            return (
                                <div>
                                    <p className="large">Invalid File</p>
                                </div>
                            );
                        }
                        return (
                            <div className="upload-media-view">
                                <i className="fal fa-plus"></i>
                                <p>Images & GIFs</p>
                            </div>
                        );
                    }}
                </Dropzone>
            </div>
        );
    }
}
