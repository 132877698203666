// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import S3FileUpload from 'react-s3';

import config from "../../config.js";
import utils from "../../utils.js";
import Badge from "../Badge.js";
import Card from "../Card.js";
import PortfolioItem from "../PortfolioItem.jsx";
import SkillsMenu from '../SkillsMenu.jsx';

import Octicon, {Octoface} from '@primer/octicons-react';

import Header from './Header';
import NextSection from './NextSection';

const uniqueString = require('unique-string');
const _ = require("lodash");



const ProjectCard = ({
    id,
    title,
    titleErrors,
    description,
    link,
    skills,
    skillBuckets,
    editSkills,
    media,
    newMedia,
    isNew,
    changed,
    save,
    remove,
    handleChange,
    handleMediaChange,
    handleClick,
    handleClose,
    handleSave,
    handleDelete,
    openModal,
    removeItem,
    hidden = false,
}, i) => {
    const style = {
        display: hidden
            ? 'none'
            : null
    };
    let img = "";

    if (media.length > 0 && media[0].preview) {
        img = media[0].preview;
    } else if (media.length > 0) {
        img = utils.image(media[0]);
    }

    return (
        <div>
            <PortfolioItem
                id={id}
                title={title}
                titleErrors={titleErrors}
                description={description}
                link={link}
                skills={skills}
                skillBuckets={skillBuckets}
                editSkills={editSkills}
                media={media}
                isNew={isNew}
                changed={changed}
                save={save}
                remove={remove}
                handleChange={handleChange}
                handleMediaChange={handleMediaChange}
                handleClick={handleClick}
                handleClose={handleClose}
                handleSave={handleSave}
                handleDelete={handleDelete}
                openModal={openModal}
                open={openModal === id}/>

            <Card
                id={id}
                title={title}
                description={description ? utils.truncateTo(description, 40) : description}
                img={img}
                images={media}
                newMedia={newMedia ? true : false}
                onClick={() => handleClick(id)}
                style={style}
                removeItem={removeItem}
                remove={true}/>
        </div>
    )
};



const SkillsModal = (props) => {
    const {isOpen, toggle} = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="skills-modal modal-dialog-centered modal-lg">
            <ModalHeader toggle={toggle}>Select the skills you used for this project</ModalHeader>
            <ModalBody>
                <SkillsMenu {...props} />
            </ModalBody>
        </Modal>
    );
}


class View2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            saved: false,
            userId: "",
            portfolioItems: [],
            skillBuckets: [],
            newMediaLinks: [],
            modal: false,
            uploading: false,
            activeItem: "",
            openPortfolioModal: -1,
            githubAuthorized: false,
            unusedRepos: [],
            newImportedProjects: [],
            loading: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleBucketClick = this.handleBucketClick.bind(this);
        this.handleSkillClick = this.handleSkillClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getUnusedRepos = this.getUnusedRepos.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.imageUpload = this.imageUpload.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.saveNotif = this.saveNotif.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.getSkills = this.getSkills.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getData();
        this.getUnusedRepos();
        this.getSkills();
    }

    getData() {
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/projects_data`;
        const params = {
            userprofile_unique_id: this.state.userId,
        }

        utils.get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        portfolioItems: res
                            .data
                            .projects
                            .map(project => ({
                                ...project,
                                unique_id: project.unique_id,
                                link: project.external_link,
                                skills: _.flatten(project.project_skills.map(b => b.skills.map(s => s.id))),
                                media: project.media,
                                isNew: false,
                                changed: false
                            }))
                    });
                    self.addItem();
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    getSkills() {
        const url = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
        const self = this;

        const params = {
            userprofile_unique_id: this.state.userId,
        }
        utils
            .get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    let {skills, all_skill_buckets} = res.data;
                    self.setState({
                        skillBuckets: all_skill_buckets
                            .filter(bucket => !bucket.hidden)
                            .map(bucket => {
                                let hasActiveSkill = false;
                                bucket.id = bucket.unique_id;
                                bucket.skills = bucket
                                    .skills
                                    .filter(skill => !skill.hidden && (!skill.is_user_added || skills.indexOf(skill.id) >= 0)) // not hidden AND (if user created, display if user has that skill)
                                    .map(skill => {
                                        skill.key = skill.id;
                                        skill.label = skill.name;
                                        skill.value = skill
                                            .id
                                            .toString();
                                        skill.active = skills.indexOf(skill.id) >= 0;
                                        hasActiveSkill = hasActiveSkill || skill.active;
                                        return skill;
                                    });
                                bucket.active = hasActiveSkill;
                                return bucket;
                            })
                    });
                }
            })
            .catch(err => {
                console.log(err.message);
                //try again?
            });
    }

    componentWillMount() {
        const url = window.location.href;
        const id = url.split('=')[1].split('&')[0];
        this.setState({userId: id});
    }

    openModal(itemId){
        //transfer skills state
        let {skills} = _.find(this.state.portfolioItems, {unique_id: itemId});
        this.setState({
            modal: true,
            activeItem: itemId,
            skillBuckets: this.state.skillBuckets
                .map(bucket => {
                    let hasActiveSkill = false;
                    bucket.skills = bucket
                        .skills
                        .map(skill => {
                            skill.active = skills.indexOf(skill.id) >= 0;
                            hasActiveSkill = hasActiveSkill || skill.active;
                            return skill;
                        });
                    bucket.active = hasActiveSkill;
                    return bucket;
                }),
        });
    }
    closeModal(){
        // save skills to portfolio project
        let skills = [];
        this.state.skillBuckets.forEach(b => {
            b.skills.forEach(s => {
                if (s.active){
                    console.log(s.name);
                    skills.push(s.id);
                }
            });
        });

        this.setState({
            modal: !this.state.modal,
            portfolioItems: this.state.portfolioItems.map(p => {
                if (p.unique_id == this.state.activeItem){
                    p.skills = skills;
                    p.changed = true;
                }
                return p;
            }),
            skillBuckets: this.state.skillBuckets
                .map(bucket => {
                    bucket.active = false;
                    bucket.skills = bucket
                        .skills
                        .map(skill => {
                            skill.active = false;
                            return skill;
                        });
                    return bucket;
                }),
        });
    }
    handleBucketClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (id == bucket.id) {
                        bucket.active = !bucket.active;
                    }
                    return bucket;
                });
            return prevState;
        });
    }

    handleSkillClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    bucket.skills = bucket.skills.map(skill => {
                        if (id == skill.id) {
                            skill.active = !skill.active;
                        }
                        return skill;
                    });
                    return bucket;
                });
            return prevState;
        });
    }

    getDescription(desc, readme) {
        let description = "";

        if (readme) {
            let readmeshort = readme.split("\n");
            let newstring = '';

            // returns the first 6 "paragraphs" in the readme (includes blank lines for space)
            for (let i = 0; i < readmeshort.length && i < 6; i++) {
                newstring = newstring + '\n' + readmeshort[i];
            }

            // stripping readme of markdown symbols, mainly #, links, and images
            readme = newstring.replace(/# /g, '').replace(/#/g, '').replace(/<.*?>/g, '').replace(/[!](?!' ').*?[)]/g, '');
        }

        if (desc) {
            if (readme) {
                description = desc + '\n' + readme;
                return description;
            }
            return desc;
        } else if (readme) {
            description = readme;
            return description;
        } else {
            return "";
        }
    }

    handleMediaChange(values, id = undefined, isNew) {
        const config2 = {
            bucketName: 'stagingjouncerfrontend',
            dirName:'static/media',
            region: 'us-east-2',
            accessKeyId: 'AKIAYXZCLXN55N2AWJZO',
            secretAccessKey: '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',
        }

        //submit logic here
        const url = `${config.BACKEND_HOST}/accounts/add_project_image`;
        const self = this;

        S3FileUpload
        .uploadFile(values[values.length - 1], config2)
        .then(data => {
            console.log("s3 file uploaded properly", data);

            if (isNew) {
                let newMediaList = this.state.newMediaLinks;
                newMediaList.push(data.location);

                self.setState({
                    portfolioItems: this
                        .state
                        .portfolioItems
                        .map(item => {
                            if (item.unique_id  ===  id) {
                                item["media"] = values;
                                item.newMedia = true;
                                item.changed = true;
                            }
                            return item;
                        }),
                    newMediaLinks: newMediaList });
            } else {
                const params = {
                    project_uuid: id,
                    uploaded_url: data.location
                };

                utils.postMultipart(url, params)
                    .then(raw => raw.json())
                    .then((res) => {
                        console.log(res);
                        this.setState({
                            portfolioItems:
                                this.state
                                .portfolioItems
                                .map(item => {
                                    if (item.unique_id  ===  id) {
                                        item["media"] = values;
                                        item.newMedia = true;
                                        item.changed = true;
                                    }
                                    return item;
                                }),
                            submitEnabled: true
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        self.setState({submitEnabled: true});
                    });
            }
        })
        .catch(err => console.error(err));
    }

    imageUpload(values, id = undefined) {
        const url = `${config.BACKEND_HOST}/accounts/add_project_image`;
        const self = this;

        values.map( link => {
            const params = {
                project_uuid: id,
                uploaded_url: link
            };

            utils.postMultipart(url, params)
                .then(raw => raw.json())
                .then((res) => {
                    console.log(res);
                    self.setState({submitEnabled: true});
                })
                .catch((err) => {
                    console.log(err);
                    self.setState({submitEnabled: true});
                    //error logic
                });
        })
    }

    handleChange(name, value, id = undefined) {
        this.setState({
            portfolioItems: this
                .state
                .portfolioItems
                .map(item => {
                    if (item.unique_id  ===  id) {
                        item[name] = value;
                        item.changed = true;
                        if (name == "media") {
                            item.newMedia = true;
                        }
                    }
                    return item;
                })
        });
    }

    handleDelete(values, unique_id) {

        this.setState({
            portfolioItems:
                this.state
                .portfolioItems
                .map(item => {
                    if (item.unique_id  ===  unique_id) {
                        item["media"] = item.media.filter(media => media.unique_id !== values[0].unique_id);
                        item.newMedia = true;
                        item.changed = true;
                    }
                    return item;
                }),
            });

        const url = `${config.BACKEND_HOST}/teams/delete_image`;
        const self = this;

        const params = {
            image_uuid: values[0].unique_id
        };

        utils.postMultipart(url, params)
            .then(raw => raw.json())
            .then((res) => {
                console.log(res);
                self.setState({submitEnabled: true});
            })
            .catch((err) => {
                if (typeof err ==="object"){
                    console.log(err.response.body);
                }
                else{
                    console.log(err);
                }
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    submitClick(event) {
        event.preventDefault();
        if (this.state.submitEnabled && (event.target.name  ===  "submit" || event.target.name  ===  "save")) {
            this
                .state
                .portfolioItems
                .forEach(item => {
                    if (item.changed) {
                        this.saveItem(item.unique_id);
                    }
                });

            this.saveNotif();
            if (event.target.name  ===  "submit") {
                const self = this;
                let checkSuccess = setInterval(() => {
                    if (self.state.portfolioItems.filter(item => item.changed).length  ===  0) {
                        self.setState({submitSuccess: true});
                        clearInterval(checkSuccess)
                    }
                }, 500);
            }
        }
    }

    addItem() {
        let items = this.state.portfolioItems;
        items.push({
            unique_id: uniqueString(),
            title: "",
            description: "",
            link: "",
            skills: [],
            media: [],
            isNew: true,
            changed: false,
        });
        this.setState({portfolioItems: items});
    }

	validateItem(item){
		if (!item.title || item.title.length === 0){
			item.titleErrors = ["Please give your project a title"];
			return false;
		}
		return true;
	}

    saveNotif() {
        this.setState({saved: true});

        setTimeout(() => {
            this.setState({
            saved: false
          })
      }, 3000);
    }

    saveItem(id, newProject) {

        if (this.state.submitEnabled) {
            this.setState({submitEnabled: false});
            this.setState({
				portfolioItems: this.state.portfolioItems.map(item => {

                    if (item.unique_id  ===  id) {

                        if (!this.validateItem(item)) {
                            return item;
                        }

                        //api call
                        const self = this;
                        const url = `${config.BACKEND_HOST}/accounts/projects_data`;

                        let params = {
                            userprofile_unique_id: this.state.userId,
                            project_title: item.title,
                            project_description: item.description,
                            project_link: item.link,
                            selected_skills: item.skills,
                            existing_file_uuids: item
                                .media
                                .filter(m => "unique_id" in m)
                                .map(m => m.unique_id)
                        };

                        if (!newProject) {
                            params.project_unique_id = id;
                        }

                        if (!item.isNew) {
                            params.project_uuid = item.unique_id;
                        }

                        utils.postMultipartNoToken(url, params)
                            .then(raw => raw.json())
                            .then(res => {
                                if (res.status) {
                                    if (item.isNew) {
                                        this.addItem();
                                        if (this.state.newMediaLinks.length > 0) {
                                            this.imageUpload(this.state.newMediaLinks, res.project.unique_id);
                                        }
                                    }
                                    this.saveNotif();
                                    self.setState({
                                        portfolioItems: self
                                            .state
                                            .portfolioItems
                                            .map(item => {
                                                if (item.unique_id  ===  id) {
                                                    item.unique_id = res.project.unique_id; //insert returned ID here
                                                    item.isNew = false;
                                                    item.changed = false;
                                                }
                                                return item;
                                            })
                                    });

                                }
                            })
                            .catch(err => {
                                console.log(err);
                            })
                            . finally(() => self.setState({submitEnabled: true}));
                    }
					return item;
                })
			});
        }
    }

    removeItem(id) {
        let item = this
            .state
            .portfolioItems
            .filter(item => item.unique_id  ===  id)[0];
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/delete_project`;
        const params = {
            userprofile_unique_id: this.state.userId,
            project_unique_id: item.unique_id
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        portfolioItems: self
                            .state
                            .portfolioItems
                            .filter(item => item.unique_id != id)
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });

    }

    handleClose(id, newProject) {
        this.setState({openPortfolioModal: -1});
        this.setState({submitEnabled: true});
        this.saveItem(id, newProject);
    }

    handleSave(id, newProject) {
        this.setState({submitEnabled: true});
        this.saveItem(id, newProject);
    }

    handleClick(id) {
        this.setState({openPortfolioModal: id});
    }

    getUnusedRepos() {
        const url = `${config.BACKEND_HOST}/accounts/unused_repos`;
        const params = {
            userprofile_unique_id: this.state.userId,
        }
        const self = this;

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    let newUnusedRepos = res.data.github_repos;
                    for (let i = newUnusedRepos.length - 1; i >= 0; i--){
                        if ( newUnusedRepos[i] && newUnusedRepos[i].already_used === true) {
                           newUnusedRepos.splice(i, 1);
                       }
                    }

                    this.setState({ unusedRepos: newUnusedRepos });
                    return;
                }
                throw "Response status false";
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
            });
    }

    selectedGithubRepo(id) {

        let newUnusedRepos = this.state.unusedRepos;
        for (let i = 0; i < newUnusedRepos.length; i++){
           if ( newUnusedRepos[i].id === id) {
               newUnusedRepos.splice(i, 1);
               this.setState({unusedRepos: newUnusedRepos});
           }
        }

        this.setState({ uploading: true });

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/import_project_from_github`;
        const params = {
            userprofile_unique_id: this.state.userId,
            repo_id: id
        }

        utils.post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({ uploading: false });
                    self.setState(prevState => {

                        return {
                            portfolioItems: [
                                ...prevState.portfolioItems,
                                {
                                    ...res.data.project,
                                    skills: _.flatten(res.data.project.project_skills.map(b => b.skills.map(s => s.id))),
                                }
                            ]
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    nextPage(event) {
        event.preventDefault();

        this.setState({loading: true});
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/projects_data`;
        const params = {
            userprofile_unique_id: this.state.userId,
            import_skills: true
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState({
                        submitSuccess: true,
                        loading: false
                    });
                    console.log(":)");
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }

    nextSectionConfirm() {
        console.log("finished completed projects")
    }

    render() {
        const pIndex = 1;
        if (this.state.submitSuccess) {
            return <Redirect to={`${(pIndex + 1).toString()}?id=${this.state.userId}`} push />;
        }

        return (
            <div className="github-signup-page d-flex justify-content-center align-items-center">
                <form action={(pIndex + 1).toString()} onSubmit={this.nextPage}>
                    <input type="hidden" name="id" value={this.state.userId} />

                    <Header title="Past Projects" subtitle="We’ve automatically added your most recent completed projects from Github. If you see one that we missed, you can always add custom projects."/>

                    <div className="content">
                        <h2>Completed Projects</h2>
                        <div className="margin-bottom-30 completed-projects-cards">
                            {this.state
                                .portfolioItems
                                .map(item => (
                                    <ProjectCard
                                        id={item.unique_id}
                                        title={item.title}
										titleErrors={item.titleErrors}
                                        description={item.description}
                                        link={item.link}
                                        skills={item.skills}
                                        skillBuckets={this.state.skillBuckets}
                                        editSkills={this.openModal}
                                        media={item.media}
                                        newMedia={item.newMedia}
                                        isNew={item.isNew}
                                        handleDelete={this.handleDelete}
                                        changed={item.changed}
                                        remove={this.removeItem}
                                        handleChange={this.handleChange}
                                        handleMediaChange={this.handleMediaChange}
                                        handleClick={this.handleClick}
                                        handleClose={this.handleClose}
                                        handleSave={this.handleSave}
                                        openModal={this.state.openPortfolioModal}
                                        removeItem={this.removeItem} />
                                ))}
                        </div>

                        <SkillsModal
                            isOpen={this.state.modal}
                            toggle={this.closeModal}
                            skillBuckets={this.state.skillBuckets}
                            handleBucketChange={this.handleBucketClick}
                            handleSkillChange={this.handleSkillClick}
                            />

                        {this.state.uploading === true ?
                            <div className="importing-project-badge">
                                <p>Importing Project...</p>
                            </div>
                        : null}

                        {this.state.unusedRepos.length > 0 ?
                            <div className="unused-repos">
                                <h3>Additional Repos</h3>
                                <p>Do you want to add any of these to your list of completed projects?</p>
                                <div className="github-project-chips">
                                    {this.state.unusedRepos.map(
                                        ({id, name, full_name}) =>
                                        <div id={name} className="project-chip" onClick={() => this.selectedGithubRepo(id)}>{name}</div>
                                    )}
                                </div>
                            </div>
                        : null }

                        <NextSection nextTitle="Skills" nextDescription="We’ve analyzed your Github repos and compiled the languages you’re most proficient at." nextSectionConfirm={this.nextSectionConfirm}/>

                        {this.state.loading === true ?
                            <div className="github-loading">
                                <p>Loading Your Skills from Github&nbsp;
                                <Octicon className="icon" icon={Octoface}/>
                                </p>
                            </div>
                        : null}

                    </div>
                </form>
            </div>
        );
    }
}

export default View2;
