import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import CommentSection from '../components/CommentSection';
import MemberIcon from './MemberIcon';
import Linkify from 'react-linkify';
import PleaseSignUp from './PleaseSignUp';
import Octicon, {GitCommit, GitMerge, Link as Link2, Flame} from '@primer/octicons-react';

import utils from '../utils';
import config from '../config';



const GithubCommits = props => {
    let {commits, newUpdate} = props;

    return (
        <div className="commits">
            {commits.map(commit => {

                let message = "";
                let branch = "";
                let link = "";

                if (newUpdate === true) {
                    message = commit.message;
                    branch = commit.branch_name;
                    link = commit.external_link;
                } else {
                    message = commit.commit__message;
                    branch = commit.commit__branch_name;
                    link = commit.commit__external_link;
                }

                return <div className="commit">
                    {message.split(" ", 2)[0] === "Merge" ? <Octicon className="git-merge" icon={GitMerge}/> : <Octicon className="git-commit" icon={GitCommit}/>}
                    <div className="info">
                        <p className="message">{message}</p>
                        <p className="branch">
                            Branch <div className="branch-name">{branch}</div>
                        </p>
                    </div>
                    {link ?
                        <a href={link} target="_blank" className="link">
                            <Octicon className="git-link" icon={Link2}/>
                        </a>
                    : null }
                </div>
            })}
        </div>
    );
}


const UpdaterIcon = props => {

    let {id, firstName, lastName, image} = props;

    return (
        <div className="updater-icon">
            <MemberIcon
                className="MemberIcon"
                id={id}
                first={firstName}
                name={`${firstName} ${lastName}`}
                imgSrc={utils.prefixImage(image)}/>
        </div>
    );
};


class Update extends Component {

    constructor(props){
        super(props);
        this.initialBackground = "";

        if (props.images.length > 0) {
            this.images = this.generateImgImages();
            this.initialBackground = props.newMedia ? props.images[0].preview : utils.image(props.images[0]);
        }

        this.state = {
            index: -1,
            active: false,
            metricActive: false,
            metricNumber: 0,
            upvoterList: [],
            showSignUp: false,
            background: this.initialBackground,
        }

        this.metricClick = this.metricClick.bind(this);
        this.handleUpvoteSubmit = this.handleUpvoteSubmit.bind(this);
        this.getUpvoteList = this.getUpvoteList.bind(this);
        this.renderUpdateImage = this.renderUpdateImage.bind(this);
    }

    componentDidMount() {
        if (this.props.upvote_count > 0) {
            this.getUpvoteList();
        }
        this.setState({
            metricNumber: this.props.upvote_count,
            metricActive: this.props.user_upvote
        });
    }

    getUpvoteList() {
        const url = `${config.BACKEND_HOST}/all/upvoter_list`;
        const self = this;

        const params = {
            team_update_unique_id: this.props.unique_id
        }

        utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res);
                }

                self.setState({
                    upvoterList: res.data.upvoters,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }


    metricClick() {
        if (this.props.notUser) {
            this.setState({showSignUp: true});
        } else {
            if (this.state.metricActive == 0) {
                this.handleUpvoteSubmit(true);
                this.setState({ metricActive: 1, metricNumber: this.state.metricNumber + 1 });

            } else if (this.state.metricActive >= 1) {
                this.handleUpvoteSubmit(false);
                this.setState({ metricActive: 0, metricNumber: this.state.metricNumber - 1 });
            }
        }
    }

    handleUpvoteSubmit(upvoteAction) {

        let params = {};

        if (upvoteAction) {
            params = {
                team_update_unique_id: this.props.unique_id,
                upvote: true,
            };
        } else {
            params = {
                team_update_unique_id: this.props.unique_id,
                downvote: true,
            };
        }

        const self = this;
        utils.post(`${config.BACKEND_HOST}/all/upvote`, params)
            .then((res) => {
                if (!res.ok) throw "Error with request";
                self.setState(prevState => {
                });
            }).catch(err => console.log(err));
    }

    hideSignup(event) {
        this.setState({showSignUp: false});
    }

    tryImage(img){
        try{return img ? `url('${img}')` : this.gradient}catch(e){return this.gradient;}
    }

    generateImages(){
        if (this.props.newMedia && this.props.images.length > 0) {
            console.log("generate Images", this.props.images);
            return [this.props.images[0].preview, ...(this.props.images || []).map(image => image.preview ? image.preview : utils.image(image))].map(this.tryImage.bind(this));
        }
        return [utils.image(this.props.images[0]), ...(this.props.images || []).map(utils.image)].map(this.tryImage.bind(this));
    }

    generateImgImages(){
        if (this.props.newMedia && this.props.images.length > 0) {
            console.log("generate Images", this.props.images);
            return [this.props.images[0].preview, ...(this.props.images || []).map(image => image.preview ? image.preview : utils.image(image))];
        }
        return [utils.image(this.props.images[0]), ...(this.props.images || []).map(utils.image)];
    }

    handleHover({clientX, target, ...ev}){
        let {images} = this.props;
        if (!images || images.length === 0){
            return;
        }
        const {top, left} = target.getBoundingClientRect();
        const width = target.clientWidth;
        const delta = Math.floor(width / images.length);
        // const y = clientY - top;
        const x = Math.max(0,clientX - left);
        const index = Math.min(Math.floor(x / delta), images.length - 1);

        this.setState({
            index,
            background: this.tryImage(utils.image(images[index]))
        });
    }

    handleMouseLeave(){
        this.setState({
            index: -1,
            background: this.initialBackground
        });
    }

    renderUpdateImage() {
        let {id = undefined, media__media_link, images  = []} = this.props;

        return(
            <div className="update-img-container" onMouseMove={this.handleHover.bind(this)}
                        onMouseLeave={this.handleMouseLeave.bind(this)}>

                {this.images.map(((img_, i) => (
                        <img className="update-img" src={img_} style={{display: i === this.state.index + 1 ? 'inherit':'none'}}/>
                    )).bind(this))}
            </div>
        );
    }

    render() {
        let {
            notUser,
            newUpdate,
            date,
            images,
            description,
            team__name,
            team__unique_id,
            team__profile_img,
            team__images,
            tags,
            media__media_link = "",
            member__first_name,
            member__last_name,
            member__userprofile__unique_id,
            member__media__media_link,
            unique_id,
            viewed,
            commits = [],
            comments = [],
            isUpload = false,
        } = this.props;

        let picture = "";
        if (newUpdate === true) {
            picture = media__media_link;
        } else {
            picture = utils.prefixImage(media__media_link);
        }
        let teamImage = team__profile_img ? utils.prefixImage(team__profile_img) : (team__images && team__images.length > 0 ? utils.prefixImage(team__images[0].media_link) : null);

        let teamImageStyle = {
            backgroundImage: `url(${teamImage})`,
            backgroundSize: "cover",
            width: "28px",
            height: "28px",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            marginRight: "6px",
            marginTop: "-4px",
            borderRadius: "2px",
        }


        let tagslist = [];
        let branches = [];

        if (newUpdate === true) {
            tagslist = tags.map(tag => {
                return <div className="tag active" style={{backgroundColor: `${utils.getTagColor(tag.unique_id)}` }}>
                    {tag.name}
                </div>
            })

            commits.forEach(
                commit => {
                    if (branches.includes(commit.branch_name)) {
                    } else {
                        branches.push(commit.branch_name);
                    }
                }
            );
        } else {
            tagslist = tags.map(tag => {
                return <div className="tag active" style={{backgroundColor: `${utils.getTagColor(tag.tag__unique_id)}` }}>
                    {tag.tag__name}
                </div>
            })

            commits.forEach(
                commit => {
                    if (branches.includes(commit.commit__branch_name)) {
                    } else {
                        branches.push(commit.commit__branch_name);
                    }
                }
            );
        }

        let text = description.replace(/```/g, "`").split('`');

        for (let i = 1; i < text.length - 1; i += 2) {
            text[i] = <div className="code"><code>{text[i]}</code></div>;
        }
        for (let i = 0; i < text.length; i += 2) {
            text[i] = text[i].trim();
        }

        return (
            <div id={`update-${unique_id}`} className="activity-card">

                <div className="sticky">
                    <div className="sticky-content">
                        <Link to={`/creators/${member__userprofile__unique_id}`}>
                            <UpdaterIcon id={member__userprofile__unique_id} firstName={member__first_name} lastName={member__last_name} image={member__media__media_link}/>
                        </Link>

                        <div className="clap-container">

                            {this.state.showSignUp ? <PleaseSignUp text="clap updates"/> : null}
                            {this.state.showSignUp ? <div className="click-off-signup" onClick={(event) => this.hideSignup(event)}/> : null}

                            <div className="clap" onClick={() => this.metricClick()}>

                                {this.state.metricActive ?

                                <svg class="svgIcon" width="29" height="29" ><g fill-rule="evenodd"><path d="M13.738 1l.762 2.966L15.262 1z"/><path d="M18.634 2.224l-1.432-.47-.408 3.022z"/><path d="M11.79 1.754l-1.431.47 1.84 2.552z"/><path d="M24.472 14.307l-3.023-5.32c-.287-.426-.689-.705-1.123-.776a1.16 1.16 0 0 0-.911.221c-.297.231-.474.515-.535.84.017.022.036.04.053.063l2.843 5.001c1.95 3.564 1.328 6.973-1.843 10.144a8.46 8.46 0 0 1-.549.501c1.205-.156 2.328-.737 3.351-1.76 3.268-3.268 3.041-6.749 1.737-8.914"/><path d="M14.58 10.887c-.156-.83.096-1.569.692-2.142L12.78 6.252c-.5-.504-1.378-.504-1.879 0-.178.18-.273.4-.329.63l4.008 4.005z"/><path d="M17.812 10.04c-.218-.323-.539-.55-.88-.606a.814.814 0 0 0-.644.153c-.176.137-.713.553-.24 1.566l1.43 3.025a.539.539 0 1 1-.868.612L9.2 7.378a.986.986 0 1 0-1.395 1.395l4.401 4.403a.538.538 0 1 1-.762.762L7.046 9.54 5.802 8.295a.99.99 0 0 0-1.396 0 .981.981 0 0 0 0 1.394l1.241 1.241 4.402 4.403a.537.537 0 0 1 0 .761.535.535 0 0 1-.762 0L4.89 11.696a.992.992 0 0 0-1.399-.003.983.983 0 0 0 0 1.395l1.855 1.854 2.763 2.765a.538.538 0 0 1-.76.761l-2.765-2.764a.982.982 0 0 0-1.395 0 .989.989 0 0 0 0 1.395l5.32 5.32c3.371 3.372 6.64 4.977 10.49 1.126C21.74 20.8 22.271 18 20.62 14.982l-2.809-4.942z"/></g></svg>
                                :
                                <svg class="svgIcon" width="29" height="29" ><g fill-rule="evenodd"><path d="M13.739 1l.761 2.966L15.261 1z"/><path d="M16.815 4.776l1.84-2.551-1.43-.471z"/><path d="M10.378 2.224l1.84 2.551-.408-3.022z"/><path d="M22.382 22.622c-1.04 1.04-2.115 1.507-3.166 1.608.168-.14.332-.29.492-.45 2.885-2.886 3.456-5.982 1.69-9.211l-1.101-1.937-.955-2.02c-.315-.676-.235-1.185.245-1.556a.836.836 0 0 1 .66-.16c.342.056.66.28.879.605l2.856 5.023c1.179 1.962 1.379 5.119-1.6 8.098m-13.29-.528l-5.02-5.02a1 1 0 0 1 .707-1.701c.255 0 .512.098.707.292l2.607 2.607a.442.442 0 0 0 .624-.624L6.11 15.04l-1.75-1.75a.998.998 0 1 1 1.41-1.413l4.154 4.156a.44.44 0 0 0 .624 0 .44.44 0 0 0 0-.624l-4.152-4.153-1.172-1.171a.998.998 0 0 1 0-1.41 1.018 1.018 0 0 1 1.41 0l1.172 1.17 4.153 4.152a.437.437 0 0 0 .624 0 .442.442 0 0 0 0-.624L8.43 9.222a.988.988 0 0 1-.291-.705.99.99 0 0 1 .29-.706 1 1 0 0 1 1.412 0l6.992 6.993a.443.443 0 0 0 .71-.501l-1.35-2.856c-.315-.676-.235-1.185.246-1.557a.85.85 0 0 1 .66-.16c.342.056.659.28.879.606L20.628 15c1.573 2.876 1.067 5.545-1.544 8.156-1.396 1.397-3.144 1.966-5.063 1.652-1.713-.286-3.463-1.248-4.928-2.714zM12.99 6.976l2.562 2.562c-.497.607-.563 1.414-.155 2.284l.265.562-4.257-4.257a.98.98 0 0 1-.117-.445c0-.267.104-.517.292-.706a1.023 1.023 0 0 1 1.41 0zm8.887 2.06c-.375-.557-.902-.916-1.486-1.011a1.738 1.738 0 0 0-1.342.332c-.376.29-.61.656-.712 1.065a2.1 2.1 0 0 0-1.095-.562 1.776 1.776 0 0 0-.992.128l-2.636-2.636a1.883 1.883 0 0 0-2.658 0 1.862 1.862 0 0 0-.478.847 1.886 1.886 0 0 0-2.671-.012 1.867 1.867 0 0 0-.503.909c-.754-.754-1.992-.754-2.703-.044a1.881 1.881 0 0 0 0 2.658c-.288.12-.605.288-.864.547a1.884 1.884 0 0 0 0 2.659l.624.622a1.879 1.879 0 0 0-.91 3.16l5.019 5.02c1.595 1.594 3.515 2.645 5.408 2.959a7.16 7.16 0 0 0 1.173.098c1.026 0 1.997-.24 2.892-.7.279.04.555.065.828.065 1.53 0 2.969-.628 4.236-1.894 3.338-3.338 3.083-6.928 1.738-9.166l-2.868-5.043z"/></g></svg> }

                                <p>{this.state.metricNumber}</p>

                            </div>
                        </div>

                        <div className="upvoter-list">
                            {this.state.upvoterList.slice(0,3).map(upvoter =>
                                <Link to={`/creators/${upvoter.user__userprofile__unique_id}`}>
                                    <div className="upvoter-image">
                                        <img src={utils.prefixImage(upvoter.user__media__media_link)}/>
                                    </div>
                                </Link>
                            )}
                            {this.state.upvoterList.length > 3 ? "..." : null}
                        </div>
                    </div>
                </div>

                {viewed === false ? <div className="unread-update-bar"/> : null}

                <div className="activity-content-top-bar">
                    <div className="details h-centred split">
                        <div className="member-info">

                            <Link to={`/creators/${member__userprofile__unique_id}`} className="posted-by">{`${member__first_name} ${member__last_name}`}</Link>
                            {this.props.isMini ?
                                <p className="updated-by">updated {team__name}</p>
                                :
                                <p className="updated-by">updated
                                    <Link to={`/project/${team__unique_id}`}>
                                        {teamImage ? <div classname="team-image" style={teamImageStyle}/> : null}
                                        {team__name}
                                    </Link>
                                </p>
                            }

                        </div>
                        <div className="date">{utils.fromNow(date)}</div>
                    </div>
                    <div className="update-tags">
                        {tagslist}
                    </div>
                </div>


                {commits.length > 0 ?
                    <div className="update-commits" onClick={() => this.setState(prevState => ({ active: !prevState.active }))}>
                        <div className="commit-marker">
                            {commits.length}
                        </div>
                        <div className="commits-text">
                            {commits.length === 1 ? "commit" : "commits"} in&nbsp;
                            {branches.length === 1 ? "branch" : "branches"}
                            {branches.map(branch => <p>{branch}</p>)}
                        </div>
                    </div>
                : null }

                {commits.length > 0 ?
                    <div className={`commits-preview-section ${this.state.active === true ? "active-preview" : ""}`}>
                        <p className="title">Github Commits</p>
                        <GithubCommits commits={commits}/>
                    </div>
                : null}

                {images.length > 0 ?
                    <div>
                        {this.renderUpdateImage()}
                        <div className="hover-zones v-centred">
                             {images.map((_, i) => <span className={i === this.state.index ? "active" : null}/>)}
                        </div>
                    </div>
                : null}

                <Linkify properties={{target: '_blank'}}>
                    <div className="activity-content">
                        <div className="description">
                            {text}
                        </div>
                    </div>
                </Linkify>

                <div className="comment-section-outer">
                    <CommentSection comments={comments} notUser={notUser} updateid={unique_id}/>
                </div>
            </div>
        );
    };
};
export default Update;
