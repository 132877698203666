import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import Tooltip from './Tooltip';

import config from '../config';
import utils from '../utils';

const MemberIcon = props => {

    let {id, name, first, last, imgSrc, className, onClick, orientation, tooltip} = props;

    let firstLetter = !imgSrc && first && first.length > 0 ? first[0].toUpperCase() : "";

    const style = {
        background: imgSrc && !imgSrc.startsWith('linear-gradient') ? `url('${imgSrc}')` : utils.randomGradient(id),
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        cursor: onClick ? "pointer" : null,
    };

    return (
        <div className={`member-icon tooltip-anchor ${className} d-flex justify-content-center align-items-center`} style={style} onClick={onClick ? (() => onClick(id)) : null}>
            {firstLetter}
            {tooltip === "true" ? <Tooltip name={name ? `${name}` : `${first} ${last}`} orientation={orientation}/> : null}
        </div>
    );
}

export default MemberIcon;
