import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";

import CommentSection from './CommentSection';
import MemberIcon from './MemberIcon';

// utils
import utils from '../utils';
import config from '../config';

const rp = require('request-promise-native');


const UpdaterIcon = props => {

    let {id, firstName, lastName, image} = props;
    let media = { media_link: image };

    return (
        <div className="updater-icon">
            <MemberIcon
                className="MemberIcon"
                id={id}
                name={`${firstName} ${lastName}`}
                imgSrc={utils.image(media)}/>
        </div>
    );
};

const Update = ({
    date,
    description,
    media__media_link = "",
    member__first_name,
    member__last_name,
    member__userprofile__unique_id,
    member__media__media_link,
    unique_id,
    viewed,
    comments = [],
    isUpload = false,
}) => {
    let picture = utils.prefixImage(media__media_link);

    return (
        <div className="activity-card">

            <div className="sticky">
                <Link to={`/creators/${member__userprofile__unique_id}`}>
                    <UpdaterIcon id={member__userprofile__unique_id} firstName={member__first_name} lastName={member__last_name} image={member__media__media_link}/>
                </Link>
            </div>

            {viewed === false ? <div className="unread-update-bar"/> : null}

            <div className="activity-content">
                <div className="details h-centred split">
                    <div className="member-info">
                        <Link to={`/creators/${member__userprofile__unique_id}`} className="posted-by">{`${member__first_name} ${member__last_name}`}</Link>
                    </div>
                    <div className="date">{utils.fromNow(date)}</div>
                </div>
                <div className="description">
                    {description}
                </div>
            </div>

            {picture
                ? <img src={picture} alt=""/>
                : null}


            <div className="comment-section">
                <CommentSection comments={comments} updateid={unique_id}/>
            </div>
        </div>
    );
};

const ActivityProfile = ({
    style,
    activity,
    handleUpload,
    handleSubmit,
    ref_,
    user
}) => {

    return (
        <div id="ActivityFeed" className="ActivityFeed" style={style}>

            <div className="activity-stream">
                { <div>{activity.map(Update)}</div> }
            </div>
        </div>
    );
}


class WelcomeBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            pageNumber: 0,
        };

        this.callActivity = this.callActivity.bind(this);
        this.feed = React.createRef();
    }

    componentWillMount() {
        this.callActivity();
    }

    async callActivity() {
        const url = `${config.BACKEND_HOST}/all/activity`;
        const self = this;

        let number = this.state.pageNumber + 1;

        let params = {
            page_number: number,
        }

        await utils.get(url, params)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    self.setState({
                        activity: res.data.activity,
                        pageNumber: number,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    render() {

        if (this.feed.offsetHeight + this.feed.scrollTop >= this.feed.scrollHeight) {
            console.log("DING DING");
            this.callActivity();
        }

        return (
            <section className="full-width-content">
                <div className="banner">
                    <div className="activity-heading">
                        <h2>Here's what your friends have been creating</h2>
                        <button className="btn btn-primary">Add an Update, too</button>
                    </div>
                    <div className="activity-stream-home" ref={this.feed}>
                        <div className="activity-list">

                        <ActivityProfile
                            activity={this.state.activity}
                            {...this.props}
                            {...this.state}/>


                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WelcomeBanner;
