import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const _ = require("lodash");

const InputError = (props) => {
    if (!_.isArray(props.errors) || props.errors.length ===0){
        return null;
    }
    return (
        <ul className="input-error">
            {props.errors.map(err => (<li>{err}</li>))}
        </ul>
    );
}

export default InputError;
