import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";

import MemberIcon from './MemberIcon';
import Question from './Question';
import UnansweredQuestion from '../components/UnansweredQuestion';
import Octicon, {MarkGithub, Gear} from '@primer/octicons-react';
import Linkify from 'react-linkify';
import Modal from '@material-ui/core/Modal';

import config from '../config';
import utils from '../utils';

import moment from 'moment';

const TeamProfileMemberIcon = ({
    member__first_name,
    member__last_name,
    member__media__media_link,
    member__userprofile__unique_id
}) => {

    return (
        <Link to={`/creators/${member__userprofile__unique_id}`}>
            <MemberIcon
                className="MemberIcon"
                id={member__userprofile__unique_id}
                first={member__first_name}
                last={member__last_name}
                imgSrc={utils.prefixImage(member__media__media_link)}
                orientation="above"
                tooltip="true" />
        </Link>
    )
}

const RequestButton = ({hasApplied, applicationStatus, id}) => {
    if (!hasApplied){
        return <Link className="btn btn-primary centred" to={`/project/${id}/join`}>Collaborate</Link>;
    }
    else {
        if (applicationStatus === "Rejected"){
            return (<div className="request-declined">
                <p>Your request was declined</p>
                <Link className="btn btn-primary centred" to={`/project/${id}/join`}>Apply again</Link>
            </div>);
        }
        else if (applicationStatus !== "Accepted"){
            return <div className="btn btn-tertiary centred">Request Pending</div>;
        }
    }
    return null;
}


const UnansweredQuestions = ({
    style,
    questions,
    newQuestions,
    handleUpload,
    handleSubmit,
    userPage,
    user
}) => {

    if (questions === null){
        return <utils.Spinner/>;
    }

    return (
        <div id="ActivityFeed" className="ActivityFeed" style={style}>
            <div className="activity-stream">
                <div>
                    {newQuestions.map(props => <UnansweredQuestion userPage={userPage} new={true} {...props}/>)}
                    {questions.map(props => <UnansweredQuestion userPage={userPage} {...props}/>)}
                </div>
            </div>
        </div>
    );
}


class TeamSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: false,
            openModal: false,
            deletedTeam: false,
            questions: [],
            newQuestions: [],
        };
        this.questionRef = React.createRef();
        this.handleQuestionSubmit = this.handleQuestionSubmit.bind(this);
        this.deleteProjectConfirm = this.deleteProjectConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.fetchUnansweredQuestions = this.fetchUnansweredQuestions.bind(this);
    }

    componentDidMount() {
        this.fetchUnansweredQuestions();
    }

    async fetchUnansweredQuestions() {
        let url = `${config.BACKEND_HOST}/all/unanswered_team_questions`;
        const self = this;
        let params = {
            team_uuid: this.props.team.unique_id
        }

        await utils.get(url, params)
            .then((res) => {
                console.log("questions", res);
                if (res.status){
                    self.setState({
                        questions: res.data.questions,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleQuestionSubmit() {
        const {text} = this.questionRef.current.state;
        this.setState({ postedIdea: true });
        this.questionRef
            .current
            .clear();

        let params = {
            team_uuid: this.props.team.unique_id,
            post_description: text
        };

        const self = this;
        utils.post(`${config.BACKEND_HOST}/teams/post_question`, params)
            .then((res) => {
                if (res.status) {
                    console.log("question submit", res);
                    self.setState({
                        newQuestions: [res.data.team_question]
                    });
                }

            }).catch(err => console.log(err));
    }

    removeShade() {
        var element = document.getElementById("shade");
        element.classList.remove("shade");

        var element = document.getElementById("question");
        element.classList.remove("bring-to-front");
    }

    showAdmin() {
        if (this.state.settings === true) {
            this.setState({ settings: false });
        } else {
            this.setState({ settings: true });
        }
    }

    deleteProjectConfirm() {
        this.setState({openModal: true});
    }

    deleteProject() {

        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/delete_team`;
        const params = {
            userprofile_unique_id: this.props.user.userId,
            team_unique_id: this.props.team.unique_id
        };
        utils
            .post(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    //autofill
                    self.setState({
                        deletedTeam: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });

    }

    handleClose() {
        this.setState({openModal: false});
    }

    render() {

        if (this.state.deletedTeam) {
            setTimeout(() => {
                return (<Redirect to="/activity" />);
            }, 3000);
        }

        let {
            id,
            user,
            team: {
                admin__userprofile__id = "",
                unique_id = "",
                mission = "",
                description = "",
                admin = {},
                github_url = "",
                date_created,
                team_skills = [],
                images = [],
                external_link = "",
                is_recruiting = false,
                name = "",
                profile_img,
            },
            team_members,
            is_current_user_team_page,
            has_applied,
            application_status,
        } = this.props;


        let icons = team_members.map(member => {
            return (TeamProfileMemberIcon(member));
        })

        let team_cover = "";
        if (profile_img) {
            team_cover = utils.prefixImage(profile_img);
        } else if (images.length > 0) {
            team_cover = utils.image(images[0]);
        }

        const normalizeUrl = require('normalize-url');
        let extLink = external_link ? normalizeUrl(external_link) : "";

        let coverStyle = {
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: team_cover.length > 0 ? `url('${team_cover}')` : utils.randomGradient(id),
        }

        return (
            <div className="team-intro">
                <div className="TeamSidebar">

                    <div className="team-cover" style={coverStyle} alt="">
                        {team_cover.length > 0 ? null : <p>{name}</p>}
                    </div>


                    <div className="o-content column">

                        <div className="section members">
                            <h3 className="subheading-label">MEMBERS</h3>
                            <div className="v-centred wrap">
                                {icons}
                            </div>
                        </div>

                        {team_skills.length > 0
                            ? <div className="section">
                                    <div className="v-centred wrap" style={{marginTop: '1px'}}>
                                        <div className="subheading-label">WE'RE USING</div>
                                        {team_skills.map(({skills}) => (
                                            skills.map(({name}) => (
                                                <div className="skill small">{name}</div>
                                            ))
                                        ))}
                                    </div>
                                </div>
                            : null}

                        {is_current_user_team_page
                            ? null
                            :
                            <RequestButton hasApplied={has_applied}
                                applicationStatus={application_status}
                                id={id} />}

                    </div>

                    {/*<div className="team-media">
                        {images.map(img => {
                            return (<img src={utils.image(img)} alt=""/>)
                        })}
                    </div>*/}
                </div>

                <div className="team-text">

                    <div className="v-centred">
                        <h1 id="top-title" className="title">{name}</h1>
                        {github_url && github_url.length > 0 ?
                            <a href={github_url} target="_blank" className="github-link">
                                <Octicon className="icon" size="medium" icon={MarkGithub}/>
                            </a>
                            : null}
                        {admin__userprofile__id === user.id ?
                            <div className="admin">
                                Admin
                                {"\u00a0\u00a0"}
                                <div onClick={() => this.showAdmin()}>
                                    <Octicon className="icon" icon={Gear}/>
                                </div>
                                {this.state.settings ?
                                    <div className="editing-menu">
                                        <Link className="edit-project" to={`/edit-team/0/${unique_id}`}>Edit Project</Link>
                                        <div className="delete-project" onClick={() => this.deleteProjectConfirm()}>Delete Project</div>
                                    </div>
                                :
                                null}
                            </div>
                        : null }
                    </div>

                    <Modal className="PortfolioModal" open={this.state.openModal} onClose={() => this.handleClose()}>
                        <div className="centred">

                            <div className="close" onClick={() => this.handleClose()}>
                                <span className="highlight fas fa-times"/>
                            </div>

                            <div className="modal-card modal-card-centered-text">
                                {this.state.deletedTeam ?
                                    <div className="successfully-deleted">Team successfully deleted!</div>
                                    :
                                    <div className="modal-card-content delete-project-modal">
                                        <h2>Are you sure you want to delete this project?</h2>
                                        <p>Once it is deleted, there is no way to recover the data or progress you have made</p>
                                        <div className="buttons">
                                            <button className="btn btn-primary confirm" onClick={() => this.deleteProject()}>Yes 🤩</button>
                                            <button className="btn btn-secondary cancel" onClick={() => this.handleClose()}>Hm maybe not 🤔</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal>

                    <div className="mission">
                        <div className="mission-content">
                            {mission}
                        </div>
                    </div>

                    <Linkify properties={{target: '_blank'}}>
                        <div className="mission-description">
                            {description}
                        </div>
                    </Linkify>

                    {is_current_user_team_page
                        ? null
                        : <Question handleQuestionSubmit={this.handleQuestionSubmit} ref={this.questionRef}/>}

                    <div className="unanswered-team-questions">
                        <UnansweredQuestions
                            questions={this.state.questions}
                            newQuestions={this.state.newQuestions}
                            userPage={is_current_user_team_page}
                            {...this.props}
                            {...this.state}/>
                    </div>

                    <div id="shade" className="shadeoff" onClick={() => this.removeShade()}></div>
                </div>
            </div>
        )
    }
}

export default TeamSidebar;
