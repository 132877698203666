import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import config from "../../config.js";
import utils from "../../utils.js";
import Badge from "../Badge.js";
import SkillsMenu from '../SkillsMenu.jsx';

const uniqueString = require('unique-string');
const _ = require("lodash");
const scss = config.SCSS;
const selectStyles = config.selectStyles;
const pIndex = 2;


export default class View2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            submitSuccess: false,
            skillBuckets: [],
        };
        this.handleBucketClick = this
            .handleBucketClick
            .bind(this);
        this.handleSkillClick = this
            .handleSkillClick
            .bind(this);
        this.addSkill = this
            .addSkill
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);

        this.getData();
    }
    getData() {
        const self = this;
        const url = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
        console.log(url);
        utils
            .get(url)
            .then(res => {
                console.log(res);
                if (res.status) {
                    let {skills, all_skill_buckets} = res.data;
                    self.setState({
                        skillBuckets: all_skill_buckets
                            .filter(bucket => !bucket.hidden)
                            .map(bucket => {
                                let hasActiveSkill = false;
                                bucket.id = bucket.unique_id;
                                bucket.skills = bucket
                                    .skills
                                    .filter(skill => !skill.hidden && (!skill.is_user_added || skills.indexOf(skill.id) >= 0)) // not hidden AND (if user created, display if user has that skill)
                                    .map(skill => {
                                        skill.key = skill.id;
                                        skill.label = skill.name;
                                        skill.value = skill
                                            .id
                                            .toString();
                                        skill.active = skills.indexOf(skill.id) >= 0;
                                        hasActiveSkill = hasActiveSkill || skill.active;
                                        return skill;
                                    });
                                bucket.active = hasActiveSkill;
                                return bucket;
                            })
                    });
                }
            })
            .catch(err => {
                console.log(err.message);
                //try again?
            });
    }
    handleBucketClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (id == bucket.id) {
                        bucket.active = !bucket.active;
                    }
                    return bucket;
                });
            return prevState;
        });
    }
    handleSkillClick(id) {
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    bucket.skills = bucket.skills.map(skill => {
                        if (id == skill.id) {
                            skill.active = !skill.active;
                        }
                        return skill;
                    });
                    return bucket;
                });
            return prevState;
        });
    }
    addSkill(name, bucketId){
        this.setState(prevState => {
            prevState.skillBuckets = prevState
                .skillBuckets
                .map(bucket => {
                    if (bucket.id === bucketId){
                        bucket.skills.push({
                            id: uniqueString(),
                            name: name,
                            active: true,
                            isNew: true,
                        });
                    }
                    return bucket;
                });
            return prevState;
        });

        const self = this;
        const url = `${config.BACKEND_HOST}/all/add_skill`;
        const params = {
            add_skill: true,
            new_skill: name,
            bucket_uuid: bucketId,
        };
        utils
            .get(url, params)
            .then(res => {
                console.log(res);
                if (res.status) {
                    self.setState(prevState => {
                        prevState.skillBuckets = prevState
                            .skillBuckets
                            .map(bucket => {
                                if (bucket.id === bucketId){
                                    bucket.skills = bucket.skills.map(skill => {
                                        if (skill.name === name){
                                            skill.id = res.data.skill_id;
                                        }
                                        return skill;
                                    });
                                }
                                return bucket;
                            });
                        return prevState;
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //try again?
            });
    }
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.submitEnabled) {
            this.setState({submitEnabled: false});

            let skillIds = [];
            this
                .state
                .skillBuckets
                .filter(bucket => bucket.active)
                .forEach(bucket => {
                    skillIds = _.concat(skillIds, bucket.skills.filter(skill => skill.active && _.isInteger(skill.id)).map(skill => skill.id));
                });

            const self = this;
            const url = `${config.BACKEND_HOST}/accounts/portfolio_skills`;
            const params = {
                selected_skills: skillIds
            };
            utils
                .post(url, params)
                .then(res => {
                    console.log(res);
                    if (res.status) {
                        self.setState({submitSuccess: true});
                    }
                })
                .catch(err => {
                    console.log(err.message);
                    self.setState({submitEnabled: true});
                    //try again?
                });
        }
    }
    render() {
        if (this.state.submitSuccess) {
            return <Redirect to={(pIndex + 1).toString()} push />;
        }
        return (
            <div className="col-sm-10 col-md-8 mx-auto">
                <form action={(pIndex + 1).toString()} onSubmit={this.handleSubmit}>

                    <div className="col">

                        <div className="form-row no-margin">
                            <h2 className="create-profile-headline float-left">Skills</h2>
                        </div>
                        <SkillsMenu
                            skillBuckets={this.state.skillBuckets}
                            handleBucketChange={this.handleBucketClick}
                            handleSkillChange={this.handleSkillClick}
                            addSkill={this.addSkill} />

			            <div className="form-row no-margin">
                            <button name="submit" type="submit" className="btn btn-primary">Next</button>
                        </div>
			            <div className="form-row">
                            <Link to={(pIndex + 1).toString()} className="small-link">Skip</Link>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
