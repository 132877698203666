import React, { Component } from 'react';
import {HashLink as Link} from "react-router-hash-link";

import img from '../media/frontend.jpg';

// utils
import utils from '../utils';
import config from '../config';

const Clap = () => {
    return (
        <svg class="svgIcon" className="clap" width="20px" height="20px" viewBox="0 0 30 30"><g fill-rule="evenodd"><path d="M13.738 1l.762 2.966L15.262 1z"/><path d="M18.634 2.224l-1.432-.47-.408 3.022z"/><path d="M11.79 1.754l-1.431.47 1.84 2.552z"/><path d="M24.472 14.307l-3.023-5.32c-.287-.426-.689-.705-1.123-.776a1.16 1.16 0 0 0-.911.221c-.297.231-.474.515-.535.84.017.022.036.04.053.063l2.843 5.001c1.95 3.564 1.328 6.973-1.843 10.144a8.46 8.46 0 0 1-.549.501c1.205-.156 2.328-.737 3.351-1.76 3.268-3.268 3.041-6.749 1.737-8.914"/><path d="M14.58 10.887c-.156-.83.096-1.569.692-2.142L12.78 6.252c-.5-.504-1.378-.504-1.879 0-.178.18-.273.4-.329.63l4.008 4.005z"/><path d="M17.812 10.04c-.218-.323-.539-.55-.88-.606a.814.814 0 0 0-.644.153c-.176.137-.713.553-.24 1.566l1.43 3.025a.539.539 0 1 1-.868.612L9.2 7.378a.986.986 0 1 0-1.395 1.395l4.401 4.403a.538.538 0 1 1-.762.762L7.046 9.54 5.802 8.295a.99.99 0 0 0-1.396 0 .981.981 0 0 0 0 1.394l1.241 1.241 4.402 4.403a.537.537 0 0 1 0 .761.535.535 0 0 1-.762 0L4.89 11.696a.992.992 0 0 0-1.399-.003.983.983 0 0 0 0 1.395l1.855 1.854 2.763 2.765a.538.538 0 0 1-.76.761l-2.765-2.764a.982.982 0 0 0-1.395 0 .989.989 0 0 0 0 1.395l5.32 5.32c3.371 3.372 6.64 4.977 10.49 1.126C21.74 20.8 22.271 18 20.62 14.982l-2.809-4.942z"/></g></svg>
    );
}

class Notification extends Component {
    render() {

        let {
            team_profile_img,
            type,
            max_date
        } = this.props.notification;


        let text = "";
        let link = "";
        let img = team_profile_img ? `url('${utils.prefixImage(team_profile_img)}')` : utils.randomGradient(type);
        let date = utils.fromNow(max_date);

        if (type === "ad_hoc") {

            //AD HOC
            let {
                description
            } = this.props.notification;

            text = description;

        } else if (type === "team_question") {

            //TEAM QUESTION
            let {
                team_question__unique_id,
                team_question__team__unique_id,
                team_question__team__name,
                team_question__description
            } = this.props.notification;

            text = <div>{`New question`}<br/>{` "${utils.truncateTo(team_question__description || "", 70)}"`}</div>;
            link = `/project/${team_question__team__unique_id}#question-${team_question__unique_id}`;

        } else if (type === "team_update") {

            //TEAM UPDATE
            let {
                team_update__unique_id,
                team_update__team__unique_id,
                team_update__team__name,
                team_update__description
            } = this.props.notification;

            text = <div>{`New update in ${team_update__team__name}`}<br/>{`"${utils.truncateTo(team_update__description || "", 70)}"`}</div>;
            link = `/project/${team_update__team__unique_id}#update-${team_update__unique_id}`;

        } else if (type === "team_update__answer") {

            //TEAM UPDATE: ANSWER
            let {
                team_update__unique_id,
                team_update__team__unique_id,
                team_update__team__name,
                team_update__description,
                team_update__team_question__unique_id,
                team_update__team_question__description
            } = this.props.notification;

            text = <div>{`Question answered in ${team_update__team__name}`}<br/>{`"${utils.truncateTo(team_update__team_question__description || "", 70)}"`}</div>;
            link = `/project/${team_update__team__unique_id}#update-${team_update__unique_id}`;

        } else if (type === "comment__team_update") {

            //TEAM UPDATE: COMMENT
            let {
                comment__team_update__unique_id,
                comment__team_update__team__unique_id,
                comment__team_update__description,
                comment__team_update__team__name,
                count
            } = this.props.notification;

            text = count > 1 ? <div>{`${count} New comments on update `}<br/>{` "${utils.truncateTo(comment__team_update__description || "", 70)}"`}</div> : <div>{`New comment on update `}<br/>{` "${utils.truncateTo(comment__team_update__description || "", 80)}"`}</div>;
            link = `/project/${comment__team_update__team__unique_id}#update-${comment__team_update__unique_id}`;

        } else if (type === "comment__team_question") {

            //TEAM QUESTION: COMMENT
            let {
                comment__team_question__unique_id,
                comment__team_question__team__unique_id,
                comment__team_question__team__name,
                comment__team_question__description,
                count
            } = this.props.notification;

            text = count > 1 ? <div>{`${count} New comments on question `}<br/>{` "${utils.truncateTo(comment__team_question__description || "", 70)}"`}</div> : <div>{`New comment on question `}<br/>{` "${utils.truncateTo(comment__team_question__description || "", 80)}"`}</div>;
            link = `/project/${comment__team_question__team__unique_id}#question-${comment__team_question__unique_id}`;

        } else if (type === "comment__portfolio_project") {

            //PORTFOLIO PROJECT: COMMENT
            let {
                project_profile_img,
                comment__portfolio_project__unique_id,
                comment__portfolio_project__title,
                count
            } = this.props.notification;

            text = count > 1 ? `${count} New comments on ${comment__portfolio_project__title}` : `New comment on ${comment__portfolio_project__title}`;
            link = `/projects/completedprojects#project-${comment__portfolio_project__unique_id}`;
            img = project_profile_img ? `url('${utils.prefixImage(project_profile_img)}')` : utils.randomGradient(type);

        } else if (type === "upvote__team_update") {

            //TEAM UPDATE: UPVOTE
            let {
                upvote__team_update__unique_id,
                upvote__team_update__team__unique_id,
                upvote__team_update__team__name,
                upvote__team_update__description,
                count
            } = this.props.notification;

            text = <div>{`${count} New `}<Clap/>{` on your update`}</div>;
            link = `/project/${upvote__team_update__team__unique_id}#update-${upvote__team_update__unique_id}`;

        } else if (type === "upvote__team_question") {

            //TEAM QUESTION: UPVOTE
            let {
                upvote__team_question__unique_id,
                upvote__team_question__team__unique_id,
                upvote__team_question__team__name,
                upvote__team_question__description,
                count
            } = this.props.notification;

            text = count > 1 ? <div>{`${count} New `}<Clap/>{` on the question `}<br/>{` "${utils.truncateTo(upvote__team_question__description || "", 70)}"`}</div> : <div>{`New `}<Clap/>{` on the question `}<br/>{` "${utils.truncateTo(upvote__team_question__description || "", 80)}"`}</div>;
            link = `/project/${upvote__team_question__team__unique_id}#question-${upvote__team_question__unique_id}`;

        } else if (type === "upvote__comment__team_update") {

            //TEAM UPDATE: COMMENT: UPVOTE
            let {
                upvote__comment__team_update__unique_id,
                upvote__comment__team_update__team__unique_id,
                upvote__comment__description,
                count
            } = this.props.notification;

            text = count > 1 ? <div>{`${count} New `}<Clap/>{` on your comment `}<br/>{` "${utils.truncateTo(upvote__comment__description || "", 70)}"`}</div> : <div>{`New `}<Clap/>{` on your comment `}<br/>{` "${utils.truncateTo(upvote__comment__description || "", 80)}"`}</div>;
            link = `/project/${upvote__comment__team_update__team__unique_id}#update-${upvote__comment__team_update__unique_id}`;

        } else if (type === "upvote__comment__team_question") {

            //TEAM QUESTION: COMMENT: UPVOTE
            let {
                upvote__comment__team_question__unique_id,
                upvote__comment__team_question__team__unique_id,
                upvote__comment__team_question__team__name,
                upvote__comment__description,
                count
            } = this.props.notification;

            text = count > 1 ? <div>{`${count} New `}<Clap/>{` on your comment `}<br/>{` "${utils.truncateTo(upvote__comment__description || "", 70)}"`}</div> : <div>{`New `}<Clap/>{` on your comment `}<br/>{` "${utils.truncateTo(upvote__comment__description || "", 80)}"`}</div>;
            link = `/project/${upvote__comment__team_question__team__unique_id}#question-${upvote__comment__team_question__unique_id}`;

        } else if (type === "upvote__comment__portfolio_project") {

            //PORTFOLIO PROJECT: COMMENT: UPVOTE
            let {
                project_profile_img,
                upvote__comment__portfolio_project__unique_id,
                upvote__comment__portfolio_project__title,
                upvote__comment__description,
                count
            } = this.props.notification;

            text = count > 1 ? <div>{`${count} New `}<Clap/>{`on your comment on `}<br/>{` ${upvote__comment__portfolio_project__title}`}</div> : <div>{`New `}<Clap/>{`on your comment on `}<br/>{` ${upvote__comment__portfolio_project__title}`}</div>;
            link = `/projects/completedprojects#project-${upvote__comment__portfolio_project__unique_id}`;
            img = project_profile_img ? `url('${utils.prefixImage(project_profile_img)}')` : utils.randomGradient(type);

        }

        let iconStyle = {
            backgroundImage: img,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
        }

        return (
            <Link scroll={el => el.scrollIntoView({ block: 'center', behavior: 'smooth' })} to={link}>
                {type === "ad_hoc" ?
                    <div className="notification adhoc">
                        <div className="notification-content">
                            {text}
                            <div className="date">
                                {date}
                            </div>
                        </div>
                    </div>

                :
                    <div className="notification">
                        <div className="icon" style={iconStyle}/>
                        <div className="notification-content">
                            {text}
                            <div className="date">
                                {date}
                            </div>
                        </div>
                    </div>
                }
            </Link>
        );
    }
}

class NotificationCenter extends Component {

    constructor(props){
        super(props);
        this.state = {
            notifications: [],
            newNotifs: [],
        }
    }

    componentDidMount() {
        this.getNotifications();
    }

    getNotifications() {
        const url = `${config.BACKEND_HOST}/all/notifications`;
        const self = this;

        utils.get(url)
            .then((res) => {
                if (res.status){
                    console.log(res);
                    let newList = res.data.notifications.shift();
                    self.setState({
                        notifications: res.data.notifications,
                        newNotifs: newList
                     });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {

        let {notifications, newNotifs} = this.state;

        return (
            <div className="notification-center">
                <div className="title">
                    <p>Notifications</p>
                    {/*<div className="manage">
                        <p>Mark All as Read</p>
                    </div>*/}
                </div>

                {newNotifs.length > 0 ?
                    <div className="notification-list">
                        <div className="new">
                            <div className="section-title">
                                New ✨
                            </div>

                            {newNotifs.map(notification => <Notification notification={notification}/>)}
                        </div>

                        {notifications.length > 0 ? <div className="earlier">
                            <div className="section-title">
                                Earlier
                            </div>
                            {notifications.map(notificationArray => notificationArray.map(notification => <Notification notification={notification}/>))}
                        </div>
                        :
                        null}
                    </div> :
                    <div className="notification-list">
                        <div className="no-notifications">No Notifications!</div>
                    </div>
                }
            </div>
        );
    }
}

export default NotificationCenter;
