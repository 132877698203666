// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect, withRouter} from 'react-router-dom';

import config from '../config';
import '../css/Login.css';
import InputError from "../components/InputError.jsx";
import logolarge from "../media/logo_name_large.png";

const _ = require("lodash");
const v = require("validator");

class LoginMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitEnabled: true,
			submitSuccess: false,
			email: "",
			emailErrors: [],
			password: "",
			passwordErrors: []
		};

		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
            // [event.target.name + "Errors"]: []
        });
    }
	validateInput(){
        const {email, password} = this.state;
        if (!v.isEmail(email)){
            let emailErrors = ["Please enter a valid email"];
            this.setState({emailErrors});
            return false;
        }
        return true;
    }

	async handleSubmit(event){
		event.preventDefault();
		this.setState({emailErrors: [], passwordErrors: []});
		if (this.state.submitEnabled && this.validateInput()){
			this.setState({submitEnabled: false});
			const email = this.state.email.toLowerCase();
			const pwd = this.state.password;

			if(!await this.props.login(email, pwd)){
				//get errors
				this.setState({
					submitEnabled: true,
					passwordErrors: ["The email or password you entered is incorrect. Please try again."]
				});
			}
		}
	}

    render() {
		if (this.props.loggedIn){
			let {next} = this.props;
			if (next ==='/login'){
				next = '/';
			}
			return <Redirect to={next}/>
		}

        return (
            <div className="login-menu">
                <div className="highlight"></div>

                <div className="login-content">
                    <h1 className="heading1">Login</h1>

                    <form id="login-form" action="/login" onSubmit={this.handleSubmit} noValidate autoComplete="off">

                        <div className="form-row">
                            <input className={"input input-add-outline " + ((this.state.emailErrors.length > 0 || this.state.passwordErrors.length > 0) ? "error" : "")} name="email" type="email" value={this.state.email} placeholder="Email" onChange={this.handleChange} />
                            <InputError errors={this.state.emailErrors} />
                        </div>

                        <div className="form-row">
                            <input className={"input input-add-outline " + (this.state.passwordErrors.length > 0 ? "error" : "")} type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.handleChange} />
							<InputError errors={this.state.passwordErrors} />
                        </div>

                        <button name="submit" type="submit" className="btn btn-primary mx-auto">Login</button>

                        <div className="form-row">
                            <Link to="/forgot-password/0" className="small-link mx-auto">Forgot your password?</Link>
                        </div>

                    </form>
                </div>
			</div>
        );
    }
}

export default withRouter(LoginMenu);
