// Required Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect, withRouter} from 'react-router-dom';

import '../css/Login.css';
import InputError from "../components/InputError.jsx";
import GitHubLogin from 'react-github-login';
import Octicon, {Octoface, MarkGithub} from '@primer/octicons-react';

import logolarge from "../media/logo_name_large.png";
import utils from '../utils';
import config from '../config';

const _ = require("lodash");
const v = require("validator");

class LoginView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitEnabled: true,
			submitSuccess: false,
			email: "",
			emailErrors: [],
			password: "",
			passwordErrors: [],
            githubStatus: null,
		};

		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.GithubSuccess = this.GithubSuccess.bind(this);
        this.GithubFail = this.GithubFail.bind(this);
        this.GithubLogin = this.GithubLogin.bind(this);
	}

	handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
            // [event.target.name + "Errors"]: []
        });
    }
	validateInput(){
        const {email, password} = this.state;
        if (!v.isEmail(email)){
            let emailErrors = ["Please enter a valid email"];
            this.setState({emailErrors});
            return false;
        }
        return true;
    }

	GithubSuccess(response) {
        this.setState({
            githubStatus: true
        });

        const url = `${config.BACKEND_HOST}/accounts/github_repos_data_collect`;
        const params = {
            github_code: response.code,
        }
        const self = this;

        utils.post(url, params)
            .then((res) => {
                console.log(res);

                if (res.status && res.token) {
                    if (this.props.signUpLogin(res.token)) {
                        window.location = `http://jouncer.com/activity`;
                    }
                }
                throw "Response status false";
            })
            .catch((err) => {
                console.log(err);
                self.setState({submitEnabled: true});
                //error logic
            });
    }

    GithubFail(response) {
        console.log(response);
        this.setState({githubStatus: false});
    }

	GithubLogin() {

        const onSuccess = response => this.GithubSuccess(response);
        const onFailure = response => this.GithubFail(response);

        return (
            <div className="github-signup-text-login">
                <div className="authorize-github-login">
                    <Octicon className="icon" size='medium' icon={MarkGithub}/>
                    <GitHubLogin
                        className="button"
                        clientId="5d294ab362a4f18f3352"
                        redirectUri=""
                        scope="read:user user:email user:follow"
                        buttonText="GitHub"
                        onSuccess={onSuccess}
                        onFailure={onFailure}/>
                </div>
            </div>
        );
    }

	async handleSubmit(event){
		event.preventDefault();
		this.setState({emailErrors: [], passwordErrors: []});
		if (this.state.submitEnabled && this.validateInput()){
			this.setState({submitEnabled: false});
			const email = this.state.email.toLowerCase();
			const pwd = this.state.password;

			if(!await this.props.login(email, pwd)){
				//get errors
				this.setState({
					submitEnabled: true,
					passwordErrors: ["Something wasn't quite right"]
				});
			}
		}
	}

    render() {
		if (this.props.loggedIn){
			let {next} = this.props;
			if (next ==='/login'){
				next = '/';
			}
			return <Redirect to={next}/>
		}

        return (
            <div className="login login-main tandem-main d-flex flex-column justify-content-center">

										<div className="login-intro row justify-content-center align-items-center">
				                <img className="home-logo" src={logolarge} />
				                <p className="home-heading">The greatest accomplishments take more than a single person</p>
		                </div>

                <div className="row d-flex justify-content-center align-items-center">
                    <div className="login-box tandem-major d-flex align-items-center">
                        <div className="highlight"></div>

                        <div className="login-content">
                            <h1 className="heading1">Login</h1>
                            <p className="heading2 gray">Welcome Back!</p>

							{this.GithubLogin()}

                            <form id="login-form" action="/login" onSubmit={this.handleSubmit} noValidate autoComplete="off">

																<div className="login-inputs">
	                                <div className="form-row">
	                                    <input className={"input input-add-outline " + ((this.state.emailErrors.length > 0 || this.state.passwordErrors.length > 0) ? "error" : "")} name="email" type="email" value={this.state.email} placeholder="Email" onChange={this.handleChange} />
	                                    <InputError errors={this.state.emailErrors} />
	                                </div>

	                                <div className="form-row mb-25">
	                                    <input className={"input input-add-outline " + (this.state.passwordErrors.length > 0 ? "error" : "")} type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.handleChange} />
																			<InputError errors={this.state.passwordErrors} />
	                                </div>
																</div>

                                <div className="form-row mb-20">
                                    <button name="submit" type="submit" className="btn btn-primary mx-auto">Login</button>
                                </div>
                                <div className="form-row">
                                    <Link to="/forgot-password/0" className="small-link mx-auto">Forgot your password?</Link>
                                </div>

								<div className="signup-link">
									<p>Don't have an account?</p>
									<Link to="/signup/0" className="signup-p">Sign Up</Link>
								</div>

                            </form>
                        </div>
                    </div>

					{/*<div className="login-box tandem-minor d-flex justify-content-center align-items-center">
						<div className="col">
							<p className="heading1">Have an invite code?</p>
							<Link to="/signup/0" className="btn btn-secondary">Sign Up</Link>
						</div>
					</div>*/}

                </div>

            </div>

        );
    }
}



export default withRouter(LoginView);
